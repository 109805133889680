import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { UserContactsApiService } from '../user-contacts-api/user.contacts.api.service';
import { getErrorMessage } from 'src/app/helper/getErrorMessage';
import { UserContactPdsEntry } from 'src/app/models/contact/userContactPdsEntry';
import { UserContactData } from 'src/app/models/contact/userContactData';

@Injectable({ providedIn: 'root' })
export class UserContactsStateService implements OnDestroy {
  // PROPERTIES

  private _userContactsSource: UserContactPdsEntry[] = [];
  private _userContacts$: BehaviorSubject<UserContactPdsEntry[]> =
    new BehaviorSubject<UserContactPdsEntry[]>(this._userContactsSource);

  private destroy$: Subject<void> = new Subject<void>();

  private _loading$ = new BehaviorSubject<boolean>(false);
  private _loadingError$ = new BehaviorSubject<string>('');

  private _saving$ = new BehaviorSubject<boolean>(false);
  private _savingError$ = new BehaviorSubject<string>('');
  private _savingSuccess$ = new Subject<void>();

  private _deleting$ = new BehaviorSubject<boolean>(false);
  private _deletionError$ = new BehaviorSubject<string>('');
  private _deletionSuccess$ = new Subject<string>();

  // CONSTRUCTOR

  constructor(private userContactApiService: UserContactsApiService) {
    this.fetchUserContactsIfNeeded();
  }

  // PUBLIC GETTERS

  get userContacts$(): Observable<UserContactPdsEntry[]> {
    this.fetchUserContactsIfNeeded();
    return this._userContacts$.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  get loadingError$(): Observable<string> {
    return this._loadingError$.asObservable();
  }

  get saving$(): Observable<boolean> {
    return this._saving$.asObservable();
  }

  get savingError$(): Observable<string> {
    return this._savingError$.asObservable();
  }

  get savingSuccess$(): Observable<void> {
    return this._savingSuccess$.asObservable();
  }

  get deleting$(): Observable<boolean> {
    return this._deleting$.asObservable();
  }

  get deletionError$(): Observable<string> {
    return this._deletionError$.asObservable();
  }

  get deletionSuccess$(): Observable<string> {
    return this._deletionSuccess$.asObservable();
  }

  // LIFECYCLE HOOKS

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // PUBLIC API

  createUserContact(userContactData: UserContactData) {
    this._saving$.next(true);
    this._savingError$.next('');

    this.userContactApiService
      .createUserContact(userContactData)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          if (!response.newContactId) {
            // server should always return the new contact id in case of success
            this._savingError$.next('Unexpected response from the server.');
            this._saving$.next(false);
            return;
          }

          let newUserContact = new UserContactPdsEntry(userContactData);
          newUserContact.contactId = response.newContactId;

          this._userContactsSource.push(newUserContact);
          this.sortUserContacts();
          this._userContacts$.next(this._userContactsSource);

          this._saving$.next(false);
          this._savingSuccess$.next();
        },
        error: (error) => {
          this._savingError$.next(
            getErrorMessage(error, 'Unable to create the new contact.')
          );
          this._saving$.next(false);
        },
      });
  }

  updateUserContact(updatedUserContact: UserContactPdsEntry) {
    this._saving$.next(true);
    this._savingError$.next('');

    this.userContactApiService
      .updateUserContact(updatedUserContact)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          // replace the old connection with the updated one (identified by id)
          this._userContactsSource = this._userContactsSource.map(
            (oldUserContact) =>
              updatedUserContact.contactId === oldUserContact.contactId
                ? updatedUserContact
                : oldUserContact
          );
          this.sortUserContacts();
          this._userContacts$.next(this._userContactsSource);

          this._saving$.next(false);
          this._savingSuccess$.next();
        },
        error: (error) => {
          this._savingError$.next(
            getErrorMessage(error, 'Unable to update the contact details.')
          );
          this._saving$.next(false);
        },
      });
  }

  deleteUserContact(userContactToBeDeleted: UserContactPdsEntry) {
    this._deleting$.next(true);
    this._deletionError$.next('');

    if (!userContactToBeDeleted.contactId) {
      this._deletionError$.next('Invalid contact id. Unable to delete.');
      this._deleting$.next(false);
      return;
    }

    this.userContactApiService
      .deleteUserContact(userContactToBeDeleted.contactId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this._userContactsSource = this._userContactsSource.filter(
            (userContact) =>
              userContact.contactId !== userContactToBeDeleted.contactId
          );
          this.sortUserContacts();
          this._userContacts$.next(this._userContactsSource);

          this._deleting$.next(false);
          this._deletionSuccess$.next(userContactToBeDeleted?.contactId ?? '');
        },
        error: (error) => {
          this._deletionError$.next(
            getErrorMessage(error, 'Unable to delete the contact.')
          );
          this._deleting$.next(false);
        },
      });
  }

  // call in ngOnDestroy of subscribing components to reset the errors (do not reset loading errors)
  resetErrors() {
    this._savingError$.next('');
    this._deletionError$.next('');
  }

  // PRIVATE API

  private fetchUserContactsIfNeeded() {
    // if the user contacts are already loaded or are being loaded, do nothing
    if (this._userContactsSource.length !== 0 || this._loading$.getValue()) {
      return;
    }

    this._loading$.next(true);
    this._loadingError$.next('');

    this.userContactApiService
      .getAllUserContacts()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (userContacts) => {
          this._userContactsSource = userContacts;
          this.sortUserContacts();

          this._userContacts$.next(this._userContactsSource);
          this._loading$.next(false);
        },
        error: (error) => {
          this._loadingError$.next(
            getErrorMessage(error, 'Unable to fetch contacts from the server.')
          );
          this._loading$.next(false);
        },
      });
  }

  private sortUserContacts() {
    this._userContactsSource = this._userContactsSource.sort(
      (a, b) =>
        parseInt(a?.contactId ?? '0', 10) ||
        0 - (parseInt(b?.contactId ?? '0', 10) || 0)
    );
  }
}
