import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';
import { FormItemTextArea } from 'src/app/models/form/form';

export const whatMattersImportanceOfRoleAsCarer = new FormItemTextArea({
  key: 'whatMattersImportanceOfRoleAsCarer',
  path: 'supportingRole.whatMatters.importanceOfRoleAsCarer',
  label:
    'Is there other information you would like to share about being a carer that highlights why the role is important to you',
  placeholder:
    'My mother had a very hard life with strict parents and my father was absent a good deal through work. She more or less raised my brother and I on her own. She was always patient and kind to us and made time to do stuff with us like going to the beach or baking. It is important to me to give back that same attention and patience. I find it hard sometimes but most of the time it is real pleasure to care for my mum.',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});
