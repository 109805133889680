import {
  FormItemButtonSelect,
  FormItemDropdown,
  FormItemTextArea,
} from 'src/app/models/form/form';
import { yesNoOptions } from '../item-options';
import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';

export const healthImpactCaringAffectsPhysicalHealth = new FormItemButtonSelect(
  {
    key: 'healthImpactCaringAffectsPhysicalHealth',
    label: 'Caring affects my physical health',
    path: 'supportingRole.healthImpact.caringAffectsPhysicalHealth',
    class: 'mb-5',
    options: yesNoOptions,
  }
);

export const healthImpactCaringAffectsPhysicalHealthDetails =
  new FormItemTextArea({
    key: 'healthImpactCaringAffectsPhysicalHealthDetails',
    path: 'supportingRole.healthImpact.caringAffectsPhysicalHealthDetails',
    label: 'This is how it affects me',
    placeholder: 'e.g. back pain, headaches, aggravates existing conditions',
    class: 'mb-5',
    validators: [Validators.minLength(1), maxByteValidator(2000)],
    displayConditions: [
      {
        key: 'healthImpactCaringAffectsPhysicalHealth',
        values: ['Yes'],
      },
    ],
  });

export const healthImpactHealthAffectsCaring = new FormItemButtonSelect({
  key: 'healthImpactHealthAffectsCaring',
  label: 'My overall physical health affects my ability to provide care',
  path: 'supportingRole.healthImpact.healthAffectsCaring',
  class: 'mb-5',
  options: yesNoOptions,
});

export const healthImpactDiscussedWithGp = new FormItemButtonSelect({
  key: 'healthImpactDiscussedWithGp',
  path: 'supportingRole.healthImpact.discussedWithGp',
  label: 'I have discussed my health and caring role with my GP',
  class: 'mb-5',
  options: yesNoOptions,
});

export const healthImpactSummary = new FormItemDropdown({
  key: 'healthImpactSummary',
  label:
    'Thinking about my health generally, the selected statement best matches how I currently feel.',
  path: 'supportingRole.healthImpact.summary',
  placeholder: 'Please select...',
  class: 'mb-5',
  options: [
    {
      name: 'I am healthy enough and look after my health well.',
      label: 'I am healthy enough and look after my health well.',
    },
    {
      name: 'My health and lifestyle are mostly OK, but there are a few changes needed.',
      label:
        'My health and lifestyle are mostly OK, but there are a few changes needed.',
    },
    {
      name: "There are no immediate concerns, but I need to look after my health a lot better. My health is at risk if I don't seek help.",
      label:
        "There are no immediate concerns, but I need to look after my health a lot better. My health is at risk if I don't seek help.",
    },
    {
      name: 'I have some support to look after my health, but my health is poor, and needs attention.',
      label:
        'I have some support to look after my health, but my health is poor, and needs attention.',
    },
    {
      name: 'My health is poor or has broken down. I have no support.',
      label: 'My health is poor or has broken down. I have no support.',
    },
  ],
});
