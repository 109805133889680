import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { filter, first, map } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

export const BlockAuthedGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const redirectAuthed = () => router.createUrlTree(['/account/about-me']);

  return authService.isAuthenticated$.pipe(
    filter((authChecked) => authChecked !== undefined),
    map((isAuthenticated) => {
      // user is authenticated and may not access the route
      if (isAuthenticated) {
        return redirectAuthed();
      }
      return true;
    }),
    first()
  );
};
