import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemSubform,
  FormItemTextArea,
  FormItemTextbox,
} from 'src/app/models/form/form';
import { yesNoOptionsLegacy } from '../item-options';

export const medicalHistoryPresentConditions = new FormItemTextArea({
  key: 'medicalHistoryPresentConditions',
  path: 'health.medicalHistory.presentConditions',
  label: 'Relevant present medical conditions',
  placeholder: 'e.g. conditions',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const medicalHistoryPastConditions = new FormItemTextArea({
  key: 'medicalHistoryPastConditions',
  path: 'health.medicalHistory.pastConditions',
  label: 'Relevant past medical conditions',
  placeholder: 'e.g. conditions',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

const medicalHistorySurgicalInterventionName = new FormItemTextbox({
  key: 'name',
  label: 'Surgical intervention',
  placeholder: 'e.g. heart surgery',
  class: 'mb-5',
  required: true,
  validators: [
    Validators.minLength(1),
    Validators.maxLength(2000),
    Validators.required,
  ],
});

const medicalHistorySurgicalInterventionDate = new FormItemTextbox({
  key: 'date',
  label: 'Date',
  placeholder: 'e.g. 05/28/2010',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const medicalHistorySurgicalInterventions = new FormItemSubform({
  key: 'medicalHistorySurgicalInterventions',
  path: 'health.medicalHistory.surgicalInterventions',
  multiple: true,
  label: 'Surgical interventions',
  addTitle: 'Add a surgical intervention',
  titleField: 'medicalHistorySurgicalInterventionName',
  display: 'inline',
  class: 'mb-5',
  subform: [
    medicalHistorySurgicalInterventionName,
    medicalHistorySurgicalInterventionDate,
  ],
});

export const medicalHistoryDisabilityPerception = new FormItemButtonSelect({
  key: 'medicalHistoryDisabilityPerception',
  label: 'Do you perceive yourself as having a disabilty?',
  path: 'health.medicalHistory.disabilityPerception',
  class: 'mb-5',
  options: yesNoOptionsLegacy,
});

export const medicalHistoryDisabilityPerceptionAffect = new FormItemTextArea({
  key: 'medicalDisabilityPerceptionAffect',
  path: 'health.medicalHistory.disabilityPerceptionAffect',
  label: 'How does this affect you?',
  placeholder: '',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
  displayConditions: [
    {
      key: 'medicalHistoryDisabilityPerception',
      values: [true],
    },
  ],
});

export const medicalHistoryDisabilityRegistered = new FormItemButtonSelect({
  key: 'medicalHistoryDisabilityRegistered',
  label: 'Are you a registered disabled person?',
  path: 'health.medicalHistory.disabilityRegistered',
  class: 'mb-5',
  options: yesNoOptionsLegacy,
});

export const medicalHistoryDisabilityRegisteredNumber = new FormItemTextbox({
  key: 'medicalHistoryDisabilityRegisteredNumber',
  path: 'health.medicalHistory.disabilityRegisteredNumber',
  label: 'Do you wish to share your registered disabled number? ',
  placeholder: 'e.g. 29387091409',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
