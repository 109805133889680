import {
  FormItemButtonSelect,
  FormItemDropdown,
  FormItemTextArea,
} from 'src/app/models/form/form';
import { yesNoOptions } from '../item-options';
import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';

export const otherLifeAspectsCaringAffectsRelationships =
  new FormItemButtonSelect({
    key: 'otherLifeAspectsCaringAffectsRelationships',
    label: 'Caring impacts on my relationships',
    path: 'supportingRole.otherLifeAspects.caringAffectsRelationships',
    class: 'mb-5',
    options: yesNoOptions,
  });

export const otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaring =
  new FormItemButtonSelect({
    key: 'otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaring',
    label: 'This impact affects my ability to provide care',
    path: 'supportingRole.otherLifeAspects.caringAffectingRelationshipsImpactsOnCaring',
    class: 'mb-5',
    options: yesNoOptions,
    displayConditions: [
      {
        key: 'otherLifeAspectsCaringAffectsRelationships',
        values: ['Yes'],
      },
    ],
  });

export const otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaringDetails =
  new FormItemTextArea({
    key: 'otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaringDetails',
    path: 'supportingRole.otherLifeAspects.caringAffectingRelationshipsImpactsOnCaringDetails',
    label: 'How it impacts',
    placeholder:
      'e.g. feeling unable to be honest with professionals about the support you provide because you know how this makes the person you care for feel',
    class: 'mb-5',
    validators: [Validators.minLength(1), maxByteValidator(2000)],
    displayConditions: [
      {
        key: 'otherLifeAspectsCaringAffectsRelationships',
        values: ['Yes'],
      },
      {
        key: 'otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaring',
        values: ['Yes'],
      },
    ],
  });

export const otherLifeAspectsBotheringCaringRoleAspects = new FormItemTextArea({
  key: 'otherLifeAspectsBotheringCaringRoleAspects',
  path: 'supportingRole.otherLifeAspects.botheringCaringRoleAspects',
  label: 'Other things that bother me about my role',
  placeholder:
    'e.g. other family issues, recent bereavements, forthcoming family/other events causing concern etc',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const otherLifeAspectsDesiredChangesAboutSituation =
  new FormItemTextArea({
    key: 'otherLifeAspectsDesiredChangesAboutSituation',
    path: 'supportingRole.otherLifeAspects.desiredChangesAboutSituation',
    label: 'What I would most like to change about my situation',
    class: 'mb-5',
    validators: [Validators.minLength(1), maxByteValidator(2000)],
  });

export const otherLifeAspectsRelationshipsImpactSummary = new FormItemDropdown({
  key: 'otherLifeAspectsRelationshipsImpactSummary',
  label:
    'Thinking about the impact of your caring role on your relationships, the selected statement best matches how you currently feel.',
  path: 'supportingRole.otherLifeAspects.relationshipsImpactSummary',
  class: 'mb-5',
  placeholder: 'Please select...',
  options: [
    {
      name: 'I have a good relationship with the person I care for, and with other key people in my life.',
      label:
        'I have a good relationship with the person I care for, and with other key people in my life.',
    },
    {
      name: 'I have some concerns about my relationship with the person I care for, and/or being able to maintain relationships with other key people in my life.',
      label:
        'I have some concerns about my relationship with the person I care for, and/or being able to maintain relationships with other key people in my life.',
    },
    {
      name: 'There are difficulties in my relationship with the person I care for that need to be addressed, and/or my caring role makes it difficult to maintain relationships with other key people in my life.',
      label:
        'There are difficulties in my relationship with the person I care for that need to be addressed, and/or my caring role makes it difficult to maintain relationships with other key people in my life.',
    },
    {
      name: 'I find it very difficult to maintain my relationship with the person I care for, and/or I cannot maintain relationships with other key people in my life.',
      label:
        'I find it very difficult to maintain my relationship with the person I care for, and/or I cannot maintain relationships with other key people in my life.',
    },
    {
      name: 'My relationship with the person I care for has broken down: I cannot continue to provide care, and/or I have lost touch with other key people in my life.',
      label:
        'My relationship with the person I care for has broken down: I cannot continue to provide care, and/or I have lost touch with other key people in my life.',
    },
  ],
});
