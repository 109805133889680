import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { isValidNumber } from 'src/app/helper/dataTypeHelper';
import { getErrorMessage } from 'src/app/helper/getErrorMessage';
import { Connection } from 'src/app/models/connection/connection';
import { PdsReferralRequest } from 'src/app/models/referral/server/pdsReferralRequest';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PdsReferralApiService {
  // PROPERTIES

  private _sending$ = new BehaviorSubject<boolean>(false);
  private _sendingError$ = new BehaviorSubject<string>('');

  // PUBLIC GETTERS

  public sending$: Observable<boolean> = this._sending$.asObservable();
  public sendingError$: Observable<string> = this._sendingError$.asObservable();

  // CONSTRUCTOR

  constructor(private _http: HttpClient) {}

  // PUBLIC API

  send(connection: Connection): Observable<any> {
    this._sending$.next(true);
    this._sendingError$.next('');

    // ensure that connection.pdsOutput?.mrdServiceIdV2 and connection.pdsOutput?.mrdServiceProviderIdV2 are valid numbers
    if (
      isValidNumber(connection.pdsOutput?.mrdServiceIdV2) === false ||
      isValidNumber(connection.pdsOutput?.mrdServiceProviderIdV2) === false
    ) {
      this._sendingError$.next(
        'Unable to store referral in PDS. Referral setup is incomplete. Please contact support.'
      );
      this._sending$.next(false);
      return throwError(() => 'Unable to store referral in PDS.');
    }

    const referralRequest = new PdsReferralRequest(
      connection.pdsOutput?.mrdServiceProviderIdV2 as number,
      connection.pdsOutput?.mrdServiceIdV2 as number
    );

    return this._http
      .post(
        `${environment.apiBaseUrl}/referral/send-pds-referral`,
        referralRequest
      )
      .pipe(
        tap(() => {
          this._sending$.next(false);
        }),
        catchError((error) => {
          this._sendingError$.next(
            getErrorMessage(error, 'Unable to store referral in PDS')
          );
          this._sending$.next(false);
          return throwError(() => error);
        })
      );
  }

  resetError() {
    this._sendingError$.next('');
  }
}
