import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTextbox,
} from 'src/app/models/form/form';
import { yesNoOptionsLegacy } from '../item-options';

export const memoryProblemAwareness = new FormItemTextArea({
  key: 'memoryProblemAwareness',
  path: 'health.memory.problemAwareness',
  label: 'When did you become aware your memory was causing you problems?',
  placeholder: 'e.g. “I think I noticed changes about six month ago”',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const memoryEasyToRemember = new FormItemButtonSelect({
  key: 'memoryEasyToRemember',
  path: 'health.memory.easyToRemember',
  label: 'What are you good at remembering?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Numbers', label: 'Numbers' },
    { name: 'Names', label: 'Names' },
    { name: 'Addresses', label: 'Addresses' },
    { name: 'Dates', label: 'Dates' },
    { name: 'Years', label: 'Years' },
    { name: 'Special events', label: 'Special events' },
  ],
});

export const memoryHardToRemember = new FormItemButtonSelect({
  key: 'memoryHardToRemember',
  path: 'health.memory.hardToRemember',
  label: 'What sort of things are you aware of forgetting?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Numbers', label: 'Numbers' },
    { name: 'Names', label: 'Names' },
    { name: 'Addresses', label: 'Addresses' },
    { name: 'Keys', label: 'Keys' },
    { name: 'Time of day', label: 'Time of day' },
    { name: 'Dates', label: 'Dates' },
    { name: 'Years', label: 'Years' },
    { name: 'Days of the week', label: 'Days of the week' },
    { name: 'Recent events', label: 'Recent events' },
    { name: 'Other', label: 'Other' },
  ],
});

export const memoryConcernedSocialCircle = new FormItemButtonSelect({
  key: 'memoryConcernedSocialCircle',
  label: 'Are your family or people close to you concerned about your memory?',
  path: 'health.memory.concernedSocialCircle',
  class: 'mb-5',
  options: yesNoOptionsLegacy,
});

export const memoryConcernedSocialCircleDetails = new FormItemTextArea({
  key: 'memoryConcernedSocialCircleDetails',
  label: 'Tell us more about their concerns',
  path: 'health.memory.concernedSocialCircleDetails',
  placeholder: '',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
  displayConditions: [
    {
      key: 'memoryConcernedSocialCircle',
      values: [true],
    },
  ],
});

export const memoryDementiaDiagnosisDate = new FormItemTextbox({
  key: 'memoryDementiaDiagnosisDate',
  path: 'health.memory.dementiaDiagnosisDate',
  label: 'If you have a diagnosis of dementia when did this happen?',
  placeholder: '',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const memoryAreasRequiringSupport = new FormItemButtonSelect({
  key: 'memoryAreasRequiringSupport',
  path: 'health.memory.areasRequiringSupport',
  label: 'Tell tell us about things you need support with?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Letters', label: 'Letters' },
    { name: 'Money', label: 'Money' },
    { name: 'Medication', label: 'Medication' },
    { name: 'Contacts', label: 'Contacts' },
    { name: 'Stroke', label: 'Stroke' },
    { name: 'Relationships', label: 'Relationships' },
    { name: 'Driving', label: 'Driving' },
    { name: 'Exercise', label: 'Exercise' },
    { name: 'Anxiety', label: 'Anxiety' },
    { name: 'Getting lost', label: 'Getting lost' },
    { name: 'Other', label: 'Other' },
  ],
});

export const memorySupportingAids = new FormItemButtonSelect({
  key: 'memorySupportingAids',
  path: 'health.memory.supportingAids',
  label: 'Do you use any aids to support your memory?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Notice board', label: 'Notice board' },
    { name: 'Diary', label: 'Diary' },
    { name: 'Digital prompts', label: 'Digital prompts' },
    { name: 'Dosset box', label: 'Dosset box' },
    { name: 'Alexa', label: 'Alexa' },
    { name: 'Alarms', label: 'Alarms' },
    { name: 'Timers', label: 'Timers' },
    { name: 'Other', label: 'Other' },
  ],
});

export const memoryCondition = new FormItemTextbox({
  key: 'memoryCondition',
  path: 'health.memory.condition',
  label: 'Do you have any condition which affects your memory?',
  placeholder: "e.g. Alzheimer's disease",
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const memoryAdditionalInfo = new FormItemTextArea({
  key: 'memoryAdditionalInfo',
  path: 'health.memory.additionalInfo',
  label: 'Anything else you would like to share about your memory?',
  placeholder: 'e.g. memory',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
