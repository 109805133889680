<!--LOADING SPINNER-->
<app-loading-spinner
  *ngIf="
    ((connectionsStateService.loading$ | async) &&
      !(connectionsStateService.loadingError$ | async)) ||
    ((userService.loading$ | async) && !(userService.loadingError$ | async)) ||
    (step === 'overview' && (userService.saving$ | async))
  "
></app-loading-spinner>
<!--END LOADING SPINNER-->

<!-- SERVER ERROR: FAILED LOADING CONNECTIONS -->
<app-no-items-error
  [isDisplayed]="
    !(connectionsStateService.loading$ | async) &&
    (connectionsStateService.loadingError$ | async)
  "
  [errorMessage]="connectionsStateService.loadingError$ | async"
  showBackButton="true"
>
</app-no-items-error>
<!-- END SERVER ERROR: FAILED LOADING CONNECTIONS -->

<!-- SERVER ERROR: FAILED LOADING USER -->
<!-- Connections error has higher priority - so only that is shown if both fail -->
<!-- Only show user error if user is authenticated -->
<app-no-items-error
  [isDisplayed]="
    !(userService.loading$ | async) &&
    (userService.loadingError$ | async) &&
    (authService.isAuthenticated$ | async) &&
    !(connectionsStateService.loadingError$ | async)
  "
  [errorMessage]="userService.loadingError$ | async"
  showBackButton="true"
>
</app-no-items-error>
<!-- END SERVER ERROR: FAILED LOADING USER -->

<!-- CONTENT -->
<ng-container
  *ngIf="
    !(connectionsStateService.loading$ | async) &&
    !(connectionsStateService.loadingError$ | async) &&
    !(userService.loading$ | async) &&
    !(userService.loadingError$ | async) &&
    (!(userService.saving$ | async) || step !== 'overview')
  "
>
  <!-- ERROR MESSAGE: CONNECTION NOT FOUND -->
  <app-no-items-error
    [isDisplayed]="!connection"
    errorMessage="Unable to find a connection with this name."
    showBackButton="true"
  >
  </app-no-items-error>
  <!--END ERROR MESSAGE: CONNECTION NOT FOUND -->

  <!-- ERROR MESSAGE: CONNECTION HAS NO PDS REFERRAL SET UP -->
  <app-no-items-error
    [isDisplayed]="!connectionOutput"
    errorMessage="This connection has no data sharing functionality implemented."
    showBackButton="true"
  >
  </app-no-items-error>
  <!--END ERROR MESSAGE: CONNECTION HAS NO PDS REFERRAL SET UP -->

  <ng-container *ngIf="connection && connectionOutput">
    <div [ngSwitch]="step" class="mx-auto max-w-5xl box-border">
      <button
        class="btn-inline underline opacity-50 hover:opacity-100 transition-opacity font-semibold p-0 m-0"
        (click)="handleBack()"
      >
        Back
      </button>
      <h3 class="text-2xl italic font-thin opacity-80">
        {{ connection?.title }} - Share your Data
      </h3>
      <div
        class="w-full bg-white flex flex-col rounded-lg overflow-hidden px-12 md:px-16 py-12 box-border"
      >
        <!-- STEP: OVERVIEW -->
        <div *ngSwitchCase="'overview'">
          <!-- REFERRAL INTRO -->
          <h4 class="text-xl font-semibold mt-0 mb-1">Referral Steps</h4>

          <p class="mb-8">
            Welcome to the enhanced referral for {{ connection.title }}.
            {{ connectionOutput.pdsReferralText }}
          </p>
          <!-- END REFERRAL INTRO -->

          <!-- PRIMARY INFORMATION -->
          <div
            class="card shadow-md bg-white p-8 rounded-lg border-solid border-gray-50"
          >
            <app-accordion-item
              #accordionItemPrimary
              [isOpen]="primaryInformationOpenState"
              (isOpenChange)="primaryInformationOpenState = $event"
            >
              <div
                class="header flex justify-between items-center cursor-pointer w-full space-x-8"
              >
                <div
                  class="flex flex-col justify-between lg:flex-row lg:items-center w-full lg:space-x-10 space-y-4 lg:space-y-0"
                >
                  <h3 class="font-normal text-lg my-2 lg:whitespace-nowrap">
                    1. Primary Information
                    <span class="text-red-600">* (Required)</span>
                  </h3>
                  <app-form-items-completed-bar
                    [formItemsCompletedPercentage]="
                      primaryInformationPercentageCalculated
                    "
                    class="inline-block w-full"
                  ></app-form-items-completed-bar>
                </div>
                <app-icon
                  name="caret-right-fill"
                  class="transition-transform duration-100 ease-in-out text-gray-700"
                  height="24"
                  width="24"
                  tabindex="0"
                  [ngClass]="{ 'rotate-90': accordionItemPrimary.isOpen }"
                  (keydown.enter)="
                    primaryInformationOpenState = !primaryInformationOpenState
                  "
                  (keydown.space)="
                    primaryInformationOpenState = !primaryInformationOpenState;
                    $event.preventDefault()
                  "
                ></app-icon>
              </div>
              <div class="my-6 body">
                <p class="my-6 text-grey">
                  Click on a card to edit your details.
                </p>
                <div class="grid gap-8 xl:grid-cols-2">
                  <app-pds-section-card
                    *ngFor="let section of primaryInformationSections"
                    [user]="user"
                    [pdsSection]="section"
                    [attr.tabindex]="primaryInformationOpenState ? '0' : null"
                    (click)="handleSectionSelected(section)"
                    (keydown.enter)="handleSectionSelected(section)"
                    (keydown.space)="
                      handleSectionSelected(section); $event.preventDefault()
                    "
                    class="cursor-pointer"
                  ></app-pds-section-card>
                </div>
              </div>
            </app-accordion-item>
          </div>
          <!-- END PRIMARY INFORMATION -->
          <!-- SECONDARY INFORMATION -->
          <div
            class="card shadow-md bg-white p-8 rounded-lg border-solid border-gray-50 mt-10 mb-14"
          >
            <app-accordion-item
              #accordionItemSecondary
              [isOpen]="secondaryInformationOpenState"
              (isOpenChange)="secondaryInformationOpenState = $event"
            >
              <div
                class="header flex justify-between items-center cursor-pointer w-full space-x-8"
              >
                <div
                  class="flex flex-col justify-between lg:flex-row lg:items-center w-full lg:space-x-8 space-y-4 lg:space-y-0"
                >
                  <h3 class="font-normal text-lg my-2 lg:whitespace-nowrap">
                    2. Secondary Information
                    <span class="text-theme">(Optional)</span>
                  </h3>
                  <app-form-items-completed-bar
                    [formItemsCompletedPercentage]="
                      secondaryInformationPercentageCalculated
                    "
                    class="inline-block w-full"
                  ></app-form-items-completed-bar>
                </div>
                <app-icon
                  name="caret-right-fill"
                  class="transition-transform duration-100 ease-in-out text-gray-700"
                  height="24"
                  width="24"
                  tabindex="0"
                  [ngClass]="{ 'rotate-90': accordionItemSecondary.isOpen }"
                  (keydown.enter)="
                    secondaryInformationOpenState =
                      !secondaryInformationOpenState
                  "
                  (keydown.space)="
                    secondaryInformationOpenState =
                      !secondaryInformationOpenState;
                    $event.preventDefault()
                  "
                ></app-icon>
              </div>

              <div class="my-6 body">
                <p class="my-6 text-grey">
                  Click on a card to edit your details.
                </p>
                <div class="grid gap-8 xl:grid-cols-2">
                  <app-pds-section-card
                    *ngFor="let section of secondaryInformationSections"
                    [user]="user"
                    [pdsSection]="section"
                    [attr.tabindex]="secondaryInformationOpenState ? '0' : null"
                    (click)="handleSectionSelected(section)"
                    (keydown.enter)="handleSectionSelected(section)"
                    (keydown.space)="
                      handleSectionSelected(section); $event.preventDefault()
                    "
                    class="cursor-pointer"
                  ></app-pds-section-card>
                </div>
              </div>
            </app-accordion-item>
          </div>
          <!-- END SECONDARY INFORMATION -->

          <!-- DATA USAGE STATEMENT -->
          <div
            class="bg-light-grey pt-2 pb-4 px-8 bg-opacity-50 rounded-md mb-12"
            *ngIf="!connection.dataUsageStatement"
          >
            <h4>How this service uses the provided data</h4>
            <p>{{ connection?.dataUsageStatement }}</p>
          </div>
          <!-- END DATA USAGE STATEMENT -->

          <!-- AGREEMENT TO PROCESS DATA -->
          <div
            class="form-text flex mb-8"
            *ngIf="primaryInformationPercentageCalculated == 100"
          >
            <label
              class="custom-checkbox cursor flex cursor-pointer mb-0 mr-auto items-center"
            >
              <input
                type="checkbox"
                class="hidden"
                (change)="agreementDataProcessing = $event.target.checked"
              />
              <div
                class="checkbox w-4 h-4 rounded border border-solid flex transition-colors my-auto mr-4"
                tabindex="0"
                role="checkbox"
                [attr.aria-checked]="agreementDataProcessing"
                (keydown.enter)="
                  agreementDataProcessing = !agreementDataProcessing
                "
                (keydown.space)="
                  agreementDataProcessing = !agreementDataProcessing;
                  $event.preventDefault()
                "
                [ngClass]="
                  agreementDataProcessing
                    ? 'bg-theme border-theme'
                    : 'bg-white border-gray-500'
                "
              >
                <app-icon name="check" class="text-white"></app-icon>
              </div>

              <span class="mt-auto mb-auto">
                I consent to the processing of this data in accordance with
                Community Connections'
                <a
                  class="text-theme underline"
                  routerLink="/privacy-policy"
                  target="_blank"
                  >Privacy Policy</a
                >. The provided details may be used by Community Connections and
                {{ connection?.organisation ?? connection.title }} for contact
                purposes and as stated above. Furthermore, I consent that after
                submitting this referral
                {{ connection?.organisation ?? connection.title }} will be able
                to access my data from the <strong>About Me</strong> sections
                'This is Me' and 'My Supporting Role'.
                <span class="text-theme">*</span>
              </span>
            </label>
          </div>
          <!-- END AGREEMENT TO PROCESS DATA -->

          <!-- REFERRAL INCOMPLETE TEXT -->
          <div
            *ngIf="primaryInformationPercentageCalculated < 100"
            class="mb-14"
          >
            <div class="flex items-center space-x-3">
              <app-icon
                name="circle-fill"
                class="text-red-700"
                height="12"
                width="12"
              ></app-icon>
              <h4>Referral Form Incomplete</h4>
            </div>
            <p class="text-grey mt-0">
              You haven't yet provided enough information to submit your
              referral. Please provide some details so that
              {{ connection?.organisation ?? connection.title }} can help you
              effectively. The more information you provide now as part of your
              referral the less information will be needed later in the process
              and the better the
              {{ connection?.organisation ?? connection.title }} team can assist
              you.
            </p>
          </div>
          <!-- END REFERRAL INCOMPLETE TEXT -->

          <!-- REFERRAL PARTIALLY COMPLETE TEXT -->
          <div
            *ngIf="
              primaryInformationPercentageCalculated == 100 &&
              secondaryInformationPercentageCalculated < 100
            "
            class="mb-14"
          >
            <div class="flex items-center space-x-3">
              <app-icon
                name="circle-fill"
                class="text-yellow-300"
                height="12"
                width="12"
              ></app-icon>
              <h4>Referral Form Partially Complete</h4>
            </div>
            <p class="text-grey mt-0">
              You have enough information to submit your referral but more
              information will be needed later. The more information you provide
              now as part of your referral the less information will be needed
              later in the process and the better the
              {{ connection?.organisation ?? connection.title }} team can assist
              you.
            </p>
          </div>
          <!-- END REFERRAL PARTIALLY COMPLETE TEXT -->

          <!-- REFERRAL COMPLETE TEXT -->
          <div
            *ngIf="
              primaryInformationPercentageCalculated == 100 &&
              secondaryInformationPercentageCalculated == 100
            "
            class="mb-14"
          >
            <div class="flex items-center space-x-3">
              <app-icon
                name="circle-fill"
                class="text-theme"
                height="12"
                width="12"
              ></app-icon>
              <h4>Referral Form Complete</h4>
            </div>
            <p class="text-grey mt-0">
              You have completed everything, well done. The
              {{ connection?.organisation ?? connection.title }} team have
              everything they need to be able to assist you well.
            </p>
          </div>
          <!-- END REFERRAL COMPLETE TEXT -->

          <!-- ACTION BUTTONS -->
          <div class="flex gap-6 items-center">
            <button
              type="button"
              class="btn-grey-outline px-14"
              (click)="handleBack()"
            >
              Back
            </button>
            <app-stateful-button
              (clickEvent)="handleSubmit($event)"
              [disabled]="primaryInformationPercentageCalculated < 100"
              [text]="'Submit'"
              [textProgress]="'Submitting'"
              [isInProgress]="pdsReferralService.sending$ | async"
              [type]="'filled'"
              [errorMessage]="
                requiredFieldsError ||
                (pdsReferralService.sendingError$ | async)
              "
              [buttonClass]="'btn px-14 btn-theme inline-flex items-center'"
            >
            </app-stateful-button>
          </div>
          <!-- END ACTION BUTTONS -->
        </div>
        <!-- END STEP: OVERVIEW -->

        <!-- STEP: FORM -->
        <div *ngSwitchCase="'form'">
          <app-pds-section-form
            [user]="user"
            [pdsSection]="currentSelectedSection"
            [form]="form"
            [formCancelText]="'Back to Referral Overview'"
            (formCancel)="handleFormCancel($event)"
          >
          </app-pds-section-form>
        </div>
        <!-- END STEP: FORM-->

        <!-- STEP: CONNECTION COMPLETE -->
        <div
          *ngSwitchCase="'complete'"
          class="flex flex-col lg:flex-row lg:items-center space-x-8"
        >
          <!-- Left Content -->
          <div
            class="lg:w-1/2 flex flex-col lg:justify-center text-left items-start"
          >
            <h4 class="text-xl font-semibold mt-0 mb-4 lg:mb-0">
              Referral Complete!
            </h4>
            <p>{{ connectionOutput.pdsReferralCompletedText }}</p>

            <button
              type="button"
              class="btn-grey-outline px-14 mt-6 lg:mt-8"
              (click)="handleBack()"
            >
              Back
            </button>
          </div>

          <!-- Image Content -->
          <div class="lg:block lg:w-1/2 hidden justify-center">
            <img
              class="mx-0 w-full max-w-md"
              src="/assets/img/pds-referral-complete.webp"
              alt="Referral Complete"
            />
          </div>
        </div>
        <!-- END STEP: CONNECTION COMPLETE -->
      </div>
    </div>
  </ng-container>
</ng-container>
<!-- END CONTENT -->
