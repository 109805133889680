// const address = new FormItemAddress(){

import {
  FormItemSubform,
  FormItemButtonSelect,
  FormItemDatePicker,
  FormItemDob,
  FormItemDropdown,
  FormItemPassword,
  FormItemSelect,
  FormItemTextbox,
  FormItemRepeater,
  FormItemTextArea,
} from 'src/app/models/form/form';
import { Validators } from '@angular/forms';

import { firstName, lastName } from '../user/me-items/personal-details-items';
import { address1, address2, postcode } from '../shared-items/address';

const firstNameNew = new FormItemTextbox(firstName);
firstNameNew.path = 'firstName';
const lastNameNew = new FormItemTextbox(lastName);
lastNameNew.path = 'lastName';

const dob = new FormItemDob({
  key: 'dob',
  label: 'Date of Birth',
  class: 'my-5',
  placeholder: 'Select a date',
  required: true,
  validators: [Validators.required],
});

const select = new FormItemDropdown({
  key: 'selectOutput',
  label: 'Select',
  class: 'my-5',
  placeholder: 'Select an option',
  required: true,
  options: [
    { name: '1', label: 'Option 1' },
    { name: '2', label: 'Option 2' },
    { name: '3', label: 'Option 3' },
    { name: '4', label: 'Option 4' },
  ],
});

// const address = new FormItemAddress({
//     key: 'address',
//     label: 'Address',
//     addTitle: 'Add a new address',
//     type: 'address',
//     validators: [
//         Validators.required,
//     ],
// })

// const address = new FormItemTextbox({
//     key: 'address',
//     label: 'Address Line 1',
//     class: 'max-w-lg',
//     placeholder: 'this should autocomplete',
//     autocomplete: 'address-line1', //https://docs.mapbox.com/mapbox-search-js/api/web/autofill/
//     type: 'address',
//     place: true,
//     validators: [
//         Validators.required,
//     ],

// });

const multiTest = new FormItemSelect({
  key: 'multiSelectOutput',
  label: 'Multi Select',
  class: 'my-5',
  placeholder: 'Select some options',
  required: true,
  multiple: true,
  options: [
    { name: '1', label: 'Option 1' },
    { name: '2', label: 'Option 2' },
    { name: '3', label: 'Option 3' },
    { name: '4', label: 'Option 4' },
  ],
});

const dropdownTest = new FormItemDropdown({
  key: 'nativeDropdownOutput',
  label: 'Native dropdown',
  class: 'my-5',
  placeholder: 'Select some options',
  required: true,
  options: [
    { name: '1', label: 'Option 1' },
    { name: '2', label: 'Option 2' },
    { name: '3', label: 'Option 3' },
    { name: '4', label: 'Option 4' },
  ],
});

const buttonExample = new FormItemButtonSelect({
  key: 'btnsingle',
  label: 'Button Select Example (single):',
  class: 'mb-4',
  required: true,
  options: [
    { name: '1', label: 'Option 1' },
    { name: '2', label: 'Option 2' },
    { name: '3', label: 'Option 3' },
    { name: '4', label: 'Option 4' },
  ],
});
const buttonExample2 = new FormItemButtonSelect({
  key: 'btnmulti',
  label: 'Button Select Example (multiple):',
  // class: 'mb-2',
  required: true,
  multiple: true,
  options: [
    { name: '1', label: 'Option 1' },
    { name: '2', label: 'Option 2' },
    { name: '3', label: 'Option 3' },
    { name: '4', label: 'Option 4' },
  ],
});

export const external = new FormItemTextbox({
  key: 'externalTest',
  // path: 'me.personalDetails.firstName',
  label: 'External Field',
  placeholder: 'Enter some text',
  class: 'my-5',
  width: 'half',
  type: 'string',
  validators: [
    Validators.minLength(1),
    Validators.maxLength(50),
    Validators.required,
  ],
});

export const date = new FormItemDatePicker({
  key: 'date',
  label: 'Date',
  class: 'my-1',
  placeholder: 'Select a date',
  required: true,
  validators: [Validators.required],
});

export const password = new FormItemPassword({
  key: 'password',
  label: 'Password',
  class: 'my-5',
  placeholder: 'Enter a password',
  required: true,
  validators: [Validators.required],
});

const days = new FormItemSubform({
  key: 'days',
  label: 'Days',
  path: 'testpath.days',
  addTitle: 'Add a new day',
  validators: [Validators.required],
  titleField: 'day',
  display: 'inline',
  required: true,
  subform: [
    new FormItemDatePicker({
      key: 'day',
      label: 'Day',
      class: 'my-5',
      placeholder: 'Select a date',
      required: true,
      validators: [Validators.required],
    }),
  ],
});
let repeaterItems = [
  new FormItemDatePicker(date),
  new FormItemPassword(password),
];
repeaterItems.forEach((x) => {
  x.class = 'half';
});
const dates = new FormItemRepeater({
  key: 'dates',
  label: 'Repeater test',
  required: true,
  formItems: repeaterItems,
});

const suggestionTest = new FormItemTextArea({
  key: 'suggestionTest',
  label: 'Textarea (with suggestions)',
  class: 'my-5',
  placeholder: 'Enter some text',
  type: 'string',
  validators: [
    Validators.minLength(1),
    Validators.maxLength(50),
    Validators.required,
  ],
  suggestions: ['suggestion1', 'suggestion2', 'suggestion3'],
});

export default [
  suggestionTest,
  // dates,
  // image,
  // image,
  // dob,
  // date,
  // days,
  // people,
  // dropdownTest,
  // multiTest,
  // buttonExample,
  // buttonExample2,
  // password,
];
