import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.css',
})
export class TooltipComponent {
  // PROPERTIES
  @Input() width: number = 17;
  @Input() height: number = 17;
  @Input() accessibilityId?: string = 'tooltipId';
  @Input() text?: string;

  isTooltipVisible = false;

  // METHODS

  // Toggle for mobile click/touch
  toggleTooltip(): void {
    this.isTooltipVisible = !this.isTooltipVisible;
  }
}
