import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Connection } from 'src/app/models/connection/connection';
import { MrdConnectionRequest } from 'src/app/models/connection/server/mrdConnectionRequest';
import { FormItem } from 'src/app/models/form/form';
import { ConnectionsStateService } from 'src/app/services/connections-state/connections.state.service';
import { FormService } from 'src/app/services/form/form.service';
import { getMrdConnectionAdminForm } from 'src/assets/forms/connection/form-add-mrd-connection';

@Component({
  selector: 'app-admin-connection',
  templateUrl: './admin-connection.component.html',
  styleUrls: ['./admin-connection.component.css'],
})
export class AdminConnectionComponent implements OnInit {
  // PROPERTIES

  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent =
    {} as ModalComponent;

  protected connection?: any;
  private connectionName: string = '';

  protected isNewConnection: boolean = false;
  protected mrdConnectionRequest: any;

  protected manualInputForm: FormItem<any>[] =
    this.formService.updateAndGetManualInputAdminFormForConnection();
  protected mrdForm: FormItem<any>[] = getMrdConnectionAdminForm();

  private connectionsListSubscription: any = undefined;

  // CONSTRUCTOR

  constructor(
    protected connectionsStateService: ConnectionsStateService,
    private router: Router,
    private route: ActivatedRoute,
    private formService: FormService
  ) {}

  // LIFECYCLE HOOKS

  ngOnInit() {
    this.isNewConnection = this.handleNewConnection();

    this.connectionsListSubscription =
      this.connectionsStateService.connections$.subscribe((connections) => {
        // if page is refreshed, connections will be empty at first
        if (connections.length !== 0 && !this.isNewConnection) {
          this.setupExistingConnection();
        }
      });
  }

  ngOnDestroy() {
    if (this.connectionsListSubscription) {
      this.connectionsListSubscription.unsubscribe();
    }

    this.connectionsStateService.resetErrors();

    this.closeDeletionModal();
  }

  // CONNECTION FORM METHODS

  // returns true if it is handling a new connection
  handleNewConnection(): boolean {
    if (this.route.snapshot.url[0].path === 'add-connection') {
      this.connection = {};
      return true;
    }
    return false;
  }

  setupExistingConnection() {
    this.connectionName = this.route.snapshot.paramMap.get('slug') || '';

    this.connection = this.connectionsStateService.getConnectionByName(
      this.connectionName
    );

    // some form items depend on the connection source
    this.manualInputForm =
      this.formService.updateAndGetManualInputAdminFormForConnection(
        this.connection
      );
  }

  handleSaveManualInputForm(data: Connection) {
    const connection = new Connection(data);

    this.connectionsStateService.saveConnection(
      this.connectionName,
      connection
    );
  }

  handleSaveMrdForm(data: MrdConnectionRequest) {
    const connectionRequest = new MrdConnectionRequest(data);

    this.connectionsStateService.addConnectionFromMrd(connectionRequest);
  }

  // DELETION METHODS

  handleDelete() {
    this.connectionsStateService.deleteConnection(this.connectionName);
  }

  openDeletionModal(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this.modal.open();
  }

  closeDeletionModal() {
    this.modal.close();
  }

  onClosingDeletionModal() {
    this.connectionsStateService.resetErrors();
  }

  // NAVIGATION METHODS

  navigateBack() {
    this.router.navigate(['/admin/connections/']);
  }
}
