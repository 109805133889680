import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTitle,
} from 'src/app/models/form/form';
import { yesNoInProgressOptions } from '../item-options';
import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';

// THIS IS ME - HOME -------------------------------------------------------

export const moneyMattersSubtitle = new FormItemTitle({
  key: 'moneyMattersSubtitle',
  label:
    'You can record the benefits or pensions you receive here, to help you keep track and share with others if you wish.',
  class: 'mt-2 mb-5',
  titleType: 'p',
});

export const pensionCredit = new FormItemButtonSelect({
  key: 'pensionCredit',
  label: 'Pension credit',
  path: 'me.moneyMatters.pensionCredit',
  options: yesNoInProgressOptions,
  class: 'mb-5',
});

export const incapacityBenefit = new FormItemButtonSelect({
  key: 'incapacityBenefit',
  label: 'Incapacity benefit',
  path: 'me.moneyMatters.incapacityBenefit',
  options: yesNoInProgressOptions,
  class: 'mb-5',
});

export const attendanceAllowance = new FormItemButtonSelect({
  key: 'attendanceAllowance',
  label: 'Attendance allowance',
  path: 'me.moneyMatters.attendanceAllowance',
  options: yesNoInProgressOptions,
  class: 'mb-5',
});

export const disabilityLivingAllowance = new FormItemButtonSelect({
  key: 'disabilityLivingAllowance',
  label: 'Disability living allowance',
  path: 'me.moneyMatters.disabilityLivingAllowance',
  options: yesNoInProgressOptions,
  class: 'mb-5',
});

export const personalIndependencePayment = new FormItemButtonSelect({
  key: 'personalIndependencePayment',
  label: 'Personal independence payment',
  path: 'me.moneyMatters.personalIndependencePayment',
  options: yesNoInProgressOptions,
  class: 'mb-5',
});

export const universalCredit = new FormItemButtonSelect({
  key: 'universalCredit',
  label: 'Universal credit',
  path: 'me.moneyMatters.universalCredit',
  options: yesNoInProgressOptions,
  class: 'mb-5',
});

export const privatePension = new FormItemButtonSelect({
  key: 'privatePension',
  label: 'Private pension',
  path: 'me.moneyMatters.privatePension',
  options: yesNoInProgressOptions,
  class: 'mb-5',
});

export const statePension = new FormItemButtonSelect({
  key: 'statePension',
  label: 'State pension',
  path: 'me.moneyMatters.statePension',
  options: yesNoInProgressOptions,
  class: 'mb-5',
});

export const financesAdditionalInfo = new FormItemTextArea({
  key: 'financesAdditionalInfo',
  label:
    'Is there any other information you would like to share about your finances?',
  placeholder: 'e.g. info',
  path: 'me.moneyMatters.financesAdditionalInfo',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});
