import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTextbox,
  FormItemTitle,
} from 'src/app/models/form/form';
import { yesNoOptionsLegacy } from '../item-options';
import { Validators } from '@angular/forms';
import { guardianshipTooltip } from 'src/assets/texts/tooltips';

export const futureCarePlanHasHadConversationAboutWishes =
  new FormItemButtonSelect({
    key: 'futureCarePlanHasHadConversationAboutWishes',
    path: 'planningAhead.futureCarePlan.hasHadConversationAboutWishes',
    label:
      'Have you had a conversation with your family or person close to you about how you would like to be cared for in the future?',
    class: 'mb-5',
    options: yesNoOptionsLegacy,
  });

export const futureCarePlanHasChosenConfidant = new FormItemButtonSelect({
  key: 'futureCarePlanHasChosenConfidant',
  path: 'planningAhead.futureCarePlan.hasChosenConfidant',
  label:
    'Do you have a named person, welfare guardian or an independent advocate who you would like to have this conversation with?',
  tooltip: guardianshipTooltip,
  class: 'mb-5',
  options: yesNoOptionsLegacy,
});

export const futureCarePlanChosenConfidantDetailsTitle = new FormItemTitle({
  key: 'futureCarePlanChosenConfidantDetailsTitle',
  label:
    'Details of named person, welfare guardian or an independent advocate who you would like to have this conversation with',
  class: 'mt-2 mb-5',
  displayConditions: [
    {
      key: 'futureCarePlanHasChosenConfidant',
      values: [true],
    },
  ],
});

export const futureCarePlanChosenConfidantName = new FormItemTextbox({
  key: 'futureCarePlanChosenConfidantDetailsName',
  path: 'planningAhead.futureCarePlan.chosenConfidant.name',
  label: 'Full Name',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
  displayConditions: [
    {
      key: 'futureCarePlanHasChosenConfidant',
      values: [true],
    },
  ],
});

export const futureCarePlanChosenConfidantRelationship = new FormItemTextbox({
  key: 'futureCarePlanChosenConfidantRelationship',
  path: 'planningAhead.futureCarePlan.chosenConfidant.relationship',
  label: 'Relationship to that person',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
  displayConditions: [
    {
      key: 'futureCarePlanHasChosenConfidant',
      values: [true],
    },
  ],
});

export const futureCarePlanChosenConfidantPhone = new FormItemTextbox({
  key: 'futureCarePlanChosenConfidantPhone',
  path: 'planningAhead.futureCarePlan.chosenConfidant.phone',
  label: 'Phone Number',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
  displayConditions: [
    {
      key: 'futureCarePlanHasChosenConfidant',
      values: [true],
    },
  ],
});

export const futureCarePlanChosenConfidantEmail = new FormItemTextbox({
  key: 'futureCarePlanChosenConfidantEmail',
  path: 'planningAhead.futureCarePlan.chosenConfidant.email',
  label: 'Email Address',
  class: 'mb-5',
  width: 'half',
  validators: [
    Validators.minLength(1),
    Validators.maxLength(2000),
    Validators.email, // use built-in validator because trying to copy PHP's validator (Mydex uses) is not recommended, as testing correctness is difficult
  ],
  displayConditions: [
    {
      key: 'futureCarePlanHasChosenConfidant',
      values: [true],
    },
  ],
});

export const futureCarePlanHealthManagementNeeds = new FormItemTextArea({
  key: 'futureCarePlanHealthManagementNeeds',
  path: 'planningAhead.futureCarePlan.healthManagementNeeds',
  label: 'What would help me manage my health better:',
  placeholder: 'e.g. more access to my GP',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const futureCarePlanConcernsAboutFuture = new FormItemTextArea({
  key: 'futureCarePlanConcernsAboutFuture',
  path: 'planningAhead.futureCarePlan.concernsAboutFuture',
  label: 'My concerns about the future:',
  placeholder:
    'e.g. My health, people close to me, people I look after, how I will manage and what will happen if I get seriously ill',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const futureCarePlanPeopleInvolvedInCare = new FormItemButtonSelect({
  key: 'futureCarePlanPeopleInvolvedInCare',
  path: 'planningAhead.futureCarePlan.peopleInvolvedInCare',
  label: 'People currently involved in your care',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'GP', label: 'GP' },
    { name: 'Consultant', label: 'Consultant' },
    { name: 'Practice nurse', label: 'Practice nurse' },
    { name: 'Community pharmacist', label: 'Community pharmacist' },
    { name: 'Care manager', label: 'Care manager' },
    { name: 'Palliative care team', label: 'Palliative care team' },
    { name: 'Occupational therapist', label: 'Occupational therapist' },
    { name: 'Physiotherapist', label: 'Physiotherapist' },
    { name: 'Podiatrist', label: 'Podiatrist' },
  ],
});

export const futureCarePlanAdditionalInfo = new FormItemTextArea({
  key: 'futureCarePlanAdditionalInfo',
  path: 'planningAhead.futureCarePlan.additionalInfo',
  label: 'Share more details here if required:',
  placeholder: 'e.g. more details about professional care person',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const futureCarePlanHospitalAdmissionRequests = new FormItemTextArea({
  key: 'futureCarePlanHospitalAdmissionRequests',
  path: 'planningAhead.futureCarePlan.hospitalAdmissionRequests',
  label:
    'If I need to be admitted to hospital the things that are important to me are:',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
