import * as _ from 'lodash';
import { Connection } from '../models/connection/connection';
import { User } from '../models/user/user';
import { PdsSection } from '../models/pds/pdsSection';
import { FormItem } from '../models/form/form';

export function computeCompletedRequiredReferralFormItemsPercentage(
  user: User,
  connection: Connection
): number {
  // total amount of form items
  const totalAmountOfFormItems = connection.allRequiredFormItemPaths.length;
  if (totalAmountOfFormItems === 0) {
    return 100; // none required, so all are completed
  }

  // number of completed form items
  const completedItems = computeNumberOfCompletedItems(
    connection.allRequiredFormItemPaths,
    user
  );

  // calculate percentage
  return calculatePercentage(completedItems, totalAmountOfFormItems);
}

export function computeCompletedMultiplePdsSectionsItemsPercentage(
  user: User | undefined,
  sections: PdsSection[] | undefined
): number {
  if (!user || !sections || sections.length === 0) {
    return 0;
  }

  // get all form item functions from the sections and all their children
  const formItems: FormItem<any>[] = sections.flatMap((section) =>
    getFormItems(section)
  );

  return computeCompletedItemsPercentage(user, formItems);
}

export function computeCompletedPdsSectionItemsPercentage(
  user: User | undefined,
  section: PdsSection | undefined
): number {
  if (!user || !section) {
    return 0;
  }

  // get all form item functions from the section and all children
  const formItems: FormItem<any>[] = getFormItems(section);

  return computeCompletedItemsPercentage(user, formItems);
}

export function getFormItems(currentSection: PdsSection): FormItem<any>[] {
  var formItems: FormItem<any>[] = [];
  var negligibleFormItems: FormItem<any>[] = [];

  // get all form items and negligible form items from the section and all children
  function collectFormItems(section: PdsSection) {
    if (section.getFormItems) {
      const newFormItems = section.getFormItems();
      formItems = formItems.concat(newFormItems);
    }

    if (section.getFormItemsNegligibleForCompleteness) {
      const newNegligibleFormItems =
        section.getFormItemsNegligibleForCompleteness();
      negligibleFormItems = negligibleFormItems.concat(newNegligibleFormItems);
    }

    if (section.children) {
      section.children.forEach((child: PdsSection) => collectFormItems(child));
    }
  }

  collectFormItems(currentSection);

  // Remove form items from the collection that have the same path as any of the negligible form items
  formItems = formItems.filter(
    (formItem) =>
      !negligibleFormItems.some(
        (negligibleFormItem) => negligibleFormItem.path === formItem.path
      )
  );

  return formItems;
}

function computeCompletedItemsPercentage(
  user: User | undefined,
  formItems: FormItem<any>[]
): number {
  // filter out form items that do not have a path set (titles etc.)
  formItems = formItems.filter((formItem) => formItem.path);

  // calculate the total number of form items with relevant paths
  const totalAmountOfFormItems = formItems.length;

  // map to get an array of just the paths
  let formItemPaths = formItems.map((formItem) => formItem.path);

  // number of completed form items
  const completedItems = computeNumberOfCompletedItems(formItemPaths, user);

  // calculate percentage
  return calculatePercentage(completedItems, totalAmountOfFormItems);
}

function computeNumberOfCompletedItems(
  formItemPaths: any[],
  user: User | undefined
): number {
  let completedItems: number = 0;

  formItemPaths.forEach((path) => {
    const value = _.get(user, path, undefined);
    if (value === undefined || value === null || value === '') {
      return;
    }
    if (Array.isArray(value) && value.length === 0) {
      return;
    }
    completedItems++;
  });

  return completedItems;
}

function calculatePercentage(
  completedItems: number,
  totalAmountOfItems: number
) {
  let completedPercentage = 100 * (completedItems / totalAmountOfItems);

  if (completedPercentage) {
    completedPercentage = Math.floor(completedPercentage);
  } else {
    completedPercentage = 0;
  }

  return completedPercentage;
}
