import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormItemSelect } from 'src/app/models/form/form';
import * as _ from 'lodash';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor {
  @Input() item!: FormItemSelect;
  @Input() disabled = false;
  @Input() control: any;

  value: string | null = null; //maybe should be empty array??

  onChange = (selected: any) => {};
  onTouched = () => {};
  touched = false;

  writeValue(value: any): void {
    // If there is an empty string, set it to undefined so that the placeholder is shown
    if (value === '') {
      value = undefined;
    }

    this.value = value;
  }

  update(value: any): void {
    this.markAsTouched();
    this.onChange(value);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  get options() {
    return this.item.options;
  }
}
