import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { getImageMimeTypeFromBase64String } from 'src/app/helper/imageHelper';
import { FormItemContacts } from 'src/app/models/form/form';
import { UserContactData } from 'src/app/models/contact/userContactData';
import { UserContactPdsEntry } from 'src/app/models/contact/userContactPdsEntry';
import { ContactForm } from 'src/app/models/contact/contactForm';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactsComponent),
      multi: true,
    },
  ],
})
export class ContactsComponent implements ControlValueAccessor, OnInit {
  // PROPERTIES
  @ViewChild('contactsOverviewModal', { static: true })
  contactsOverviewModal: ModalComponent = {} as ModalComponent;
  @ViewChild('contactEditorModel', { static: true })
  contactEditorModel: ModalComponent = {} as ModalComponent;
  @ViewChild('contactDeletionModal', { static: true })
  contactDeletionModal: ModalComponent = {} as ModalComponent;

  @Input() item?: FormItemContacts;
  @Input() control: any;
  @Input() disabled: boolean = false;

  @Input() contactsSource?: UserContactPdsEntry[];
  @Input() contactsSourceLoading: boolean = true;
  @Input() contactsSourceLoadingError: string = '';
  @Input() contactsSourceSaving: boolean = false;
  @Input() contactsSourceSavingError: string = '';
  @Input() contactsSourceDeleting: boolean = false;
  @Input() contactsSourceDeletionError: string = '';

  @Output() contactCreated = new EventEmitter();
  @Output() contactUpdated = new EventEmitter();
  @Output() contactDeleted = new EventEmitter();
  @Output() clearErrors = new EventEmitter();

  protected contactsFormGroup: FormGroup = new FormGroup({
    select: new FormControl([]),
  });

  protected currentUserContact: UserContactPdsEntry | undefined;

  protected contactsSourceSavingSuccess: boolean = false;
  protected contactsSourceDeletionSuccess: boolean = false;

  protected selectedContactEditorFormIndex: number | undefined = undefined;
  protected currentContactEditorForm: ContactForm | undefined = undefined;

  protected contactEditorType: 'add' | 'update' | undefined = undefined;

  private onChange: any = () => {};
  private onTouched: any = () => {};

  // LIFECYCLE

  ngOnInit(): void {
    this.contactsFormGroup.valueChanges.subscribe((value) => {
      this.onChange(value);
      this.onTouched();
    });

    if (this.item?.disabled) {
      this.selectFormControl.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      setTimeout(() => {
        if (this.disabled && this.selectFormControl.enabled) {
          this.selectFormControl.disable();
          this.onChange(this.selectFormControl.value);
        } else if (!this.disabled && this.selectFormControl.disabled) {
          this.selectFormControl.enable();
          this.onChange(this.selectFormControl.value);
        }
      });
    }

    if (changes['item']) {
      this.updateCurrentContactEditorForm(this.item?.initialSelectedFormIndex);
    }
  }

  // CONTROL VALUE ACCESSOR

  writeValue(value: any): void {
    if (this.selectFormControl.value !== value) {
      this.selectFormControl.setValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  update(): void {
    this.onChange(this.selectFormControl.value);
    this.onTouched();
  }

  // ACCESS FORM GROUP COMPONENTS

  get selectFormControl(): FormControl {
    return this.contactsFormGroup.get('select') as FormControl;
  }

  // ACCESS IMAGE

  getImageSrc(contact: UserContactPdsEntry): string | undefined {
    if (!contact.picture) {
      return undefined;
    }

    const mimeType = getImageMimeTypeFromBase64String(contact.picture);

    if (!mimeType) {
      return undefined;
    }

    return `data:image/${mimeType};base64,${contact.picture}`;
  }

  // MODAL METHODS

  openContactsOverviewModal(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this.contactsOverviewModal.open();
  }

  openContactEditorModal(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this.contactEditorModel.open();
  }

  openContactDeletionModal(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this.contactDeletionModal.open();
  }

  // CONTACT EDITOR METHODS

  onAddNewContactClicked(e: any) {
    this.contactEditorType = 'add';
    this.currentUserContact = undefined;
    this.openContactEditorModal(e);
  }

  onEditContactClicked(e: any, contact: UserContactPdsEntry) {
    this.contactEditorType = 'update';
    this.currentUserContact = contact;
    this.openContactEditorModal(e);
  }

  onContactEditorClosed() {
    this.contactEditorType = undefined;
    this.currentUserContact = undefined;
    this.contactsSourceSavingSuccess = false;
    this.clearErrors.emit();
  }

  handleContactEditorFormCancel(e: any) {
    this.contactEditorModel.close();
    this.currentUserContact = undefined;
  }

  handleContactEditorFormSave(data: Partial<UserContactData>) {
    // Set currentUserContact data to the form while saving
    let currentContactId = this.currentUserContact?.contactId;
    this.currentUserContact = new UserContactPdsEntry(data);
    this.currentUserContact.contactId = currentContactId;

    // Create new contact
    if (!this.currentUserContact.contactId) {
      this.contactCreated.emit(new UserContactData(data));
      return;
    }

    // Update existing contact
    var updatedUser = new UserContactPdsEntry(data);
    updatedUser.contactId = this.currentUserContact.contactId;
    this.contactUpdated.emit(updatedUser);
  }

  handleContactSavingSuccess() {
    // Show success message
    this.contactsSourceSavingSuccess = true;
    setTimeout(() => {
      // Close the modal if a new contact was created
      if (this.contactEditorType === 'add') {
        this.contactEditorModel.close();
      }
      // Stop showing the success message
      this.contactsSourceSavingSuccess = false;
    }, 3000);
  }

  updateCurrentContactEditorForm(index: number | undefined) {
    if (
      index !== undefined &&
      this.item?.allContactEditorForms &&
      this.item.allContactEditorForms.length - 1 >=
        this.item.initialSelectedFormIndex
    ) {
      this.selectedContactEditorFormIndex = index;
      this.currentContactEditorForm = this.item.allContactEditorForms[index];
    }
  }

  // CONTACT DELETION METHODS

  onContactDeletionClicked(e: any, contact: UserContactPdsEntry) {
    this.currentUserContact = contact;
    this.openContactDeletionModal(e);
  }

  onCancelContactDeletionClicked() {
    this.contactDeletionModal.close();
  }

  onProceedContactDeletionClicked() {
    this.contactDeleted.emit(this.currentUserContact);
  }

  handleContactDeletionSuccess(contactId: string) {
    // After contact deletion, the selectFormControl value is updated to not include the deleted contact
    var validContacts = this.selectFormControl.value.filter(
      (selectedContactIds: string) => selectedContactIds !== contactId
    );

    if (validContacts !== this.selectFormControl.value) {
      this.selectFormControl.setValue(validContacts);
      this.update();
    }

    // Show success message and close the modal
    this.contactsSourceDeletionSuccess = true;
    setTimeout(() => {
      this.contactDeletionModal.close();
      this.contactsSourceDeletionSuccess = false;
      this.currentUserContact = undefined;
    }, 3000);
  }

  onContactDeletionModalClosed() {
    this.currentUserContact = undefined;
    this.contactsSourceDeletionSuccess = false;
    this.clearErrors.emit();
  }

  // INNER CONTACTS ITEM METHODS

  handleInnerItemContactCreated(e: any) {
    this.contactCreated.emit(e);
  }

  handleInnerItemContactUpdated(e: any) {
    this.contactUpdated.emit(e);
  }

  handleInnerItemContactDeleted(e: any) {
    this.contactDeleted.emit(e);
  }

  handleInnerItemErrorsCleared(e: any) {
    this.clearErrors.emit(e);
  }
}
