import {
  allergies,
  emergencyMedication,
  gpEmail,
  gpName,
  gpPhone,
  gpTitle,
  medicalHistory,
  surgeryName,
} from './medical-details-items';
import {
  medicationStorage,
  medicationTimes,
} from './medication-location-items';
import {
  mainSupportName,
  mainSupportPerson,
  mainSupportPhone,
} from './main-support-items';
import {
  accessAdditionalInfo,
  howToEmergencyAccess,
  keyPersonName,
  keyPersonPhone,
  keySafeCode,
  neighbourName,
  neighbourPhone,
} from './access-items';
import { otherDependents, pets } from './priorities-items';
import {
  supportGeneralInfo,
  supportOrganisations,
  supportSchedule,
} from './support-package-items';
import { preferencesAvoid, preferencesPositive } from './preferences-items';
import {
  FormItem,
  FormItemTextbox,
  FormItemTitle,
} from 'src/app/models/form/form';
import {
  address2,
  getFormItemsAddress,
  state,
} from '../../shared-items/address';

// All form items for user - key information
export function getFormItemsUserKeyInfo(): FormItem<any>[] {
  return [
    new FormItemTitle({ label: 'Medical Details' }),
    ...getFormItemsUserKeyInfoMedicalDetails(),
    new FormItemTitle({ label: 'Medication Location' }),
    ...getFormItemsUserKeyInfoMedicationLocation(),
    new FormItemTitle({ label: 'Main Support' }),
    ...getFormItemsUserKeyInfoMainSupport(),
    new FormItemTitle({ label: 'Access' }),
    ...getFormItemsUserKeyInfoAccess(),
    new FormItemTitle({ label: 'Priorities' }),
    ...getFormItemsUserKeyInfoPriorities(),
    new FormItemTitle({ label: 'Support Package' }),
    ...getFormItemsUserKeyInfoSupportPackage(),
    new FormItemTitle({ label: 'Preferences' }),
    ...getFormItemsUserKeyInfoPreferences(),
  ];
}

// All form items for user - key information - medical details
export function getFormItemsUserKeyInfoMedicalDetails(): FormItem<any>[] {
  return [
    medicalHistory,
    allergies,
    emergencyMedication,
    gpTitle,
    surgeryName,
    gpName,
    ...getFormItemsAddress('keyInformation.medicalDetails.gp.address'),
    gpPhone,
    gpEmail,
  ];
}

export function getFormItemsUserKeyInfoMedicalDetailsNegligibleForCompleteness(): FormItem<any>[] {
  // create copies of items to not change the original path (only path needed to determine if item is negligible)
  const address2Copy = new FormItemTextbox(address2);
  address2Copy.path = `keyInformation.medicalDetails.gp.address.address2`;

  const stateCopy = new FormItemTextbox(state);
  stateCopy.path = `keyInformation.medicalDetails.gp.address.state`;

  return [address2Copy, stateCopy];
}

// All form items for user - key information - medication location
export function getFormItemsUserKeyInfoMedicationLocation(): FormItem<any>[] {
  return [medicationStorage, medicationTimes];
}

// All form items for user - key information - main support
export function getFormItemsUserKeyInfoMainSupport(): FormItem<any>[] {
  return [
    mainSupportPerson,
    mainSupportName,
    ...getFormItemsAddress('keyInformation.mainSupport.address'),
    mainSupportPhone,
  ];
}

export function getFormItemsUserKeyInfoMainSupportNegligibleForCompleteness(): FormItem<any>[] {
  // create copies of items to not change the original path (only path needed to determine if item is negligible)
  const address2Copy = new FormItemTextbox(address2);
  address2Copy.path = `keyInformation.mainSupport.address.address2`;

  const stateCopy = new FormItemTextbox(state);
  stateCopy.path = `keyInformation.mainSupport.address.state`;

  return [address2Copy, stateCopy];
}

// All form items for user - key information - access
export function getFormItemsUserKeyInfoAccess(): FormItem<any>[] {
  return [
    howToEmergencyAccess,
    keySafeCode,
    keyPersonName,
    keyPersonPhone,
    neighbourName,
    neighbourPhone,
    accessAdditionalInfo,
  ];
}

// All form items for user - key information - priorities
export function getFormItemsUserKeyInfoPriorities(): FormItem<any>[] {
  return [otherDependents, pets];
}

// All form items for user - key information - support package
export function getFormItemsUserKeyInfoSupportPackage(): FormItem<any>[] {
  return [supportGeneralInfo, supportOrganisations, supportSchedule];
}

// All form items for user - key information - preferences
export function getFormItemsUserKeyInfoPreferences(): FormItem<any>[] {
  return [preferencesPositive, preferencesAvoid];
}
