import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.css'],
})
export class AccordionItemComponent {
  // PROPERTIES
  @ViewChild('accordionContent') contentElement!: ElementRef;
  @Input() isOpen = false;
  @Input() onlyTriggerViaCustomToggle = false; // if true, the toggle is .toggle (ng-content), false is the whole header
  @Output() isOpenChange = new EventEmitter();

  style: any = { 'max-height': 0 }; // initial body style

  // PUBLIC GETTERS

  get height(): number {
    // get the height of the body content
    if (!this.contentElement) {
      return 0;
    }
    return this.contentElement.nativeElement.scrollHeight;
  }

  // LIFECYCLE HOOKS
  ngOnChanges(changes: SimpleChanges) {
    if (changes['isOpen']) {
      if (changes['isOpen'].currentValue !== changes['isOpen'].previousValue) {
        this.handleIsOpenUpdated();
      }
    }
  }

  // PUBLIC API

  toggle(event: any): void {
    if (
      this.onlyTriggerViaCustomToggle &&
      !event.target.classList.contains('toggle')
    )
      return;

    this.isOpen = !this.isOpen;
    this.handleIsOpenUpdated();
  }

  handleIsOpenUpdated(): void {
    this.isOpenChange.emit(this.isOpen);
    this.style = { 'max-height': this.height + 'px' };
    if (this.isOpen) {
      setTimeout(() => {
        this.style = {}; //unset max height incase content changes height later
      }, 200);
    } else {
      setTimeout(() => {
        this.style = { 'max-height': 0 };
      });
    }
  }
}
