import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';

export const PostLoginRedirectGuard: CanActivateFn = (route, state) => {
  const sessionStorageService = inject(SessionStorageService);
  const router = inject(Router);

  // check if there is a redirect route
  const redirectRoute = sessionStorageService.getRedirectRouteAfterLogin();

  if (!redirectRoute) {
    return true;
  }

  // if there is a redirect route, clear it and redirect to it
  sessionStorageService.removeRedirectRouteAfterLogin();
  return router.createUrlTree([redirectRoute]);
};
