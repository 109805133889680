import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PdsConnectionStatus } from 'src/app/models/pds/pdsConnectionStatus';
import { getErrorMessage } from 'src/app/helper/getErrorMessage';
import { AuthService } from '../auth/auth.service';
import { SessionStorageService } from '../session-storage/session-storage.service';

// Auth service
@Injectable({ providedIn: 'root' })
export class PdsService {
  // PROPERTIES
  private _awaitingUrlRedirect$ = new BehaviorSubject<boolean>(false);
  private _awaitingUrlRedirectError$ = new BehaviorSubject<string>('');

  private _pdsConnectionStatus$ = new BehaviorSubject<
    PdsConnectionStatus | undefined
  >(undefined);

  private destroy$: Subject<void> = new Subject<void>();

  // PUBLIC GETTERS

  get awaitingUrlRedirect$(): Observable<boolean> {
    return this._awaitingUrlRedirect$.asObservable();
  }

  get awaitingUrlRedirectError$(): Observable<string> {
    return this._awaitingUrlRedirectError$.asObservable();
  }

  get pdsConnectionStatus$(): Observable<PdsConnectionStatus | undefined> {
    return this._pdsConnectionStatus$.asObservable();
  }

  // CONSTRUCTOR

  constructor(
    private _http: HttpClient,
    private authService: AuthService,
    private sessionStorageService: SessionStorageService
  ) {
    this.initializePdsVerification();
  }

  // LIFECYCLE HOOKS

  ngOnDestory(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // PUBLIC API

  resetErrors() {
    this._awaitingUrlRedirectError$.next('');
  }

  async getAccessApprovalUrlAndRedirect() {
    this._awaitingUrlRedirect$.next(true);
    this._awaitingUrlRedirectError$.next('');

    let returnTo: string;

    returnTo = `${environment.url}/account/welcome`;

    try {
      const response: any = await firstValueFrom(
        this._http.post(
          `${environment.apiBaseUrl}/pds/construct-approval-url`,
          {
            returnTo,
          }
        )
      );

      if (response.url) {
        window.location.href = response.url;
      }
      this._awaitingUrlRedirect$.next(false);
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        this._awaitingUrlRedirectError$.next(
          getErrorMessage(error, 'Unable to redirect to Mydex.')
        );
      } else {
        this._awaitingUrlRedirectError$.next('Unable to redirect to Mydex.');
      }
      this._awaitingUrlRedirect$.next(false);
    }
  }

  async getUpdateAccessApprovalUrlAndRedirect() {
    this._awaitingUrlRedirect$.next(true);
    this._awaitingUrlRedirectError$.next('');

    let returnTo = `${environment.url}/account/about-me`;

    try {
      const response: any = await firstValueFrom(
        this._http.post(
          `${environment.apiBaseUrl}/pds/construct-update-approval-url`,
          { returnTo }
        )
      );

      if (response.url) {
        window.location.href = response.url;
      }
      this._awaitingUrlRedirect$.next(false);
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        // Handle HttpErrorResponse
        this._awaitingUrlRedirectError$.next(
          getErrorMessage(error, 'Unable to redirect to Mydex.')
        );
      } else {
        this._awaitingUrlRedirectError$.next('Unable to redirect to Mydex.');
      }
      this._awaitingUrlRedirect$.next(false);
    }
  }

  private initializePdsVerification(): void {
    this.authService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated === true),
        first(), // Ensure it only fetches once after condition is met
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: () => this.verifyPdsConnection(),
        error: (error) =>
          console.error('Error initializing pds verification:', error),
      });
  }

  private async verifyPdsConnection() {
    try {
      const response: any = await firstValueFrom(
        this._http.get(`${environment.apiBaseUrl}/pds/verify-connection`)
      );
      const connectionStatus = new PdsConnectionStatus(response);

      // reset registration-in-progress if connection is valid
      // (either the user completed registration process or another user logged in)
      if (connectionStatus.isValid) {
        this.sessionStorageService.setRegistrationInProgress(false);
      }

      this._pdsConnectionStatus$.next(connectionStatus);
    } catch (error) {
      console.log('Error verifying PDS connection:', error);
    }
  }
}
