<div class="relative inline-block">
  <app-icon
    name="question-circle-fill"
    [height]="height"
    [width]="width"
    class="text-yellow-orange hover:opacity-70 cursor-help"
    tabindex="0"
    [attr.aria-describedby]="accessibilityId"
    (click)="toggleTooltip()"
    (mouseenter)="isTooltipVisible = true"
    (mouseleave)="isTooltipVisible = false"
    (focus)="isTooltipVisible = true"
    (blur)="isTooltipVisible = false"
  ></app-icon>

  <div
    *ngIf="isTooltipVisible"
    [attr.id]="accessibilityId"
    role="tooltip"
    class="absolute z-10 bg-gray-800 text-white rounded-lg py-3 px-4 text-sm opacity-90 left-1/2 transform -translate-x-1/2 bottom-full mb-2 min-w-[10rem] md:min-w-[16rem] lg:min-w-[24rem] max-w-max"
  >
    {{ text }}
  </div>
</div>
