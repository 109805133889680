<div class="mt-4 mb-5 flex flex-col">
  <!-- MEDICATIONS LOADING SPINNER -->
  <app-loading-spinner
    *ngIf="
      (allMedicationsLoading ||
        medicationAdding ||
        medicationUpdating ||
        medicationDeleting) &&
      !allMedicationsLoadingError
    "
    class="text-gray-500 flex-grow mt-0 border-solid border-gray-300 bg-gray-100 rounded-lg p-4 xl:w-2/3"
    text="Loading medications"
  >
  </app-loading-spinner>
  <!-- END MEDICATIONS LOADING ERROR -->

  <!-- MEDICATIONS LOADING ERROR -->
  <p
    *ngIf="!allMedicationsLoading && allMedicationsLoadingError"
    class="text-error-red form-error-text flex-grow w-full mt-0 border-solid border-gray-300 bg-gray-100 rounded-lg p-4"
  >
    {{ allMedicationsLoadingError }}
  </p>
  <!-- END MEDICATIONS LOADING ERROR -->

  <!-- NO MEDICATIONS MESSAGE -->
  <p
    *ngIf="
      !allMedicationsLoading &&
      !allMedicationsLoadingError &&
      (!allMedications || allMedications.length === 0)
    "
    class="italic text-grey mb-8"
  >
    No medications added, yet.
  </p>
  <!-- END NO MEDICATIONS MESSAGE -->

  <!-- MEDICATIONS DISPLAY -->
  <ng-container
    *ngIf="
      !allMedicationsLoading &&
      !medicationAdding &&
      !medicationUpdating &&
      !medicationDeleting &&
      !allMedicationsLoadingError
    "
  >
    <!-- MEDICATIONS LIST -->
    <div
      *ngIf="allMedications && allMedications.length > 0"
      class="mb-6 pl-6 pr-12 border-gray-300 border border-solid rounded-lg bg-white bg-opacity-30 xl:w-2/3"
    >
      <div
        *ngFor="let medication of allMedications; let i = index"
        class="flex flex-row"
      >
        <div class="form-text flex">
          <label
            class="custom-checkbox cursor flex cursor-pointer mb-0 mr-3 items-center"
            [attr.aria-label]="
              'Select ' + medication?.nameOther
                ? medication?.nameOther
                : medication?.name
            "
          >
            <input
              type="checkbox"
              class="hidden"
              (change)="toggleMedicationSelection(medication.medicationId)"
            />
            <div
              class="checkbox w-4 h-4 rounded border border-solid flex transition-colors my-auto mr-4"
              tabindex="0"
              role="checkbox"
              [attr.aria-checked]="
                isMedicationSelected(medication.medicationId)
              "
              (keydown.enter)="
                toggleMedicationSelection(medication.medicationId);
                $event.preventDefault();
                $event.stopPropagation()
              "
              (keydown.space)="
                toggleMedicationSelection(medication.medicationId);
                $event.preventDefault()
              "
              [ngClass]="
                isMedicationSelected(medication.medicationId)
                  ? 'bg-theme border-theme'
                  : 'bg-white border-gray-500'
              "
            >
              <app-icon name="check" class="text-white"></app-icon>
            </div>
          </label>
        </div>
        <div
          class="flex w-full mb-0 border-solid border-t-0 border-l-0 border-r-0 border-gray-300 py-2 px-3"
          [ngClass]="{
            'border-b': i != allMedications.length - 1,
            'border-b-0': i == allMedications.length - 1,
          }"
        >
          <p
            class="flex-1 truncate"
            title="{{
              medication?.nameOther ? medication?.nameOther : medication?.name
            }}"
            [ngClass]="
              isMedicationSelected(medication.medicationId)
                ? 'text-dark'
                : 'text-grey'
            "
          >
            {{
              medication?.nameOther ? medication?.nameOther : medication?.name
            }}
          </p>
          <button
            class="btn-theme-underline my-auto ml-4 flex items-center"
            type="button"
            (click)="onEditMedicationClicked($event, medication)"
            [disabled]="disabled"
          >
            <app-icon name="edit" class="w-5"></app-icon>
            <span class="ml-1">Edit</span>
          </button>
          <button
            class="btn-red-underline my-auto ml-8 flex items-center"
            type="button"
            (click)="onDeleteMedicationClicked($event, medication)"
            [disabled]="disabled"
          >
            <app-icon name="delete" class="w-5"></app-icon>
            <span class="ml-1">Remove</span>
          </button>
        </div>
      </div>
    </div>
    <!-- END MEDICATIONS LIST -->

    <!-- ADD NEW MEDICATION BUTTON -->
    <button
      class="btn btn-theme-outline p-4 flex items-center self-start"
      type="button"
      (click)="onAddNewMedicationClicked($event)"
      [disabled]="disabled"
    >
      <app-icon name="capsule-pill" class="w-5"></app-icon>
      <span class="ml-1">Add a new medication</span>
    </button>
    <!-- END ADD NEW MEDICATION BUTTON -->
  </ng-container>
  <!-- END MEDICATIONS DISPLAY -->
</div>

<!--MEDICATION EDITOR FORM-->
<app-modal #medicationEditorModel (onClose)="onMedicationEditorClosed()">
  <h2 class="mb-6 font-semibold text-lg">
    {{
      medicationEditorType === "add"
        ? " Add a Medication"
        : "Update your Medication"
    }}
  </h2>

  <!-- STATEFUL LOADING SPINNER -->
  <app-stateful-loading-spinner
    *ngIf="
      medicationDetailsLoading ||
      (medicationDetailsLoadingError && medicationEditorType === 'update')
    "
    spinnerText="Loading Medication"
    [errorMessage]="medicationDetailsLoadingError"
  ></app-stateful-loading-spinner>
  <!-- END STATEFUL LOADING SPINNER -->

  <!-- MEDICATION FORM -->
  <div
    *ngIf="
      !medicationDetailsLoading &&
      (!medicationDetailsLoadingError || medicationEditorType === 'add') &&
      item?.medicationForm &&
      item?.medicationForm?.length > 0
    "
    class="mt-8"
  >
    <app-form
      [formData]="item?.medicationForm"
      [formValues]="currentUserMedication"
      (submit)="handleMedicationEditorFormSave($event)"
      [submitText]="
        medicationEditorType === 'add' ? 'Add Medication' : 'Update'
      "
      [loading]="medicationAdding || medicationUpdating"
      [loadingText]="medicationEditorType === 'add' ? 'Adding' : 'Updating'"
      [success]="medicationAdditionSuccess || medicationUpdateSuccess"
      [successText]="medicationEditorType === 'add' ? 'Added!' : 'Updated!'"
      [error]="
        medicationAdditionError !== ''
          ? medicationAdditionError
          : medicationUpdateError
      "
      [cancellable]="true"
      [cancelText]="'Back'"
      (cancel)="handleMedicationEditorFormCancel($event)"
      [disabledForm]="
        disabled ||
        (medicationAdditionSuccess && medicationEditorType === 'add')
      "
    >
    </app-form>
    <!-- END MEDICATION FORM -->

    <!-- MEDICATION ADDITION SUCCESS MESSAGE -->
    <p
      *ngIf="
        !medicationAdding &&
        medicationAdditionSuccess &&
        medicationEditorType === 'add'
      "
      class="text-theme my-auto mt-2 text-right"
    >
      Medication added successfully! Window will close shortly.
    </p>
    <!-- END MEDICATION ADDITION SUCCESS MESSAGE -->
  </div>
</app-modal>

<!--END MEDICATION EDITOR FORM-->

<!--MEDICATION DELETION MODAL-->
<app-modal
  #medicationDeletionModal
  (onClose)="onMedicationDeletionModalClosed()"
>
  <h3 class="mt-0">Proceed with removal?</h3>
  <p>
    Are you sure that you would like to remove this medication from your
    Personal Data Store?
  </p>

  <div class="md:flex md:flex-row md:items-center mt-4">
    <app-stateful-button
      (clickEvent)="onProceedMedicationDeletionClicked()"
      [disabled]="disabled || medicationDeleting || medicationDeletionSuccess"
      [text]="'Remove Medication'"
      [textProgress]="'Deleting'"
      [isInProgress]="medicationDeleting"
    >
    </app-stateful-button>

    <button
      (click)="onCancelMedicationDeletionClicked()"
      type="button"
      [disabled]="medicationDeleting || medicationDeletionSuccess"
      class="btn btn-theme-outline p-4 md:ml-2 mt-2 md:mt-0"
    >
      Cancel
    </button>
  </div>

  <div
    *ngIf="!medicationDeleting && medicationDeletionError"
    class="text-error-red form-error-text my-auto mt-2"
  >
    {{ medicationDeletionError }}
  </div>

  <p
    *ngIf="!medicationDeleting && medicationDeletionSuccess"
    class="text-theme my-auto mt-2"
  >
    Medication removed successfully! Window will close shortly.
  </p>
</app-modal>
<!--END MEDICATION DELETION MODAL-->
