import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: [
        {
          configId: 'mydex',
          authority: environment.oidc.mydex.authority,
          postLoginRoute: '/account/about-me',
          scope: 'openid mydexid offline_access',
          responseType: 'code',
          clientId: environment.oidc.mydex.clientId,
          redirectUrl: environment.oidc.mydex.redirectUrl,
          postLogoutRedirectUri: environment.oidc.mydex.postLogoutRedirectUri,
          silentRenew: true,
          useRefreshToken: true,
          ignoreNonceAfterRefresh: true, // this is required if the id_token is not returned
          triggerRefreshWhenIdTokenExpired: false, // required when refreshing the browser if id_token is not updated after the first authentication
        },
      ],
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
