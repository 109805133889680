import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { getFormattedItemPath } from '../../../../assets/forms/connection/form-sections-items';
import {
  EmailOutputFormItem,
  EmailOutputFormSection,
} from 'src/app/models/connection/outputFormSection';
import { FormItem, FormItemFormSections } from 'src/app/models/form/form';

@Component({
  selector: 'app-form-sections',
  templateUrl: './form-sections.component.html',
  styleUrls: ['./form-sections.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSectionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormSectionsComponent),
      multi: true,
    },
  ],
})
export class FormSectionsComponent implements ControlValueAccessor, OnInit {
  // PROPERTIES

  @Input() item?: FormItemFormSections;
  @Input() disabled: boolean = false;
  @Input() allFormItemPathOptions?: FormItem<any>[];
  @Input() getFormattedItemPath?: (path: string) => string;

  @ViewChild('searchInput') searchInput?: ElementRef;

  formSectionsFormGroup: FormGroup = new FormGroup({
    formSectionsFormArray: new FormArray<FormSectionFormGroup>([]),
  });

  availableFormItemPathOptions: FormItem<any>[] = [];

  private onChange: any = () => {};
  private onTouched: any = () => {};
  validatorChange: any;

  // LIFECYCLE

  ngOnInit(): void {
    this.availableFormItemPathOptions = this.allFormItemPathOptions ?? [];

    this.formSectionsFormGroup.valueChanges.subscribe((value) => {
      // remove all form items that are empty
      value.formSectionsFormArray.forEach(
        (formSection: EmailOutputFormSection) => {
          formSection.formItems = formSection.formItems.filter((formItem) => {
            return formItem.path !== null && formItem.path !== '';
          });
        }
      );

      // remove all form sections that are empty
      const newFormSections = value.formSectionsFormArray.filter(
        (formSection: EmailOutputFormSection) => {
          return (
            formSection.formItems.length > 0 ||
            formSection.title ||
            formSection.description
          );
        }
      );

      this.onChange(newFormSections);
      this.onTouched();
    });
  }

  // filter the dropdown for selected items after the view has been initialized and the binding of previously selected items has been done
  // (otherwise the previously selected items would be filtered out as options before they are set as selected)
  ngAfterViewInit() {
    this.filterDropdown();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      this.handleInteractionState();
    }
  }

  // CONTROL VALUE ACCESSOR

  writeValue(value: any): void {
    this.formSectionsFormArray.clear();

    if (!Array.isArray(value)) {
      return;
    }

    // map the data to the form control values, so the structure represents a FormSection array
    value.forEach((currentFormSection, formSectionIndex) => {
      let formSection = new EmailOutputFormSection(currentFormSection);

      let formSectionFormGroup = this.getNewFormSectionFormGroup(formSection);
      this.formSectionsFormArray.push(formSectionFormGroup);

      const formItemsFormArray =
        this.formItemsFormArrayOfFormSection(formSectionIndex);

      formSection.formItems.forEach((formItem) => {
        formItemsFormArray.push(this.getNewFormItemFormGroup(formItem));
      });
    });

    this.formSectionsFormGroup?.updateValueAndValidity();
    this.handleInteractionState(); // call after all sections have been added
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // VALIDATION

  registerOnValidatorChange(fn: any): void {
    this.validatorChange = fn;
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    let maxLengthError = false;

    this.formSectionsFormArray.controls.forEach((control: FormGroup) => {
      const titleValue = control.get('title')?.value;
      const descriptionValue = control.get('description')?.value;

      if (titleValue && titleValue.length > (this.item?.maxLengthTitle ?? 0)) {
        maxLengthError = true;
      } else if (
        descriptionValue &&
        descriptionValue.length > (this.item?.maxLengthDescription ?? 0)
      ) {
        maxLengthError = true;
      }
    });

    if (maxLengthError) {
      return { maxlengthMultiple: true };
    }

    return null;
  }

  // ENABLE / DISABLE

  private handleInteractionState() {
    if (this.disabled) {
      this.formSectionsFormArray.controls.forEach((formSection) => {
        formSection.disable();
        formSection.controls.formItems.disable();
      });
    } else {
      this.formSectionsFormArray.controls.forEach((formSection) => {
        formSection.enable();
        formSection.controls.formItems.enable();
      });
    }
  }

  // ACCESS FORM GROUP COMPONENTS

  get formSectionsFormArray(): FormArray<FormSectionFormGroup> {
    return this.formSectionsFormGroup.get(
      'formSectionsFormArray'
    ) as FormArray<FormSectionFormGroup>;
  }

  formItemsFormArrayOfFormSection(
    formSectionIndex: number
  ): FormArray<FormItemFormGroup> {
    if (!this.formSectionsFormArray.at(formSectionIndex)) {
      return new FormArray<FormItemFormGroup>([]);
    }

    return this.formSectionsFormArray
      .at(formSectionIndex)
      .get('formItems') as FormArray;
  }

  // ADDING/REMOVING FORM CONTROLS

  getNewFormSectionFormGroup(
    formSection?: EmailOutputFormSection
  ): FormSectionFormGroup {
    return new FormGroup({
      title: new FormControl(formSection?.title ?? '', [
        Validators.maxLength(this.item?.maxLengthTitle ?? 0),
      ]),
      description: new FormControl(formSection?.description ?? '', [
        Validators.maxLength(this.item?.maxLengthDescription ?? 0),
      ]),
      formItems: new FormArray<FormItemFormGroup>([]),
    });
  }

  addFormSection() {
    this.formSectionsFormArray.push(this.getNewFormSectionFormGroup());
  }

  removeFormSection(formSectionIndex: number) {
    this.formSectionsFormArray.removeAt(formSectionIndex);
    this.filterDropdown();
  }

  getNewFormItemFormGroup(formItem?: EmailOutputFormItem): FormItemFormGroup {
    return new FormGroup({
      path: new FormControl(formItem?.path ?? null),
      required: new FormControl(formItem?.required ?? false),
      placeholder: new FormControl(formItem?.placeholder ?? ''),
    });
  }

  addFormItem(formSectionIndex: number) {
    this.formItemsFormArrayOfFormSection(formSectionIndex).push(
      this.getNewFormItemFormGroup()
    );
  }

  removeFormItem(formSectionIndex: number, formItemIndex: number) {
    this.formItemsFormArrayOfFormSection(formSectionIndex).removeAt(
      formItemIndex
    );
    this.filterDropdown();
  }

  // FORM ITEM DROPDOWN METHODS

  groupItemByPath(item: any): string {
    if (item && item.groupKey) {
      return item.groupKey;
    }

    return getFormattedItemPath(item.path);
  }

  filterDropdown(searchText: string = '') {
    // reset the available options to all options
    this.availableFormItemPathOptions = this.allFormItemPathOptions ?? [];

    // filter out all selected items
    this.filterDropdownForSelectedItems();

    // filter out all items that do not contain the search text
    this.filterDropdownForSearchText(searchText);
  }

  private filterDropdownForSelectedItems() {
    // get paths of all selected items
    var selectedItemPaths: string[] = [];
    this.formSectionsFormArray.controls.forEach((formSection) => {
      formSection.controls.formItems.value.forEach((formItem) => {
        selectedItemPaths.push(formItem.path);
      });
    });

    // filter out all selected items from the dropdown
    this.availableFormItemPathOptions =
      this.availableFormItemPathOptions.filter((formItem) => {
        if (!formItem.path) {
          return true;
        }
        if (selectedItemPaths.includes(formItem.path)) {
          return false;
        }
        return true;
      });
  }

  private filterDropdownForSearchText(searchText: string): void {
    // If the search bar is empty, do not filter the dropdown further
    if (searchText.trim() === '') {
      if (this.searchInput) {
        this.searchInput.nativeElement.value = '';
      }
      return;
    }

    const lowerCaseSearchText = searchText.toLowerCase();

    if (!this.availableFormItemPathOptions) {
      return;
    }

    // check if the label or the item path contains the search text - if so, keep the item
    this.availableFormItemPathOptions =
      this.availableFormItemPathOptions?.filter((option) => {
        let lowerCaseLabel = option.label.toLowerCase();
        let lowerCaseItemPath = getFormattedItemPath(
          option.path ?? ''
        ).toLowerCase();

        if (
          lowerCaseLabel.includes(lowerCaseSearchText) ||
          lowerCaseItemPath.includes(lowerCaseSearchText)
        ) {
          return true;
        }

        return false;
      });
  }
}

interface FormSectionFormGroup extends FormGroup {
  controls: {
    title: FormControl;
    description: FormControl;
    formItems: FormArray<FormItemFormGroup>;
  };
}

interface FormItemFormGroup extends FormGroup {
  controls: {
    path: FormControl;
    required: FormControl;
    placeholder: FormControl;
  };
}
