import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import { RegisterComponent } from './pages/register/register.component';

import { IndexComponent } from './pages/index/index.component';
import { CarerComponent } from './pages/carer/carer.component';

import { RegisterIntroComponent } from './pages/register/intro/intro.component';
import { RegisterSetupComponent } from './pages/register/setup/setup.component';
import { RegisterSetupPrivateKeyComponent } from './pages/register/setup/private-key/private-key.component';
import { RegisterSetupPermissionsComponent } from './pages/register/setup/permissions/permissions.component';
import { RegisterSetupRecordComponent } from './pages/register/setup/record/record.component';
import { LoginComponent } from './pages/login/login.component';
import { AdminConnectionsComponent } from './pages/admin-connections/admin-connections.component';
import { ConnectionComponent } from './pages/connection/connection.component';
import { FinderComponent } from './pages/finder/finder.component';
import { AdminConnectionComponent } from './pages/admin-connection/admin-connection.component';
import { AccountServicesComponent } from './pages/account/account-services/account-services.component';
import { AccountAboutMeComponent } from './pages/account/account-about-me/account-about-me.component';
import { AccountAboutMeSectionComponent } from './pages/account/account-about-me/account-about-me-section/account-about-me-section.component';
import { AdminFormItemsComponent } from './pages/admin-form-items/admin-form-items.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { MydexLoginComponent } from './pages/mydex-login/mydex-login.component';
import { RegisterSetupLoginComponent } from './pages/register/setup/login/login.component';
import { VerifiedGuard } from './guards/verified/verified.guard';
import { AccountWelcomeComponent } from './pages/account/account-welcome/account-welcome.component';
import { UpdatePermissionsComponent } from './pages/update-permissions/update-permissions.component';
import { ConnectionEmailReferralComponent } from './pages/connection-email-referral/connection-email-referral.component';
import { PageFaqComponent } from './pages/page-faq/page-faq.component';
import { PagePrivacyPolicyComponent } from './pages/page-privacy-policy/page-privacy-policy.component';
import { PageCookiePreferencesComponent } from './page-cookie-preferences/page-cookie-preferences.component';
import { PageOrganisationComponent } from './pages/page-organisation/page-organisation.component';
import { PageFinderSearchComponent } from './pages/page-finder-search/page-finder-search.component';
import { PageProblemReportComponent } from './pages/page-problem-report/page-problem-report.component';
import { PageAccountSummaryComponent } from './pages/account/page-account-summary/page-account-summary.component';
import { BlockAuthedGuard } from './guards/block-authed/block-authed.guard';
import { RegistrationProcessGuard } from './guards/registration-process/registration-process.guard';
import { RegistrationRedirectGuard } from './guards/registration-redirect/registration-redirect.guard';
import { CarerAssessmentComponent } from './pages/carer-assessment/carer-assessment.component';
import { ConnectionPdsReferralComponent } from './pages/connection-pds-referral/connection-pds-referral.component';
import { RegistrationEnabledGuard } from './guards/registration-enabled/registration-enabled.guard';
import { AdminGuard } from './guards/admin/admin.guard';
import { PostLoginRedirectGuard } from './guards/post-login-redirect/post-login-redirect.guard';
import { PageAccountReferralsComponent } from './pages/account/page-account-referrals/page-account-referrals.component';
// import { AccountConnectionsComponent } from './pages/account/account-connections/account-connections.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    title: 'Home',
    data: { layout: 'home' },
  },
  {
    path: 'finder',
    component: FinderComponent,
    title: 'Find Services',
    data: { layout: 'dashboard' },
    children: [
      {
        path: '**',
        component: FinderComponent,
      },
    ],
  },
  {
    path: 'search',
    component: PageFinderSearchComponent,
    title: 'Search Services',
    data: { layout: 'dashboard' },
  },
  {
    path: 'register',
    component: RegisterComponent,
    title: 'Register',
    canActivate: [RegistrationEnabledGuard, BlockAuthedGuard],
    data: { layout: 'auth' },
  },
  {
    path: 'registration',
    component: RegisterComponent,
    canActivate: [RegistrationRedirectGuard], // will always redirect to /register
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'Login',
    canActivate: [BlockAuthedGuard],
    data: { layout: 'auth' },
  },
  {
    path: 'register/intro',
    component: RegisterIntroComponent,
    title: 'Register - Intro',
    data: { layout: 'home' },
  },
  {
    path: 'register/setup',
    component: RegisterSetupComponent,
    title: 'Register - Setup',
    data: { layout: 'dashboard', sidebar: false },
    children: [
      {
        path: '',
        redirectTo: '/register/setup/private-key',
        pathMatch: 'full',
        data: { layout: 'dashboard', sidebar: false },
      },
      {
        path: 'private-key',
        component: RegisterSetupPrivateKeyComponent,
        title: 'Setup - Private Key',
        canActivate: [BlockAuthedGuard, RegistrationProcessGuard],
        data: { layout: 'dashboard', sidebar: false },
      },
      {
        path: 'record',
        component: RegisterSetupRecordComponent,
        title: 'Setup - Record',
        canActivate: [BlockAuthedGuard, RegistrationProcessGuard],
        data: { layout: 'dashboard', sidebar: false },
      },
      {
        path: 'login',
        component: RegisterSetupLoginComponent,
        title: 'Setup - Login',
        canActivate: [BlockAuthedGuard, RegistrationProcessGuard],
        data: { layout: 'dashboard', sidebar: false },
      },
      {
        path: 'permissions',
        component: RegisterSetupPermissionsComponent,
        title: 'Setup - Permissions',
        data: {
          layout: 'dashboard',
          sidebar: false,
          blockIfVerified: true,
          exemptConnectionStatus: 'no-connection',
        },
        canActivate: [VerifiedGuard],
      },
    ],
  },
  {
    path: 'setup/update-permissions',
    component: UpdatePermissionsComponent,
    title: 'Update Permissions',
    data: {
      layout: 'dashboard',
      sidebar: false,
      blockIfVerified: true,
      exemptConnectionStatus: 'expired',
    },
    canActivate: [VerifiedGuard],
  },
  {
    path: 'mydex-login',
    component: MydexLoginComponent,
    title: 'Mydex Login',
    data: { layout: 'dashboard', sidebar: false },
  },
  {
    path: 'faq',
    component: PageFaqComponent,
    title: 'FAQ',
    data: { layout: 'home' },
  },
  {
    path: 'report-problem',
    component: PageProblemReportComponent,
    title: 'Report a Problem',
    data: { layout: 'home' },
  },
  {
    path: 'privacy-policy',
    component: PagePrivacyPolicyComponent,
    title: 'Privacy Policy',
    data: { layout: 'home' },
  },
  {
    path: 'cookie-preferences',
    component: PageCookiePreferencesComponent,
    title: 'Cookie Preferences',
    data: { layout: 'home' },
  },
  {
    path: 'carer',
    component: CarerComponent,
    title: 'Carer',
    data: { layout: 'home' },
  },
  {
    path: 'carer/assessment',
    component: CarerAssessmentComponent,
    title: 'Carer Assessment',
    data: { layout: 'dashboard', sidebar: false },
  },
  {
    path: 'org/:slug',
    component: PageOrganisationComponent,
    data: { layout: 'org' },
  },
  {
    path: 'connection/:slug',
    component: ConnectionComponent,
    data: { layout: 'dashboard' },
  },
  {
    path: 'connection/:slug/contact-form',
    component: ConnectionEmailReferralComponent,
    data: { layout: 'dashboard' },
  },
  {
    path: 'connection/:slug/share-data-form',
    canActivate: [VerifiedGuard],
    component: ConnectionPdsReferralComponent,
    data: { layout: 'dashboard' },
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    data: { layout: 'admin' },
    children: [
      {
        path: 'connections',
        component: AdminConnectionsComponent,
        title: 'Admin - Connections',
      },
      {
        path: 'add-connection',
        component: AdminConnectionComponent,
        title: 'Admin - Connections',
      },
      {
        path: 'connections/:slug',
        component: AdminConnectionComponent,
        title: 'Admin - Connections',
      },
      {
        path: 'form-items',
        component: AdminFormItemsComponent,
        title: 'Admin - Form Items',
      },
    ],
  },
  {
    path: 'account',
    canActivate: [VerifiedGuard],
    data: { layout: 'dashboard' },
    children: [
      {
        path: '',
        redirectTo: '/account/services',
        pathMatch: 'full',
      },
      // TODO: uncomment when service/cc lifecycle is ready
      // {
      //   path: 'services',
      //   component: AccountServicesComponent,
      //   title: 'Services',
      // },
      {
        path: 'about-me',
        canActivate: [VerifiedGuard, PostLoginRedirectGuard],
        component: AccountAboutMeComponent,
        title: 'About Me',
      },
      {
        path: 'summary',
        component: PageAccountSummaryComponent,
        title: 'My Summary',
      },
      {
        path: 'referrals',
        component: PageAccountReferralsComponent,
        title: 'My Referrals',
      },
      {
        path: 'welcome',
        component: AccountWelcomeComponent,
        title: 'Welcome',
      },
      {
        path: 'about-me/:section',
        component: AccountAboutMeSectionComponent,
        title: 'About Me - Section',
        children: [
          {
            path: '**',
            component: AccountAboutMeSectionComponent,
            title: 'About Me - Section',
          },
        ],
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { layout: 'home' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
    CommonModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
