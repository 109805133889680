export class UserEvent {
  type?:
    | 'referral' // 'referral' is legacy support
    | 'interested'
    | 'pds-referral'
    | 'email-referral'
    | 'assessment';
  context?: string; // referral target ('me', 'them', 'quarriers', etc.)
  connection?: string; // connection name
  timestamp?: number;
  status?: 'completed' | 'in-progress';

  constructor(data: Partial<UserEvent | undefined>) {
    this.type = data?.type ?? 'email-referral';
    this.context = data?.context ?? 'no-context';
    this.connection = data?.connection;
    this.timestamp = data?.timestamp ? data.timestamp : Date.now();
    this.status = data?.status ?? 'completed'; // default to completed because this property was added after initialy only tracking completed events
  }
}
