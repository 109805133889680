export class UserMedication {
  name?: string;
  nameOther?: string; // used if name is not matching Mydex' options
  dose?: string;
  frequency?: string;
  guidance?: string;
  prescriberName?: string;
  // prescriptionStartDate?: Date; TODO: Re-add once Mydex allows for null
  dispensingPharmacy?: string;
  isRepeatPrescription?: string;
  // repeatPrescriptionDays?: number; TODO: Re-add once Mydex allows for null
  // repeatPrescriptionQuantity?: number; TODO: Re-add once Mydex allows for null
  // lastIssuedDate?: Date; TODO: Re-add once Mydex allows for null
  notes?: string;

  constructor(userMedication?: Partial<UserMedication>) {
    this.name = userMedication?.name;
    this.nameOther = userMedication?.nameOther;
    this.dose = userMedication?.dose;
    this.frequency = userMedication?.frequency;
    this.guidance = userMedication?.guidance;
    this.prescriberName = userMedication?.prescriberName;
    // this.prescriptionStartDate = userMedication?.prescriptionStartDate; TODO: Re-add once Mydex allows for null
    this.dispensingPharmacy = userMedication?.dispensingPharmacy;
    this.isRepeatPrescription = userMedication?.isRepeatPrescription;
    // this.repeatPrescriptionDays = userMedication?.repeatPrescriptionDays; TODO: Re-add once Mydex allows for null
    // this.repeatPrescriptionQuantity = userMedication?.repeatPrescriptionQuantity; TODO: Re-add once Mydex allows for null
    // this.lastIssuedDate = userMedication?.lastIssuedDate; TODO: Re-add once Mydex allows for null
    this.notes = userMedication?.notes;
  }
}
