import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';
import {
  FormItemButtonSelect,
  FormItemTextArea,
} from 'src/app/models/form/form';

// THIS IS ME - PASTIMES -----------------------

export const pastimesInterestsNow = new FormItemButtonSelect({
  key: 'pastimeInterestsNow',
  label: 'The types of things that you enjoy',
  path: 'me.pastimes.interestsNow',
  placeholder: 'Please select...',
  multiple: true,
  options: [
    { name: 'i_enjoy_reading', label: 'Reading' },
    { name: 'i_enjoy_walking', label: 'Walking' },
    { name: 'i_enjoy_tv', label: 'TV' },
    { name: 'i_enjoy_movies', label: 'Movies' },
    { name: 'i_enjoy_sports', label: 'Sport' },
    { name: 'i_enjoy_cooking', label: 'Cooking' },
    { name: 'i_enjoy_games', label: 'Games' },
    { name: 'i_enjoy_art', label: 'Art' },
    { name: 'i_enjoy_music', label: 'Music' },
    { name: 'i_enjoy_knitting', label: 'Knitting' },
    { name: 'i_enjoy_sewing', label: 'Sewing' },
    { name: 'i_enjoy_skiing', label: 'Skiing' },
    { name: 'i_enjoy_other', label: 'Other' },
  ],
  class: 'mb-5',
});

export const pastimesInterestsNowAdditionalInfo = new FormItemTextArea({
  key: 'pastimeInterestsNowAdditionalInfo',
  path: 'me.pastimes.interestsNowAdditionalInfo',
  label: 'Share more about the things you enjoy',
  placeholder: 'e.g. hiking',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const pastimesInterestsPast = new FormItemButtonSelect({
  key: 'pastimeInterestsPast',
  label: 'Things I used to enjoy',
  path: 'me.pastimes.interestsPast',
  placeholder: 'Please select...',
  multiple: true,
  options: [
    { name: 'used_to_enjoy_reading', label: 'Reading' },
    { name: 'used_to_enjoy_walking', label: 'Walking' },
    { name: 'used_to_enjoy_tv', label: 'TV' },
    { name: 'used_to_enjoy_movies', label: 'Movies' },
    { name: 'used_to_enjoy_sports', label: 'Sport' },
    { name: 'used_to_enjoy_cooking', label: 'Cooking' },
    { name: 'used_to_enjoy_games', label: 'Games' },
    { name: 'used_to_enjoy_art', label: 'Art' },
    { name: 'used_to_enjoy_music', label: 'Music' },
    { name: 'used_to_enjoy_knitting', label: 'Knitting' },
    { name: 'used_to_enjoy_sewing', label: 'Sewing' },
    { name: 'used_to_enjoy_skiing', label: 'Skiing' },
    { name: 'used_to_enjoy_other', label: 'Other' },
  ],
  class: 'mb-5',
});

export const pastimesInterestsPastAdditionalInfo = new FormItemTextArea({
  key: 'pastimesInterestsPastAdditionalInfo',
  path: 'me.pastimes.interestsPastAdditionalInfo',
  label: 'Share more about the things you used to enjoy',
  placeholder: 'e.g. stargazing',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});
