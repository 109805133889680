import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user/user';
import { UserUpdateRequest } from 'src/app/models/user/server/userUpdateRequest';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  private readonly baseUrl: string;
  http: any;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiBaseUrl + '/user-data';
    this.http = this.httpClient;
  }

  getUserData(): Observable<User> {
    return this.http
      .get(this.baseUrl)
      .pipe(map((user: User) => new User(user)));
  }

  updateUserData(user: User, serverMamPaths: string[]): Observable<User> {
    var request: UserUpdateRequest = {
      userData: user,
      mamModuleSubsectionsPaths: serverMamPaths,
    };
    return this.http.put(this.baseUrl, request).pipe(map(() => new User(user)));
  }
}
