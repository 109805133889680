import { Component, Input } from '@angular/core';
import { computeCompletedPdsSectionItemsPercentage } from 'src/app/helper/formCompleteness';
import { PdsSection } from 'src/app/models/pds/pdsSection';
import { User } from 'src/app/models/user/user';

@Component({
  selector: 'app-pds-section-card',
  templateUrl: './pds-section-card.component.html',
  styleUrl: './pds-section-card.component.css',
})
export class PdsSectionCardComponent {
  @Input() user?: User;
  @Input() pdsSection?: PdsSection;
  @Input() cardLink?: string;

  get completedPercentageOfSection() {
    return computeCompletedPdsSectionItemsPercentage(
      this.user,
      this.pdsSection
    );
  }
}
