<form
  [formGroup]="form"
  (keydown)="onKeydown($event)"
  class="form flex flex-wrap"
>
  <ng-container *ngFor="let item of formData">
    <div
      class="item-wrapper"
      [class.title]="item.type == 'title'"
      *ngIf="questionDisplayConditionsMet(item, form) && item.hide !== true"
      [class.half]="item.width == 'half'"
      [ngClass]="item.class"
    >
      <div
        *ngIf="
          item.type !== 'title' && item.type !== 'checkbox' && item.showLabel
        "
        class="mb-2"
      >
        <label [attr.for]="item.key" class="form-label w-100 title-label">
          {{ item.label }}
          <span class="text-theme" *ngIf="item.required">*</span>
          <span
            class="inline-block align-middle relative"
            style="padding-bottom: 1px"
          >
            <app-tooltip
              class="ml-1"
              *ngIf="item.tooltip"
              [text]="item.tooltip"
              [accessibilityId]="item.key + '-tooltip'"
            ></app-tooltip>
          </span>
          <span
            class="float-end form-error-text text-error-red"
            *ngIf="
              this.form?.controls &&
              this.form.controls[item.key] &&
              !this.form.controls[item.key].valid &&
              (this.form.controls[item.key].dirty ||
                this.form.controls[item.key].touched)
            "
          >
            <span [hidden]="!formError('required', item.key)">
              <span>Required</span>
            </span>
            <span [hidden]="!formError('pattern', item.key)">
              {{ getCustomErrorMessage("pattern", item, "Incorrect format.") }}
            </span>
            <span [hidden]="!formError('minlength', item.key)">
              Value is too short.
            </span>
            <span [hidden]="!formError('minlengthMultiple', item.key)">
              One or several values are too short.
            </span>
            <span
              [hidden]="
                !formError('maxlength', item.key) &&
                !formError('maxByteLength', item.key)
              "
            >
              The input value is too long.
            </span>
            <span [hidden]="!formError('maxlengthMultiple', item.key)">
              One or several values are too long.
            </span>
            <span [hidden]="!formError('min', item.key)">
              Value is below the minimum.
            </span>
            <span [hidden]="!formError('max', item.key)">
              Value is above the maximum.
            </span>
            <span [hidden]="!formError('invalidDOB', item.key)">
              Please enter a valid date of birth.
            </span>
            <span [hidden]="!formError('invalidPdsPhoneNumber', item.key)">
              Please enter a valid phone number.
            </span>
            <span
              class="opacity-70"
              [hidden]="!formError('requiredFields', item.key)"
            >
              <!-- For multi-field controls where all are needed for a value, ie date of birth -->
              *Make sure to fill out all fields.
            </span>
            <span [hidden]="!formError('email', item.key)">
              Please enter a valid email address.
            </span>
            <span [hidden]="!formError('invalidDropDown', item.key)">
              Please select a valid option.
            </span>
          </span>
          <span
            class="float-end text-theme inline-block"
            *ngIf="
              form?.controls &&
              form.controls[item.key] &&
              form.controls[item.key].touched &&
              !form.controls[item.key].errors
            "
          >
            <app-icon name="check"></app-icon>
          </span>
        </label>
        <small class="help">{{ item.help }}</small>
      </div>

      <div
        [ngSwitch]="item.type"
        class="form-item"
        [ngClass]="{ 'has-icon': item.icon?.length }"
      >
        <div class="icon-wrapper z-10" *ngIf="item.icon">
          <img
            class="icon"
            [src]="'/assets/img/icons/' + item.icon"
            [alt]="item.icon"
          />
        </div>

        <div *ngSwitchCase="'subform'">
          <app-subform
            [item]="item"
            [loading]="loading"
            [formControlName]="item.key"
            [control]="form.controls[item.key]"
            (submitParentForm)="submitForm($event)"
            [disabled]="loading || item.disabled || disabledForm"
          ></app-subform>
        </div>

        <div *ngSwitchCase="'repeater'">
          <app-repeater
            [item]="item"
            [formControl]="form.controls[item.key]"
            [control]="form.controls[item.key]"
            [disabled]="loading || item.disabled || disabledForm"
            [loading]="loading"
          ></app-repeater>
        </div>

        <ng-container *ngSwitchCase="'select'">
          <app-select
            [item]="item"
            [formControlName]="item.key"
            [control]="form.controls[item.key]"
            [disabled]="loading || item.disabled || disabledForm"
          ></app-select>
        </ng-container>

        <app-image-upload
          *ngSwitchCase="'image-upload'"
          class="flex flex-col"
          [item]="item"
          [formControlName]="item.key"
          [control]="form.controls[item.key]"
          [disabled]="loading || item.disabled || disabledForm"
        ></app-image-upload>

        <app-image-upload-gallery
          *ngSwitchCase="'image-upload-gallery'"
          #imagesItem
          class="flex flex-col"
          [item]="item"
          [formControlName]="item.key"
          [control]="form.controls[item.key]"
          [disabled]="loading || item.disabled || disabledForm"
          [imagesThumbnailsSource]="imagesItemOptions"
          [imagesThumbnailsSourceLoading]="imagesItemLoadingAll"
          [imagesThumbnailsSourceLoadingError]="imagesItemLoadingAllError"
          [imageFullSizeLoading]="imagesItemLoadingSingleImage"
          [imageFullSizeLoadingError]="imagesItemLoadingSingleImageError"
          [imageAdding]="imagesItemAdding"
          [imageAdditionError]="imagesItemAdditionError"
          [imageUpdating]="imagesItemUpdating"
          [imageUpdatingError]="imagesItemUpdatingError"
          [imageDeleting]="imagesItemDeleting"
          [imageDeletionError]="imagesItemDeletionError"
          (fullSizeImageRequested)="handleFullSizeImageRequested($event)"
          (imageAdded)="handleImageAdded($event)"
          (imageUpdated)="handleImageUpdated($event)"
          (imageDeleted)="handleImageDeleted($event)"
          (clearErrors)="handleClearImagesItemErrors($event)"
        ></app-image-upload-gallery>

        <app-file-upload
          *ngSwitchCase="'file-upload'"
          class="flex flex-col"
          [item]="item"
          [formControlName]="item.key"
          [control]="form.controls[item.key]"
          [disabled]="loading || item.disabled || disabledForm"
        ></app-file-upload>

        <div class="form-item relative" *ngSwitchCase="'dropdown'">
          <select
            [formControlName]="item.key"
            [id]="item.key"
            #dropdown
            class="form-select relative pr-10 truncate"
            [class.is-placeholder]="
              !form.controls[item.key].value ||
              form.controls[item.key].value == ''
            "
            [class.is-invalid]="
              form.controls[item.key].touched && form.controls[item.key].errors
            "
            [disabled]="loading || item.disabled || disabledForm"
          >
            <option
              class="disabled-option"
              *ngIf="item.placeholder?.length"
              value=""
            >
              {{ item.placeholder }}
            </option>

            <ng-container *ngFor="let group of item.optionGroups">
              <optgroup [label]="group.label">
                <option
                  *ngFor="let option of group.options"
                  [value]="option.name"
                >
                  {{ option.label ?? option.name }}
                </option>
              </optgroup>
            </ng-container>

            <option *ngFor="let option of item.options" [value]="option.name">
              {{ option.label ?? option.name }}
            </option>
          </select>
          <span
            class="ng-arrow-wrapper z-10 absolute right-0 top-0 h-full flex pointer-events-none"
            ><span class="ng-arrow my-auto mr-[25px]"></span
          ></span>
        </div>

        <app-button-select
          [classes]="item.class"
          [formControlName]="item.key"
          [options]="item.options"
          [multiple]="item.multiple"
          [styleType]="item.styleType"
          [disabled]="loading || item.disable || disabledForm"
          [requireOneSelection]="item.requireOneSelection"
          *ngSwitchCase="'buttonselect'"
        ></app-button-select>

        <app-address
          *ngSwitchCase="'address'"
          [options]="item.options"
          [item]="item"
          [formControlName]="item.key"
          [isInvalid]="
            form?.controls &&
            form.controls[item.key] &&
            form.controls[item.key].touched &&
            form.controls[item.key].errors
          "
          (autofill)="handleAutofill($event)"
          [disabled]="loading || item.disabled || disabledForm"
        ></app-address>

        <app-dob
          *ngSwitchCase="'dob'"
          [formControl]="form.controls[item.key]"
          [id]="item.key"
          [item]="item"
          [class.is-invalid]="
            form?.controls &&
            form.controls[item.key] &&
            form.controls[item.key].touched &&
            form.controls[item.key].errors
          "
          [disabled]="loading || item.disabled || disabledForm"
        ></app-dob>

        <app-email-content-control
          *ngSwitchCase="'emailcontent'"
          [formControlName]="item.key"
          [item]="item"
          [control]="form.controls[item.key]"
          [disabled]="loading || item.disabled || disabledForm"
        ></app-email-content-control>

        <app-contacts
          *ngSwitchCase="'contacts'"
          #contactsItem
          [formControl]="form.controls[item.key]"
          [item]="item"
          [control]="form.controls[item.key]"
          [disabled]="loading || item.disabled || disabledForm"
          [contactsSource]="contactsItemOptions"
          [contactsSourceLoading]="contactsItemLoading"
          [contactsSourceLoadingError]="contactsItemLoadingError"
          [contactsSourceSaving]="contactsItemSaving"
          [contactsSourceSavingError]="contactsItemSavingError"
          [contactsSourceDeleting]="contactsItemDeleting"
          [contactsSourceDeletionError]="contactsItemDeletionError"
          (contactCreated)="handleContactCreated($event)"
          (contactUpdated)="handleContactUpdated($event)"
          (contactDeleted)="handleContactDeleted($event)"
          (clearErrors)="handleClearContactsItemErrors($event)"
        ></app-contacts>

        <app-medications-control
          *ngSwitchCase="'medications'"
          #medicationsItem
          [formControl]="form.controls[item.key]"
          [item]="item"
          [control]="form.controls[item.key]"
          [disabled]="loading || item.disabled || disabledForm"
          [allMedications]="medicationsItemAllMedications"
          [allMedicationsLoading]="medicationsItemLoadingAll"
          [allMedicationsLoadingError]="medicationsItemLoadingAllError"
          [medicationDetailsLoading]="medicationsItemLoadingDetails"
          [medicationDetailsLoadingError]="medicationsItemLoadingDetailsError"
          [medicationAdding]="medicationsItemAdding"
          [medicationAdditionError]="medicationsItemAdditionError"
          [medicationUpdating]="medicationsItemUpdating"
          [medicationUpdateError]="medicationsItemUpdateError"
          [medicationDeleting]="medicationsItemDeleting"
          [medicationDeletionError]="medicationsItemDeletionError"
          (medicationDetailsRequested)="
            handleMedicationDetailsRequested($event)
          "
          (medicationAdded)="handleMedicationAdded($event)"
          (medicationUpdated)="handleMedicationUpdated($event)"
          (medicationDeleted)="handleMedicationDeleted($event)"
          (clearErrors)="handleClearMedicationItemErrors($event)"
        ></app-medications-control>

        <app-form-sections
          *ngSwitchCase="'formsections'"
          [formControlName]="item.key"
          [item]="item"
          [allFormItemPathOptions]="item.formItemPathOptions"
          [getFormattedItemPath]="item.getFormattedItemPath"
          [id]="item.key"
          [disabled]="loading || item.disabled || disabledForm"
        ></app-form-sections>

        <app-display-logic
          *ngSwitchCase="'displaylogic'"
          [formControlName]="item.key"
          [id]="item.key"
          [class.is-invalid]="
            form.controls[item.key].touched && form.controls[item.key].errors
          "
          [disabled]="loading || item.disabled || disabledForm"
        ></app-display-logic>

        <app-textbox-with-affix
          *ngSwitchCase="'textboxwithaffix'"
          [formControlName]="item.key"
          [item]="item"
          [control]="form.controls[item.key]"
          [disabled]="loading || item.disabled || disabledForm"
        >
        </app-textbox-with-affix>

        <div class="input-group" *ngSwitchCase="'textbox'">
          <div class="input-group-prepend" *ngIf="item.unit">
            <span class="input-group-text" id="">{{ item.unit }}</span>
          </div>

          <input
            [formControlName]="item.key"
            [id]="item.key"
            [type]="item.type"
            [placeholder]="item.placeholder"
            class="form-control"
            [autocomplete]="item.autocomplete"
            [place]="item.place"
            [disabled]="loading || item.disabled || disabledForm"
            [class.is-invalid]="
              form?.controls &&
              form.controls[item.key] &&
              form.controls[item.key].touched &&
              form.controls[item.key].errors
            "
            *ngIf="!item.hide"
          />

          <div class="input-group-append" *ngIf="item.action">
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="handleActionClicked(item.action)"
              [disabled]="loading || item.disabled || disabledForm"
            >
              {{ item.action.label }}
            </button>
          </div>
        </div>

        <ng-container *ngSwitchCase="'title'">
          <h1 *ngIf="item.titleType === 'h1'" class="mt-0 mb-1">
            {{ item.label }}
          </h1>
          <h2
            *ngIf="!item.titleType || item.titleType === 'h2'"
            class="form-title-h2 mb-1"
          >
            {{ item.label }}
          </h2>
          <h3 *ngIf="item.titleType === 'h3'" class="mt-0 mb-1">
            {{ item.label }}
          </h3>
          <h4 *ngIf="item.titleType === 'h4'" class="mt-0 mb-1">
            {{ item.label }}
          </h4>

          <p *ngIf="item.titleType === 'p'" class="mt-0 mb-1">
            {{ item.label }}
          </p>
          <small class="help mb-4">{{ item.help }}</small>
        </ng-container>

        <ng-container *ngSwitchCase="'link'">
          <p class="mb-1 mt-1">{{ item.description }}</p>
          <a
            *ngIf="item.linkType === 'routerlink'"
            [routerLink]="item.link"
            class="mt-0 mb-1 text-theme"
          >
            {{ item.linkLabel }}
          </a>
          <a
            *ngIf="item.linkType === 'href'"
            [href]="item.link"
            target="_blank"
            class="mt-0 mb-1 text-theme"
          >
            {{ item.linkLabel }}
          </a>
          <small class="help mb-4">{{ item.help }}</small>
        </ng-container>

        <div *ngSwitchCase="'number'" class="input-group">
          <div class="input-group-prepend" *ngIf="item.unit">
            <span class="input-group-text">{{ item.unit }}</span>
          </div>
          <input
            [formControlName]="item.key"
            [id]="item.key"
            [name]="item.key"
            [type]="item.type"
            [placeholder]="item.placeholder"
            [disabled]="loading || item.disabled || disabledForm"
            [value]="item.value"
            class="form-control"
            [class.is-invalid]="
              form?.controls &&
              form.controls[item.key] &&
              form.controls[item.key].touched &&
              form.controls[item.key].errors
            "
          />
        </div>

        <div class="input-group password-input" *ngSwitchCase="'password'">
          <input
            [formControlName]="item.key"
            [id]="item.key"
            [type]="item.showPassword ? 'text' : 'password'"
            [disabled]="loading || item.disabled || disabledForm"
            [placeholder]="item.placeholder"
            class="form-control"
            [class.is-invalid]="
              form?.controls &&
              form.controls[item.key] &&
              form.controls[item.key].touched &&
              form.controls[item.key].errors
            "
          />

          <button
            class="show-password-btn"
            type="button"
            (click)="togglePasswordVisibility(item)"
            [disabled]="loading || item.disabled || disabledForm"
          >
            <img
              src="/assets/img/icons/hidePassword.svg"
              alt=""
              *ngIf="item.showPassword"
            />
            <img
              src="/assets/img/icons/showPassword.svg"
              alt=""
              *ngIf="!item.showPassword"
            />
          </button>
        </div>

        <app-date-picker-control
          *ngSwitchCase="'date-picker'"
          [formControlName]="item.key"
          [item]="item"
          [control]="form.controls[item.key]"
          [disabled]="loading || item.disabled || disabledForm"
        >
        </app-date-picker-control>

        <app-textarea
          *ngSwitchCase="'textarea'"
          [formControlName]="item.key"
          [item]="item"
          [control]="form.controls[item.key]"
          [disabled]="loading || item.disabled || disabledForm"
        >
        </app-textarea>

        <div
          class="form-text"
          *ngSwitchCase="'html'"
          [innerHTML]="item.label"
        ></div>

        <div class="form-text flex flex-col" *ngSwitchCase="'checkbox'">
          <label
            class="custom-checkbox cursor flex cursor-pointer mb-0 mr-auto"
            [ngStyle]="{
              'pointer-events':
                loading || item.disabled || disabledForm ? 'none' : ''
            }"
          >
            <input
              type="checkbox"
              [id]="item.key"
              class="hidden"
              [formControlName]="item.key"
              [disabled]="loading || item.disabled || disabledForm"
            />
            <div
              class="checkbox w-4 h-4 rounded border border-solid flex text-white transition-colors my-auto"
              [ngClass]="[
                form.controls[item.key].value
                  ? 'bg-theme border-theme'
                  : 'bg-white border-gray-500',
                loading || item.disabled || disabledForm
                  ? 'disabled-button'
                  : ''
              ]"
            >
              <app-icon
                name="check"
                [class.opacity-100]="form.controls[item.key].value"
              ></app-icon>
            </div>

            <span
              class="my-auto ml-3 pb-[1px]"
              [class.text-danger]="
                item.required &&
                form?.controls &&
                form.controls[item.key] &&
                form.controls[item.key].value !== true &&
                form.controls[item.key].touched
              "
              (click)="onLabelClick($event)"
            >
              <ng-container *ngIf="item.labelIsHtml; else plainLabel">
                <span [innerHTML]="item.label"></span>
              </ng-container>
              <ng-template #plainLabel>
                {{ item.label }}
              </ng-template>
              <span class="text-theme ml-1" *ngIf="item.required">*</span></span
            >
          </label>
          <small class="help">{{ item.help }}</small>
        </div>
      </div>
    </div>
  </ng-container>
</form>
<ng-content selector=".child"></ng-content>
<div class="submit-area flex justify-between" *ngIf="!noSubmit">
  <button
    *ngIf="removable"
    class="btn-inline text-red-600 underline opacity-50 hover:opacity-100 transition-opacity font-semibold mr-8"
    (click)="handleRemove()"
    [disabled]="disabled || loading || disabledForm"
  >
    {{ removeText ?? "Remove Item" }}
  </button>
  <div
    class="flex flex-1 items-center"
    [ngClass]="inlineSubmitError ? 'justify-end' : 'flex-col-reverse'"
  >
    <div
      *ngIf="error.length"
      class="form-error-text text-error-red float-end my-auto mr-4"
    >
      {{ error }}
    </div>
    <button
      *ngIf="cancellable"
      class="btn-inline underline opacity-50 hover:opacity-100 transition-opacity font-semibold mr-8"
      (click)="handleCancel()"
      type="button"
    >
      {{ cancelText }}
    </button>

    <button
      class="btn m-0 btn-theme submit-btn flex justify-center float-end btn-sm h-14 items-center"
      (click)="submitForm($event)"
      [type]="isSubForm ? 'button' : 'submit'"
      [disabled]="loading || submitDisabled || disabled || disabledForm"
      [ngClass]="{
        'pointer-events-none': loading || submitDisabled || disabled || disabledForm,
        'btn-theme-outline text-theme': isSubForm,
      }"
    >
      <ng-container
        [ngSwitch]="loading ? 'loading' : success ? 'success' : null"
      >
        <div class="opacity-75 flex mx-auto" *ngSwitchCase="'loading'">
          <app-loading-spinner [text]="loadingText"></app-loading-spinner>
        </div>

        <div class="flex opacity-90 mx-auto" *ngSwitchCase="'success'">
          <app-icon
            class="h-5"
            [width]="25"
            [height]="25"
            name="check"
          ></app-icon>
          <span class="inline-block ml-1">{{ successText }}</span>
        </div>

        <ng-container *ngSwitchDefault>
          {{ submitText }}
        </ng-container>
      </ng-container>
    </button>
  </div>
</div>
