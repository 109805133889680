import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { getErrorMessage } from '../../helper/getErrorMessage';
import { EmailReferralRequest } from '../../models/referral/server/emailReferralRequest';

@Injectable({ providedIn: 'root' })
export class EmailReferralApiService {
  // PROPERTIES

  private _sending$ = new BehaviorSubject<boolean>(false);
  private _sendingError$ = new BehaviorSubject<string>('');

  // PUBLIC GETTERS

  public sending$: Observable<boolean> = this._sending$.asObservable();
  public sendingError$: Observable<string> = this._sendingError$.asObservable();

  // CONSTRUCTOR

  constructor(private _http: HttpClient) {}

  // PUBLIC API

  send(emailReferralRequest: EmailReferralRequest): Observable<any> {
    this._sending$.next(true);
    this._sendingError$.next('');

    return this._http
      .post(
        `${environment.apiBaseUrl}/referral/send-email-referral`,
        emailReferralRequest
      )
      .pipe(
        tap(() => {
          this._sending$.next(false);
        }),
        catchError((error) => {
          this._sendingError$.next(
            getErrorMessage(error, 'Unable to send referral email')
          );
          this._sending$.next(false);
          return throwError(() => error);
        })
      );
  }

  resetError() {
    this._sendingError$.next('');
  }
}
