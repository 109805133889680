import { ConnectionDisplayType } from './connectionDisplayType';
import {
  ConnectionOutput,
  OutputContact,
  OutputEmail,
  OutputPds,
  OutputRedirect,
} from './connectionOutput';
import { ConnectionDisplayLogic } from './connectionDisplayLogic';
import { EmailOutputFormSection } from './outputFormSection';
import { ConnectionServerModel } from './server/connectionServerModel';
import { ConnectionSummary } from './connectionSummary';

export class Connection {
  // PROPERTIES

  // information
  source: 'manual-input' | 'mrd';
  type: 'connection' | 'signpost';
  alissId?: string;
  name: string; // a unique name + used as the slug, regex: ^([a-z0-9]+-?)+$
  title: string; // 80 character title
  excerpt: string; // 200 character intro to the connection
  image: string; // URL to an image for the connection
  icon?: string; // Name of an icon or the SVG. The icon component will determine which it is by the <svg> tag
  priority: number; // used for ordering 1-5 from org perspective (1 is highest, 5 is lowest)
  priorityUser: number; // used for ordering 1-5 from user perspective (1 is highest, 5 is lowest)
  postcode?: string; // used for postcode lookup
  organisation?: string; // used for organisation lookup
  dataUsageStatement?: string; // What the connection intends to do with the data

  // links
  appLink?: {
    url: string;
    text: string;
  };

  webLink?: {
    url: string;
    text: string;
  };

  // content
  summary?: ConnectionSummary;

  // outputs
  outputs: ConnectionOutput<
    OutputPds | OutputEmail | OutputRedirect | OutputContact
  >[];

  // email output forms
  emailOutputForm: EmailOutputFormSection[];
  emailOutputFormThem?: EmailOutputFormSection[];
  useSeparateEmailOutputFormThem?: boolean; // used as display condition for emailOutputFormThem in connection editor

  // display
  finderPaths: string[];
  displayInPublicFinder: boolean; // Whether the connection should be displayed in the public finder
  displayLogic?: ConnectionDisplayLogic;
  displayType?: ConnectionDisplayType; // how the connection shall be displayed in the app (not modeled out in backend yet)

  // PUBLIC GETTERS
  get emailOutput(): OutputEmail | undefined {
    return this.outputs.find((output) => output.type === 'email') as
      | OutputEmail
      | undefined;
  }

  get pdsOutput(): OutputPds | undefined {
    return this.outputs.find((output) => output.type === 'pds') as
      | OutputPds
      | undefined;
  }

  get outputsNoLegacySortedByTypePriority(): ConnectionOutput<
    OutputPds | OutputEmail | OutputRedirect | OutputContact
  >[] {
    const typePriority: Record<string, number> = {
      contact: 1,
      pds: 2,
      email: 3,
      redirect: 4,
    };

    // remove redirect from the list of outputs
    const outputs = this.outputs.filter((output) => output.type !== 'redirect');

    // sort the outputs by type priority
    return outputs.sort((a, b) => {
      if (typeof a.type === 'string' && typeof b.type === 'string') {
        return typePriority[a.type] - typePriority[b.type];
      }
      return 0; // fallback if type is invalid (or undefined)
    });
  }

  get allFormItemPaths(): string[] {
    return this.emailOutputForm.flatMap(
      (formSection) => formSection.allFormSectionItemPaths
    );
  }

  get allRequiredFormItemPaths(): string[] {
    return this.emailOutputForm.flatMap(
      (formSection) => formSection.allRequiredFormSectionItemPaths
    );
  }

  get allThemFormItemPaths(): string[] | undefined {
    return this.emailOutputFormThem?.flatMap(
      (formSection) => formSection.allFormSectionItemPaths
    );
  }

  // CONSTRUCTOR
  constructor(connection: Partial<Connection>) {
    this.source = connection.source ?? 'manual-input';
    this.alissId = connection.alissId;
    this.name = connection.name ?? 'no-connection-name';
    this.type = connection.type === 'signpost' ? 'signpost' : 'connection';
    this.title = connection.title ?? 'Connection Title missing';
    this.excerpt = connection.excerpt ?? '';
    this.image =
      connection.image ??
      'https://images.pexels.com/photos/5825696/pexels-photo-5825696.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
    this.icon = connection.icon;
    this.priority = connection.priority ?? 3;
    this.priorityUser = connection.priorityUser ?? 5;
    this.postcode = connection.postcode;
    this.organisation = connection.organisation;
    this.dataUsageStatement = connection.dataUsageStatement;

    this.appLink = connection.appLink;
    this.webLink = connection.webLink;
    this.summary = new ConnectionSummary(connection.summary);
    this.finderPaths = connection.finderPaths ?? [];
    this.displayInPublicFinder = connection.displayInPublicFinder ?? true;
    this.displayLogic = connection.displayLogic;

    this.outputs = mapConnectionOutputs(connection.outputs);
    this.emailOutputForm = Array.isArray(connection?.emailOutputForm)
      ? connection.emailOutputForm.map((e) => new EmailOutputFormSection(e))
      : [];
    this.emailOutputFormThem = Array.isArray(connection?.emailOutputFormThem)
      ? connection.emailOutputFormThem.map((e) => new EmailOutputFormSection(e))
      : [];
    this.useSeparateEmailOutputFormThem =
      connection.useSeparateEmailOutputFormThem ?? false;
  }

  public static fromConnectionServerModel(
    serverModel: ConnectionServerModel
  ): Connection {
    let connection = new Connection({});

    connection.source = serverModel.source || 'manual-input';
    connection.alissId = serverModel.alissId;
    connection.name = serverModel.name || 'no-connection-name';
    connection.type =
      serverModel.type === 'signpost' ? 'signpost' : 'connection';
    connection.title = serverModel.title || 'Connection Title missing';
    connection.excerpt = serverModel.excerpt || '';
    connection.image =
      serverModel.image ||
      'https://images.pexels.com/photos/5825696/pexels-photo-5825696.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
    connection.icon = serverModel.icon;
    connection.priority = serverModel.priority || 3;
    connection.priorityUser = serverModel.priorityUser || 5;
    connection.postcode = serverModel.postcode;
    connection.organisation = serverModel.organisation;
    connection.dataUsageStatement = serverModel.dataUsageStatement;

    connection.appLink = serverModel.connectionData.appLink;
    connection.webLink = serverModel.connectionData.webLink;
    connection.summary = new ConnectionSummary(
      serverModel.connectionData.summary
    );
    connection.finderPaths = serverModel.connectionData.finderPaths;
    connection.emailOutputForm = Array.isArray(serverModel.connectionData?.data)
      ? serverModel.connectionData.data.map(
          (e) => new EmailOutputFormSection(e)
        )
      : [];
    connection.emailOutputFormThem = Array.isArray(
      serverModel.connectionData?.outputFormThem
    )
      ? serverModel.connectionData.outputFormThem.map(
          (e) => new EmailOutputFormSection(e)
        )
      : [];
    connection.useSeparateEmailOutputFormThem =
      serverModel.connectionData.useSeparateOutputFormThem ?? false;

    connection.displayInPublicFinder =
      serverModel.connectionData.displayInPublicFinder;
    connection.displayLogic = serverModel.connectionData.displayLogic;
    connection.outputs = mapConnectionOutputs(
      serverModel.connectionData.outputs
    );

    return connection;
  }
}

// HELPER FUNCTIONS

function mapConnectionOutputs(
  outputs?:
    | ConnectionOutput<
        OutputPds | OutputEmail | OutputRedirect | OutputContact
      >[]
    | undefined
): ConnectionOutput<
  OutputPds | OutputEmail | OutputRedirect | OutputContact
>[] {
  if (!outputs) {
    return [];
  }

  return outputs.map((c) => {
    switch (c.type) {
      case 'contact':
        return new OutputContact(c);
      case 'email':
        return new OutputEmail(c);
      case 'pds':
        return new OutputPds(c);
      default:
        return new OutputRedirect(c); // legacy support
    }
  });
}
