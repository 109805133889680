<div class="home-container">
  <div
    class="header flex mb-24 sm:mb-48 lg:mb-32 justify-between flex-col lg:flex-row pt-[1.5vw]"
  >
    <div class="lg:w-2/4 max-w-lg my-auto pb-[6vh] mx-2">
      <h1 class="text-4xl sm:text-5xl mb-12">
        Supporting you and connecting you to what matters.
      </h1>
      <div class="flex flex-col">
        <a routerLink="/register" class="flex-1 block">
          <button class="btn-theme outlined w-full home-btn">
            Create your <strong>About Me</strong>
          </button>
        </a>
        <div class="w-4">&nbsp;</div>
        <button
          (click)="openVideoModal($event)"
          class="flex-1 btn-theme-outline home-btn flex justify-center"
        >
          <app-icon name="play" class="mr-2 pt-[1px] my-auto"></app-icon>
          Watch the Film
        </button>
      </div>
    </div>
    <div class="lg:w-2/4 flex">
      <img
        class="mx-0 mr-auto lg:mx-auto w-full max-w-lg xs:mt-12 sm:mt-16 lg:mt-0 lg:max-w-2xl"
        src="/assets/img/home-header-bg.svg"
        alt=""
      />
    </div>
  </div>

  <div class="mb-32">
    <div
      class="flex flex-col lg:grid grid-cols-1 lg:grid-cols-3 gap-x-16 help-grid mt-8"
    >
      <p class="text-2xl mb-12 col-span-2">
        Community Connections supports older people and those caring for them to
        find services that matter to them more easily and directly connect.
      </p>
      <div></div>
      <a
        routerLink="/finder"
        routerLinkActive="router-link-active"
        class="mb-8 lg:mb-0"
      >
        <div class="card h-full box-border">
          <img src="/assets/img/icons/home-pin.svg" alt="" class="home-icon" />
          <h3 class="text-2xl text-theme font-medium">Find Services</h3>
          <p class="mb-0">
            Search our directory for help and guidance on your support needs.
          </p>
        </div>
      </a>
      <a routerLink="/register" class="mb-8 lg:mb-0">
        <div class="card box-border h-full">
          <img src="/assets/img/icons/home-user.svg" alt="" class="home-icon" />
          <h3 class="text-2xl text-theme font-medium">
            Create your <strong>About Me</strong>
          </h3>
          <p class="mb-0">
            Build your own story and start your personal data store.
          </p>
        </div>
      </a>
      <a routerLink="/carer" class="mb-8 lg:mb-0">
        <div class="card h-full box-border">
          <img
            src="/assets/img/icons/home-group.svg"
            alt=""
            class="home-icon"
          />
          <h3 class="text-2xl text-theme font-medium">Unpaid Carers</h3>
          <p class="mb-0">Discover support options for unpaid carers.</p>
        </div>
      </a>
    </div>
  </div>

  <div class="flex mb-32">
    <div class="lg:w-2/4 hidden lg:flex lg:mr-16">
      <img
        class="mx-auto w-full max-w-2xl"
        src="/assets/img/home-illustration.svg"
        alt=""
      />
    </div>
    <div class="lg:w-2/4 kg:max-w-lg m-auto flex flex-col">
      <h2 class="text-4xl mb-10">
        "Everything's in one place and I don't need to have the same
        conversation 100 times"
      </h2>
      <a routerLink="/register" class="mr-auto btn-theme home-btn px-8 btn">
        Create your <strong>About Me</strong>
      </a>
    </div>
  </div>
</div>

<!--VIDEO MODAL-->
<app-modal (onClose)="onVideoModalClose()">
  <div [hidden]="!player" class="relative w-full pt-[56.25%]">
    <div #vimeoPlayer class="absolute inset-0 w-full h-full"></div>
  </div>
  <p [hidden]="player">
    Optional: Enable the video player cookies in your
    <a routerLink="/cookie-preferences" class="text-theme"
      >Cookie Preferences</a
    >
    to allow us to display the Vimeo video about Community Connections.
  </p>
</app-modal>
<!--END VIDEO MODAL-->
