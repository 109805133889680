import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';
import { pdsPhoneNumberValidator } from 'src/app/helper/validators/pdsPhoneNumberValidator';
import {
  FormItemButtonSelect,
  FormItemContacts,
  FormItemDatePicker,
  FormItemDropdown,
  FormItemImageUpload,
  FormItemSelect,
  FormItemTextArea,
  FormItemTextbox,
} from 'src/app/models/form/form';
import {
  pdsEthnicityOptionGroups,
  yesNoInProgressLegacyOptions,
  yesNoOptions,
  yesNoUnknownOptions,
} from './item-options';
import {
  getUtcYesterdayMidnight,
  utcDateAtMidnightFromYmd,
} from 'src/app/helper/dateHelper';
import { ContactForm } from 'src/app/models/contact/contactForm';
import {
  appointeeTooltip,
  guardianshipTooltip,
  powerOfAttorneyTooltip,
} from 'src/assets/texts/tooltips';
import {
  getFormItemsAddressUserContact,
  getFormItemsAddressUserContactHidden,
} from '../shared-items/address';

// ITEMS ----------------------------------------------------------------

const contactFirstName = new FormItemTextbox({
  key: 'firstName',
  label: 'First Name',
  class: 'mb-5',
  required: true,
  validators: [
    Validators.minLength(1),
    maxByteValidator(2000),
    Validators.required,
  ],
});

const contactFirstNameHidden = new FormItemTextbox(contactFirstName);
contactFirstNameHidden.hide = true;

const contactLastName = new FormItemTextbox({
  key: 'lastName',
  label: 'Last Name',
  class: 'mb-5',
  required: true,
  validators: [
    Validators.minLength(1),
    maxByteValidator(2000),
    Validators.required,
  ],
});

const contactLastNameHidden = new FormItemTextbox(contactLastName);
contactLastNameHidden.hide = true;

const contactNickname = new FormItemTextbox({
  key: 'nickname',
  label: 'How I refer to them',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

const contactNicknameHidden = new FormItemTextbox(contactNickname);
contactNicknameHidden.hide = true;

const contactRole = new FormItemTextbox({
  key: 'role',
  label: 'Their role in your life',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

const contactRoleHidden = new FormItemTextbox(contactRole);
contactRoleHidden.hide = true;

const contactHomePhone = new FormItemTextbox({
  key: 'phoneHome',
  label: 'Home Phone',
  class: 'mb-5',
  validators: [
    Validators.minLength(1),
    maxByteValidator(2000),
    pdsPhoneNumberValidator(),
  ],
});

const contactHomePhoneHidden = new FormItemTextbox(contactHomePhone);
contactHomePhoneHidden.hide = true;

const contactMobilePhone = new FormItemTextbox({
  key: 'phoneMobile',
  label: 'Mobile Phone',
  class: 'mb-5',
  validators: [
    Validators.minLength(1),
    maxByteValidator(2000),
    pdsPhoneNumberValidator(),
  ],
});

const contactMobilePhoneHidden = new FormItemTextbox(contactMobilePhone);
contactMobilePhoneHidden.hide = true;

// TODO: Re-add once Mydex allows Null value
// export const contactEmail = new FormItemTextbox({
//   key: 'email',
//   label: 'Email',
//   class: 'mb-5',
//   required: true,
//   validators: [
//     Validators.minLength(1),
//     maxByteValidator(2000),
//     Validators.email,
//     Validators.required, // TODO: Update once Mydex changed to not required
//   ],
// });

// const contactEmailHidden = new FormItemTextbox(contactEmail);
// contactEmailHidden.hide = true;

const contactAdditionalInfo = new FormItemTextArea({
  key: 'additionalInfo',
  label: 'Other information about this person',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

const contactAdditionalInfoHidden = new FormItemTextArea(contactAdditionalInfo);
contactAdditionalInfoHidden.hide = true;

const contactIsLostAndMissed = new FormItemButtonSelect({
  key: 'isLostAndMissed',
  label: 'This is someone I have lost and miss',
  class: 'mb-5',
  multiple: false,
  options: yesNoOptions,
});

const contactIsLostAndMissedHidden = new FormItemButtonSelect(
  contactIsLostAndMissed
);
contactIsLostAndMissedHidden.hide = true;

const contactPicture = new FormItemImageUpload({
  key: 'picture',
  label: 'Picture',
  class: 'mb-5',
  imageDownloadName: 'contact-picture',
  placeholderIconName: 'person',
  validators: [Validators.minLength(1), Validators.maxLength(5000000)], // TODO: add compression library?
});

const contactPictureHidden = new FormItemImageUpload(contactPicture);
contactPictureHidden.hide = true;

const contactRelationshipType = new FormItemSelect({
  key: 'relationshipType',
  label: 'What is their relationship to you',
  class: 'mb-5',
  multiple: false,
  placeholder: 'Please select or start typing to filter...',
  options: [
    { name: 'Mother', label: 'Mother' },
    { name: 'Father', label: 'Father' },
    { name: 'Partner', label: 'Partner' },
    { name: 'Spouse', label: 'Spouse' },
    { name: 'Daughter', label: 'Daughter' },
    { name: 'Son', label: 'Son' },
    { name: 'Grandmother', label: 'Grandmother' },
    { name: 'Grandfather', label: 'Grandfather' },
    { name: 'Sister', label: 'Sister' },
    { name: 'Brother', label: 'Brother' },
    { name: 'Aunt', label: 'Aunt' },
    { name: 'Uncle', label: 'Uncle' },
    { name: 'Niece', label: 'Niece' },
    { name: 'Nephew', label: 'Nephew' },
    { name: 'Cousin', label: 'Cousin' },
    { name: 'Great Grandmother', label: 'Great Grandmother' },
    { name: 'Great Grandfather', label: 'Great Grandfather' },
    { name: 'Great Aunt', label: 'Great Aunt' },
    { name: 'Great Uncle', label: 'Great Uncle' },
    { name: 'Step Mother', label: 'Step Mother' },
    { name: 'Step Father', label: 'Step Father' },
    { name: 'Step Sister', label: 'Step Sister' },
    { name: 'Step Brother', label: 'Step Brother' },
    { name: 'Foster Mother', label: 'Foster Mother' },
    { name: 'Foster Father', label: 'Foster Father' },
    { name: 'Foster Daughter', label: 'Foster Daughter' },
    { name: 'Foster Son', label: 'Foster Son' },
    { name: 'Godmother', label: 'Godmother' },
    { name: 'Godfather', label: 'Godfather' },
    { name: 'Friend', label: 'Friend' },
    { name: 'Neighbour', label: 'Neighbour' },

    { name: 'Adviser', label: 'Adviser' },
    { name: 'Citizen', label: 'Citizen' },
    { name: 'Civil Partner', label: 'Civil Partner' },
    { name: 'Coach', label: 'Coach' },
    { name: 'Councillor', label: 'Councillor' },
    { name: 'Customer', label: 'Customer' },
    { name: 'Diabetic Nurse', label: 'Diabetic Nurse' },
    { name: 'Dietician', label: 'Dietician' },
    { name: 'Direct Report', label: 'Direct Report' },
    { name: 'Employee', label: 'Employee' },
    { name: 'Employer', label: 'Employer' },
    { name: 'Financial Adviser', label: 'Financial Adviser' },
    { name: 'GP', label: 'GP' },
    { name: 'Guardian', label: 'Guardian' },
    { name: 'Homeopath', label: 'Homeopath' },
    { name: 'Line Manager', label: 'Line Manager' },
    { name: 'LPA Holder', label: 'LPA Holder' },
    { name: 'Social Worker', label: 'Social Worker' },
    { name: 'Student', label: 'Student' },
    { name: 'Teacher', label: 'Teacher' },
  ],
});

const contactRelationshipTypeHidden = new FormItemSelect(
  contactRelationshipType
);
contactRelationshipTypeHidden.hide = true;

const contactAgeGroup = new FormItemDropdown({
  key: 'ageGroup',
  label: 'Their age group',
  placeholder: 'Please select...',
  class: 'mb-5',
  options: [
    { name: 'Under 18', label: 'Under 18' },
    { name: '19-24', label: '19-24' },
    { name: '25-34', label: '25-34' },
    { name: '35-44', label: '35-44' },
    { name: '45-54', label: '45-54' },
    { name: '55-64', label: '55-64' },
    { name: '65-74', label: '65-74' },
    { name: '75-84', label: '75-84' },
    { name: '85-94', label: '85-94' },
    { name: '95+', label: '95+' },
  ],
});

const contactAgeGroupHidden = new FormItemDropdown(contactAgeGroup);
contactAgeGroupHidden.hide = true;

const contactResidence = new FormItemDropdown({
  key: 'residence',
  label: 'Where they live',
  placeholder: 'Please select...',
  class: 'mb-5',
  options: [
    { name: 'With me', label: 'With me' },
    { name: 'In their own home', label: 'In their own home' },
    { name: 'Nursing Care', label: 'Nursing Care' },
    { name: 'Supported living', label: 'Supported living' },
  ],
});

const contactResidenceHidden = new FormItemDropdown(contactResidence);
contactResidenceHidden.hide = true;

const contactEthnicity = new FormItemDropdown({
  key: 'ethnicity',
  label: 'Their ethnicity',
  placeholder: 'Please select...',
  optionGroups: pdsEthnicityOptionGroups,
  class: 'mb-5',
});

const contactEthnicityHidden = new FormItemDropdown(contactEthnicity);
contactEthnicityHidden.hide = true;

const contactChiNumber = new FormItemTextbox({
  key: 'chiNumber',
  label: 'CHI number',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

const contactChiNumberHidden = new FormItemTextbox(contactChiNumber);
contactChiNumberHidden.hide = true;

const contactUniqueRecordNumberInCareSystem = new FormItemTextbox({
  key: 'uniqueRecordNumberInCareSystem',
  label: 'Unique Record Number in Care System',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

const contactUniqueRecordNumberInCareSystemHidden = new FormItemTextbox(
  contactUniqueRecordNumberInCareSystem
);
contactUniqueRecordNumberInCareSystemHidden.hide = true;

const contactNationalInsuranceNumber = new FormItemTextbox({
  key: 'nationalInsuranceNumber',
  label: 'National Insurance Number',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

const contactNationalInsuranceNumberHidden = new FormItemTextbox(
  contactNationalInsuranceNumber
);
contactNationalInsuranceNumberHidden.hide = true;

const contactOtherIdentificationNumbers = new FormItemTextArea({
  key: 'otherIdentificationNumbers',
  label: 'Any other identifiers of relevance',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

const contactOtherIdentificationNumbersHidden = new FormItemTextArea(
  contactOtherIdentificationNumbers
);
contactOtherIdentificationNumbersHidden.hide = true;

const contactHasCareNeedsAssessment = new FormItemButtonSelect({
  key: 'hasCareNeedsAssessment',
  label: 'The person I care for has a care needs assessment',
  class: 'mb-5',
  multiple: false,
  options: yesNoUnknownOptions,
});

const contactHasCareNeedsAssessmentHidden = new FormItemButtonSelect(
  contactHasCareNeedsAssessment
);
contactHasCareNeedsAssessmentHidden.hide = true;

const contactCareNeedsAssessmentDate = new FormItemDatePicker({
  key: 'careNeedsAssessmentDate',
  label: 'The assessment was done',
  class: 'mb-5',
  width: 'half',
  minDate: utcDateAtMidnightFromYmd(1900, 0, 1),
  maxDate: getUtcYesterdayMidnight(),
  displayConditions: [
    {
      key: 'hasCareNeedsAssessment',
      values: ['Yes'],
    },
  ],
});

const contactCareNeedsAssessmentDateHidden = new FormItemDatePicker(
  contactCareNeedsAssessmentDate
);
contactCareNeedsAssessmentDateHidden.hide = true;

const contactCareNeedsAssessmentDoneBy = new FormItemTextbox({
  key: 'careNeedsAssessmentDoneBy',
  label: 'The assessment was done by',
  class: 'mb-5',
  displayConditions: [
    {
      key: 'hasCareNeedsAssessment',
      values: ['Yes'],
    },
  ],
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

const contactCareNeedsAssessmentDoneByHidden = new FormItemTextbox(
  contactCareNeedsAssessmentDoneBy
);
contactCareNeedsAssessmentDoneByHidden.hide = true;

const contactCareNeedsAssessmentNotDoneReason = new FormItemTextArea({
  key: 'careNeedsAssessmentNotDoneReason',
  label: 'The assessment was not done because...',
  class: 'mb-5',
  displayConditions: [
    {
      key: 'hasCareNeedsAssessment',
      values: ['No'],
    },
  ],
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

const contactCareNeedsAssessmentNotDoneReasonHidden = new FormItemTextArea(
  contactCareNeedsAssessmentNotDoneReason
);
contactCareNeedsAssessmentNotDoneReasonHidden.hide = true;

const contactHadOccupationalTherapyAssessment = new FormItemButtonSelect({
  key: 'hadOccupationalTherapyAssessment',
  label: 'The person I care for has had an Occupational Therapy assessment',
  class: 'mb-5',
  multiple: false,
  options: yesNoUnknownOptions,
});

const contactHadOccupationalTherapyAssessmentHidden = new FormItemButtonSelect(
  contactHadOccupationalTherapyAssessment
);
contactHadOccupationalTherapyAssessmentHidden.hide = true;

const contactNeeds = new FormItemButtonSelect({
  key: 'needs',
  label: 'The person I care for has the following needs',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'person_has_needs_dementia', label: 'Dementia' },
    {
      name: 'person_has_needs_learning_disability',
      label: 'Learning Disability',
    },
    {
      name: 'person_has_needs_physical_disability',
      label: 'Physical Disability',
    },
    { name: 'person_has_needs_palliative_care', label: 'Palliative Care' },
    { name: 'person_has_needs_alcohol_problems', label: 'Alcohol Problems' },
    { name: 'person_has_needs_drug_problems', label: 'Drug Problems' },
    {
      name: 'person_has_needs_frail_older_person',
      label: 'Frail Older Person',
    },
    {
      name: 'person_has_needs_mental_health_problems',
      label: 'Mental Health Problems',
    },
    {
      name: 'person_has_needs_autistic_spectrum_disorder',
      label: 'Autistic Spectrum Disorder',
    },
    {
      name: 'person_has_needs_neurological_condition',
      label: 'Neurological Condition',
    },
    { name: 'person_has_needs_not_known', label: 'Not Known' },
  ],
});

const contactNeedsHidden = new FormItemButtonSelect(contactNeeds);
contactNeedsHidden.hide = true;

const contactAreasOfCareProvided = new FormItemButtonSelect({
  key: 'areasOfCareProvided',
  label: 'The care I provide includes',
  class: 'mb-5',
  multiple: true,
  options: [
    {
      name: 'person_needs_help_with_medication',
      label: 'Help with medication',
    },
    {
      name: 'person_needs_help_with_personal_care',
      label: 'Help with personal care',
    },
    { name: 'person_needs_help_with_transport', label: 'Help with transport' },
    {
      name: 'person_needs_help_with_domestic_tasks',
      label: 'Help with domestic tasks',
    },
    { name: 'person_needs_supervision_support', label: 'Supervision support' },
    { name: 'person_needs_emotional_support', label: 'Emotional support' },
  ],
});

const contactAreasOfCareProvidedHidden = new FormItemButtonSelect(
  contactAreasOfCareProvided
);
contactAreasOfCareProvidedHidden.hide = true;

const contactSituationSummary = new FormItemTextArea({
  key: 'situationSummary',
  label: "Brief summary of the person I care for's situation",
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

const contactSituationSummaryHidden = new FormItemTextArea(
  contactSituationSummary
);
contactSituationSummaryHidden.hide = true;

const contactCaringForThemIsVoluntary = new FormItemButtonSelect({
  key: 'caringForThemIsVoluntary',
  label: 'I feel I have a choice about providing care for this person',
  class: 'mb-5',
  multiple: false,
  options: yesNoOptions,
});

const contactCaringForThemIsVoluntaryHidden = new FormItemButtonSelect(
  contactCaringForThemIsVoluntary
);
contactCaringForThemIsVoluntaryHidden.hide = true;

const contactHasCommunityAlarm = new FormItemButtonSelect({
  key: 'hasCommunityAlarm',
  label: 'The person I care for has a community alarm',
  class: 'mb-5',
  options: yesNoOptions,
});

const contactHasCommunityAlarmHidden = new FormItemButtonSelect(
  contactHasCommunityAlarm
);
contactHasCommunityAlarmHidden.hide = true;

const contactIsSoleCarerForThem = new FormItemButtonSelect({
  key: 'isSoleCarerForThem',
  label: 'Are you the only person providing care and support',
  class: 'mb-5',
  options: yesNoOptions,
});

const contactIsSoleCarerForThemHidden = new FormItemButtonSelect(
  contactIsSoleCarerForThem
);
contactIsSoleCarerForThemHidden.hide = true;

const contactOtherCarers = new FormItemContacts({
  key: 'otherCarers',
  label: 'Who are the other people who provide care and support',
  // no contactEditorForm to not cause issues with access before initialization
  canEditContacts: false,
  multiple: true,
  class: 'mb-5',
  displayConditions: [
    {
      key: 'isSoleCarerForThem',
      values: ['No'],
    },
  ],
});

const contactOtherCarersHidden = new FormItemContacts(contactOtherCarers);
contactOtherCarersHidden.hide = true;

const contactOtherPossibleCarers = new FormItemContacts({
  key: 'otherPossibleCarers',
  label: 'Who are the other people who could provide care and support',
  // no contactEditorForm to not cause issues with access before initialization
  canEditContacts: false,
  multiple: true,
  class: 'mb-5',
});

const contactOtherPossibleCarersHidden = new FormItemContacts(
  contactOtherCarers
);
contactOtherCarersHidden.hide = true;

export const contactArrangementsForCareInPlace = new FormItemButtonSelect({
  key: 'arrangementsForCareInPlace',
  label:
    'I have plans in place should something unexpected happen to prevent me providing care',
  class: 'mb-5',
  options: yesNoOptions,
});

const contactArrangementsForCareInPlaceHidden = new FormItemButtonSelect(
  contactArrangementsForCareInPlace
);
contactArrangementsForCareInPlaceHidden.hide = true;

export const contactArrangementInPlaceAppointee = new FormItemButtonSelect({
  key: 'arrangementInPlaceAppointee',
  label: 'I have appointee arrangements in place for the person I care for',
  tooltip: appointeeTooltip,
  class: 'mb-5',
  options: yesNoInProgressLegacyOptions,
});

const contactArrangementInPlaceAppointeeHidden = new FormItemButtonSelect(
  contactArrangementInPlaceAppointee
);
contactArrangementInPlaceAppointeeHidden.hide = true;

export const contactArrangementInPlaceGuardianFinancial =
  new FormItemButtonSelect({
    key: 'arrangementInPlaceGuardianFinancial',
    label:
      'I have financial guardian arrangements in place for the person I care for',
    tooltip: guardianshipTooltip,
    class: 'mb-5',
    options: yesNoInProgressLegacyOptions,
  });

const contactArrangementInPlaceGuardianFinancialHidden =
  new FormItemButtonSelect(contactArrangementInPlaceGuardianFinancial);
contactArrangementInPlaceGuardianFinancialHidden.hide = true;

export const contactArrangementInPlaceGuardianWelfare =
  new FormItemButtonSelect({
    key: 'arrangementInPlaceGuardianWelfare',
    label:
      'I have welfare guardian arrangements in place for the person I care for',
    tooltip: guardianshipTooltip,
    class: 'mb-5',
    options: yesNoInProgressLegacyOptions,
  });

const contactArrangementInPlaceGuardianWelfareHidden = new FormItemButtonSelect(
  contactArrangementInPlaceGuardianWelfare
);
contactArrangementInPlaceGuardianWelfareHidden.hide = true;

export const contactArrangementInPlaceNamedPerson = new FormItemButtonSelect({
  key: 'arrangementInPlaceNamedPerson',
  label: 'I have named person arrangements in place for the person I care for',
  class: 'mb-5',
  options: yesNoInProgressLegacyOptions,
});

const contactArrangementInPlaceNamedPersonHidden = new FormItemButtonSelect(
  contactArrangementInPlaceNamedPerson
);
contactArrangementInPlaceNamedPersonHidden.hide = true;

export const contactArrangementInPlaceWill = new FormItemButtonSelect({
  key: 'arrangementInPlaceWill',
  label: 'The person I care for has a will in place',
  class: 'mb-5',
  options: yesNoInProgressLegacyOptions,
});

const contactArrangementInPlaceWillHidden = new FormItemButtonSelect(
  contactArrangementInPlaceWill
);
contactArrangementInPlaceWillHidden.hide = true;

export const contactArrangementInPlacePoaFinancial = new FormItemButtonSelect({
  key: 'arrangementInPlacePoaFinancial',
  label:
    'I have financial power of attorney arrangements in place for the person I care for',
  tooltip: powerOfAttorneyTooltip,
  class: 'mb-5',
  options: yesNoInProgressLegacyOptions,
});

const contactArrangementInPlacePoaFinancialHidden = new FormItemButtonSelect(
  contactArrangementInPlacePoaFinancial
);
contactArrangementInPlacePoaFinancialHidden.hide = true;

export const contactArrangementInPlacePoaContinuingWelfare =
  new FormItemButtonSelect({
    key: 'arrangementInPlacePoaContinuingWelfare',
    label:
      'I have continuing welfare power of attorney arrangements in place for the person I care for',
    tooltip: powerOfAttorneyTooltip,

    class: 'mb-5',
    options: yesNoInProgressLegacyOptions,
  });

const contactArrangementInPlacePoaContinuingWelfareHidden =
  new FormItemButtonSelect(contactArrangementInPlacePoaContinuingWelfare);
contactArrangementInPlacePoaContinuingWelfareHidden.hide = true;

const contactIsLivingWithThem = new FormItemButtonSelect({
  key: 'isLivingWithThem',
  label: 'Does the person you care for live with you',
  class: 'mb-5',
  options: yesNoOptions,
});

const contactIsLivingWithThemHidden = new FormItemButtonSelect(
  contactIsLivingWithThem
);
contactIsLivingWithThemHidden.hide = true;

const contactTheirHomeIsSuitableForThem = new FormItemButtonSelect({
  key: 'theirHomeIsSuitableForThem',
  label: "The person I care for's home is suitable for their needs",
  class: 'mb-5',
  options: yesNoOptions,
});

const contactTheirHomeIsSuitableForThemHidden = new FormItemButtonSelect(
  contactTheirHomeIsSuitableForThem
);
contactTheirHomeIsSuitableForThemHidden.hide = true;

const contactTheirHomeIsNotSuitableForThemDetails = new FormItemTextArea({
  key: 'theirHomeIsNotSuitableForThemDetails',
  label: 'Their home is not suitable because...',
  placeholder:
    'e.g. consider difficulties with climbing stairs, using the bath or shower, or moving around the home',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
  displayConditions: [
    {
      key: 'theirHomeIsSuitableForThem',
      values: ['No'],
    },
  ],
});

const contactTheirHomeIsNotSuitableForThemDetailsHidden = new FormItemTextArea(
  contactTheirHomeIsNotSuitableForThemDetails
);
contactTheirHomeIsNotSuitableForThemDetailsHidden.hide = true;

// FORMS ----------------------------------------------------------------

export function getAllContactsForms(): ContactForm[] {
  return [
    getContactsFormKeyDetails(),
    getContactsFormFullDetails(),
    getContactsFormSupportedPerson(),
  ];
}

// equals Mydex'"add_person_form_type": "person_basic"
export function getContactsFormKeyDetails(): ContactForm {
  return new ContactForm({
    name: 'Key Details',
    formItems: [
      contactFirstName,
      contactLastName,
      contactHomePhone,
      contactMobilePhone,
      // contactEmail, TODO: Re-add once Mydex allows Null value
      ...getFormItemsAddressUserContact(),

      // include all missing fields as hidden (so that form is always fully representing the user contact model and no data is lost if only subset is saved)
      contactNicknameHidden,
      contactRoleHidden,
      contactAdditionalInfoHidden,
      contactIsLostAndMissedHidden,
      contactPictureHidden,
      contactRelationshipTypeHidden,
      contactAgeGroupHidden,
      contactResidenceHidden,
      contactEthnicityHidden,
      contactChiNumberHidden,
      contactUniqueRecordNumberInCareSystemHidden,
      contactNationalInsuranceNumberHidden,
      contactOtherIdentificationNumbersHidden,
      contactHasCareNeedsAssessmentHidden,
      // contactCareNeedsAssessmentDateHidden, TODO: Re-add once Mydex allows Null value
      contactCareNeedsAssessmentDoneByHidden,
      contactCareNeedsAssessmentNotDoneReasonHidden,
      contactHadOccupationalTherapyAssessmentHidden,
      contactNeedsHidden,
      contactAreasOfCareProvidedHidden,
      contactSituationSummaryHidden,
      contactCaringForThemIsVoluntaryHidden,
      contactHasCommunityAlarmHidden,
      contactIsSoleCarerForThemHidden,
      contactOtherCarersHidden,
      contactOtherPossibleCarersHidden,
      contactArrangementsForCareInPlaceHidden,
      contactArrangementInPlaceAppointeeHidden,
      contactArrangementInPlaceGuardianFinancialHidden,
      contactArrangementInPlaceGuardianWelfareHidden,
      contactArrangementInPlaceNamedPersonHidden,
      contactArrangementInPlaceWillHidden,
      contactArrangementInPlacePoaFinancialHidden,
      contactArrangementInPlacePoaContinuingWelfareHidden,
      contactIsLivingWithThemHidden,
      contactTheirHomeIsSuitableForThemHidden,
      contactTheirHomeIsNotSuitableForThemDetailsHidden,
    ],
  });
}

// equals Mydex'"add_person_form_type": "person" + address fields (since this is a sensible addition)
export function getContactsFormFullDetails(): ContactForm {
  return new ContactForm({
    name: 'Full Details',
    formItems: [
      contactFirstName,
      contactLastName,
      contactNickname,
      contactRole,
      contactHomePhone,
      contactMobilePhone,
      ...getFormItemsAddressUserContact(),
      // contactEmail, TODO: Re-add once Mydex allows Null value
      contactAdditionalInfo,
      contactIsLostAndMissed,
      contactPicture,

      // include all missing fields as hidden (so that form is always fully representing the user contact model and no data is lost if only subset is saved)
      contactRelationshipTypeHidden,
      contactAgeGroupHidden,
      contactResidenceHidden,
      contactEthnicityHidden,
      contactChiNumberHidden,
      contactUniqueRecordNumberInCareSystemHidden,
      contactNationalInsuranceNumberHidden,
      contactOtherIdentificationNumbersHidden,
      contactHasCareNeedsAssessmentHidden,
      // contactCareNeedsAssessmentDateHidden, TODO: Re-add once Mydex allows Null value
      contactCareNeedsAssessmentDoneByHidden,
      contactCareNeedsAssessmentNotDoneReasonHidden,
      contactHadOccupationalTherapyAssessmentHidden,
      contactNeedsHidden,
      contactAreasOfCareProvidedHidden,
      contactSituationSummaryHidden,
      contactCaringForThemIsVoluntaryHidden,
      contactHasCommunityAlarmHidden,
      contactIsSoleCarerForThemHidden,
      contactOtherCarersHidden,
      contactOtherPossibleCarersHidden,
      contactArrangementsForCareInPlaceHidden,
      contactArrangementInPlaceAppointeeHidden,
      contactArrangementInPlaceGuardianFinancialHidden,
      contactArrangementInPlaceGuardianWelfareHidden,
      contactArrangementInPlaceNamedPersonHidden,
      contactArrangementInPlaceWillHidden,
      contactArrangementInPlacePoaFinancialHidden,
      contactArrangementInPlacePoaContinuingWelfareHidden,
      contactIsLivingWithThemHidden,
      contactTheirHomeIsSuitableForThemHidden,
      contactTheirHomeIsNotSuitableForThemDetailsHidden,
    ],
  });
}

// equals Mydex'"add_person_form_type": "cared_for_person"
export function getContactsFormSupportedPerson(): ContactForm {
  return new ContactForm({
    name: 'Supported Person',
    formItems: [
      contactFirstName,
      contactLastName,
      contactRelationshipType,
      contactAgeGroup,
      contactResidence,
      contactEthnicity,
      contactChiNumber,
      contactUniqueRecordNumberInCareSystem,
      contactNationalInsuranceNumber,
      contactOtherIdentificationNumbers,
      contactHasCareNeedsAssessment,
      // contactCareNeedsAssessmentDate, TODO: Re-add once Mydex allows Null value
      contactCareNeedsAssessmentDoneBy,
      contactCareNeedsAssessmentNotDoneReason,
      contactHadOccupationalTherapyAssessment,
      contactNeeds,
      contactAreasOfCareProvided,
      contactSituationSummary,
      contactCaringForThemIsVoluntary,
      contactHasCommunityAlarm,
      contactIsSoleCarerForThem,
      contactOtherCarers,
      contactOtherPossibleCarers,
      contactArrangementsForCareInPlace,
      contactArrangementInPlaceAppointee,
      contactArrangementInPlaceGuardianFinancial,
      contactArrangementInPlaceGuardianWelfare,
      contactArrangementInPlaceNamedPerson,
      contactArrangementInPlaceWill,
      contactArrangementInPlacePoaFinancial,
      contactArrangementInPlacePoaContinuingWelfare,
      contactIsLivingWithThem,
      contactTheirHomeIsSuitableForThem,
      contactTheirHomeIsNotSuitableForThemDetails,

      // include all missing fields as hidden (so that form is always fully representing the user contact model and no data is lost if only subset is saved)
      contactNicknameHidden,
      contactRoleHidden,
      contactHomePhoneHidden,
      contactMobilePhoneHidden,
      ...getFormItemsAddressUserContactHidden(),
      // contactEmailHidden, TODO: Re-add once Mydex allows Null value
      contactAdditionalInfoHidden,
      contactIsLostAndMissedHidden,
      contactPictureHidden,
    ],
  });
}
