import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { UserImagesApiService } from '../user-images-api/user.images.api.service';
import { getErrorMessage } from 'src/app/helper/getErrorMessage';
import { UserImagePdsEntry } from 'src/app/models/image/userImagePdsEntry';
import { UserImageData } from 'src/app/models/image/userImageData';
import { UserImageCreationRequest } from 'src/app/models/image/server/userImageCreationRequest';
import { UserImageUpdateRequest } from 'src/app/models/image/server/userImageUpdateRequest';
@Injectable({ providedIn: 'root' })
export class UserImagesStateService implements OnDestroy {
  // PROPERTIES

  private _userImageThumbnailsSource: UserImagePdsEntry[] = [];
  private _userImageThumbnails$: BehaviorSubject<UserImagePdsEntry[]> =
    new BehaviorSubject<UserImagePdsEntry[]>(this._userImageThumbnailsSource);

  private _loadingImageThumbnails$ = new BehaviorSubject<boolean>(false);
  private _loadingImageThumbnailsError$ = new BehaviorSubject<string>('');

  private _loadingImageFullSize$ = new BehaviorSubject<boolean>(false);
  private _loadingImageFullSizeError$ = new BehaviorSubject<string>('');
  private _loadingImageFullSizeSuccess$ = new Subject<UserImagePdsEntry>();

  private _addingImage$ = new BehaviorSubject<boolean>(false);
  private _addingImageError$ = new BehaviorSubject<string>('');

  private _updatingImage$ = new BehaviorSubject<boolean>(false);
  private _updatingImageError$ = new BehaviorSubject<string>('');
  private _updatingImageSuccess$ = new Subject<void>();

  private _deletingImage$ = new BehaviorSubject<boolean>(false);
  private _deletingImageError$ = new BehaviorSubject<string>('');
  private _deletingImageSuccess$ = new Subject<string>();

  private _destroy$: Subject<void> = new Subject<void>();

  // CONSTRUCTOR

  constructor(private userImageApiService: UserImagesApiService) {
    this.fetchUserImagesIfNeeded();
  }

  // PUBLIC GETTERS

  // includes thumbnail data in the UserImagePdsEntry, but not the file data
  get userImageThumbnails$(): Observable<UserImagePdsEntry[]> {
    this.fetchUserImagesIfNeeded();
    return this._userImageThumbnails$.asObservable();
  }

  get loadingImageThumbnails$(): Observable<boolean> {
    return this._loadingImageThumbnails$.asObservable();
  }

  get loadingImageThumbnailsError$(): Observable<string> {
    return this._loadingImageThumbnailsError$.asObservable();
  }

  get loadingImageFullSize$(): Observable<boolean> {
    return this._loadingImageFullSize$.asObservable();
  }

  get loadingImageFullSizeError$(): Observable<string> {
    return this._loadingImageFullSizeError$.asObservable();
  }

  // includes file data in the UserImagePdsEntry, but not the thumbnail data
  get loadingImageFullSizeSuccess$(): Observable<UserImageData> {
    return this._loadingImageFullSizeSuccess$.asObservable();
  }

  get addingImage$(): Observable<boolean> {
    return this._addingImage$.asObservable();
  }

  get addingImageError$(): Observable<string> {
    return this._addingImageError$.asObservable();
  }

  get updatingImage$(): Observable<boolean> {
    return this._updatingImage$.asObservable();
  }

  get updatingImageError$(): Observable<string> {
    return this._updatingImageError$.asObservable();
  }

  get updatingImageSuccess$(): Observable<void> {
    return this._updatingImageSuccess$.asObservable();
  }

  get deletingImage$(): Observable<boolean> {
    return this._deletingImage$.asObservable();
  }

  get deletingImageError$(): Observable<string> {
    return this._deletingImageError$.asObservable();
  }

  get deletingImageSuccess$(): Observable<string> {
    return this._deletingImageSuccess$.asObservable();
  }

  // LIFECYCLE HOOKS

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  // PUBLIC API

  getUserImageFullSize(imageId: string) {
    this._loadingImageFullSize$.next(true);
    this._loadingImageFullSizeError$.next('');

    this.userImageApiService
      .getUserImageFullSizeById(imageId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (userImage) => {
          this._loadingImageFullSize$.next(false);
          this._loadingImageFullSizeSuccess$.next(userImage);
        },
        error: (error) => {
          this._loadingImageFullSizeError$.next(
            getErrorMessage(error, 'Unable to fetch the full size image.')
          );
          this._loadingImageFullSize$.next(false);
        },
      });
  }

  addUserImage(userImageData: UserImageData) {
    this._addingImage$.next(true);
    this._addingImageError$.next('');

    let userImageCreationRequest =
      UserImageCreationRequest.fromUserImageData(userImageData);

    this.userImageApiService
      .addUserImage(userImageCreationRequest)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (!response.newImageId) {
            // server should always return the new image id in case of success
            this._addingImageError$.next(
              'Unexpected response from the server.'
            );
            this._addingImage$.next(false);
            return;
          }

          // Save the file data as the thumbnail data so no need to fetch all images again
          let newImage = new UserImagePdsEntry(userImageData);
          newImage.imageId = response.newImageId;
          newImage.thumbnail = userImageData.file;

          this._userImageThumbnailsSource.push(newImage);
          this.sortUserImageThumbnails();
          this._userImageThumbnails$.next(this._userImageThumbnailsSource);

          this._addingImage$.next(false);
        },
        error: (error) => {
          this._addingImageError$.next(
            getErrorMessage(error, 'Unable to add the image.')
          );
          this._addingImage$.next(false);
        },
      });
  }

  updateUserImage(updatedUserImage: UserImagePdsEntry) {
    this._updatingImage$.next(true);
    this._updatingImageError$.next('');

    let userImageUpdateRequest =
      UserImageUpdateRequest.fromUserImagePdsEntry(updatedUserImage);

    this.userImageApiService
      .updateUserImage(userImageUpdateRequest)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          // Save the file data as the thumbnail data so no need to fetch all images again
          updatedUserImage.thumbnail = updatedUserImage.file;

          // Replace the old image with the updated one (identified by id)
          this._userImageThumbnailsSource = this._userImageThumbnailsSource.map(
            (oldUserImage) =>
              oldUserImage.imageId === updatedUserImage.imageId
                ? updatedUserImage
                : oldUserImage
          );
          this.sortUserImageThumbnails();
          this._userImageThumbnails$.next(this._userImageThumbnailsSource);

          this._updatingImage$.next(false);
          this._updatingImageSuccess$.next();
        },
        error: (error) => {
          this._updatingImageError$.next(
            getErrorMessage(error, 'Unable to update the image details.')
          );
          this._updatingImage$.next(false);
        },
      });
  }

  deleteUserImage(userImageToBeDeleted: UserImagePdsEntry) {
    this._deletingImage$.next(true);
    this._deletingImageError$.next('');

    if (!userImageToBeDeleted.imageId) {
      this._deletingImageError$.next('Invalid image id. Unable to delete.');
      this._deletingImage$.next(false);
      return;
    }

    this.userImageApiService
      .deleteUserImage(userImageToBeDeleted.imageId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          // Remove the image from the source
          this._userImageThumbnailsSource =
            this._userImageThumbnailsSource.filter(
              (image) => image.imageId !== userImageToBeDeleted.imageId
            );
          this.sortUserImageThumbnails();
          this._userImageThumbnails$.next(this._userImageThumbnailsSource);

          this._deletingImage$.next(false);
          this._deletingImageSuccess$.next(userImageToBeDeleted?.imageId ?? '');
        },
        error: (error) => {
          this._deletingImageError$.next(
            getErrorMessage(error, 'Unable to delete the image.')
          );
          this._deletingImage$.next(false);
        },
      });
  }

  // call in ngOnDestroy of subscribing components to reset the errors (do not reset loading errors)
  resetErrors() {
    this._addingImageError$.next('');
    this._updatingImageError$.next('');
    this._deletingImageError$.next('');
  }

  // PRIVATE API

  private fetchUserImagesIfNeeded() {
    // if the user images are already loaded or are being loaded, do nothing
    if (
      this._userImageThumbnailsSource.length !== 0 ||
      this._loadingImageThumbnails$.getValue()
    ) {
      return;
    }

    this._loadingImageThumbnails$.next(true);
    this._loadingImageThumbnailsError$.next('');

    this.userImageApiService
      .getAllUserImageThumbnails()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (userImages) => {
          this._userImageThumbnailsSource = userImages;
          this.sortUserImageThumbnails();

          this._userImageThumbnails$.next(this._userImageThumbnailsSource);
          this._loadingImageThumbnails$.next(false);
        },
        error: (error) => {
          this._loadingImageThumbnailsError$.next(
            getErrorMessage(error, 'Unable to fetch images from the server.')
          );
          this._loadingImageThumbnails$.next(false);
        },
      });
  }

  private sortUserImageThumbnails() {
    this._userImageThumbnailsSource = this._userImageThumbnailsSource.sort(
      (a, b) =>
        parseInt(a?.imageId ?? '0', 10) ||
        0 - (parseInt(b?.imageId ?? '0', 10) || 0)
    );
  }
}
