import { FormItem, FormItemButtonSelect } from 'src/app/models/form/form';
import {
  supportedPeopleSupportInYears,
  supportedPeopleWeeklyHours,
} from '../user/supporting-role-items/supported-people-items';
import { healthImpactCaringAffectsPhysicalHealth } from '../user/supporting-role-items/health-impact-items';
import { emotionalWellbeingCaringAffectsWellbeing } from '../user/supporting-role-items/emotional-wellbeing-items';
import { financialImpactCaringAffectsFinances } from '../user/supporting-role-items/financial-impact-items';
import { personalDetailsAbleToContinueProvidingCare } from '../user/supporting-role-items/personal-details-items';
import { dataProcessingConsent } from '../general/consent';

const supportedPeopleWeeklyHoursCarerFormItem = new FormItemButtonSelect(
  supportedPeopleWeeklyHours
);
supportedPeopleWeeklyHoursCarerFormItem.styleType = 'tab';

const supportedPeopleSupportInYearsCarerFormItem = new FormItemButtonSelect(
  supportedPeopleSupportInYears
);
supportedPeopleSupportInYearsCarerFormItem.styleType = 'tab';

const healthImpactCaringAffectsPhysicalHealthCarerFormItem =
  new FormItemButtonSelect(healthImpactCaringAffectsPhysicalHealth);
healthImpactCaringAffectsPhysicalHealthCarerFormItem.styleType = 'tab';

const emotionalWellbeingCaringAffectsWellbeingCarerFormItem =
  new FormItemButtonSelect(emotionalWellbeingCaringAffectsWellbeing);
emotionalWellbeingCaringAffectsWellbeingCarerFormItem.styleType = 'tab';

const financialImpactCaringAffectsFinancesCarerFormItem =
  new FormItemButtonSelect(financialImpactCaringAffectsFinances);
financialImpactCaringAffectsFinancesCarerFormItem.styleType = 'tab';

const personalDetailsAbleToContinueProvidingCareCarerFormItem =
  new FormItemButtonSelect(personalDetailsAbleToContinueProvidingCare);
personalDetailsAbleToContinueProvidingCareCarerFormItem.styleType = 'tab';

const unpaidCarerFormItems: FormItem<any>[] = [
  supportedPeopleWeeklyHoursCarerFormItem,
  supportedPeopleSupportInYearsCarerFormItem,
  healthImpactCaringAffectsPhysicalHealthCarerFormItem,
  emotionalWellbeingCaringAffectsWellbeingCarerFormItem,
  financialImpactCaringAffectsFinancesCarerFormItem,
  personalDetailsAbleToContinueProvidingCareCarerFormItem,
];

export const unpaidCarerForm: FormItem<any>[] = [
  ...unpaidCarerFormItems,
  dataProcessingConsent,
];

export function getUnpaidCarerForm(): FormItem<any>[] {
  return unpaidCarerFormItems;
}
