// THIS IS ME - CURRENT -------------------------------------------------------

import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemContacts,
  FormItemImageUploadGallery,
  FormItemTextArea,
} from 'src/app/models/form/form';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';
import { getAllContactsForms } from '../forms-contacts';

export const currentLifeSituation = new FormItemButtonSelect({
  key: 'currentLifeSituation',
  path: 'me.current.lifeSituation',
  label: 'Tell us a bit about how things are for you right now',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'currently_retired', label: 'Retired' },
    { name: 'currently_unpaid_carer', label: 'Unpaid carer' },
    { name: 'currently_in_work', label: 'In work' },
    { name: 'currently_student', label: 'Student' },
    { name: 'currently_volunteer', label: 'Volunteer' },
    { name: 'currently_live_with_family', label: 'Live with family' },
    { name: 'currently_live_alone', label: 'Live alone' },
  ],
});

export const friendsAndFamily = new FormItemTextArea({
  key: 'friendsAndFamily',
  path: 'me.current.friendsAndFamily',
  label: 'About your friends and your family',
  placeholder: 'e.g. I am now a widow, my husband died 5 years ago',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const livingWith = new FormItemContacts({
  key: 'livingWith',
  path: 'me.current.livingWith',
  label: 'Who lives with you?',
  allContactEditorForms: getAllContactsForms(),
  initialSelectedFormIndex: 1,
  multiple: true,
  class: 'mb-5',
});

export const supportedBy = new FormItemContacts({
  key: 'supportedBy',
  path: 'me.current.supportedBy',
  label: 'People that support you',
  allContactEditorForms: getAllContactsForms(),
  initialSelectedFormIndex: 1,
  multiple: true,
  class: 'mb-5',
});

export const supporting = new FormItemContacts({
  key: 'supporting',
  path: 'me.current.supporting',
  label: 'People that you support',
  allContactEditorForms: getAllContactsForms(),
  initialSelectedFormIndex: 2,
  multiple: true,
  class: 'mb-5',
});

export const interests = new FormItemTextArea({
  key: 'interests',
  path: 'me.current.interests',
  label: 'How do you like to spend your time?',
  placeholder: 'e.g. reading books',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const currentAdditionalInfo = new FormItemTextArea({
  key: 'currentAdditionalInfo',
  path: 'me.current.additionalInfo',
  label: 'Anything else you would like to share?',
  placeholder: 'e.g. I have 2 dogs',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const currentPhotosTellingPersonalStory = new FormItemImageUploadGallery(
  {
    key: 'currentPhotosTellingPersonalStory',
    path: 'me.current.photosTellingPersonalStory',
    label: 'Photos to help tell your story',
    help: 'Select images from your image gallery that help you tell your story. These photos will also appear in your Reminiscence Gallery. Click on an image for a detailed view.',
    class: 'mb-5',
    imageDownloadName: 'pds-image',
    placeholderIconName: 'image',
    validators: [Validators.minLength(1), Validators.maxLength(5000000)], // TODO: add compression library?
  }
);

export const currentPhotosTellingPersonalStoryForReminiscenceUseage =
  new FormItemImageUploadGallery(currentPhotosTellingPersonalStory);
currentPhotosTellingPersonalStoryForReminiscenceUseage.help =
  'Select images from your image gallery that help you tell your story. Click on an image for a detailed view.';
