import * as _ from 'lodash';
import { UserKeyInformation } from './userKeyInformation';
import { UserMe } from './userMe';
import { UserHealth } from './userHealth';
import { UserSupportingRole } from './userSupportingRole';
import { UserSupport } from './userSupport';
import { UserPlanningAhead } from './userPlanningAhead';
import { UserEvent } from './userEvent';
import { UserConnectionOpinion } from './userConnectionOpinion';

export class User {
  me?: UserMe;
  keyInformation?: UserKeyInformation;
  health?: UserHealth;
  supportingRole?: UserSupportingRole;
  support?: UserSupport;
  planningAhead?: UserPlanningAhead;
  //preferences?: UserPreferences;
  //future?: UserFuture;

  events?: UserEvent[];
  connectionOpinions?: UserConnectionOpinion[];

  constructor(user?: Partial<User>) {
    this.me = new UserMe(user?.me);
    this.keyInformation = new UserKeyInformation(user?.keyInformation);
    this.health = new UserHealth(user?.health);
    this.supportingRole = new UserSupportingRole(user?.supportingRole);
    this.support = new UserSupport(user?.support);
    this.planningAhead = new UserPlanningAhead(user?.planningAhead);
    this.events = Array.isArray(user?.events)
      ? user.events.map((e) => new UserEvent(e))
      : [];
    this.connectionOpinions = Array.isArray(user?.connectionOpinions)
      ? user.connectionOpinions.map((e) => new UserConnectionOpinion(e))
      : [];
  }

  // HELPER FUNCTIONS

  // note that this only handles currently possible user property types:
  // number, string, boolean, null/undefined, array, object, date
  public static mergeUserData(
    oldData?: Partial<User>,
    newData?: Partial<User>,
    overrideExistingOldValues = true
  ) {
    if (!oldData) {
      return new User(newData);
    } else if (!newData) {
      return new User(oldData);
    }

    const customDeepMerge = (a: any, b: any): any => {
      // For arrays of UserEvents: in any case keep both arrays but remove duplicates
      if (
        _.isArray(a) &&
        a.every((element) => element instanceof UserEvent) &&
        _.isArray(b) &&
        b.every((element) => element instanceof UserEvent)
      ) {
        const mergedArray = _.uniqWith([...a, ...b], _.isEqual);
        return mergedArray;
      }

      // If a is an array or a date that is not empty: if no override return a, else b
      if ((_.isDate(a) && !_.isEmpty(a)) || (_.isArray(a) && !_.isEmpty(a))) {
        if (!overrideExistingOldValues) {
          return a;
        } else {
          return b;
        }
      }

      // If existing values shall be overriden and b is undefined or null,
      // return null - otherwise for "undefined" the default merging behaviour would be used which does not override existing values
      if (overrideExistingOldValues && (b === undefined || b === null)) {
        return null;
      }

      // If a is not an empty object, recursion is necessary to check on deepest level
      // (check after array and date as those would also pass the object check)
      if (_.isObject(a) && !_.isEmpty(a)) {
        // Pass an empty object as first argument to _.mergeWith to avoid mutating the other objects
        return _.mergeWith({}, a, b, customDeepMerge);
      }

      // If existing old data shall not be overridden and a is not an object
      // and does have a value, return a
      if (
        !overrideExistingOldValues &&
        !_.isNull(a) &&
        !_.isUndefined(a) &&
        !(_.isArray(a) && _.isEmpty(a)) &&
        !(_.isDate(a) && _.isEmpty(a)) &&
        !(_.isObject(a) && _.isEmpty(a)) &&
        a !== ''
      ) {
        return a;
      }

      // if b is undefined or null, return null - otherwise for "undefined" the default merging behaviour would be used which does not override existing values
      if (b === undefined || b === null) {
        return null;
      }

      // in all other cases, return b
      return b;
    };

    // Pass an empty object as first argument to _.mergeWith to avoid mutating the other objects
    const mergedUser = new User(
      _.mergeWith({}, oldData, newData, customDeepMerge)
    );

    return mergedUser;
  }
}
