<ng-select
  [(ngModel)]="value"
  [multiple]="item.multiple"
  [closeOnSelect]="item.multiple ? false : true"
  [id]="item.key"
  (change)="update($event)"
  [placeholder]="item.placeholder"
  [class.is-invalid]="control.touched && control.errors"
  [disabled]="disabled"
>
  <ng-option *ngFor="let option of options" [value]="option.name">
    <span>
      {{ option.label ?? option.name }}
    </span>
  </ng-option>
</ng-select>
