import { FormItem } from '../form/form';

export class ContactForm {
  name?: string;
  formItems?: FormItem<any>[];

  constructor(contactForm?: Partial<ContactForm>) {
    this.name = contactForm?.name;
    this.formItems = contactForm?.formItems;
  }
}
