import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-stateful-button',
  templateUrl: './stateful-button.component.html',
  styleUrl: './stateful-button.component.css',
})
export class StatefulButtonComponent {
  @Input() type: 'filled' | 'outline' = 'filled';
  @Input() iconName: string = '';
  @Input() buttonClass: string = 'btn btn-theme p-4 inline-flex items-center';

  @Input() disabled: boolean = false;

  @Input() isInProgress: boolean = false;
  @Input() isSuccess: boolean = false;

  @Input() text: string = 'Save';
  @Input() textProgress: string = 'Saving';
  @Input() textSuccess: string = 'Saved';

  @Input() errorMessage: string = '';
  @Input() errorMessagePlacement: 'left' | 'right' = 'right';

  @Output() clickEvent = new EventEmitter();

  constructor() {}
}
