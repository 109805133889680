import { Component, Input } from '@angular/core';
import { ConnectionOutput } from 'src/app/models/connection/connectionOutput';

@Component({
  selector: 'app-output-subform-display',
  templateUrl: './output-subform-display.component.html',
  styleUrls: ['./output-subform-display.component.css'],
})
export class OutputSubformDisplayComponent {
  @Input() value: ConnectionOutput<any> | undefined;

  get title(): string | undefined {
    if (!(this.value instanceof ConnectionOutput)) {
      return undefined;
    }

    switch (this.value.type) {
      case 'contact':
        return 'Contact Output Details';
      case 'email':
        return 'Email Output Details';
      case 'redirect':
        return 'Redirect Output Details';
      case 'pds':
        return 'PDS Output Details';
      default:
        return undefined;
    }
  }
}
