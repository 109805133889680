import {
  FormItemButtonSelect,
  FormItemDropdown,
  FormItemTextArea,
} from 'src/app/models/form/form';
import { Validators } from '@angular/forms';
import { yesNoOptions } from '../item-options';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';

export const emotionalWellbeingCaringAffectsWellbeing =
  new FormItemButtonSelect({
    key: 'emotionalWellbeingCaringAffectsWellbeing',
    label: 'Caring affects my emotional wellbeing',
    path: 'supportingRole.emotionalWellbeing.caringAffectsWellbeing',
    class: 'mb-5',
    options: yesNoOptions,
  });

export const emotionalWellbeingCaringAffectsWellbeingDetails =
  new FormItemTextArea({
    key: 'emotionalWellbeingCaringAffectsWellbeingDetails',
    path: 'supportingRole.emotionalWellbeing.caringAffectsWellbeingDetails',
    label: 'This is how it affects me',
    placeholder:
      "e.g. feeling stressed, isolated, like others don't realise what I'm having to do",
    class: 'mb-5',
    validators: [Validators.minLength(1), maxByteValidator(2000)],
    displayConditions: [
      {
        key: 'emotionalWellbeingCaringAffectsWellbeing',
        values: ['Yes'],
      },
    ],
  });

export const emotionalWellbeingCaringAffectsMentalHealth =
  new FormItemButtonSelect({
    key: 'emotionalWellbeingCaringAffectsMentalHealth',
    label: 'Caring affects my mental health',
    path: 'supportingRole.emotionalWellbeing.caringAffectsMentalHealth',
    class: 'mb-5',
    options: yesNoOptions,
  });

export const emotionalWellbeingCaringAffectsMentalHealthDetails =
  new FormItemTextArea({
    key: 'emotionalWellbeingCaringAffectsMentalHealthDetails',
    path: 'supportingRole.emotionalWellbeing.caringAffectsMentalHealthDetails',
    label: 'This is how it affects me',
    placeholder: 'e.g. poor sleep, depressed, anxious',
    class: 'mb-5',
    validators: [Validators.minLength(1), maxByteValidator(2000)],
    displayConditions: [
      {
        key: 'emotionalWellbeingCaringAffectsMentalHealth',
        values: ['Yes'],
      },
    ],
  });

export const emotionalWellbeingHasImpactOnCaring = new FormItemButtonSelect({
  key: 'emotionalWellbeingHasImpactOnCaring',
  label: 'My overall emotional wellbeing affects my ability to provide care',
  path: 'supportingRole.emotionalWellbeing.wellbeingHasImpactOnCaring',
  class: 'mb-5',
  options: yesNoOptions,
});

export const emotionalWellbeingDiscussedWithGp = new FormItemButtonSelect({
  key: 'emotionalWellbeingDiscussedWithGp',
  path: 'supportingRole.emotionalWellbeing.discussedWithGp',
  label: 'I have discussed my emotional wellbeing and caring role with my GP',
  class: 'mb-5',
  options: yesNoOptions,
});

export const emotionalWellbeingSummary = new FormItemDropdown({
  key: 'emotionalWellbeingSummary',
  label:
    'Thinking about my emotional wellbeing, the selected statement best matches how I currently feel.',
  path: 'supportingRole.emotionalWellbeing.summary',
  class: 'mb-5',
  placeholder: 'Please select...',
  options: [
    {
      name: 'I mostly feel calm and positive enough, and can deal with the pressures of being a carer.',
      label:
        'I mostly feel calm and positive enough, and can deal with the pressures of being a carer.',
    },
    {
      name: "I'm finding what helps me feel OK, or to manage stress, anxiety or difficulties in a relationship, but things could be better.",
      label:
        "I'm finding what helps me feel OK, or to manage stress, anxiety or difficulties in a relationship, but things could be better.",
    },
    {
      name: "I'm trying things that might help me manage stress or anxiety.",
      label: "I'm trying things that might help me manage stress or anxiety.",
    },
    {
      name: 'Stress and/or anxiety are getting me down. I have some support with this, but I know my emotional wellbeing needs attention.',
      label:
        'Stress and/or anxiety are getting me down. I have some support with this, but I know my emotional wellbeing needs attention.',
    },
    {
      name: "I'm trying things that might help me manage stress or anxiety.",
      label: "I'm trying things that might help me manage stress or anxiety.",
    },
  ],
});
