import { keyInfoSection } from './keyInfoSection';
import { healthSection } from './healthSection';
import { supportSection } from './supportSection';
import { planningAheadSection } from './planningAheadSection';
import { thisIsMeSection } from './thisIsMeSection';
import { supportingRoleSection } from './supportingRoleSection';
import { flattenPdsSections, PdsSection } from 'src/app/models/pds/pdsSection';

export const aboutMeSections: PdsSection[] = [
  thisIsMeSection,
  keyInfoSection,
  healthSection,
  supportingRoleSection,
  supportSection,
  planningAheadSection,
];

export const aboutMeSectionsIncludingTheirChildren =
  flattenPdsSections(aboutMeSections);
