import { UserMedication } from '../userMedication';
import { UserMedicationPdsEntry } from '../userMedicationPdsEntry';

export class UserMedicationUpdateRequest extends UserMedication {
  medicationId: string;

  constructor(data?: Partial<UserMedicationUpdateRequest>) {
    super(data);
    this.medicationId = data?.medicationId ?? '';
  }

  public static fromUserMedicationPdsEntry(
    medicationPdsEntry: UserMedicationPdsEntry
  ): UserMedicationUpdateRequest {
    const medication = new UserMedication(medicationPdsEntry);

    return new UserMedicationUpdateRequest({
      ...medication,
      medicationId: medicationPdsEntry.medicationId,
    });
  }
}
