import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';
import { UserMedicationCreationResponse } from 'src/app/models/medication/server/userMedicationCreationResponse';
import { UserMedicationListResponse } from 'src/app/models/medication/server/userMedicationListResponse';
import { UserMedicationUpdateRequest } from 'src/app/models/medication/server/userMedicationUpdateRequest';
import { UserMedication } from 'src/app/models/medication/userMedication';
import { UserMedicationPdsEntry } from 'src/app/models/medication/userMedicationPdsEntry';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserMedicationsApiService {
  http: any;

  private readonly baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiBaseUrl + '/medications';
    this.http = this.httpClient;
  }

  getAllUserMedicationsWithBasics(): Observable<UserMedicationPdsEntry[]> {
    return this.http.get(this.baseUrl).pipe(
      map((response: UserMedicationListResponse) => {
        let userMedications: UserMedicationPdsEntry[] =
          response.medications.map(
            (userMedication: UserMedicationPdsEntry) =>
              new UserMedicationPdsEntry(userMedication)
          );
        return userMedications;
      })
    );
  }

  getUserMedicationDetailsById(
    medicationId: string
  ): Observable<UserMedicationPdsEntry> {
    return this.http.get(`${this.baseUrl}?id=${medicationId}`).pipe(
      map((response: UserMedicationListResponse) => {
        let userMedications: UserMedicationPdsEntry[] =
          response.medications.map(
            (medication: UserMedicationPdsEntry) =>
              new UserMedicationPdsEntry(medication)
          );

        // Unexpected response from the server
        if (userMedications.length != 1) {
          return throwError(
            () => new Error('Unexpected response from the PDS.')
          );
        }

        // Return the first image in the array (there should only be one)
        return userMedications[0];
      })
    );
  }

  addUserMedication(
    medication: UserMedication
  ): Observable<UserMedicationCreationResponse> {
    return this.http
      .post(this.baseUrl, medication)
      .pipe(
        map(
          (response: UserMedicationCreationResponse) =>
            new UserMedicationCreationResponse(response)
        )
      );
  }

  updateUserMedication(
    medicationUpdateRequest: UserMedicationUpdateRequest
  ): Observable<string> {
    return this.http.put(this.baseUrl, medicationUpdateRequest, {
      responseType: 'text',
    });
  }

  deleteUserMedication(medicationId: string): Observable<string> {
    return this.http.delete(`${this.baseUrl}/${medicationId}`, {
      responseType: 'text',
    });
  }
}
