import { PdsSection } from 'src/app/models/pds/pdsSection';
import {
  getFormItemsUserHealthImportant,
  getFormItemsUserHealthMedicalHistory,
  getFormItemsUserHealthMedicalHistoryNegligibleForCompleteness,
  getFormItemsUserHealthMemory,
  getFormItemsUserHealthMemoryNegligibleForCompleteness,
  getFormItemsUserHealthStayingSafe,
  getFormItemsUserHealthWellbeing,
} from '../../forms/user/health-items/form-health';

// TODO: Check neglible for completeness items once connected to MAM

export const healthSection: PdsSection = {
  title: 'Health & Wellbeing',
  name: 'health-wellbeing',
  description: 'Details about your health',
  longDescription:
    'In this section, you can tell us about your health and wellbeing. Click on an area to get started.',
  routePath: 'health-wellbeing',
  userPath: 'health',
  children: [
    {
      title: 'Medical History',
      name: 'health-wellbeing-medical-history',
      description: 'Your medical history',
      routePath: 'medical-history',
      userPath: 'medicalHistory',
      getFormItems: getFormItemsUserHealthMedicalHistory,
      getFormItemsNegligibleForCompleteness:
        getFormItemsUserHealthMedicalHistoryNegligibleForCompleteness,
    },
    {
      title: 'Wellbeing',
      name: 'health-wellbeing-wellbeing',
      description: 'How you are feeling',
      routePath: 'wellbeing',
      userPath: 'wellbeing',
      getFormItems: getFormItemsUserHealthWellbeing,
    },
    {
      title: 'Memory',
      name: 'health-wellbeing-memory',
      description: 'Problems with memory',
      routePath: 'memory',
      userPath: 'memory',
      getFormItems: getFormItemsUserHealthMemory,
      getFormItemsNegligibleForCompleteness:
        getFormItemsUserHealthMemoryNegligibleForCompleteness,
    },
    {
      title: 'Staying Safe',
      name: 'health-wellbeing-staying-safe',
      description: 'What makes you feel safe',
      routePath: 'staying-safe',
      userPath: 'stayingSafe',
      getFormItems: getFormItemsUserHealthStayingSafe,
    },
    {
      title: 'Important to Me',
      name: 'health-wellbeing-important',
      description: 'What is important to you',
      routePath: 'important',
      userPath: 'important',
      getFormItems: getFormItemsUserHealthImportant,
    },
  ],
};
