import {
  FormItemButtonSelect,
  FormItemDropdown,
  FormItemTextArea,
} from 'src/app/models/form/form';
import { yesNoInProgressLegacyOptions } from '../item-options';
import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';
import { powerOfAttorneyTooltip } from 'src/assets/texts/tooltips';

export const futurePlansImpactPotentialImpactingAspects = new FormItemTextArea({
  key: 'futurePlansImpactPotentialImpactingAspects',
  path: 'supportingRole.futurePlansImpact.potentialImpactingAspects',
  label: 'These things might impact my caring role in the future:',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const futurePlansImpactDesires = new FormItemTextArea({
  key: 'futurePlansImpactDesires',
  path: 'supportingRole.futurePlansImpact.desires',
  label: 'What I want for my future',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const futurePlansImpactSummary = new FormItemDropdown({
  key: 'futurePlansImpactSummary',
  label:
    'Thinking about the future, the selected statement best matches how you currently feel.',
  path: 'supportingRole.futurePlansImpact.summary',
  placeholder: 'Please select...',
  class: 'mb-5',
  options: [
    {
      name: 'I mostly have the skills, understanding and information I need and can plan ahead. I have no concerns.',
      label:
        'I mostly have the skills, understanding and information I need and can plan ahead. I have no concerns.',
    },
    {
      name: 'I have a good idea about my caring role, but still would like to learn more. I feel confident about the future, but have some concerns.',
      label:
        'I have a good idea about my caring role, but still would like to learn more. I feel confident about the future, but have some concerns.',
    },
    {
      name: "Things are difficult, but I'm starting to get to grips with my caring role. I don't feel confident about the future, and have some concerns.",
      label:
        "Things are difficult, but I'm starting to get to grips with my caring role. I don't feel confident about the future, and have some concerns.",
    },
    {
      name: "I'm finding it hard to cope. I get some support but am not confident it'll really help. I feel anxious about the future, and have significant concerns.",
      label:
        "I'm finding it hard to cope. I get some support but am not confident it'll really help. I feel anxious about the future, and have significant concerns.",
    },
    {
      name: "It's hard. I don't know if I can carry on as things are, or how anyone could help. I'm very anxious about the future, and have severe concerns.",
      label:
        "It's hard. I don't know if I can carry on as things are, or how anyone could help. I'm very anxious about the future, and have severe concerns.",
    },
  ],
});

// LEGACY ITEMS FOR OTHER SUBSECTIONS
// Remove once structure in PDS is updated
export const futurePlansImpactArrangementInPlacePoaFinancial =
  new FormItemButtonSelect({
    key: 'futurePlansImpactArrangementInPlacePoaFinancial',
    path: 'supportingRole.futurePlansImpact.arrangementInPlacePoaFinancial',
    label: 'Arrangement in place: Power of Attorney, Financial',
    tooltip: powerOfAttorneyTooltip,
    class: 'mb-5',
    options: yesNoInProgressLegacyOptions,
  });

export const futurePlansImpactArrangementInPlacePoaContinuingWelfare =
  new FormItemButtonSelect({
    key: 'futurePlansImpactArrangementInPlacePoaContinuingWelfare',
    path: 'supportingRole.futurePlansImpact.arrangementInPlacePoaContinuingWelfare',
    label: 'Arrangement in place: Power of Attorney, Continuing Welfare',
    tooltip: powerOfAttorneyTooltip,
    class: 'mb-5',
    options: yesNoInProgressLegacyOptions,
  });

export const futurePlansImpactArrangementInPlaceWill = new FormItemButtonSelect(
  {
    key: 'futurePlansImpactArrangementInPlaceWill',
    path: 'supportingRole.futurePlansImpact.arrangementInPlaceWill',
    label: 'Arrangement in place: Will',
    class: 'mb-5',
    options: yesNoInProgressLegacyOptions,
  }
);
