import { Component, Input } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Component({
  selector: 'app-no-items-error',
  templateUrl: './no-items-error.component.html',
  styleUrls: ['./no-items-error.component.css'],
})
export class NoItemsErrorComponent {
  @Input() errorMessage: string = '';
  @Input() isDisplayed: boolean = false;
  @Input() showBackButton: boolean = false;
  @Input() backNavigationContext: string = '/';

  constructor(protected navigationService: NavigationService) {}
}
