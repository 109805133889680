import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  first,
  take,
  takeUntil,
} from 'rxjs';
import { User } from '../../models/user/user';
import * as _ from 'lodash';
import { Connection } from '../../models/connection/connection';
import { getErrorMessage } from '../../helper/getErrorMessage';
import { PdsService } from '../pds/pds.service';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { UserApiService } from '../user-api/user.api.service';
import { AuthService } from '../auth/auth.service';
import { UserEvent } from 'src/app/models/user/userEvent';
import { UserConnectionOpinion } from 'src/app/models/user/userConnectionOpinion';

@Injectable({ providedIn: 'root' })
export class UserStateService {
  // PROPERTIES

  private _user$: BehaviorSubject<User | undefined> = new BehaviorSubject<
    User | undefined
  >(undefined);

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _loadingError$ = new BehaviorSubject<string>('');

  private _saving$ = new BehaviorSubject<boolean>(false);
  private _savingError$ = new BehaviorSubject<string>('');
  private _savingSuccess$ = new Subject<void>();

  private _destroy$: Subject<void> = new Subject<void>();

  // PUBLIC GETTERS

  get user$(): Observable<User | undefined> {
    return this._user$.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  get loadingError$(): Observable<string> {
    return this._loadingError$.asObservable();
  }

  get saving$(): Observable<boolean> {
    return this._saving$.asObservable();
  }

  get savingError$(): Observable<string> {
    return this._savingError$.asObservable();
  }

  get savingSuccess$(): Observable<void> {
    return this._savingSuccess$.asObservable();
  }

  // CONSTRUCTOR

  constructor(
    private userApiService: UserApiService,
    private authService: AuthService,
    private pdsService: PdsService,
    private sessionStorageService: SessionStorageService
  ) {
    this.updateLoadingState();
    this.initializeUserFetch();
  }

  // LIFECYCLE HOOKS

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  // PUBLIC API

  updateUser(
    data?: Partial<User>,
    serverMamPaths: string[] = [],
    customErrorMessage?: string
  ) {
    this._saving$.next(true);
    this._savingError$.next('');

    this._user$
      .pipe(
        filter((user) => user !== undefined), // Wait for the user source to be available
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        let userWithNewData = User.mergeUserData(this._user$.value, data);

        // This is only done if the user registered with Mydex via CC@M
        if (this.sessionStorageService.getRegistrationInProgress()) {
          userWithNewData = this.updateWithSessionStorageData(userWithNewData);
        } else {
          this.sessionStorageService.removeUserData();
        }

        this.userApiService
          .updateUserData(userWithNewData, serverMamPaths)
          .subscribe({
            next: () => {
              // put only returns a 200 status code, so we can assume the data was saved
              this._user$.next(userWithNewData);
              this._saving$.next(false);
              this._savingSuccess$.next();
            },
            error: (error) => {
              this._savingError$.next(
                getErrorMessage(
                  error ?? customErrorMessage ?? 'Unable to save user data'
                )
              );
              this._saving$.next(false);
            },
          });
      });
  }

  // call in ngOnDestroy of subscribing components to reset the errors (do not reset loading errors)
  resetErrors() {
    this._savingError$.next('');
  }

  logEvent(event: UserEvent) {
    this.updateUser({ events: [event] }, [], 'Unable to log the event');
  }

  markConnection(opinion: UserConnectionOpinion) {
    //needs to check if there is already an opinion for this connection??

    if (!this._user$.value) {
      return;
    }

    const existingOpinionIndex =
      this._user$.value.connectionOpinions?.findIndex(
        ({ connection }) => connection === opinion.connection
      );
    if (
      existingOpinionIndex != undefined &&
      existingOpinionIndex > -1 &&
      this._user$.value?.connectionOpinions?.[existingOpinionIndex]
    ) {
      this._user$.value.connectionOpinions[existingOpinionIndex] = opinion;
    } else {
      this._user$.value?.connectionOpinions?.push(opinion);
    }

    this.updateUser(this._user$.value, [], 'Unable to log the opinion');
  }

  userHasCompletedReferral(connection: Connection): boolean {
    return (
      this._user$.value?.events?.find(
        (e) => e.context === connection.name && e.type === 'referral'
      ) !== undefined
    );
  }

  getUserFilteredConnections() {
    let res = {
      interested: [], //connections that a user has marked as interested in
      notInterested: [], //connections that a user has marked as not interested in
      unsure: [], //connections where a user has pressed 'Maybe Later'
      completed: [], //connections where a user has completed the referral process
      general: [], //connections that a user has not interacted with and haven't marked as not interested... ordered by interested first, non-interacted second, unsure third, completed last
    };

    let opinions = {};
    this._user$.value?.connectionOpinions?.forEach(
      (opinion: UserConnectionOpinion) => {
        opinions = {
          ...opinions,
          [opinion.connection ? opinion.connection.toString() : '']:
            opinion.opinion,
        };
      }
    );

    //WIP function for categorising connections
    // this.connectionsService.connections$.subscribe((connections) => {
    //   for (let connection of connections) {
    //     const opinion = opinions[connection.name];
    //     if (opinion === 'interested') {
    //       res.interested.push(connection);
    //     } else if (opinion === 'not-interested') {
    //       res.notInterested.push(connection);
    //     } else if (opinion === 'unsure') {
    //       res.unsure.push(connection);
    //     } else if (this.userHasCompletedReferral(connection)) {
    //       res.completed.push(connection);
    //     } else {
    //       res.general.push(connection);
    //     }
    //   }
    // });

    // return this._userSource.connectionOpinions?.filter((opinion) => opinion.opinion === 'interested').map((opinion) => opinion.connection) ?? [];
  }

  // PRIVATE API

  private fetchUserData(): void {
    this._loading$.next(true);
    this._loadingError$.next('');

    this.userApiService
      .getUserData()
      .pipe(
        takeUntil(this._destroy$) // Ensure clean up on destroy
      )
      .subscribe({
        next: (user) => {
          this._user$.next(user);
          this._loading$.next(false);
        },
        error: (error) => {
          this._loadingError$.next(
            getErrorMessage(error, 'Unable to load user data')
          );
          this._loading$.next(false);
        },
      });
  }

  private initializeUserFetch(): void {
    this.pdsService.pdsConnectionStatus$
      .pipe(
        filter((pdsConnectionStatus) => pdsConnectionStatus?.value === 'valid'),
        first(), // Ensure it only fetches once after condition is met
        takeUntil(this._destroy$) // Clean up on destroy
      )
      .subscribe({
        next: () => this.fetchUserData(),
        error: (error) =>
          console.error('Error initializing user fetch:', error),
      });
  }

  private updateLoadingState(): void {
    this.authService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated === false),
        first(), // Ensure it only fetches once after condition is met
        takeUntil(this._destroy$)
      )
      .subscribe({
        next: () => this._loading$.next(false), // Stop loading if authentication failed (other path: see initializeUserFetch)
      });
  }

  // this is only done if user registered with mydex via CC@M
  private updateWithSessionStorageData(user: User): User {
    // check if user registered with mydex via CC@M
    if (this.sessionStorageService.getRegistrationInProgress() === false) {
      return user;
    }

    // get user data from the session storage and remove it from there
    const userDataFromStorage = this.sessionStorageService.popUserData();

    // safe merge of the user data with the session storage data (session storage data does not override existing user data)
    // this is done to ensure that new user data is not lost if the user has already updated their data in edge case routing scenarios
    if (userDataFromStorage) {
      user = User.mergeUserData(user, userDataFromStorage, false);
    }

    // finish the registration process after session storage data has been added for pds set up
    this.sessionStorageService.removeRegistrationInProgress();

    return user;
  }
}
