import { FormItem, FormItemTitle } from 'src/app/models/form/form';
import {
  dateOfBirth,
  ethnicity,
  firstName,
  lastName,
  middleName,
} from '../me-items/personal-details-items';
import { email } from '../me-items/contact-details-items';
import {
  address2,
  getFormItemsAddressForUserContactDetails,
  state,
} from '../../shared-items/address';
import {
  personalDetailsAbleToContinueProvidingCare,
  personalDetailsFeelingValuedAsCarer,
  personalDetailsWillingToProvideCare,
} from './personal-details-items';
import {
  supportedPeopleSupportInYears,
  supportedPeopleWeeklyHours,
} from './supported-people-items';
import {
  caringRoleChallenges,
  caringRoleInvolvedInServicesDesign,
  caringRoleServicesInPlace,
  caringRoleIsSatisfiedWithServices,
  caringRoleServicesSatisfactionDetails,
} from './caring-role-items';
import {
  healthImpactCaringAffectsPhysicalHealth,
  healthImpactCaringAffectsPhysicalHealthDetails,
  healthImpactDiscussedWithGp,
  healthImpactHealthAffectsCaring,
  healthImpactSummary,
} from './health-impact-items';
import {
  emotionalWellbeingCaringAffectsMentalHealth,
  emotionalWellbeingCaringAffectsMentalHealthDetails,
  emotionalWellbeingCaringAffectsWellbeing,
  emotionalWellbeingCaringAffectsWellbeingDetails,
  emotionalWellbeingDiscussedWithGp,
  emotionalWellbeingHasImpactOnCaring,
  emotionalWellbeingSummary,
} from './emotional-wellbeing-items';
import {
  financialImpactCaringAffectsFinances,
  financialImpactHasFinancialDifficulties,
  financialImpactSummary,
} from './financial-impact-items';
import {
  lifeBalanceAreasAffectedByCaring,
  lifeBalanceAreasAffectedByCaringDetails,
  lifeBalanceSummary,
} from './life-balance-items';
import {
  futurePlansImpactDesires,
  futurePlansImpactPotentialImpactingAspects,
  futurePlansImpactSummary,
} from './future-plans-impact-items';
import {
  employmentImpactHasOrInSearchOfOccupation,
  employmentImpactCaringAffectsOccupation,
  employmentImpactEmploymentStatus,
  employmentImpactSummary,
  employmentImpactCaringAffectsOccupationDetails,
} from './employment-impact-items';
import {
  homeImpactCaringAffectsLivingEnvironment,
  homeImpactCaringAffectsLivingEnvironmentDetails,
  homeImpactDifficultDailyTasks,
  homeImpactSummary,
} from './home-impact-items';
import {
  otherLifeAspectsBotheringCaringRoleAspects,
  otherLifeAspectsCaringAffectsRelationships,
  otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaring,
  otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaringDetails,
  otherLifeAspectsDesiredChangesAboutSituation,
  otherLifeAspectsRelationshipsImpactSummary,
} from './other-life-aspects-items';
import { skillsAndExperienceQualifications } from './skills-and-experience-items';
import { whatMattersImportanceOfRoleAsCarer } from './what-matters-items';
import { supporting } from '../me-items/current-items';

// All form items for user - supporting role
export function getFormItemsUserSupportingRole(): FormItem<any>[] {
  return [
    new FormItemTitle({ label: 'Personal Details' }),
    ...getFormItemsUserSupportingRolePersonalDetails(),
    new FormItemTitle({ label: 'The People I support' }),
    ...getFormItemsUserSupportingRoleSupportedPeople(),
    new FormItemTitle({ label: 'My Caring Role' }),
    ...getFormItemsUserSupportingRoleCaringRole(),
    new FormItemTitle({ label: 'Impact on my Health' }),
    ...getFormItemsUserSupportingRoleHealthImpact(),
    new FormItemTitle({ label: 'My Emotional Wellbeing' }),
    ...getFormItemsUserSupportingRoleEmotionalWellbeing(),
    new FormItemTitle({ label: 'Impact on my Finances' }),
    ...getFormItemsUserSupportingRoleFinancialImpact(),
    new FormItemTitle({ label: 'My Life Balance' }),
    ...getFormItemsUserSupportingRoleLifeBalance(),
    new FormItemTitle({ label: 'Impact on Future Plans' }),
    ...getFormItemsUserSupportingRoleFuturePlansImpact(),
    new FormItemTitle({ label: 'Impact on Employment' }),
    ...getFormItemsUserSupportingRoleEmploymentImpact(),
    new FormItemTitle({ label: 'Impact on My Home' }),
    ...getFormItemsUserSupportingRoleHomeImpact(),
    new FormItemTitle({ label: 'Other Aspects of Life' }),
    ...getFormItemsUserSupportingRoleOtherLifeAspects(),
    new FormItemTitle({ label: 'Skills and Experience' }),
    ...getFormItemsUserSupportingRoleSkillsAndExperience(),
    new FormItemTitle({ label: 'What Matters' }),
    ...getFormItemsUserSupportingRoleWhatMatters(),
  ];
}

// All form items for user - supporting role - personal details
export function getFormItemsUserSupportingRolePersonalDetails(): FormItem<any>[] {
  return [
    // Reusing items from This is Me / Personal Details
    firstName,
    middleName,
    lastName,
    ethnicity,
    dateOfBirth,
    // Reusing items from This is Me / Contact Details
    email,
    ...getFormItemsAddressForUserContactDetails(),
    // New items
    personalDetailsWillingToProvideCare,
    personalDetailsAbleToContinueProvidingCare,
    personalDetailsFeelingValuedAsCarer,
  ];
}

export function getFormItemsUserSupportingRolePersonalDetailsNegligibleForCompleteness(): FormItem<any>[] {
  return [
    address2, // has same path as address2 from getFormItemsAddressForUserContactDetails()
    state, // has same path as state from getFormItemsAddressForUserContactDetails()
  ];
}

// All form items for user - supporting role - supported people
export function getFormItemsUserSupportingRoleSupportedPeople(): FormItem<any>[] {
  return [
    // Reusing items from This is Me / Current
    supporting,
    // New items
    supportedPeopleSupportInYears,
    supportedPeopleWeeklyHours,
  ];
}

// All form items for user - supporting role - caring role
export function getFormItemsUserSupportingRoleCaringRole(): FormItem<any>[] {
  return [
    caringRoleServicesInPlace,
    caringRoleInvolvedInServicesDesign,
    caringRoleIsSatisfiedWithServices,
    caringRoleServicesSatisfactionDetails,
    caringRoleChallenges,
  ];
}

export function getFormItemsUserSupportingRoleCaringRoleNegligibleForCompleteness(): FormItem<any>[] {
  return [
    caringRoleServicesSatisfactionDetails, // optional display
  ];
}

// All form items for user - supporting role - health impact
export function getFormItemsUserSupportingRoleHealthImpact(): FormItem<any>[] {
  return [
    healthImpactCaringAffectsPhysicalHealth,
    healthImpactCaringAffectsPhysicalHealthDetails,
    healthImpactHealthAffectsCaring,
    healthImpactDiscussedWithGp,
    healthImpactSummary,
  ];
}

export function getFormItemsUserSupportingRoleHealthImpactNegligibleForCompleteness(): FormItem<any>[] {
  return [
    healthImpactCaringAffectsPhysicalHealthDetails, // optional display
  ];
}

// All form items for user - supporting role - emotional wellbeing
export function getFormItemsUserSupportingRoleEmotionalWellbeing(): FormItem<any>[] {
  return [
    emotionalWellbeingCaringAffectsWellbeing,
    emotionalWellbeingCaringAffectsWellbeingDetails,
    emotionalWellbeingCaringAffectsMentalHealth,
    emotionalWellbeingCaringAffectsMentalHealthDetails,
    emotionalWellbeingHasImpactOnCaring,
    emotionalWellbeingDiscussedWithGp,
    emotionalWellbeingSummary,
  ];
}

export function getFormItemsUserSupportingRoleEmotionalWellbeingNegligibleForCompleteness(): FormItem<any>[] {
  return [
    emotionalWellbeingCaringAffectsWellbeingDetails, // optional display
    emotionalWellbeingCaringAffectsMentalHealthDetails, // optional display
  ];
}

// All form items for user - supporting role - financial impact
export function getFormItemsUserSupportingRoleFinancialImpact(): FormItem<any>[] {
  return [
    financialImpactCaringAffectsFinances,
    financialImpactHasFinancialDifficulties,
    financialImpactSummary,
  ];
}

// All form items for user - supporting role - life balance
export function getFormItemsUserSupportingRoleLifeBalance(): FormItem<any>[] {
  return [
    lifeBalanceAreasAffectedByCaring,
    lifeBalanceAreasAffectedByCaringDetails,
    lifeBalanceSummary,
  ];
}

// All form items for user - supporting role - future plans impact
export function getFormItemsUserSupportingRoleFuturePlansImpact(): FormItem<any>[] {
  return [
    futurePlansImpactPotentialImpactingAspects,
    futurePlansImpactDesires,
    futurePlansImpactSummary,
  ];
}

// All form items for user - supporting role - employment impact
export function getFormItemsUserSupportingRoleEmploymentImpact(): FormItem<any>[] {
  return [
    employmentImpactEmploymentStatus,
    employmentImpactHasOrInSearchOfOccupation,
    employmentImpactCaringAffectsOccupation,
    employmentImpactCaringAffectsOccupationDetails,
    employmentImpactSummary,
  ];
}

export function getFormItemsUserSupportingRoleEmploymentImpactNegligibleForCompleteness(): FormItem<any>[] {
  return [
    employmentImpactCaringAffectsOccupationDetails, // optional display
  ];
}

// All form items for user - supporting role - home impact
export function getFormItemsUserSupportingRoleHomeImpact(): FormItem<any>[] {
  return [
    homeImpactCaringAffectsLivingEnvironment,
    homeImpactCaringAffectsLivingEnvironmentDetails,
    homeImpactDifficultDailyTasks,
    homeImpactSummary,
  ];
}

export function getFormItemsUserSupportingRoleHomeImpactNegligibleForCompleteness(): FormItem<any>[] {
  return [
    homeImpactCaringAffectsLivingEnvironmentDetails, // optional display
  ];
}

// All form items for user - supporting role - other life aspects
export function getFormItemsUserSupportingRoleOtherLifeAspects(): FormItem<any>[] {
  return [
    otherLifeAspectsCaringAffectsRelationships,
    otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaring,
    otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaringDetails,
    otherLifeAspectsBotheringCaringRoleAspects,
    otherLifeAspectsDesiredChangesAboutSituation,
    otherLifeAspectsRelationshipsImpactSummary,
  ];
}

export function getFormItemsUserSupportingRoleOtherLifeAspectsNegligibleForCompleteness(): FormItem<any>[] {
  return [
    otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaring, // optional display
    otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaringDetails, // optional display
  ];
}

// All form items for user - supporting role - skills and experience
export function getFormItemsUserSupportingRoleSkillsAndExperience(): FormItem<any>[] {
  return [skillsAndExperienceQualifications];
}

// All form items for user - supporting role - what matters
export function getFormItemsUserSupportingRoleWhatMatters(): FormItem<any>[] {
  return [whatMattersImportanceOfRoleAsCarer];
}
