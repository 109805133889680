export class UserConnectionOpinion {
  opinion?: 'interested' | 'not-interested' | 'unsure';
  connection?: string;
  timestamp?: number;
  constructor(data: Partial<UserConnectionOpinion | undefined>) {
    this.opinion = data?.opinion;
    this.connection = data?.connection;
    this.timestamp = Date.now();
  }
}
