import { Component, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { UserEventsService } from 'src/app/services/user-event/user.event.service';
import { UserStateService } from 'src/app/services/user-state/user.state.service';

@Component({
  selector: 'app-carer',
  templateUrl: './carer.component.html',
  styleUrls: ['./carer.component.css'],
})
export class CarerComponent {
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent =
    {} as ModalComponent;
  completedBefore?: string | false = false;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private eventService: UserEventsService,
    private userService: UserStateService
  ) {}

  ngOnInit(): void {
    this.eventService
      .checkEventsCompletion([
        {
          type: 'referral',
          context: 'quarriers',
          status: 'completed',
        }, // legacy event
        { type: 'assessment', context: 'quarriers', status: 'completed' },
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.completedBefore = result; // result should be the formatted date string
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
