<!-- TITLE -->
<div class="mt-2">
  <div class="flex mb-4 justify-between">
    <div class="mt-2">
      <h1 class="text-3xl font-semibold m-0">
        {{ pdsSection?.title }}
      </h1>
      <p class="m-0 mt-2 text-gray-500">
        {{ pdsSection?.longDescription ?? pdsSection?.description }}
      </p>
    </div>

    <div class="flex ml-5">
      <span
        [class.text-theme]="completedPercentageOfSection == 100"
        class="font-light transition-colors mr-2 my-auto text-base block whitespace-nowrap pb-2"
        >{{ completedPercentageOfSection }}% Complete</span
      >
      <app-piechart
        [borderWidth]="6"
        class="my-auto"
        [width]="40"
        [value]="completedPercentageOfSection"
      >
        <img
          [class.opacity-0]="completedPercentageOfSection < 100"
          class="m-auto w-5 transition-opacity"
          src="/assets/img/icons/tick.svg"
        />
      </app-piechart>
    </div>
  </div>
</div>
<!-- END TITLE -->

<!-- FORM -->
<div *ngIf="form && form.length" class="mt-8">
  <app-form
    #formComponent
    [formData]="form"
    [formValues]="user"
    (submit)="handleSave($event)"
    submitText="Save"
    loadingText="Saving"
    [loading]="(userService.saving$ | async) === true"
    [success]="savingUserSuccess"
    [error]="(userService.savingError$ | async) || ''"
    [cancellable]="true"
    [cancelText]="formCancelText ? formCancelText : 'Back'"
    (cancel)="formCancel.emit($event)"
    class="connection-form"
    [contactsItemOptions]="contacts"
    [contactsItemLoading]="(userContactsStateService.loading$ | async) === true"
    [contactsItemLoadingError]="
      (userContactsStateService.loadingError$ | async) || ''
    "
    [contactsItemSaving]="(userContactsStateService.saving$ | async) === true"
    [contactsItemSavingError]="
      (userContactsStateService.savingError$ | async) || ''
    "
    [contactsItemDeleting]="
      (userContactsStateService.deleting$ | async) === true
    "
    [contactsItemDeletionError]="
      (userContactsStateService.deletionError$ | async) || ''
    "
    (contactCreated)="handleContactCreated($event)"
    (contactUpdated)="handleContactUpdated($event)"
    (contactDeleted)="handleContactDeleted($event)"
    (contactsItemClearErrors)="handleClearContactsItemErrors($event)"
    [imagesItemOptions]="images"
    [imagesItemLoadingAll]="
      (userImagesStateService.loadingImageThumbnails$ | async) === true
    "
    [imagesItemLoadingAllError]="
      (userImagesStateService.loadingImageThumbnailsError$ | async) || ''
    "
    [imagesItemLoadingSingleImage]="
      (userImagesStateService.loadingImageFullSize$ | async) === true
    "
    [imagesItemLoadingSingleImageError]="
      (userImagesStateService.loadingImageFullSizeError$ | async) || ''
    "
    [imagesItemAdding]="(userImagesStateService.addingImage$ | async) === true"
    [imagesItemAdditionError]="
      (userImagesStateService.addingImageError$ | async) || ''
    "
    [imagesItemUpdating]="
      (userImagesStateService.updatingImage$ | async) === true
    "
    [imagesItemUpdatingError]="
      (userImagesStateService.updatingImageError$ | async) || ''
    "
    [imagesItemDeleting]="
      (userImagesStateService.deletingImage$ | async) === true
    "
    [imagesItemDeletionError]="
      (userImagesStateService.deletingImageError$ | async) || ''
    "
    (fullSizeImageRequested)="handleFullSizeImageRequested($event)"
    (imageAdded)="handleImageAdded($event)"
    (imageUpdated)="handleImageUpdated($event)"
    (imageDeleted)="handleImageDeleted($event)"
    (imagesItemClearErrors)="handleClearImagesItemErrors($event)"
    [medicationsItemAllMedications]="medications"
    [medicationsItemLoadingAll]="
      (userMedicationsStateService.loadingMedicationsWithBasics$ | async) ===
      true
    "
    [medicationsItemLoadingAllError]="
      (userMedicationsStateService.loadingMedicationsWithBasicsError$
        | async) || ''
    "
    [medicationsItemLoadingDetails]="
      (userMedicationsStateService.loadingMedicationDetails$ | async) === true
    "
    [medicationsItemLoadingDetailsError]="
      (userMedicationsStateService.loadingMedicationDetailsError$ | async) || ''
    "
    [medicationsItemAdding]="
      (userMedicationsStateService.addingMedication$ | async) === true
    "
    [medicationsItemAdditionError]="
      (userMedicationsStateService.addingMedicationError$ | async) || ''
    "
    [medicationsItemUpdating]="
      (userMedicationsStateService.updatingMedication$ | async) === true
    "
    [medicationsItemUpdateError]="
      (userMedicationsStateService.updatingMedicationError$ | async) || ''
    "
    [medicationsItemDeleting]="
      (userMedicationsStateService.deletingMedication$ | async) === true
    "
    [medicationsItemDeletionError]="
      (userMedicationsStateService.deletingMedicationError$ | async) || ''
    "
    (medicationDetailsRequested)="handleMedicationDetailsRequested($event)"
    (medicationAdded)="handleMedicationAdded($event)"
    (medicationUpdated)="handleMedicationUpdated($event)"
    (medicationDeleted)="handleMedicationDeleted($event)"
    (medicationsItemClearErrors)="handleClearMedicationItemErrors($event)"
  >
  </app-form>
</div>
<!-- END FORM -->
