import { PdsSection } from 'src/app/models/pds/pdsSection';
import {
  getFormItemsUserSupportActionsList,
  getFormItemsUserSupportBedtime,
  getFormItemsUserSupportBedtimeNegligibleForCompleteness,
  getFormItemsUserSupportCommunication,
  getFormItemsUserSupportContinence,
  getFormItemsUserSupportContinenceNegligibleForCompleteness,
  getFormItemsUserSupportDigital,
  getFormItemsUserSupportEatingAndDrinking,
  getFormItemsUserSupportFalls,
  getFormItemsUserSupportFallsNegligibleForCompleteness,
  getFormItemsUserSupportFootCare,
  getFormItemsUserSupportFootCareNegligibleForCompleteness,
  getFormItemsUserSupportGeneralFitness,
  getFormItemsUserSupportGettingWashed,
  getFormItemsUserSupportMedication,
  getFormItemsUserSupportMovingAroundInside,
  getFormItemsUserSupportMovingAroundOutside,
  getFormItemsUserSupportPersonalAids,
  getFormItemsUserSupportPracticalHelp,
} from '../../forms/user/support-items/form-support';

// TODO: Check neglible for completeness items once connected to MAM
// TODO: Update My Summary once connected to MAM

export const supportSection: PdsSection = {
  title: 'My Support',
  name: 'my-support',
  description: 'Details about the support you need',
  longDescription:
    'In this section, you can tell us all about the support you need. Click on an area to get started.',
  routePath: 'my-support',
  userPath: 'support',
  children: [
    {
      title: 'Communication',
      name: 'my-support-communication',
      description: 'Your needs and preferences for communication',
      longDescription: 'What do you want us to know before we contact you?',
      routePath: 'communication',
      userPath: 'communication',
      getFormItems: getFormItemsUserSupportCommunication,
    },
    {
      title: 'My Medication',
      name: 'my-support-medication',
      description: 'Details about the medication you take',
      routePath: 'my-medication',
      userPath: 'medication',
      getFormItems: getFormItemsUserSupportMedication,
    },
    {
      title: 'General Fitness',
      name: 'my-support-general-fitness',
      description: 'Tell us more about your fitness',
      routePath: 'general-fitness',
      userPath: 'generalFitness',
      getFormItems: getFormItemsUserSupportGeneralFitness,
    },
    {
      title: 'Practical Help',
      name: 'my-support-practical-help',
      description: 'Daily tasks you could use help with',
      routePath: 'practical-help',
      userPath: 'practicalHelp',
      getFormItems: getFormItemsUserSupportPracticalHelp,
    },
    {
      title: 'Moving Around Inside',
      name: 'my-support-moving-around-inside',
      description: 'How you get on moving around inside your home',
      routePath: 'moving-around-inside',
      userPath: 'movingAroundInside',
      getFormItems: getFormItemsUserSupportMovingAroundInside,
    },
    {
      title: 'Moving Around Outside',
      name: 'my-support-moving-around-outside',
      description: 'How you get on moving around outside your home',
      routePath: 'moving-around-outside',
      userPath: 'movingAroundOutside',
      getFormItems: getFormItemsUserSupportMovingAroundOutside,
    },
    {
      title: 'Getting Washed',
      name: 'my-support-getting-washed',
      description: 'Support you need with washing',
      routePath: 'getting-washed',
      userPath: 'gettingWashed',
      getFormItems: getFormItemsUserSupportGettingWashed,
    },
    {
      title: 'Personal Aids',
      name: 'my-support-personal-aids',
      description: 'Tell us more about the personal aids you use',
      routePath: 'personal-aids',
      userPath: 'personalAids',
      getFormItems: getFormItemsUserSupportPersonalAids,
    },
    {
      title: 'Continence',
      name: 'my-support-continence',
      description: 'Details about your continence needs',
      routePath: 'continence',
      userPath: 'continence',
      getFormItems: getFormItemsUserSupportContinence,
      getFormItemsNegligibleForCompleteness:
        getFormItemsUserSupportContinenceNegligibleForCompleteness,
    },
    {
      title: 'Eating & Drinking',
      name: 'my-support-eating-drinking',
      description: 'Your needs and preferences for eating and drinking',
      routePath: 'eating-drinking',
      userPath: 'eatingAndDrinking',
      getFormItems: getFormItemsUserSupportEatingAndDrinking,
    },
    {
      title: 'Bedtime',
      name: 'my-support-bedtime',
      description: 'Your support needs for going to bed and getting up',
      routePath: 'bedtime',
      userPath: 'bedtime',
      getFormItems: getFormItemsUserSupportBedtime,
      getFormItemsNegligibleForCompleteness:
        getFormItemsUserSupportBedtimeNegligibleForCompleteness,
    },
    {
      title: 'Falls',
      name: 'my-support-falls',
      description: 'You can tell us about any falls you have had',
      routePath: 'falls',
      userPath: 'falls',
      getFormItems: getFormItemsUserSupportFalls,
      getFormItemsNegligibleForCompleteness:
        getFormItemsUserSupportFallsNegligibleForCompleteness,
    },
    {
      title: 'Digital',
      name: 'my-support-digital',
      description: 'Details about technology you use for support',
      routePath: 'digital',
      userPath: 'digital',
      getFormItems: getFormItemsUserSupportDigital,
    },
    // { TODO: Add back in when BE ready
    //   title: 'Support Package',
    //   description: 'All about your support package',
    //   routePath: 'support-package',
    //   userPath: 'supportPackage',
    //   getFormItems: getFormItemsUserSupportSupportPackage,
    // },
    {
      title: 'Actions List',
      name: 'my-support-actions-list',
      description: 'Tell us about the actions you need help with',
      routePath: 'actions-list',
      userPath: 'actionsList',
      getFormItems: getFormItemsUserSupportActionsList,
    },
    {
      title: 'Foot Care',
      name: 'my-support-foot-care',
      description: 'Details about your foot care needs',
      routePath: 'foot-care',
      userPath: 'footCare',
      getFormItems: getFormItemsUserSupportFootCare,
      getFormItemsNegligibleForCompleteness:
        getFormItemsUserSupportFootCareNegligibleForCompleteness,
    },
  ],
};
