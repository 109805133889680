import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Connection } from 'src/app/models/connection/connection';
import { User } from 'src/app/models/user/user';
import { ConnectionsStateService } from 'src/app/services/connections-state/connections.state.service';
import { UserEventsService } from 'src/app/services/user-event/user.event.service';
import { UserStateService } from 'src/app/services/user-state/user.state.service';

@Component({
  selector: 'app-page-account-referrals',
  templateUrl: './page-account-referrals.component.html',
  styleUrl: './page-account-referrals.component.css',
})
export class PageAccountReferralsComponent {
  // PROPERTIES

  protected user: User | undefined;
  protected connectionsWithOngoingPdsReferrals: Connection[] = [];

  private allConnections: Connection[] = [];
  private destroy$: Subject<void> = new Subject<void>();

  // CONSTRUCTOR

  constructor(
    protected userService: UserStateService,
    protected connectionService: ConnectionsStateService,
    private userEventService: UserEventsService
  ) {}

  // LIFECYCLE HOOKS

  ngOnInit() {
    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        this.user = user;
        this.checkForOngoingReferrals();
      },
    });

    this.connectionService.connections$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (connections) => {
          if (!connections) {
            return;
          }

          this.allConnections = connections;
          this.checkForOngoingReferrals();
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.userService.resetErrors();
    this.connectionService.resetErrors();
  }

  // PRIVATE API

  checkForOngoingReferrals() {
    if (!this.user || !this.allConnections) {
      return;
    }

    var connectionNames: string[] = [];

    this.userEventService
      .getEventsLikeEventInProgressOnly({
        type: 'pds-referral',
        status: 'in-progress',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (!result) {
          return;
        }
        result.forEach((event) => {
          connectionNames.push(event.connection ?? '');
        });

        this.connectionsWithOngoingPdsReferrals = this.allConnections.filter(
          (connection) => connectionNames.includes(connection.name)
        );
      });
  }
}
