import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';
import {
  FormItemButtonSelect,
  FormItemDropdown,
  FormItemTextArea,
} from 'src/app/models/form/form';

export const lifeBalanceAreasAffectedByCaring = new FormItemButtonSelect({
  key: 'lifeBalanceAreasAffectedByCaring',
  path: 'supportingRole.lifeBalance.areasAffectedByCaring',
  label: 'My caring role impacts on these aspects of my life',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'caring_affects_social_life', label: 'Social life' },
    {
      name: 'caring_affects_cultural_activities',
      label: 'Cultural activities',
    },
    { name: 'caring_affects_leisure_activities', label: 'Leisure activities' },
    { name: 'caring_affects_childcare', label: 'Childcare' },
    {
      name: 'caring_affects_religious_activities',
      label: 'Religious activities',
    },
  ],
});

export const lifeBalanceAreasAffectedByCaringDetails = new FormItemTextArea({
  key: 'lifeBalanceAreasAffectedByCaringDetails',
  path: 'supportingRole.lifeBalance.areasAffectedByCaringDetails',
  label:
    'Anything else you would like to share about the impact caring has on your life balance',
  placeholder: 'e.g. losing touch with friends, missing wider family events',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const lifeBalanceSummary = new FormItemDropdown({
  key: 'lifeBalanceSummary',
  label:
    'Thinking about the balance between my caring role and a life of my own, the selected statement best matches how I currently feel.',
  path: 'supportingRole.lifeBalance.summary',
  class: 'mb-5',
  placeholder: 'Please select...',
  options: [
    {
      name: 'Things are as good as they can be. I take breaks and balance caring with other things.',
      label:
        'Things are as good as they can be. I take breaks and balance caring with other things.',
    },
    {
      name: "I have some time for myself, and some activities or social life outside my caring role, but things could be better. I feel I don't have many choices of things to do.",
      label:
        "I have some time for myself, and some activities or social life outside my caring role, but things could be better. I feel I don't have many choices of things to do.",
    },
    {
      name: "I'm trying to get some time for myself, and some activities or social life outside my caring role, but it's difficult and often doesn't work out.",
      label:
        "I'm trying to get some time for myself, and some activities or social life outside my caring role, but it's difficult and often doesn't work out.",
    },
    {
      name: "Caring has taken over my life. I rarely get away from my caring role, and can't plan to take time to myself. I'm getting some help to see if I can change this",
      label:
        "Caring has taken over my life. I rarely get away from my caring role, and can't plan to take time to myself. I'm getting some help to see if I can change this.",
    },
    {
      name: "Caring is my whole life. I can't see how it could be any other way. I get no breaks, and have no life of my own away from caring.",
      label:
        "Caring is my whole life. I can't see how it could be any other way. I get no breaks, and have no life of my own away from caring.",
    },
  ],
});
