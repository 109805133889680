import { Component, Input } from '@angular/core';
import { Subject, catchError, takeUntil, tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/models/user/user';
import { UserStateService } from 'src/app/services/user-state/user.state.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  navActive: boolean = false;
  user: User | undefined;

  @Input() type: 'sidebar' | null = null;
  @Input() layout: string = 'dashboard';

  logoutLoading = false;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    protected authService: AuthService,
    private userService: UserStateService
  ) {}

  ngOnInit() {
    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        this.user = user;
      },
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout() {
    this.logoutLoading = true;
    this.authService.logout();
  }

  toggleNav() {
    this.navActive = !this.navActive;
  }

  closeNav() {
    this.navActive = false;
  }
}
