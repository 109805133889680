import { Component, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { computeCompletedRequiredReferralFormItemsPercentage } from 'src/app/helper/formCompleteness';
import { Connection } from 'src/app/models/connection/connection';
import { User } from 'src/app/models/user/user';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { UserStateService } from 'src/app/services/user-state/user.state.service';

@Component({
  selector: 'app-email-referral-completeness',
  templateUrl: './email-referral-completeness.component.html',
  styleUrls: ['./email-referral-completeness.component.css'],
})
export class EmailReferralCompletenessComponent implements OnInit {
  @Input() connection?: Connection;
  protected referralCompletedPercentage: number = 0;

  private user: User | undefined;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    protected userService: UserStateService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        if (user) {
          this.user = user;
        } else {
          this.user = this.sessionStorageService.getUserData();
        }
        this.updateReferralCompletenessPercentage();
      },
    });
  }

  ngOnDestory(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateReferralCompletenessPercentage() {
    if (!this.connection || !this.user) {
      return;
    }

    this.referralCompletedPercentage =
      computeCompletedRequiredReferralFormItemsPercentage(
        this.user,
        this.connection
      );
  }
}
