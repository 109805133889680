import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { getImageMimeTypeFromBase64String } from 'src/app/helper/imageHelper';
import { FormItemImageUploadGallery } from 'src/app/models/form/form';
import { UserImageData } from 'src/app/models/image/userImageData';
import { UserImagePdsEntry } from 'src/app/models/image/userImagePdsEntry';

@Component({
  selector: 'app-image-upload-gallery',
  templateUrl: './image-upload-gallery.component.html',
  styleUrl: './image-upload-gallery.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ImageUploadGalleryComponent),
    },
  ],
})
export class ImageUploadGalleryComponent implements ControlValueAccessor {
  // PROPERTIES
  @ViewChild('imageGalleryModal', { static: true })
  imageGalleryModal: ModalComponent = {} as ModalComponent;
  @ViewChild('largeImageDisplayModal', { static: true })
  largeImageDisplayModal: ModalComponent = {} as ModalComponent;
  @ViewChild('imagePickerModal', { static: true })
  imagePickerModal: ModalComponent = {} as ModalComponent;
  @ViewChild('fileInputRef') fileInputRef?: ElementRef<HTMLInputElement>;

  @Input() item?: FormItemImageUploadGallery;
  @Input() control: any;
  @Input() disabled = false;

  @Input() imagesThumbnailsSource?: UserImagePdsEntry[];
  @Input() imagesThumbnailsSourceLoading: boolean = true;
  @Input() imagesThumbnailsSourceLoadingError: string = '';
  @Input() imageFullSizeLoading: boolean = true;
  @Input() imageFullSizeLoadingError: string = '';
  @Input() imageAdding: boolean = false;
  @Input() imageAdditionError: string = '';
  @Input() imageUpdating: boolean = false;
  @Input() imageUpdatingError: string = '';
  @Input() imageDeleting: boolean = false;
  @Input() imageDeletionError: string = '';

  @Output() fullSizeImageRequested = new EventEmitter();
  @Output() imageAdded = new EventEmitter();
  @Output() imageUpdated = new EventEmitter();
  @Output() imageDeleted = new EventEmitter();
  @Output() clearErrors = new EventEmitter();

  formControlValue: string[] | undefined = undefined; // selected image ids

  selectedImages: UserImagePdsEntry[] = [];

  largeImageModalDisplayMode: 'edit' | 'view' | undefined = undefined;
  currentFullSizeImage: UserImagePdsEntry | null = null;
  currentEditableImageDescription: string = '';
  currentImageToBeDeleted: UserImagePdsEntry | null = null;

  onChange = (selected: any) => {};
  onTouched = () => {};
  touched = false;

  // LIFE CYCLE HOOKS

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // update the selected images when the images thumbnails source changes (i.e. after deletion)
    if (changes['imagesThumbnailsSource']) {
      this.updateSelectedImages();
    }
  }

  // CONTROL VALUE ACCESSOR

  writeValue(value: string[]): void {
    this.formControlValue = value;
    this.updateSelectedImages();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  handleUpdatedFormControlValue() {
    this.onChange(this.formControlValue);
    this.onTouched();
  }

  // MODAL METHODS
  openImagePickerModal(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this.imagePickerModal.open();
  }

  closeImagePickerModal(e: any) {
    this.imagePickerModal.close();
  }

  openImageGalleryModal(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this.imageGalleryModal.open();
  }

  onImageGalleryModalClosed() {
    this.currentImageToBeDeleted = null;
    this.clearErrors.emit();
  }

  openLargeImageDisplayModal(
    e: any,
    image: UserImagePdsEntry,
    largeImageModalDisplayMode: 'edit' | 'view'
  ) {
    e.stopPropagation();
    e.preventDefault();

    this.fullSizeImageRequested.emit(image.imageId);
    this.largeImageModalDisplayMode = largeImageModalDisplayMode;
    this.largeImageDisplayModal.open();
  }

  closeLargeImageDisplayModal(e: any) {
    this.largeImageDisplayModal.close();
  }

  onLargeImageDisplayModalClosed() {
    this.currentFullSizeImage = null;
    this.largeImageModalDisplayMode = undefined;
    this.clearErrors.emit();
  }

  // IMAGE SELECTION METHODS

  isImageSelected(imageId: string): boolean {
    if (!this.formControlValue) {
      return false;
    }
    return this.formControlValue.includes(imageId);
  }

  toggleImageSelection(imageId: string): void {
    if (this.isImageSelected(imageId)) {
      this.formControlValue = this.formControlValue?.filter(
        (id) => id !== imageId
      );
    } else {
      this.formControlValue?.push(imageId);
    }

    this.updateSelectedImages();
    this.handleUpdatedFormControlValue();
  }

  // ADD IMAGE / UPLOAD METHODS

  onAddNewImageClicked(e: any) {
    this.fileInputRef?.nativeElement.click();
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (readerEvt) => {
      this.handleReaderLoaded(readerEvt, file);
    };
    reader.readAsDataURL(file);
  }

  handleReaderLoaded(readerEvt: any, file: File): void {
    const base64WithPrefix = readerEvt.target.result;
    // Remove the prefix (i.e. "data:image/png;base64,") because Mydex does not accept the prefix
    const base64 = base64WithPrefix.split(',')[1];

    // Add new Image
    const newImageData: UserImageData = {
      fileName: file.name,
      file: base64,
    };

    this.imageAdded.emit(newImageData);
  }

  onCancelFilePicker(event: any) {
    event.stopPropagation();
  }

  // IMAGE EDITING METHODS

  onSaveImageDescriptionClicked(e: any, image: UserImagePdsEntry) {
    e.stopPropagation();
    e.preventDefault();

    // Update the image description
    image.description = this.currentEditableImageDescription;
    this.imageUpdated.emit(image);
  }

  handleImageUpdatingSuccess() {
    // Ensure that the image is being edited
    if (this.largeImageModalDisplayMode === 'edit') {
      this.largeImageDisplayModal.close();
    }
  }

  // IMAGE DELETION METHODS

  onImageDeletionClicked(e: any, image: UserImagePdsEntry) {
    this.currentImageToBeDeleted = image;
    this.imageDeleted.emit(image);
  }

  handleImageDeletionSuccess(imageId: string) {
    // Remove the image from the selected image ids
    this.formControlValue = this.formControlValue?.filter(
      (id) => id !== imageId
    );
    this.handleUpdatedFormControlValue();

    this.currentImageToBeDeleted = null;
  }

  // IMAGE DISPLAY METHODS

  updateSelectedImages() {
    if (!this.imagesThumbnailsSource) {
      this.selectedImages = [];
      return;
    }

    this.selectedImages = this.imagesThumbnailsSource.filter((image) => {
      return this.formControlValue?.includes(image.imageId) ?? false;
    });
  }

  getImageSrc(base64String: string | undefined): string | undefined {
    if (!base64String) {
      return undefined;
    }

    const mimeType = getImageMimeTypeFromBase64String(base64String);

    if (!mimeType) {
      return undefined;
    }

    return `data:image/${mimeType};base64,${base64String}`;
  }

  handleLoadingImageFullSizeSuccess(fullSizeImage: UserImagePdsEntry) {
    this.currentFullSizeImage = fullSizeImage;
    this.currentEditableImageDescription = fullSizeImage.description ?? '';
  }

  downloadImage(image: UserImagePdsEntry) {
    const link = document.createElement('a');
    link.href = this.getImageSrc(image.file) ?? '';
    link.download = image.fileName ?? this.item?.imageDownloadName ?? 'image';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
