import { UserMeMyPeopleRelationship } from './userMe';

export class UserSupportingRole {
  personalDetails?: UserSupportingRolePersonalDetails;
  supportedPeople?: UserSupportingRoleSupportedPeople;
  caringRole?: UserSupportingRoleCaringRole;
  healthImpact?: UserSupportingRoleHealthImpact;
  emotionalWellbeing?: UserSupportingRoleEmotionalWellbeing;
  financialImpact?: UserSupportingRoleFinancialImpact;
  lifeBalance?: UserSupportingRoleLifeBalance;
  futurePlansImpact?: UserSupportingRoleFuturePlansImpact;
  employmentImpact?: UserSupportingRoleEmploymentImpact;
  homeImpact?: UserSupportingRoleHomeImpact;
  otherLifeAspects?: UserSupportingRoleOtherLifeAspects;
  skillsAndExperience?: UserSupportingRoleSkillsAndExperience;
  whatMatters?: UserSupportingWhatMatters;

  constructor(supportingRole?: Partial<UserSupportingRole>) {
    this.personalDetails = new UserSupportingRolePersonalDetails(
      supportingRole?.personalDetails
    );
    this.supportedPeople = new UserSupportingRoleSupportedPeople(
      supportingRole?.supportedPeople
    );
    this.caringRole = new UserSupportingRoleCaringRole(
      supportingRole?.caringRole
    );
    this.healthImpact = new UserSupportingRoleHealthImpact(
      supportingRole?.healthImpact
    );
    this.emotionalWellbeing = new UserSupportingRoleEmotionalWellbeing(
      supportingRole?.emotionalWellbeing
    );
    this.financialImpact = new UserSupportingRoleFinancialImpact(
      supportingRole?.financialImpact
    );
    this.lifeBalance = new UserSupportingRoleLifeBalance(
      supportingRole?.lifeBalance
    );
    this.futurePlansImpact = new UserSupportingRoleFuturePlansImpact(
      supportingRole?.futurePlansImpact
    );
    this.employmentImpact = new UserSupportingRoleEmploymentImpact(
      supportingRole?.employmentImpact
    );
    this.homeImpact = new UserSupportingRoleHomeImpact(
      supportingRole?.homeImpact
    );
    this.otherLifeAspects = new UserSupportingRoleOtherLifeAspects(
      supportingRole?.otherLifeAspects
    );
    this.skillsAndExperience = new UserSupportingRoleSkillsAndExperience(
      supportingRole?.skillsAndExperience
    );
    this.whatMatters = new UserSupportingWhatMatters(
      supportingRole?.whatMatters
    );
  }
}

class UserSupportingRolePersonalDetails {
  willingToProvideCare?: string;
  ableToContinueProvidingCare?: string;
  feelingValuedAsCarer?: string;

  constructor(data: Partial<UserSupportingRolePersonalDetails | undefined>) {
    this.willingToProvideCare = data?.willingToProvideCare;
    this.ableToContinueProvidingCare = data?.ableToContinueProvidingCare;
    this.feelingValuedAsCarer = data?.feelingValuedAsCarer;
  }
}

class UserSupportingRoleSupportedPeople {
  supportInYears?: string;
  weeklyHours?: string;

  constructor(data: Partial<UserSupportingRoleSupportedPeople | undefined>) {
    this.supportInYears = data?.supportInYears;
    this.weeklyHours = data?.weeklyHours;
  }
}

class UserSupportingRoleCaringRole {
  servicesInPlace?: string;
  involvedInServicesDesign?: string;
  isSatisfiedWithServices?: string;
  satisfactionDetails?: string;
  challenges?: string;

  constructor(data: Partial<UserSupportingRoleCaringRole | undefined>) {
    this.servicesInPlace = data?.servicesInPlace;
    this.involvedInServicesDesign = data?.involvedInServicesDesign;
    this.isSatisfiedWithServices = data?.isSatisfiedWithServices;
    this.satisfactionDetails = data?.satisfactionDetails;
    this.challenges = data?.challenges;
  }
}

class UserSupportingRoleHealthImpact {
  caringAffectsPhysicalHealth?: string;
  caringAffectsPhysicalHealthDetails?: string;
  healthAffectsCaring?: string;
  discussedWithGp?: string;
  summary?: string;

  constructor(data: Partial<UserSupportingRoleHealthImpact | undefined>) {
    this.caringAffectsPhysicalHealth = data?.caringAffectsPhysicalHealth;
    this.caringAffectsPhysicalHealthDetails =
      data?.caringAffectsPhysicalHealthDetails;
    this.healthAffectsCaring = data?.healthAffectsCaring;
    this.discussedWithGp = data?.discussedWithGp;
    this.summary = data?.summary;
  }
}

class UserSupportingRoleEmotionalWellbeing {
  caringAffectsWellbeing?: string;
  caringAffectsWellbeingDetails?: string;
  caringAffectsMentalHealth?: string;
  caringAffectsMentalHealthDetails?: string;
  wellbeingHasImpactOnCaring?: string;
  discussedWithGp?: string;
  summary?: string;

  constructor(data: Partial<UserSupportingRoleEmotionalWellbeing | undefined>) {
    this.caringAffectsWellbeing = data?.caringAffectsWellbeing;
    this.caringAffectsWellbeingDetails = data?.caringAffectsWellbeingDetails;
    this.caringAffectsMentalHealth = data?.caringAffectsMentalHealth;
    this.caringAffectsMentalHealthDetails =
      data?.caringAffectsMentalHealthDetails;
    this.wellbeingHasImpactOnCaring = data?.wellbeingHasImpactOnCaring;
    this.discussedWithGp = data?.discussedWithGp;
    this.summary = data?.summary;
  }
}

class UserSupportingRoleFinancialImpact {
  caringAffectsFinances?: string;
  hasFinancialDifficulties?: string;
  summary?: string;

  constructor(data: Partial<UserSupportingRoleFinancialImpact | undefined>) {
    this.caringAffectsFinances = data?.caringAffectsFinances;
    this.hasFinancialDifficulties = data?.hasFinancialDifficulties;
    this.summary = data?.summary;
  }
}

class UserSupportingRoleLifeBalance {
  areasAffectedByCaring?: string[];
  areasAffectedByCaringDetails?: string;
  summary?: string;

  constructor(data: Partial<UserSupportingRoleLifeBalance | undefined>) {
    this.areasAffectedByCaring = data?.areasAffectedByCaring;
    this.areasAffectedByCaringDetails = data?.areasAffectedByCaringDetails;
    this.summary = data?.summary;
  }
}

class UserSupportingRoleFuturePlansImpact {
  arrangementInPlaceWill?: string; // used in other sections for legacy support
  arrangementInPlacePoaFinancial?: string; // used in other sections for legacy support
  arrangementInPlacePoaContinuingWelfare?: string; // used in other sections for legacy support
  potentialImpactingAspects?: string;
  desires?: string;
  summary?: string;

  constructor(data: Partial<UserSupportingRoleFuturePlansImpact | undefined>) {
    this.arrangementInPlaceWill = data?.arrangementInPlaceWill;
    this.arrangementInPlacePoaFinancial = data?.arrangementInPlacePoaFinancial;
    this.arrangementInPlacePoaContinuingWelfare =
      data?.arrangementInPlacePoaContinuingWelfare;
    this.potentialImpactingAspects = data?.potentialImpactingAspects;
    this.desires = data?.desires;
    this.summary = data?.summary;
  }
}

class UserSupportingRoleEmploymentImpact {
  employmentStatus?: string;
  hasOrInSearchOfOccupation?: string;
  caringAffectsOccupation?: string;
  caringAffectsOccupationDetails?: string;
  summary?: string;

  constructor(data: Partial<UserSupportingRoleEmploymentImpact | undefined>) {
    this.employmentStatus = data?.employmentStatus;
    this.hasOrInSearchOfOccupation = data?.hasOrInSearchOfOccupation;
    this.caringAffectsOccupation = data?.caringAffectsOccupation;
    this.caringAffectsOccupationDetails = data?.caringAffectsOccupationDetails;
    this.summary = data?.summary;
  }
}

class UserSupportingRoleHomeImpact {
  caringAffectsLivingEnvironment?: string;
  caringAffectsLivingEnvironmentDetails?: string;
  difficultDailyTasks?: string[];
  summary?: string;

  constructor(data: Partial<UserSupportingRoleHomeImpact | undefined>) {
    this.caringAffectsLivingEnvironment = data?.caringAffectsLivingEnvironment;
    this.caringAffectsLivingEnvironmentDetails =
      data?.caringAffectsLivingEnvironmentDetails;
    this.difficultDailyTasks = data?.difficultDailyTasks;
    this.summary = data?.summary;
  }
}

class UserSupportingRoleOtherLifeAspects {
  caringAffectsRelationships?: string;
  caringAffectingRelationshipsImpactsOnCaring?: string;
  caringAffectingRelationshipsImpactsOnCaringDetails?: string;
  botheringCaringRoleAspects?: string;
  desiredChangesAboutSituation?: string;
  relationshipsImpactSummary?: string;

  constructor(data: Partial<UserSupportingRoleOtherLifeAspects | undefined>) {
    this.caringAffectsRelationships = data?.caringAffectsRelationships;
    this.caringAffectingRelationshipsImpactsOnCaring =
      data?.caringAffectingRelationshipsImpactsOnCaring;
    this.caringAffectingRelationshipsImpactsOnCaringDetails =
      data?.caringAffectingRelationshipsImpactsOnCaringDetails;
    this.botheringCaringRoleAspects = data?.botheringCaringRoleAspects;
    this.desiredChangesAboutSituation = data?.desiredChangesAboutSituation;
    this.relationshipsImpactSummary = data?.relationshipsImpactSummary;
  }
}

class UserSupportingRoleSkillsAndExperience {
  qualifications?: string[];

  constructor(
    data: Partial<UserSupportingRoleSkillsAndExperience | undefined>
  ) {
    this.qualifications = data?.qualifications;
  }
}

class UserSupportingWhatMatters {
  importanceOfRoleAsCarer?: string;

  constructor(data: Partial<UserSupportingWhatMatters | undefined>) {
    this.importanceOfRoleAsCarer = data?.importanceOfRoleAsCarer;
  }
}
