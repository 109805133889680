import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RegistrationStateService {
  private registrationEnabled: boolean = false;

  constructor() {}

  setRegistrationEnabled(enabled: boolean): void {
    this.registrationEnabled = enabled;
  }

  getRegistrationEnabled(): boolean {
    return this.registrationEnabled;
  }
}
