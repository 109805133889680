import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { loginForm } from 'src/assets/forms/register-and-login/form-login';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { RegistrationStateService } from 'src/app/services/registration-state/registration-state.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent =
    {} as ModalComponent;
  form = loginForm;
  user = {};
  error = '';
  loading = {
    scotAccountLogin: false,
    mydexLogin: false,
    mydexRegister: false,
  };

  success = false;

  logoutLoading = false;

  registerFrom: string | undefined;

  constructor(
    public authService: AuthService,
    private registrationStateService: RegistrationStateService
  ) {}

  loginForm = new FormGroup({
    user: new FormControl(''),
    password: new FormControl(''),
  });
  enableScotAccountAccess =
    environment.enableRegistration ||
    this.registrationStateService.getRegistrationEnabled();

  mydexLogin() {
    this.loading.mydexLogin = true;
    this.authService.login();
  }

  scotAccountLogin() {
    this.loading.scotAccountLogin = true;
    this.authService.loginScotAccount();
  }

  logout() {
    this.authService.logout();
  }

  mydexRegister() {
    this.loading.mydexRegister = true;
    this.authService.handleMydexRegistration();
  }
}
