import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { FormItemMedications } from 'src/app/models/form/form';
import { UserMedication } from 'src/app/models/medication/userMedication';
import { UserMedicationPdsEntry } from 'src/app/models/medication/userMedicationPdsEntry';

@Component({
  selector: 'app-medications-control',
  templateUrl: './medications-control.component.html',
  styleUrl: './medications-control.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MedicationsControlComponent),
      multi: true,
    },
  ],
})
export class MedicationsControlComponent implements ControlValueAccessor {
  // PROPERTIES
  @ViewChild('medicationEditorModel', { static: true })
  medicationEditorModel: ModalComponent = {} as ModalComponent;
  @ViewChild('medicationDeletionModal', { static: true })
  medicationDeletionModal: ModalComponent = {} as ModalComponent;

  @Input() item?: FormItemMedications;
  @Input() control: any;
  @Input() disabled: boolean = false;

  @Input() allMedications?: UserMedicationPdsEntry[];
  @Input() allMedicationsLoading: boolean = true;
  @Input() allMedicationsLoadingError: string = '';
  @Input() medicationDetailsLoading: boolean = true;
  @Input() medicationDetailsLoadingError: string = '';
  @Input() medicationAdding: boolean = false;
  @Input() medicationAdditionError: string = '';
  @Input() medicationUpdating: boolean = false;
  @Input() medicationUpdateError: string = '';
  @Input() medicationDeleting: boolean = false;
  @Input() medicationDeletionError: string = '';

  @Output() medicationDetailsRequested = new EventEmitter();
  @Output() medicationAdded = new EventEmitter();
  @Output() medicationUpdated = new EventEmitter();
  @Output() medicationDeleted = new EventEmitter();
  @Output() clearErrors = new EventEmitter();

  protected formControlValue: string[] | undefined = undefined; // selected medication ids
  protected selectedMedications: UserMedicationPdsEntry[] = [];

  protected currentUserMedication: UserMedicationPdsEntry | undefined;

  protected medicationAdditionSuccess: boolean = false; // temporarily store a recent medication addition success
  protected medicationUpdateSuccess: boolean = false; // temporarily store a recent medication update success
  protected medicationDeletionSuccess: boolean = false; // temporarily store a recent medication deletion success

  protected medicationEditorType: 'add' | 'update' | undefined = undefined;

  private onChange = (selected: any) => {};
  private onTouched = () => {};

  // LIFE CYCLE HOOKS

  ngOnInit() {}

  // CONTROL VALUE ACCESSOR

  writeValue(value: string[] | undefined): void {
    this.formControlValue = value ?? [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  handleUpdatedFormControlValue() {
    this.onChange(this.formControlValue);
    this.onTouched();
  }

  // MEDICATION SELECTION METHODS

  isMedicationSelected(medicationId: string): boolean {
    if (!this.formControlValue) {
      return false;
    }

    return this.formControlValue.includes(medicationId);
  }

  toggleMedicationSelection(medicationId: string): void {
    if (this.isMedicationSelected(medicationId)) {
      this.formControlValue = this.formControlValue?.filter(
        (id) => id !== medicationId
      );
    } else {
      this.formControlValue?.push(medicationId);
    }

    this.handleUpdatedFormControlValue();
  }

  // MEDICATION EDITOR METHODS
  onAddNewMedicationClicked(e: any) {
    this.medicationEditorType = 'add';
    this.openMedicationEditorModal(e);
  }

  onEditMedicationClicked(e: any, medication: UserMedicationPdsEntry) {
    this.medicationEditorType = 'update';
    this.openMedicationEditorModal(e);
    this.medicationDetailsRequested.emit(medication.medicationId);
    // set medicationDetailsLoading to true?
  }

  openMedicationEditorModal(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this.currentUserMedication = undefined;
    this.medicationEditorModel.open();
  }

  handleLoadingMedicationDetailsSuccess(
    medicationDetails: UserMedicationPdsEntry
  ) {
    this.currentUserMedication = medicationDetails;
  }

  handleMedicationEditorFormSave(data: Partial<UserMedication>) {
    // Set currentUserMedication to the form data while saving
    const currentMedicationId = this.currentUserMedication?.medicationId;
    this.currentUserMedication = new UserMedicationPdsEntry(data);

    // Create new contact (if there is no previous id)
    if (!currentMedicationId) {
      this.medicationAdded.emit(new UserMedication(data));
      return;
    }

    // Update existing contact
    this.currentUserMedication.medicationId = currentMedicationId;
    this.medicationUpdated.emit(this.currentUserMedication);
  }

  handleMedicationAdditionSuccess() {
    // Show success message
    this.medicationAdditionSuccess = true;

    setTimeout(() => {
      // Close the modal if a new medication was created
      if (this.medicationEditorType === 'add') {
        this.medicationEditorModel.close();
      }
      // Stop showing the success message
      this.medicationAdditionSuccess = false;
    }, 3000);
  }

  handleMedicationUpdateSuccess() {
    // Show success message in button
    this.medicationUpdateSuccess = true;
    setTimeout(() => {
      // Stop showing the success message
      this.medicationUpdateSuccess = false;
    }, 3000);
  }

  handleMedicationEditorFormCancel(e: any) {
    this.medicationEditorModel.close();
  }

  onMedicationEditorClosed() {
    this.medicationEditorType = undefined;
    this.currentUserMedication = undefined;
    this.medicationAdditionSuccess = false;
    this.medicationUpdateSuccess = false;
    this.clearErrors.emit();
  }

  // MEDICATION DELETION METHODS

  onDeleteMedicationClicked(e: any, medication: UserMedicationPdsEntry) {
    this.currentUserMedication = medication;
    this.openMedicationDeletionModal(e);
  }

  openMedicationDeletionModal(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this.medicationDeletionModal.open();
  }

  handleMedicationDeletionSuccess(medicationId: string) {
    // Remove the medication from the selected medication ids
    this.formControlValue = this.formControlValue?.filter(
      (id) => id !== medicationId
    );
    this.handleUpdatedFormControlValue();

    // Show success message and close the modal
    this.medicationDeletionSuccess = true;
    setTimeout(() => {
      this.medicationDeletionModal.close();
      this.medicationDeletionSuccess = false;
    }, 3000);
  }

  onProceedMedicationDeletionClicked() {
    this.medicationDeleted.emit(this.currentUserMedication);
  }

  onCancelMedicationDeletionClicked() {
    this.medicationDeletionModal.close();
  }

  onMedicationDeletionModalClosed() {
    this.currentUserMedication = undefined;
    this.medicationDeletionSuccess = false;
    this.clearErrors.emit();
  }
}
