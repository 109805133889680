import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Connection } from 'src/app/models/connection/connection';
import { ConnectionsStateService } from 'src/app/services/connections-state/connections.state.service';
import { User } from 'src/app/models/user/user';
import { UserStateService } from 'src/app/services/user-state/user.state.service';
import * as _ from 'lodash';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FinderService } from 'src/app/services/finder/finder.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Subject, takeUntil } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { UserEventsService } from 'src/app/services/user-event/user.event.service';

@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
  styleUrls: ['./connection.component.css'],
})
export class ConnectionComponent {
  // PROPERTIES

  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent =
    {} as ModalComponent;

  protected connection?: Connection;
  protected user: User | undefined;

  protected modalContinueFunction: () => void = () => {};
  protected completedReferralBefore: string | false = false;

  protected pdsReferralCompletedPercentage: number = 0;

  private destroy$: Subject<void> = new Subject<void>();

  // CONSTRUCTOR

  constructor(
    protected userService: UserStateService,
    protected connectionsStateService: ConnectionsStateService,
    protected navigationService: NavigationService,
    protected authService: AuthService,
    protected finderService: FinderService,
    protected eventService: UserEventsService,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}

  // LIFECYCLE HOOKS

  ngOnInit() {
    this.connectionsStateService.connections$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        let connectionName = this.route.snapshot.paramMap.get('slug') || '';
        this.connection =
          this.connectionsStateService.getConnectionByName(connectionName);
      });

    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.userService.resetErrors();
    this.connectionsStateService.resetErrors();
  }

  // METHODS

  handleEmailReferralClicked() {
    this.eventService
      .checkEventsCompletion([
        {
          type: 'referral',
          context: this.finderService.audience,
          connection: this.connection?.name,
          status: 'completed',
        }, // legacy event
        {
          type: 'email-referral',
          context: this.finderService.audience,
          connection: this.connection?.name,
          status: 'completed',
        },
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.completedReferralBefore = result; // result should be the formatted date string
          this.modalContinueFunction = this.navigateToEmailReferral.bind(this);
          this.modal.open();
        } else {
          this.completedReferralBefore = false;
          this.navigateToEmailReferral();
        }
      });
  }

  handlePdsReferralClicked() {
    this.eventService
      .checkEventsCompletion([
        {
          type: 'pds-referral',
          connection: this.connection?.name,
          status: 'completed',
        },
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.completedReferralBefore = result; // result should be the formatted date string
          this.modalContinueFunction = this.navigateToPdsReferral.bind(this);
          this.modal.open();
        } else {
          this.completedReferralBefore = false;
          this.navigateToPdsReferral();
        }
      });
  }

  handleHyperlinkClicked(hyperlinkType: 'website' | 'facebook') {
    this.analyticsService.trackConnectionHyperlinkClicked(
      this.connection?.name ?? 'connection-name-unknown',
      hyperlinkType
    );
  }

  navigateToEmailReferral() {
    this.analyticsService.trackEmailReferralStarted(
      this.connection?.name ?? 'connection-name-unknown'
    );
    this.router.navigate([this.router.url + '/contact-form']);
  }

  navigateToPdsReferral() {
    this.analyticsService.trackPdsReferralStarted(
      this.connection?.name ?? 'connection-name-unknown'
    );

    this.router.navigate([this.router.url + '/share-data-form']);
  }

  handleModalClosed() {
    this.completedReferralBefore = false;
    this.modalContinueFunction = () => {};
  }
}
