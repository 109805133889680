import {
  FormItemButtonSelect,
  FormItemDropdown,
  FormItemTextArea,
} from 'src/app/models/form/form';
import { yesNoOptions } from '../item-options';
import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';

export const homeImpactCaringAffectsLivingEnvironment =
  new FormItemButtonSelect({
    key: 'homeImpactCaringAffectsLivingEnvironment',
    label: 'Caring impacts my living environment',
    path: 'supportingRole.homeImpact.caringAffectsLivingEnvironment',
    class: 'mb-5',
    options: yesNoOptions,
  });

export const homeImpactCaringAffectsLivingEnvironmentDetails =
  new FormItemTextArea({
    key: 'homeImpactCaringAffectsLivingEnvironmentDetails',
    path: 'supportingRole.homeImpact.caringAffectsLivingEnvironmentDetails',
    label: 'How it impacts',
    placeholder:
      "e.g. perhaps you've had to change the use of a room, or restrict use to only part of the house, you may have to store and use equipment and aids...",
    class: 'mb-5',
    validators: [Validators.minLength(1), maxByteValidator(2000)],
    displayConditions: [
      {
        key: 'homeImpactCaringAffectsLivingEnvironment',
        values: ['Yes'],
      },
    ],
  });

export const homeImpactDifficultDailyTasks = new FormItemButtonSelect({
  key: 'homeImpactDifficultDailyTasks',
  path: 'supportingRole.homeImpact.difficultDailyTasks',
  label: 'These daily tasks cause me problems',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'shopping_causes_problems', label: 'Shopping' },
    { name: 'reading_labels_causes_problems', label: 'Reading labels' },
    { name: 'cleaning_home_causes_problems', label: 'Cleaning home' },
    { name: 'cooking_causes_problems', label: 'Cooking' },
    { name: 'washing_clothes_causes_problems', label: 'Washing clothes' },
    { name: 'taking_bins_out_causes_problems', label: 'Taking bins out' },
    { name: 'gardening_causes_problems', label: 'Gardening' },
    { name: 'using_stairs_causes_problems', label: 'Using stairs' },
    { name: 'using_bathroom_causes_problems', label: 'Using bathroom' },
    { name: 'using_toilet_causes_problems', label: 'Using toilet' },
  ],
});

export const homeImpactSummary = new FormItemDropdown({
  key: 'homeImpactSummary',
  label:
    "Thinking about how I'm managing at home, the selected statement best matches how I currently feel.",
  path: 'supportingRole.homeImpact.summary',
  class: 'mb-5',
  placeholder: 'Please select...',
  options: [
    {
      name: 'Our/my home is suitable and we can manage day-to-day tasks well enough. There are no risks to me or the person/people I care for.',
      label:
        'Our/my home is suitable and we can manage day-to-day tasks well enough. There are no risks to me or the person/people I care for.',
    },
    {
      name: 'Mostly we can manage day-to-day tasks at home, but some areas need to be addressed. There could be risks for me or the person/people I care for in the future.',
      label:
        'Mostly we can manage day-to-day tasks at home, but some areas need to be addressed. There could be risks for me or the person/people I care for in the future.',
    },
    {
      name: "We're getting by, but it's hard to stay on top of day-to-day tasks or changes are needed to our/their home, but there are no immediate risks to me or the person/people I care for.",
      label:
        "We're getting by, but it's hard to stay on top of day-to-day tasks or changes are needed to our/their home, but there are no immediate risks to me or the person/people I care for.",
    },
    {
      name: "We're not coping with many of the day-to-day tasks or our/their home isn't suitable. This poses immediate risks to me or the person/people I care for, and I need help to sort this out.",
      label:
        "We're not coping with many of the day-to-day tasks or our/their home isn't suitable. This poses immediate risks to me or the person/people I care for, and I need help to sort this out.",
    },
    {
      name: "We're not coping with many of the day-to-day tasks or our/their home isn't suitable. This poses immediate risk to me or the person/people I care for. I have no help.",
      label:
        "We're not coping with many of the day-to-day tasks or our/their home isn't suitable. This poses immediate risk to me or the person/people I care for. I have no help.",
    },
  ],
});
