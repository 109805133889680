<div class="home-container flex h-full flex-1">
  <div class="h-full w-full container-sm title-labels">
    <!-- TITLE -->
    <h3 class="text-2xl italic font-thin opacity-80">
      Understanding the support you give
    </h3>
    <!-- END TITLE -->
    <div
      class="bg-white w-full mt-4 rounded px-16 pb-16 pt-12 box-border carer-questions-wrapper xl-labels"
    >
      <!-- STEP: FORM -->
      <ng-container *ngIf="step === 'form'">
        <!-- INTRO: AUTHED USER -->
        <p class="mb-14" *ngIf="authService.isAuthenticated$ | async">
          Below are the questions to understand the support you provide. We have
          added the details we already have but please fill in anything missing.
          We will also add any new details to your
          <strong>About Me</strong> when you click on 'Submit'.
        </p>
        <!-- END INTRO: AUTHED USER -->

        <!-- INTRO: UNAUTHED USER -->
        <p class="mb-14" *ngIf="!(authService.isAuthenticated$ | async)">
          Below are the questions to understand the support you provide. After
          you complete the questionnaire you can create an
          <strong>About Me</strong>. If you do, the entered details about your
          role as carer will be added for the initial set up but can always be
          changed again later.
        </p>
        <!-- END INTRO: UNAUTHED USER -->

        <app-form
          #carerForm
          [formData]="unpaidCarerForm"
          [formValues]="user"
          (submit)="saveUnpaidCarerForm($event)"
          submitText="Submit"
          [loading]="userService.loading$ | async"
          [cancellable]="false"
        >
        </app-form>
      </ng-container>
      <!-- END STEP: FORM -->

      <!-- STEP: RESULTS -->
      <ng-container *ngIf="step === 'results'">
        <!-- VERIFIED USER: ABOUT ME INFO -->
        <!-- VERIFIED USER: LOADING SPINNER -->
        <app-loading-spinner
          *ngIf="
            pdsConnectionStatus?.isValid &&
            !(userService.savingError$ | async) &&
            (userService.saving$ | async)
          "
          text="Updating your About Me"
          class="inline-block mb-6 text-theme"
        >
        </app-loading-spinner>
        <!-- END VERIFIED USER: LOADING SPINNER -->

        <!-- VERIFIED USER: SUCCESS MESSAGE -->
        <div
          *ngIf="
            pdsConnectionStatus?.isValid &&
            !(userService.savingError$ | async) &&
            !(userService.saving$ | async)
          "
        >
          <p class="mb-6 mt-0 text-theme">
            We've updated your <strong>About Me</strong> with any new
            information.
          </p>
        </div>
        <!-- END VERIFIED USER: SUCCESS MESSAGE -->

        <!-- VERIFIED USER: ERROR MESSAGE -->
        <div
          *ngIf="
            pdsConnectionStatus?.isValid &&
            (userService.savingError$ | async) &&
            !(userService.saving$ | async)
          "
        >
          <p class="mb-6 mt-0 text-error-red">
            Unfortunately, an issue occured and we were unable to update your
            <strong>About Me</strong>.
          </p>
        </div>
        <!-- END VERIFIED USER: ERROR MESSAGE -->
        <!-- END VERIFIED USER: ABOUT ME INFO -->

        <!-- RESULTS -->
        <h3 class="text-xl font-semibold mt-0 mb-4">
          Your experience as an unpaid carer
        </h3>
        <p class="m-0">
          Thank you for completing the questionnaire. This information can be
          used, with your consent, to contact services you wish to access and
          share your circumstances with. It is also useful to reflect on the
          valuable contribution you make as a carer.
        </p>

        <!-- RESULTS: QUARRIERS SUPPORT RECOMMENDATION -->
        <h4 class="text-xl font-semibold mt-10 mb-6">Available support</h4>
        <div
          class="card bg-white flex flex-col p-8 rounded-lg border-solid border-gray-50"
        >
          <div class="flex flex-col">
            <div class="h-6">
              <img
                class="h-full object-contain"
                src="/assets/img/quarriers-logo.png"
                alt=""
              />
            </div>
            <h4 class="text-xl font-medium text-theme mt-3 mb-6">
              Quarriers Carer Support Service (Moray)
            </h4>
          </div>

          <div class="flex flex-1 mt-0 gap-x-4">
            <div class="flex-1 overflow-hidden rounded-lg hidden lg:block">
              <img
                src="/assets/img/quarriers-bg.png"
                alt=""
                class="object-cover h-56 object-center rounded-lg"
              />
            </div>
            <div class="flex flex-col flex-1">
              <p class="mt-0">
                Quarriers is one of Scotland's leading social care charities.
                They provide practical care and support for those caring for
                others. Speaking to them could really benefit you and connect
                you with useful services and support.
              </p>

              <button
                class="btn btn-theme btn-sm mt-auto mr-auto mb-1"
                (click)="onReviewedResults()"
              >
                Learn more
              </button>
            </div>
          </div>
        </div>

        <!-- END RESULTS: QUARRIERS SUPPORT RECOMMENDATION -->

        <!-- END RESULTS -->
      </ng-container>
      <!-- END STEP: RESULTS -->
    </div>
  </div>
</div>
