<div
  *ngIf="
    referralCompletedPercentage >= 0 &&
    !(userService.loading$ | async) &&
    !(userService.loadingError$ | async) &&
    !(userService.saving$ | async)
  "
>
  <app-form-items-completed-bar
    [formItemsCompletedPercentage]="referralCompletedPercentage"
    class="inline-block w-full my-6"
  ></app-form-items-completed-bar>
</div>
