import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { v4 as uuid } from 'uuid';
import { tap, catchError, map, filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { SessionStorageService } from '../session-storage/session-storage.service';
import {
  EventTypes,
  OidcSecurityService,
  PublicEventsService,
} from 'angular-auth-oidc-client';
import { set } from 'lodash';
import { SASessionStoragekeys } from 'src/app/models/auth/models';

interface IAuthResponse {
  AccessToken: string;
  ExpiresIn: number;
  RefreshToken: string;
  RefreshTokenExpiresIn: number;
  TokenType: string;
  UserId: string;
  IdToken: string;
}

@Injectable({ providedIn: 'root' })

// PROPERTIES
export class AuthService {
  private isAuthenticatedSubject = new BehaviorSubject<boolean | undefined>(
    undefined
  );
  private isScotAuthenticatedSubject = new BehaviorSubject<boolean | undefined>(
    undefined
  );
  private isMydexAuthenticatedSubject = new BehaviorSubject<
    boolean | undefined
  >(undefined);
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  isScotAuthenticated$ = this.isScotAuthenticatedSubject.asObservable();
  isMydexAuthenticated$ = this.isMydexAuthenticatedSubject.asObservable();
  private isAdminSubject = new BehaviorSubject<boolean | undefined>(undefined);
  isAdmin$ = this.isAdminSubject.asObservable();
  requiresMappingSubject = new BehaviorSubject<boolean>(false);
  requiresMapping$ = this.requiresMappingSubject.asObservable();

  hasRefreshed = false;

  auth_code = '';

  // CONSTRUCTOR
  constructor(
    private httpClient: HttpClient,
    private oidcSecurityService: OidcSecurityService,
    private eventService: PublicEventsService,
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {
    // read router params for code and state
    this.router.events
      .pipe(
        filter((event) => {
          return (
            event instanceof NavigationEnd && event.url.includes('scotaccount')
          );
        }),
        map(() => this.router.parseUrl(this.router.url))
      )
      .subscribe((url) => {
        this.auth_code = url.queryParams['code'];
        // remove the query params from url
        this.router.navigate([], {
          queryParams: {
            code: null,
            state: null,
            iss: null,
          },
          queryParamsHandling: 'merge',
        });

        if (this.auth_code && url.queryParams['iss'].includes('scotaccount')) {
          this.getScotToken();
        }
      });
    this.initializeAuthState();
  }

  // ----------------------------
  // ----- PUBLIC API ----------
  // ----------------------------

  logout() {
    this.logoutMydex();
    this.logoutScotAccount();
  }

  // ----------------------------
  // -- Mydex Specific ---
  // ----------------------------

  login(): void {
    // clear the scotaccount access token
    this.clearScotAccountAccessData();
    if (this.requiresMappingSubject.value) {
      setTimeout(
        () =>
          this.oidcSecurityService.authorize('mydex', {
            customParams: { mapping: 'scotaccount' },
          }),
        0
      );
    } else {
      setTimeout(() => this.oidcSecurityService.authorize('mydex'), 0);
    }
  }

  logoutMydex() {
    this.oidcSecurityService.logoffAndRevokeTokens('mydex').subscribe();
  }

  /**
   * Register on the Mydex site for users who have signed up with ScotAccount
   * This will redirect the user to the Mydex registration page
   * and handle mapping the user's ScotAccount to their Mydex account
   */
  handleMydexRegistration = () => {
    this.oidcSecurityService.authorize('mydex', {
      customParams: { action: 'register', mapping: 'scotaccount' },
    });
  };

  //Register method, taking a username, email and password and POSTing to /pds/register
  register(mydexid: string, email: string, password: string): Observable<any> {
    let returnTo = `${environment.url}/register/setup/record`;

    return this.httpClient
      .post(`${environment.apiBaseUrl}/pds/register`, {
        mydexid,
        email,
        password,
        returnTo,
      })
      .pipe(
        map((response: any) => {
          this.sessionStorageService.setRegistrationInProgress(true);
          this.sessionStorageService.setMydexPrivateKeyCreationUrl(
            response?.url
          );
          this.sessionStorageService.mergeUserData({
            me: { contact: { email } },
          });
          return response;
        })
      );
  }

  // ----------------------------
  // -- ScotAccount Specific ---
  // ----------------------------

  loginScotAccount() {
    const nonce = uuid();
    const state = uuid();
    sessionStorage.setItem(SASessionStoragekeys.Nonce, nonce);
    sessionStorage.setItem(SASessionStoragekeys.State, state);

    this.httpClient
      .post(
        `${environment.functionUrl}/api/auth/code`,
        {
          nonce: nonce,
          state: state,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .subscribe(
        (data) => {
          const typedData = data as { authorizationUrl: string };
          if (typedData?.authorizationUrl) {
            window.location.href = typedData.authorizationUrl;
          }
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  refresh() {
    if (this.isMydexAuthenticatedSubject.value) {
      this.login(); // refresh the mydex login with redirect
    } else if (this.isScotAuthenticatedSubject.value) {
      const refreshTime = sessionStorage.getItem(
        SASessionStoragekeys.RefreshExpiresAt
      );
      const refresh_token = sessionStorage.getItem(
        SASessionStoragekeys.RefreshToken
      );
      if (
        !this.hasRefreshed &&
        refreshTime &&
        refresh_token &&
        Date.now() < parseInt(refreshTime)
      ) {
        this.hasRefreshed = true;
        this.httpClient
          .post(`${environment.functionUrl}/api/auth/refresh`, {
            refreshToken: refresh_token,
          })
          .subscribe(
            (data) => {
              const typedData = data as IAuthResponse;
              this.saveScotAccountAccessData(typedData, false);
              this.verifyAuthStatus();
              this.verifyAdminStatus();
              setTimeout(() => (this.hasRefreshed = false), 5000);
            },
            (error) => {
              console.log('ERROR', error);
              this.hasRefreshed = true;
              this.clearScotAccountAccessData();
            }
          );
      } else {
        this.logout();
      }
    }
  }

  logoutScotAccount() {
    const idToken = sessionStorage.getItem('id_token') ?? '';
    const url = new URL(
      environment.oidc.scotaccount.authority + '/authorize/logout'
    );
    url.searchParams.append('id_token_hint', idToken);
    url.searchParams.append(
      'post_logout_redirect_uri',
      environment.oidc.scotaccount.postLogoutRedirectUri
    );
    this.clearScotAccountAccessData();

    if (idToken) {
      window.location.href = url.toString();
    } else {
      console.log('No id token found');
    }
  }

  setRequiresMapping = (requiresMapping: boolean) => {
    this.isScotAuthenticatedSubject.next(true);
    this.requiresMappingSubject.next(requiresMapping);
  };

  // ----------------------------
  // -- PRIVATE API ---
  // ----------------------------

  // ----------------------------
  // -- General Methods ---
  // ----------------------------
  private initializeAuthState(): void {
    this.oidcSecurityService.checkAuth(undefined, 'mydex').subscribe(); //init oidc module

    //Wait for the oidc module to finish checking auth before verifying the token with our api
    this.eventService
      .registerForEvents()
      .pipe(
        filter(
          (notification) =>
            notification.type === EventTypes.CheckingAuthFinished
        )
      )
      .pipe(
        tap(() => {
          this.verifyAuthStatus(); // Verify with api
          this.verifyAdminStatus(); // Also verify admin status
        })
      )
      .subscribe();
  }

  private verifyAuthStatus(): void {
    this.httpClient
      .get(`${environment.apiBaseUrl}/auth/test-authorized`)
      .pipe(
        tap(() => {
          if (sessionStorage.getItem('access_token')) {
            this.isScotAuthenticatedSubject.next(true);
            this.isAuthenticatedSubject.next(true);
          } else {
            this.isAuthenticatedSubject.next(true);
            this.isMydexAuthenticatedSubject.next(true);
          }
        }),
        catchError((e) => {
          console.log(e);
          this.isScotAuthenticatedSubject.next(false);
          this.isMydexAuthenticatedSubject.next(false);
          this.isAuthenticatedSubject.next(false);
          return of(false); // Swallow the error to keep the stream alive
        })
      )
      .subscribe();
  }

  private verifyAdminStatus(): void {
    // Verify admin via api
    this.httpClient
      .get<boolean>(`${environment.apiBaseUrl}/auth/test-admin-authorized`)
      .pipe(
        tap(() => {
          return this.isAdminSubject.next(true);
        }),
        catchError(() => {
          this.isAdminSubject.next(false);
          return of(false); // Swallow the error to keep the stream alive
        })
      )
      .subscribe();
  }
  // ----------------------------
  // -- ScotAccount Specific ---
  // ----------------------------

  private saveScotAccountAccessData = (
    data: IAuthResponse,
    withIdToken = true,
    nonce?: string,
    state?: string
  ) => {
    sessionStorage.setItem(
      SASessionStoragekeys.AccessToken,
      data['AccessToken']
    );
    sessionStorage.setItem(
      SASessionStoragekeys.RefreshToken,
      data['RefreshToken']
    );

    sessionStorage.setItem(
      SASessionStoragekeys.ExpiresAt,
      (Number(data['ExpiresIn']) * 1000 + Date.now()).toString()
    );
    sessionStorage.setItem(
      SASessionStoragekeys.RefreshExpiresAt,
      (Number(data['RefreshTokenExpiresIn']) * 1000 + Date.now()).toString()
    );
    withIdToken &&
      sessionStorage.setItem(SASessionStoragekeys.IdToken, data['IdToken']);
    nonce && sessionStorage.setItem(SASessionStoragekeys.Nonce, nonce);
    state && sessionStorage.setItem(SASessionStoragekeys.State, state);

    if (data.AccessToken && data.ExpiresIn) {
      setTimeout(this.refresh, (data.RefreshTokenExpiresIn - 60) * 1000);
    }
  };

  private clearScotAccountAccessData = (withIdToken = true) => {
    sessionStorage.removeItem(SASessionStoragekeys.AccessToken);
    sessionStorage.removeItem(SASessionStoragekeys.RefreshToken);
    withIdToken && sessionStorage.removeItem(SASessionStoragekeys.IdToken);
    sessionStorage.removeItem(SASessionStoragekeys.ExpiresAt);
    sessionStorage.removeItem(SASessionStoragekeys.RefreshExpiresAt);
    sessionStorage.removeItem(SASessionStoragekeys.Nonce);
    sessionStorage.removeItem(SASessionStoragekeys.State);
  };

  private async getScotToken() {
    const nonce = sessionStorage.getItem(SASessionStoragekeys.Nonce) ?? '';
    const state = sessionStorage.getItem(SASessionStoragekeys.State) ?? '';

    if (this.auth_code && nonce && state) {
      this.httpClient
        .post(`${environment.functionUrl}/api/auth/token`, {
          nonce: nonce,
          state: state,
          code: this.auth_code,
        })
        .subscribe(
          (data) => {
            const typedData = data as IAuthResponse;
            this.saveScotAccountAccessData(typedData);
            this.verifyAuthStatus();
            this.verifyAdminStatus();
            this.router.navigate(['/account/about-me']);
          },
          (error: unknown) => {
            console.log('ERROR', error);
            this.router.navigate(['/']);
          }
        );
    } else {
      console.log('ERROR: Missing auth code, nonce or state');
    }
  }
}
