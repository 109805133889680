import {
  FormItem,
  FormItemButtonSelect,
  FormItemTextbox,
  FormItemTitle,
} from 'src/app/models/form/form';
import {
  attitudeToChangeImportantCareAspects,
  attitudeToChangeContinousCareHousingPreference,
  attitudeToChangeSummary,
} from './attitude-to-change-items';
import {
  address2,
  getFormItemsAddress,
  state,
} from '../../shared-items/address';
import {
  powerOfAttorneyAssigneeEmail,
  powerOfAttorneyAssigneeName,
  powerOfAttorneyAssigneePhone,
  powerOfAttorneyAssigneeRelationship,
  powerOfAttorneyAssigneeContactDetailsTitle,
  powerOfAttorneyCurrentSupport,
  powerOfAttorneyDocument,
  powerOfAttorneyDocumentLocation,
  powerOfAttorneyIsRequestingSupport,
  powerOfAttorneyOtherCompletedStatements,
} from './power-of-attorney-items';
import {
  futureCarePlanAdditionalInfo,
  futureCarePlanChosenConfidantDetailsTitle,
  futureCarePlanChosenConfidantEmail,
  futureCarePlanChosenConfidantName,
  futureCarePlanChosenConfidantPhone,
  futureCarePlanChosenConfidantRelationship,
  futureCarePlanConcernsAboutFuture,
  futureCarePlanHasChosenConfidant,
  futureCarePlanHasHadConversationAboutWishes,
  futureCarePlanHealthManagementNeeds,
  futureCarePlanHospitalAdmissionRequests,
  futureCarePlanPeopleInvolvedInCare,
} from './future-care-plan-items';
import {
  willDocumentLocation,
  willHasOrganDonationCard,
  willMoreInfoLink,
} from './will-items';
import { unpaidCarerWishesForCaredForPeople } from './unpaid-carer-items';
import {
  livingWillAdvancedDirectiveLocationAndSharingInfo,
  livingWillHasAdvancedDirective,
  livingWillResuscitationThoughtsAndQuestions,
  livingWillResuscitationTitle,
} from './living-will-items';
import { yesNoInProgressLegacyOptions } from '../item-options';
import {
  futurePlansImpactArrangementInPlacePoaContinuingWelfare,
  futurePlansImpactArrangementInPlacePoaFinancial,
  futurePlansImpactArrangementInPlaceWill,
} from '../supporting-role-items/future-plans-impact-items';

// All form items for user - planning ahead
export function getFormItemsUserPlanningAhead(): FormItem<any>[] {
  return [
    new FormItemTitle({ label: 'Attitude to Change' }),
    ...getFormItemsUserPlanningAheadAttitudeToChange(),
    new FormItemTitle({ label: 'Power of Attorney' }),
    ...getFormItemsUserPlanningAheadPowerOfAttorney(),
    new FormItemTitle({ label: 'Future Care Plan' }),
    ...getFormItemsUserPlanningAheadFutureCarePlan(),
    new FormItemTitle({ label: 'Will' }),
    ...getFormItemsUserPlanningAheadWill(),
    new FormItemTitle({ label: 'Unpaid Carer' }),
    ...getFormItemsUserPlanningAheadUnpaidCarer(),
    new FormItemTitle({ label: 'Living Will' }),
    ...getFormItemsUserPlanningAheadLivingWill(),
  ];
}

// All form items for user - planning ahead - attitude to change
export function getFormItemsUserPlanningAheadAttitudeToChange(): FormItem<any>[] {
  return [
    attitudeToChangeSummary,
    attitudeToChangeContinousCareHousingPreference,
    attitudeToChangeImportantCareAspects,
  ];
}

// All form items for user - planning ahead - power of attorney
export function getFormItemsUserPlanningAheadPowerOfAttorney(): FormItem<any>[] {
  return [
    // Reusing items from Supporting Role - Future Plans Impact // TODO: figure out storage in pds
    futurePlansImpactArrangementInPlacePoaFinancial,
    futurePlansImpactArrangementInPlacePoaContinuingWelfare,
    powerOfAttorneyCurrentSupport,
    powerOfAttorneyOtherCompletedStatements,
    powerOfAttorneyAssigneeContactDetailsTitle,
    powerOfAttorneyAssigneeName,
    powerOfAttorneyAssigneeRelationship,
    // address
    ...getFormItemsAddress('planningAhead.powerOfAttorney.assignee.address'),
    powerOfAttorneyAssigneePhone,
    powerOfAttorneyAssigneeEmail,
    powerOfAttorneyDocumentLocation,
    powerOfAttorneyDocument,
    powerOfAttorneyIsRequestingSupport,
  ];
}

export function getFormItemsUserPlanningAheadPowerOfAttorneyNegligibleForCompleteness(): FormItem<any>[] {
  // create copies of items to not change the original path (only path needed to determine if item is negligible)
  const address2Copy = new FormItemTextbox(address2);
  address2Copy.path = `planningAhead.powerOfAttorney.assignee.address.address2`;

  const stateCopy = new FormItemTextbox(state);
  stateCopy.path = `planningAhead.powerOfAttorney.assignee.address.state`;

  return [address2Copy, stateCopy];
}

// All form items for user - planning ahead - future care plan
export function getFormItemsUserPlanningAheadFutureCarePlan(): FormItem<any>[] {
  return [
    futureCarePlanHasHadConversationAboutWishes,
    futureCarePlanHasChosenConfidant,
    futureCarePlanChosenConfidantDetailsTitle,
    futureCarePlanChosenConfidantName,
    futureCarePlanChosenConfidantRelationship,
    // address with display condition
    ...getFormItemsAddress(
      'planningAhead.futureCarePlan.chosenConfidant.address',
      [{ key: 'futureCarePlanHasChosenConfidant', values: [true] }]
    ),
    futureCarePlanChosenConfidantPhone,
    futureCarePlanChosenConfidantEmail,
    futureCarePlanHealthManagementNeeds,
    futureCarePlanConcernsAboutFuture,
    futureCarePlanPeopleInvolvedInCare,
    futureCarePlanAdditionalInfo,
    futureCarePlanHospitalAdmissionRequests,
  ];
}

export function getFormItemsUserPlanningAheadFutureCarePlanNegligibleForCompleteness(): FormItem<any>[] {
  return [
    ...getFormItemsAddress(
      'planningAhead.futureCarePlan.chosenConfidant.address'
    ), // optional display
    futureCarePlanChosenConfidantName, // optional display
    futureCarePlanChosenConfidantRelationship, // optional display
    futureCarePlanChosenConfidantPhone, // optional display
    futureCarePlanChosenConfidantEmail, // optional display
  ];
}

// All form items for user - planning ahead - will
export function getFormItemsUserPlanningAheadWill(): FormItem<any>[] {
  return [
    // Reusing item from Supporting Role - Future Plans Impact // TODO: figure out storage in pds
    futurePlansImpactArrangementInPlaceWill,
    willDocumentLocation,
    willMoreInfoLink,
    willHasOrganDonationCard,
  ];
}

export function getFormItemsUserPlanningAheadWillNegligibleForCompleteness(): FormItem<any>[] {
  return [
    willDocumentLocation, // optional display
  ];
}

// All form items for user - planning ahead - unpaid carer
export function getFormItemsUserPlanningAheadUnpaidCarer(): FormItem<any>[] {
  return [
    // Reusing item from Supporting Role - Supported People // TODO: figure out storage in pds
    unpaidCarerWishesForCaredForPeople,
  ];
}

// All form items for user - planning ahead - living will
export function getFormItemsUserPlanningAheadLivingWill(): FormItem<any>[] {
  return [
    livingWillHasAdvancedDirective,
    livingWillAdvancedDirectiveLocationAndSharingInfo,
    livingWillResuscitationTitle,
    livingWillResuscitationThoughtsAndQuestions,
  ];
}

export function getFormItemsUserPlanningAheadLivingWillNegligibleForCompleteness(): FormItem<any>[] {
  return [
    livingWillAdvancedDirectiveLocationAndSharingInfo, // optional display
  ];
}
