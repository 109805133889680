import { Validators } from '@angular/forms';
import {
  FormItemCheckbox,
  FormItemDisplayLogic,
  FormItemDropdown,
  FormItemRepeater,
  FormItemTitle,
} from 'src/app/models/form/form';
import { getOptionsForFinderPathSelection } from 'src/assets/finder-nodes/finder.nodes.collection';

// -------------------------------------------- TITLE --------------------------------------------

export const displayConditionsFormTitle = new FormItemTitle({
  label: 'Display Settings',
  class: 'mb-2',
});

// -------------------------------------------- FINDER PATHS --------------------------------------------

export const finderPaths = new FormItemRepeater({
  label: 'Service Finder Paths',
  key: 'finderPath',
  path: 'finderPaths',
  class: 'mb-5',
  formItems: [
    new FormItemDropdown({
      label: '',
      placeholder: 'Please select...',
      options: getOptionsForFinderPathSelection(),
      validators: [Validators.minLength(1), Validators.maxLength(100)],
    }),
  ],
});

// -------------------------------------------- PUBLIC DISPLAY --------------------------------------------

export const displayInPublicFinder = new FormItemCheckbox({
  key: 'displayInPublicFinder',
  label: 'Display in public search and finder',
  help: 'If checked, this connection will be visible to all users (signed in or not) in the search tool as well as in the service finder at the defined paths. If unchecked, the connection can currently only be accessed via a direct link. Later, more display options will be available.',
  path: 'displayInPublicFinder',
  value: true,
  class: 'mb-5',
  // width: 'half',
});

// -------------------------------------------- CRITERIA --------------------------------------------

export const displayLogic = new FormItemDisplayLogic({
  key: 'displayLogic',
  path: 'displayLogic',
  class: 'mt-6',
});
