import { FormItemButtonSelect } from 'src/app/models/form/form';
import { yesNoOptions } from '../item-options';

export const personalDetailsWillingToProvideCare = new FormItemButtonSelect({
  key: 'personalDetailsWillingToProvideCare',
  label: 'I am willing to provide care',
  path: 'supportingRole.personalDetails.willingToProvideCare',
  class: 'mb-5',
  options: yesNoOptions,
});

export const personalDetailsAbleToContinueProvidingCare =
  new FormItemButtonSelect({
    key: 'personalDetailsAbleToContinueProvidingCare',
    label: 'I feel able to continue providing care',
    path: 'supportingRole.personalDetails.ableToContinueProvidingCare',
    class: 'mb-5',
    options: yesNoOptions,
  });

export const personalDetailsFeelingValuedAsCarer = new FormItemButtonSelect({
  key: 'personalDetailsFeelingValuedAsCarer',
  label: 'I feel valued as a carer',
  path: 'supportingRole.personalDetails.feelingValuedAsCarer',
  class: 'mb-5',
  options: yesNoOptions,
});
