<div class="flex mb-2">
  <img src="/assets/img/icons/tick.svg" alt="" />
  <div class="text-grey flex-1 text-right">
    {{ formItemsCompletedPercentage }}% of data complete
  </div>
</div>

<div
  class="flex w-full h-1.5 bg-light-grey text-sm rounded-full overflow-hidden dark:bg-gray-700"
>
  <div
    class="flex flex-col justify-center overflow-hidden bg-theme"
    role="progressbar"
    [style.width]="formItemsCompletedPercentage + '%'"
    [attr.aria-valuenow]="formItemsCompletedPercentage"
    aria-valuemin="0"
    aria-valuemax="100"
  ></div>
</div>
