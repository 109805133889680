<div class="btn-group tabs mt-3" [ngClass]="classes" style="margin-bottom: 0">
  <button
    type="button"
    *ngFor="let option of options"
    (click)="update(option.name)"
    [ngClass]="[
      checkActive(option.name) ? 'active' : '',
      styleType,
      disabled ? 'disabled-select-button' : ''
    ]"
    class="my-1 mr-1"
    [disabled]="disabled"
  >
    {{ option.label ?? option.name }}
  </button>
</div>
