// const address = new FormItemAddress(){

import {
  FormItem,
  FormItemSubform,
  FormItemButtonSelect,
  FormItemDatePicker,
  FormItemDropdown,
  FormItemPassword,
  FormItemSelect,
  FormItemTextbox,
} from 'src/app/models/form/form';
import { Validators } from '@angular/forms';

const buttonExample = new FormItemButtonSelect({
  key: 'show',
  label: 'Show input (inverted)',
  class: 'mb-4',
  required: true,
  options: [
    { name: true, label: 'Yes' },
    { name: false, label: 'No' },
  ],
});

const text = new FormItemTextbox({
  key: 'text',
  label: 'Conditional input',
  class: 'mb-4',
  required: true,
  placeholder: 'Enter some text',
  help: 'This input is only visible when the button above is set to "No"',
  validators: [Validators.required],
  displayConditions: [
    {
      key: 'show',
      values: [false],
    },
  ],
});

export default [buttonExample, text];
