import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';
import { FormItemRepeater, FormItemTextbox } from 'src/app/models/form/form';

export const skillsAndExperienceQualifications = new FormItemRepeater({
  label:
    'Verified credentials i.e. certified training courses such as micro credentials in digital care / Experience gained through being a carer.',
  help: 'For example: How to manage challenging behaviour and agitation?, How to move and handle safety?, How to monitor respiratory conditions and ensure all equipment is working?, How to check if the telecare equipment is property connected?, Digital Skills. Example: this would help with the ability to support their cared-for person to populate their PDS and navigate different features',
  key: 'skillsAndExperienceQualifications',
  path: 'supportingRole.skillsAndExperience.qualifications',
  class: 'mb-5',
  formItems: [
    new FormItemTextbox({
      placeholder: 'Tell us about the skills and experience you have...',
      validators: [Validators.minLength(1), maxByteValidator(2000)],
    }),
  ],
});
