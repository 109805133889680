import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Organisation } from 'src/app/models/organisation/organisation';
import { organisationSeeds } from 'src/assets/seeds/organisations/organisationSeeds';

@Injectable({
  providedIn: 'root',
})
export class OrganisationsStateService {
  // PROPERTIES

  private _organisationsSource: Organisation[] = [];
  _organisations$: BehaviorSubject<Organisation[]> = new BehaviorSubject<
    Organisation[]
  >(this._organisationsSource);

  private _loading$ = new BehaviorSubject<boolean>(false);
  private _loadingError$ = new BehaviorSubject<string>('');

  // CONSTRUCTOR
  constructor() {
    this.fetchOrganisationsIfNeeded();
  }

  // PUBLIC GETTERS

  get organisations$(): Observable<Organisation[]> {
    this.fetchOrganisationsIfNeeded();
    return this._organisations$.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  get loadingError$(): Observable<string> {
    return this._loadingError$.asObservable();
  }

  // PUBLIC API

  getOrganisationByName(name: string): Organisation | undefined {
    return this._organisationsSource.find(
      (organisation) => organisation.name === name
    );
  }

  // PRIVATE API

  private fetchOrganisationsIfNeeded() {
    // if the connections are already loaded or are being loaded, do nothing
    if (this._organisationsSource.length !== 0 || this._loading$.getValue()) {
      return;
    }

    this._loading$.next(true);
    this._loadingError$.next('');

    // TODO: load connections from the API
    organisationSeeds.forEach((organisationSeed) => {
      this._organisationsSource.push(new Organisation(organisationSeed));
    });

    this._organisations$.next(this._organisationsSource);
    this._loading$.next(false);
  }
}
