<div class="input-group relative">
  <input
    [(ngModel)]="dateStringValue"
    class="form-control cursor-pointer pr-10"
    [placeholder]="item?.placeholder"
    name="date-picker"
    type="text"
    mwlFlatpickr
    altFormat="F j, Y"
    [altInput]="true"
    dateFormat="Y-m-d\\TH:i:S"
    [id]="item?.key"
    [minDate]="item?.minDate"
    [maxDate]="item?.maxDate"
    [disabled]="disabled"
    [class.is-invalid]="control.touched && control.errors"
    (flatpickrChange)="update($event)"
  />
  <app-icon
    name="calendar"
    class="absolute inset-y-0 right-0 pr-5 flex items-center text-gray-500 pointer-events-none"
  ></app-icon>
</div>
