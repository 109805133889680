import { UserMedication } from './userMedication';

export class UserMedicationPdsEntry extends UserMedication {
  medicationId: string;

  constructor(userMedication?: Partial<UserMedicationPdsEntry>) {
    super(userMedication);
    this.medicationId = userMedication?.medicationId ?? '';
  }
}
