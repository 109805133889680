import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FormItem } from 'src/app/models/form/form';
import { UserStateService } from 'src/app/services/user-state/user.state.service';
import * as _ from 'lodash';
import { User } from 'src/app/models/user/user';
import { Subject, takeUntil } from 'rxjs';
import { computeCompletedPdsSectionItemsPercentage } from 'src/app/helper/formCompleteness';
import { aboutMeSections } from 'src/assets/about-me/sections/aboutMeSections';
import { PdsSection } from 'src/app/models/pds/pdsSection';

@Component({
  selector: 'app-account-about-me-section',
  templateUrl: './account-about-me-section.component.html',
  styleUrls: ['./account-about-me-section.component.css'],
})
export class AccountAboutMeSectionComponent {
  protected sections = aboutMeSections;
  protected currentSection: PdsSection | undefined;
  protected parentSection: PdsSection | undefined;

  protected user: User | undefined;

  protected form: FormItem<any>[] = [];

  protected breadcrumbSegments: {
    label: string;
    path: string;
  }[] = [];

  protected subsections = [];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    protected userService: UserStateService
  ) {}

  get urlPath() {
    // ignore query params
    return this.router.url.split('?')[0];
  }

  get nodePath() {
    return this.urlPath.split('/').slice(3);
  }

  get nodeName() {
    return this.urlPath.split('/').slice(3).at(-1);
  }

  ngOnInit() {
    this.currentSection = this.findCurrentSection(this.sections);

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.currentSection = this.findCurrentSection(this.sections);
      if (event instanceof NavigationEnd) {
        this.updateForm();
      }
    });

    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        this.user = user;
        this.updateForm();
      },
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleBack() {
    const lastPath = this.breadcrumbSegments?.at(-2)?.path;
    if (lastPath) {
      this.router.navigate([lastPath]);
    }
  }

  findCurrentSection(
    sections: PdsSection[],
    depth = 0
  ): PdsSection | undefined {
    if (depth == 0) {
      this.breadcrumbSegments = [
        { label: 'About Me', path: '/account/about-me' },
      ];
    }
    let section = sections.find(
      (section) => this.nodePath[depth] === section.routePath
    );
    this.breadcrumbSegments.push({
      label: section?.title ?? '',
      path: this.breadcrumbSegments?.at(-1)?.path
        ? this.breadcrumbSegments?.at(-1)?.path +
          '/' +
          (section?.routePath ? section.routePath : '')
        : '',
    });
    if (depth < this.nodePath.length - 1 && section) {
      this.parentSection = section;
      //if not at the end of a branch, recurse
      return this.findCurrentSection(section.children ?? [], depth + 1);
    }
    return section;
  }

  updateForm() {
    if (!this.user) {
      return;
    }

    // Common logic extracted into a helper function
    const processFormItems = (items: FormItem<any>[]) =>
      items.map((item) => {
        item.validators = item.validators.filter(
          ({ name }) => name !== 'required'
        );
        return item;
      });

    this.form = processFormItems(this.currentSection?.getFormItems?.() ?? []);
  }

  computeCompletedPercentageForSection(section: PdsSection) {
    return computeCompletedPdsSectionItemsPercentage(this.user, section);
  }
}
