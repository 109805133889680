import { Component, Input, SimpleChanges } from '@angular/core';
import { Subject, filter, first, takeUntil } from 'rxjs';
import { ContentHolder } from 'src/app/models/content/ContentHolder';
import { ConnectionsStateService } from 'src/app/services/connections-state/connections.state.service';

@Component({
  selector: 'app-content-holder',
  templateUrl: './content-holder.component.html',
  styleUrl: './content-holder.component.css',
})

// TODO: Handle Loading Error, Handle Different Custom Layout Types (i.e. background image), Handle Header Types + Cleanup Header Demos
export class ContentHolderComponent {
  // PROPERTIES

  @Input() contentHolder?: ContentHolder;

  protected connectionsForContentBlocks: { [key: string]: any } = {}; // store the connections for each content block (key is content block orderid)

  private destroy$: Subject<void> = new Subject<void>();

  // CONSTRUCTOR

  constructor(protected connectionsStateService: ConnectionsStateService) {}

  // LIFECYCLE HOOKS

  ngOnChanges(changes: SimpleChanges) {
    if (changes['contentHolder'] && this.contentHolder) {
      this.connectionsStateService.connections$
        .pipe(
          filter((connections) => connections.length > 0),
          first(), // Ensure it only fetches once after condition is met
          takeUntil(this.destroy$) // Clean up on destroy
        )
        .subscribe(() => {
          this.setConnectionsForContentBlocks();
        });
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // PUBLIC API

  protected getConnectionsForContentBlock(contentBlockOrderId: string) {
    return this.connectionsForContentBlocks[contentBlockOrderId] || [];
  }

  // PRIVATE API

  private setConnectionsForContentBlocks() {
    // store the connections for each content block in a dictionary (key is content block orderid)
    this.contentHolder?.allContentBlocksConnections.forEach(
      (contentBlockConnection) => {
        this.connectionsForContentBlocks[contentBlockConnection.orderId] =
          this.connectionsStateService.getConnectionsByNames(
            contentBlockConnection.connectionNames
          );
      }
    );
  }
}
