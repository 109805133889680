import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';

export const RegistrationProcessGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const sessionStorageService = inject(SessionStorageService);

  if (sessionStorageService.getRegistrationInProgress() === true) {
    return true;
  }

  // the registration is not in progress (user did not register during current session), redirect to register page
  return router.createUrlTree(['/register']);
};
