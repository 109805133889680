export function computeCurrentAgeFromDateOfBirth(dateOfBirth: Date): number {
  const dob = new Date(dateOfBirth);

  const ageDiffMs = Date.now() - dob.getTime();
  const ageDate = new Date(ageDiffMs); // miliseconds from epoch

  const age = Math.abs(ageDate.getUTCFullYear() - 1970);
  return age;
}

export function utcDateAtMidnightFromYmd(
  year: number,
  month: number,
  day: number
): Date {
  // Check if any of the values is NaN
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return new Date(NaN);
  }

  let utcDate = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));

  // Check if provided data was valid for date creation, if not the date object will not match the input
  // i.e. 31st of February etc.
  if (
    utcDate.getUTCFullYear() !== year ||
    utcDate.getUTCMonth() !== month ||
    utcDate.getUTCDate() !== day
  ) {
    return new Date(NaN);
  }

  return utcDate;
}

export function getUtcYesterdayMidnight(): Date {
  // Get the current date and time
  let now = new Date();

  // Get midnight of today in UTC
  let yesterdayUtcMidnight = utcDateAtMidnightFromYmd(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate()
  );

  // Subtract one day
  yesterdayUtcMidnight.setUTCDate(yesterdayUtcMidnight.getUTCDate() - 1);

  return yesterdayUtcMidnight;
}

export function convertIsoStringToUTCIsoString(dateString: string): string {
  // Check if the input already contains 'Z' or a timezone offset
  const hasTimezone = dateString.endsWith('Z') || dateString.includes('+');

  // If it already has a timezone, return it as is
  if (hasTimezone) {
    return dateString;
  }

  // Otherwise, append 'Z' to indicate it's in UTC
  return `${dateString}Z`;
}
