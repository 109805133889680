import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Connection } from 'src/app/models/connection/connection';
import {
  EmailOutputFormItem,
  EmailOutputFormSection,
} from 'src/app/models/connection/outputFormSection';
import { FormItem, FormItemTitle } from 'src/app/models/form/form';
import { getManualInputConnectionAdminBaseForm } from 'src/assets/forms/connection/form-connection';
import {
  alissId,
  organisation,
  postcode,
  slug,
  source,
  title,
} from 'src/assets/forms/connection/items/basic-information-items';
import {
  emailContact,
  outputContactAddress,
  phone,
  website,
} from 'src/assets/forms/connection/items/output-items';
import { summaryDescription } from 'src/assets/forms/connection/items/summary-items';
import { getFormItemsUser } from 'src/assets/forms/user/form-user';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  // PROPERTIES

  private _userFormItems: FormItem<any>[] = getFormItemsUser();
  private _manualInputConnectionAdminBaseForm: FormItem<any>[] =
    getManualInputConnectionAdminBaseForm();

  // CONSTRUCTOR

  constructor() {}

  // PUBLIC API

  createReferralFormFromOutputFormSections(
    outputFormSections: EmailOutputFormSection[],
    referralSubject: 'me' | 'them' = 'me'
  ): FormItem<any>[] {
    let form: FormItem<any>[] = [];

    outputFormSections.forEach((section) => {
      // add a title form item for the section if needed
      if (section.title && section.title.length > 0) {
        const titleFormItem = new FormItemTitle({
          key: section.title,
          label: section.title,
          class: 'mt-0 mb-5',
          help: section.description || undefined,
        });

        form.push(titleFormItem);
      }

      // add form items for the section
      section.formItems.forEach((outputFormItem) => {
        const userFormItem = this.createUserFormItemFromOutputFormItem(
          outputFormItem,
          referralSubject
        );

        if (userFormItem) {
          form.push(userFormItem);
        }
      });
    });

    return form;
  }

  updateAndGetManualInputAdminFormForConnection(
    connection?: Connection
  ): FormItem<any>[] {
    // this is changing the original form (might be refactored later if form items are needed in two states simultaneously)
    if (!connection) {
      source.hide = true;
    } else {
      source.hide = false;
    }

    if (connection && connection.source === 'mrd') {
      // case MRD connection
      slug.prefix = '';
      alissId.hide = false;

      this.disableManualInputAdminFormForManualInputConnection(true);
    } else {
      // case manual input or no connection
      slug.prefix = 'mi-';
      alissId.hide = true;

      this.disableManualInputAdminFormForManualInputConnection(false);
    }

    return this._manualInputConnectionAdminBaseForm;
  }

  // PRIVATE API

  private createUserFormItemFromOutputFormItem(
    outputFormItem: EmailOutputFormItem,
    referralSubject: 'me' | 'them' = 'me'
  ): FormItem<any> | undefined {
    const formItem = this._userFormItems.find(
      (item) => item.path === outputFormItem.path
    );

    // create a copy of the form item to not mutate the original
    const formItemCopy = _.cloneDeep(formItem);

    if (!formItemCopy) {
      return undefined;
    }

    // update the form item with the output form item values
    if (outputFormItem.required === true && formItemCopy.required === false) {
      formItemCopy.validators?.push(Validators.required);
      formItemCopy.required = true;
    } else if (!outputFormItem.required && formItemCopy.required) {
      formItemCopy.validators = formItemCopy.validators?.filter(
        (validator) => validator !== Validators.required
      );
      formItemCopy.required = false;
    }

    formItemCopy.placeholder =
      outputFormItem.placeholder ?? formItemCopy.placeholder;

    // update the form item key and path to differentiate between 'me' and 'them' items
    if (referralSubject === 'them') {
      formItemCopy.path = formItemCopy.path?.replace('me', 'them');
      formItemCopy.key += 'Them';
    }

    return formItemCopy;
  }

  private disableManualInputAdminFormForManualInputConnection(
    disableItems: boolean
  ): void {
    title.disabled = disableItems;
    slug.disabled = disableItems;
    postcode.disabled = disableItems;
    organisation.disabled = disableItems;
    summaryDescription.disabled = disableItems;

    // contact output
    phone.disabled = disableItems;
    emailContact.disabled = disableItems;
    website.disabled = disableItems;
    outputContactAddress.forEach((item) => (item.disabled = disableItems));
  }
}
