import { Address } from '../base/address';
import { PdsSection } from '../pds/pdsSection';

export class ConnectionOutput<T> {
  type?: 'redirect' | 'email' | 'contact' | 'pds'; // 'redirect' is legacy support
  introduction?: string; // used to introduce what the output will do for this particular connection
  nextSteps?: string; // used to introduce what will happen next when the output is executed

  constructor(data: Partial<ConnectionOutput<any>>) {
    this.type = data && data.type ? data.type : 'redirect';
    this.introduction = data.introduction;
    this.nextSteps = data.nextSteps;
  }
}

export class OutputPds extends ConnectionOutput<OutputPds> {
  mrdServiceProviderIdV2: number | null; // MrdServiceProviderId is deprecated; use MrdServiceProviderIdV2 instead
  mrdServiceIdV2: number | null; // MrdServiceId is deprecated; use MrdServiceIdV2 instead

  pdsReferralText?: string;
  pdsReferralCompletedText?: string;

  requiredPdsSections?: string[] = []; // list of names/ids of required PDS sections
  optionalPdsSections?: string[] = []; // list of names/ids of optional PDS sections

  constructor(data: Partial<OutputPds>) {
    super(data);
    this.type = 'pds';
    this.mrdServiceProviderIdV2 = data.mrdServiceProviderIdV2 ?? null;
    this.mrdServiceIdV2 = data.mrdServiceIdV2 ?? null;
    this.pdsReferralText = data.pdsReferralText;
    this.pdsReferralCompletedText = data.pdsReferralCompletedText;
  }
}

export class OutputEmail extends ConnectionOutput<OutputEmail> {
  email: string;
  confirmationEmailText?: string;
  referralEmailText?: string;

  constructor(data: Partial<OutputEmail>) {
    super(data);
    this.type = 'email';
    this.email = data.email ? data.email : '';
    this.confirmationEmailText = data.confirmationEmailText;
    this.referralEmailText = data.referralEmailText;
  }
}

// legacy support start
export class OutputRedirect extends ConnectionOutput<OutputRedirect> {
  destinationUrl: string;
  title?: string;

  constructor(data: Partial<OutputRedirect>) {
    super(data);
    this.type = 'redirect';
    this.destinationUrl = data.destinationUrl ? data.destinationUrl : '';
    this.title = data.title;
  }
}
// legacy support end

export class OutputContact extends ConnectionOutput<OutputContact> {
  phone?: string;
  address?: Address;
  email?: string;
  openingHours?: string[];
  website?: string;
  facebook?: string;

  get hasAddressData(): boolean {
    return this.address?.address1 ||
      this.address?.address2 ||
      this.address?.city ||
      this.address?.postcode
      ? true
      : false;
  }

  constructor(data: Partial<OutputContact>) {
    super(data);
    this.type = 'contact';
    this.phone = data.phone;
    this.address = data.address;
    this.email = data.email;
    this.openingHours = data.openingHours || [];
    this.website = data.website;
    this.facebook = data.facebook;
  }
}

// HELPER FUNCTION FOR CONNECTION AUTHORING FORM

// getValueFromSource

export function getOutputPdsFromSource(
  source: ConnectionOutput<any>[]
): ConnectionOutput<OutputPds> | undefined {
  return getValueFromSource(source, 'pds');
}

export function getOutputContactFromSource(
  source: ConnectionOutput<any>[]
): ConnectionOutput<OutputContact> | undefined {
  return getValueFromSource(source, 'contact');
}

export function getOutputEmailFromSource(
  source: ConnectionOutput<any>[]
): ConnectionOutput<OutputEmail> | undefined {
  return getValueFromSource(source, 'email');
}

function getValueFromSource<T>(
  source: ConnectionOutput<T>[] | undefined,
  targetType: 'email' | 'contact' | 'pds'
): ConnectionOutput<T> | undefined {
  if (Array.isArray(source)) {
    return source.find((item) => item.type === targetType);
  }
  return undefined;
}

// prepareValueForPayload

export function prepareOutputPdsForPayload(
  currentValueAtPath: ConnectionOutput<any>[],
  newValue: ConnectionOutput<any>[]
): ConnectionOutput<any>[] {
  return prepareValueForPayload('pds', currentValueAtPath, newValue);
}

export function prepareOutputContactForPayload(
  currentValueAtPath: ConnectionOutput<any>[],
  newValue: ConnectionOutput<any>[]
): ConnectionOutput<any>[] {
  return prepareValueForPayload('contact', currentValueAtPath, newValue);
}

export function prepareOutputEmailForPayload(
  currentValueAtPath: ConnectionOutput<any>[],
  newValue: ConnectionOutput<any>[]
): ConnectionOutput<any>[] {
  return prepareValueForPayload('email', currentValueAtPath, newValue);
}

function prepareValueForPayload(
  targetType: 'email' | 'contact' | 'pds',
  currentValueAtPath: ConnectionOutput<any>[],
  newValue?: ConnectionOutput<any>[]
): ConnectionOutput<any>[] {
  // expected input: currentValueAtPath is an array, else set to empty array
  if (!Array.isArray(currentValueAtPath)) {
    currentValueAtPath = [];
  }

  // expected input: newValue is an array, else return currentValueAtPath
  if (!Array.isArray(newValue)) {
    return currentValueAtPath;
  }

  // get the relevant output (of targetType) from the newValue array
  let newOutput = newValue.find((item) => item.type === targetType);

  // get the relevant output (of targetType) from the currentValueAtPath
  let oldOutput = currentValueAtPath.find((item) => item.type === targetType);

  // case: old item exists and new value is defined -> replace
  if (oldOutput && newOutput) {
    currentValueAtPath[currentValueAtPath.indexOf(oldOutput)] = newOutput;
  }
  // case: old item exists, new value is undefined -> remove
  else if (oldOutput && !newOutput) {
    currentValueAtPath.splice(currentValueAtPath.indexOf(oldOutput), 1);
  }
  // case: no old item -> just add the new value
  else if (!oldOutput && newOutput) {
    currentValueAtPath.push(newOutput);
  }
  // case: no old item and no new value -> do nothing

  return currentValueAtPath;
}
