import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemFileUpload,
  FormItemTextbox,
  FormItemTitle,
} from 'src/app/models/form/form';
import { yesNoOptionsLegacy } from '../item-options';
import { powerOfAttorneyTooltip } from 'src/assets/texts/tooltips';

export const powerOfAttorneyCurrentSupport = new FormItemTextbox({
  key: 'powerOfAttorneyCurrentSupport',
  path: 'planningAhead.powerOfAttorney.currentSupport',
  label: 'Who helps you with decisions at present?',
  placeholder: 'e.g. in my daughter/son',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const powerOfAttorneyOtherCompletedStatements = new FormItemButtonSelect(
  {
    key: 'powerOfAttorneyOtherCompletedStatements',
    path: 'planningAhead.powerOfAttorney.otherCompletedStatements',
    label: 'Have you completed other statements about your future wishes?',
    class: 'mb-5',
    multiple: true,
    options: [
      { name: 'Advanced care plan', label: 'Advanced care plan' },
      { name: 'Living will', label: 'Living will' },
      { name: 'DNACPR', label: 'DNACPR' },
      { name: 'Future care plan', label: 'Future care plan' },
      {
        name: 'Getting-to-know-you life story',
        label: 'Getting-to-know-you life story',
      },
      { name: 'What matters', label: 'What matters' },
      { name: 'Other', label: 'Other' },
    ],
  }
);

export const powerOfAttorneyAssigneeContactDetailsTitle = new FormItemTitle({
  key: 'continenceCareTeamContactDetails',
  label: 'Details of person granted financial and welfare powers to',
  class: 'mt-2 mb-5',
});

export const powerOfAttorneyAssigneeName = new FormItemTextbox({
  key: 'powerOfAttorneyAssigneeName',
  path: 'planningAhead.powerOfAttorney.assignee.name',
  label: 'Full Name',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const powerOfAttorneyAssigneeRelationship = new FormItemTextbox({
  key: 'powerOfAttorneyAssigneeRelationship',
  path: 'planningAhead.powerOfAttorney.assignee.relationship',
  label: 'Relationship to that person',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const powerOfAttorneyAssigneePhone = new FormItemTextbox({
  key: 'powerOfAttorneyAssigneeContactDetailsPhone',
  path: 'planningAhead.powerOfAttorney.assignee.phone',
  label: 'Phone Number',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const powerOfAttorneyAssigneeEmail = new FormItemTextbox({
  key: 'powerOfAttorneyAssigneeContactDetailsEmail',
  path: 'planningAhead.powerOfAttorney.assignee.email',
  label: 'Email Address',
  class: 'mb-5',
  width: 'half',
  validators: [
    Validators.minLength(1),
    Validators.maxLength(2000),
    Validators.email, // use built-in validator because trying to copy PHP's validator (Mydex uses) is not recommended, as testing correctness is difficult
  ],
});

export const powerOfAttorneyDocumentLocation = new FormItemTextbox({
  key: 'powerOfAttorneyDocumentLocation',
  path: 'planningAhead.powerOfAttorney.documentLocation',
  label: 'Where do you keep the document?',
  placeholder: 'e.g. in my desk drawer',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

// TODO: change to file upload
export const powerOfAttorneyDocument = new FormItemFileUpload({
  key: 'powerOfAttorneyDocument',
  path: 'planningAhead.powerOfAttorney.document',
  label: 'Would you like to keep a copy here?',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(5000000)], // TODO: add compression library?
});

export const powerOfAttorneyIsRequestingSupport = new FormItemButtonSelect({
  key: 'powerOfAttorneyIsRequestingSupport',
  path: 'planningAhead.powerOfAttorney.isRequestingSupport',
  label:
    'Would you like help and advice with putting Power of Attorney in Place?',
  tooltip: powerOfAttorneyTooltip,
  class: 'mb-5',
  options: yesNoOptionsLegacy,
});
