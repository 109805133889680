import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTextbox,
  FormItemTextboxWithAffix,
  FormItemTitle,
} from 'src/app/models/form/form';

// -------------------------------------------- TITLE --------------------------------------------

export const basicInformationFormTitle = new FormItemTitle({
  label: 'Basic Information',
  class: 'mt-2 mb-2',
});

// -------------------------------------------- INFORMATION --------------------------------------------

export const slug = new FormItemTextboxWithAffix({
  key: 'slug',
  path: 'name',
  label: 'Name / Slug',
  prefix: 'mi-',
  help: 'The name is used in the URL and must be unique. It can only contain lowercase letters, numbers and dashes. Regex: ^([a-z0-9]+-?)+$',
  required: true,
  validators: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(200),
    Validators.pattern('^([a-z0-9]+-?)+$'),
  ],
  class: 'mb-6',
});

export const source = new FormItemButtonSelect({
  key: 'source',
  label: 'Source',
  path: 'source',
  options: [
    { name: 'mrd', label: 'MRD' },
    { name: 'manual-input', label: 'Manual input' },
  ],
  value: 'manual-input',
  requireOneSelection: true,
  class: 'mb-6',
  disabled: true,
  required: true,
  validators: [Validators.required],
});

export const alissId = new FormItemTextbox({
  key: 'alissId',
  path: 'alissId',
  label: 'ALLIS ID',
  disabled: true,
  required: true,
  validators: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(128),
  ],
  class: 'mb-6',
});

export const type = new FormItemButtonSelect({
  key: 'type',
  label: 'Type',
  path: 'type',
  options: [
    { name: 'connection', label: 'Connection' },
    { name: 'signpost', label: 'Signpost' },
  ],
  value: 'connection',
  requireOneSelection: true,
  class: 'mb-6',
  required: true,
  validators: [Validators.required],
});

export const title = new FormItemTextbox({
  key: 'title',
  path: 'title',
  label: 'Title',
  required: true,
  validators: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(80),
  ],
  class: 'mb-6',
});

export const excerpt = new FormItemTextArea({
  key: 'excerpt',
  path: 'excerpt',
  label: 'Excerpt',
  help: 'Small intro to the signpost/connection shown on the title card.',
  required: true,
  validators: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(200),
  ],
  class: 'mb-6',
});

export const image = new FormItemTextbox({
  key: 'image',
  path: 'image',
  label: 'Image URL',
  help: 'URL to the image for the signpost/connection. Ensure the image at the provided URL is royalty-free, licensed for reuse, or explicitly permitted for use, and always adhere to the usage terms and guidelines of the source.',
  required: true,
  validators: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(500),
  ],
  class: 'mb-6',
});

export const icon = new FormItemTextbox({
  key: 'icon',
  path: 'icon',
  label: 'Icon',
  help: 'Name of an icon or the SVG. The icon component will determine which it is by the <svg> tag.',
  validators: [Validators.minLength(1), Validators.maxLength(500)],
  class: 'mb-6',
});

const priorityOptions = [
  { name: 1, label: '1' },
  { name: 2, label: '2' },
  { name: 3, label: '3' },
  { name: 4, label: '4' },
  { name: 5, label: '5' },
];

export const priority = new FormItemButtonSelect({
  key: 'priority',
  path: 'priority',
  value: 3,
  requireOneSelection: true,
  help: 'Used for ordering 1-5 from org perspective (1 is highest, 5 is lowest).',
  label: 'Priority',
  options: priorityOptions,
  required: true,
  validators: [Validators.required],
  class: 'mb-6',
});

export const priorityUser = new FormItemButtonSelect({
  key: 'priorityUser',
  path: 'priorityUser',
  value: 5,
  requireOneSelection: true,
  help: 'Used for ordering 1-5 from user perspective (1 is highest, 5 is lowest).',
  label: 'Priority User',
  options: priorityOptions,
  required: true,
  validators: [Validators.required],
  class: 'mb-6',
});

export const postcode = new FormItemTextbox({
  key: 'postcode',
  path: 'postcode',
  label: 'Postcode',
  validators: [Validators.minLength(1), Validators.maxLength(10)],
  class: 'mb-6',
});

export const organisation = new FormItemTextbox({
  key: 'organisation',
  path: 'organisation',
  label: 'Organisation',
  validators: [Validators.minLength(1), Validators.maxLength(200)],
  class: 'mb-6',
});

export const dataUsageStatement = new FormItemTextArea({
  key: 'dataUsageStatement',
  path: 'dataUsageStatement',
  label: 'Data Usage Statement',
  help: 'Explains to the user how the connection will use the data that they provide for a referral.',
  validators: [Validators.minLength(1), Validators.maxLength(1000)],
  class: 'mb-6',
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

// -------------------------------------------- WEB LINK --------------------------------------------

export const webLinkUrl = new FormItemTextbox({
  key: 'webLinkUrl',
  path: 'webLink.url',
  label: 'Signpost Link URL',
  help: "User will be navigated to this webpage when clicking on the signpost's button.",
  required: true,
  validators: [
    Validators.minLength(1),
    Validators.maxLength(200),
    Validators.required,
  ],
  class: 'mb-6',
  displayConditions: [
    {
      key: 'type',
      values: ['signpost'],
    },
  ],
});

export const webLinkText = new FormItemTextbox({
  key: 'webLinkText',
  path: 'webLink.text',
  label: 'Signpost Link Button Text',
  help: 'Text for the button to navigate to another webpage.',
  placeholder: 'Visit Website',
  required: true,
  validators: [
    Validators.minLength(1),
    Validators.maxLength(30),
    Validators.required,
  ],
  class: 'mb-6',
  displayConditions: [
    {
      key: 'type',
      values: ['signpost'],
    },
  ],
});

// -------------------------------------------- APP LINK --------------------------------------------

export const appLinkUrl = new FormItemTextbox({
  key: 'appLinkUrl',
  path: 'appLink.url',
  label: 'App Link URL Path',
  help: 'URL path to navigate to another page on the web app.',
  validators: [Validators.minLength(1), Validators.maxLength(200)],
  class: 'mb-6',
  placeholder: '/path/to/page',
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

export const appLinkText = new FormItemTextbox({
  key: 'appLinkText',
  path: 'appLink.text',
  label: 'App Link Text',
  help: 'Text for the link to navigate to another page on the web app.',
  validators: [Validators.minLength(1), Validators.maxLength(200)],
  class: 'mb-6',
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});
