<div class="md:flex md:flex-row md:items-center">
  <button
    (click)="clickEvent.emit($event)"
    [disabled]="isInProgress || disabled"
    type="button"
    [class]="buttonClass"
    [ngClass]="{
    'btn-theme-outline': type === 'outline',
    'md:order-2': errorMessagePlacement === 'left',
    'pointer-events-none': isInProgress || disabled,
    }"
  >
    <app-loading-spinner
      *ngIf="isInProgress && !isSuccess"
      [text]="textProgress"
      class="opacity-75"
    ></app-loading-spinner>

    <div class="flex opacity-90 mx-auto" *ngIf="!isInProgress && isSuccess">
      <app-icon class="h-5" [width]="25" [height]="25" name="check"></app-icon>
      <span class="inline-block ml-1">{{ textSuccess }}</span>
    </div>

    <ng-container
      *ngIf="(!isInProgress && !isSuccess) || (isInProgress && isSuccess)"
    >
      <app-icon *ngIf="iconName" [name]="iconName" class="w-5 mr-1"></app-icon>
      <span>{{ text }}</span>
    </ng-container>
  </button>

  <ng-container *ngIf="errorMessage">
    <p
      class="text-error-red form-error-text mt-2 md:mt-3 md:ml-4"
      [ngClass]="{
          'md:order-1 md:ml-0 md:mr-4': errorMessagePlacement === 'left',
      }"
    >
      {{ errorMessage }}
    </p>
  </ng-container>
</div>
