import {
  Component,
  EventEmitter,
  Output,
  Input,
  QueryList,
  ViewChildren,
  SkipSelf,
  Optional,
  ElementRef,
  ChangeDetectorRef,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import {
  FormItem,
  FormItemPassword,
  implementsSourceValueTransformerInterface,
} from 'src/app/models/form/form';
import { set } from 'lodash';
import * as _ from 'lodash';
import { ModalComponent } from '../modal/modal.component';
import { ContactsComponent } from './controls/contacts/contacts.component';
import { ImageUploadGalleryComponent } from './controls/image-upload-gallery/image-upload-gallery.component';
import { MedicationsControlComponent } from './controls/medications-control/medications-control.component';
interface Payload {
  [key: string]: any;

  address: {
    [key: string]: any;
    country?: string;
  };
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent {
  @ViewChildren('addModal') addModals!: QueryList<ModalComponent>;
  @ViewChildren('editModal') editModals!: QueryList<ModalComponent>;
  @ViewChildren('textbox') textboxes!: QueryList<ElementRef>;
  @ViewChildren('dropdown') dropdowns?: QueryList<ElementRef>;
  @ViewChildren('contactsItem') contactsItems?: QueryList<ContactsComponent>;
  @ViewChildren('imagesItem')
  imagesItems?: QueryList<ImageUploadGalleryComponent>;
  @ViewChildren('medicationsItem')
  medicationsItems?: QueryList<MedicationsControlComponent>;

  /* General Form */
  @Input() formData!: FormItem<any>[];
  @Input() formValues!: any;
  @Input() submitText: string = 'Save';
  @Input() loadingText: string = 'Submitting';
  @Input() cancelText: string = 'Cancel';
  @Input() successText: string = 'Saved';
  @Input() error: string = '';
  @Input() success: boolean = false;
  @Input() submitDisabled: boolean = false;
  @Input() loading: boolean = true;
  @Input() cancellable: boolean = true;
  @Input() removable: boolean = false;
  @Input() inlineSubmitError: boolean = true;
  @Input() disabledForm?: boolean = undefined; // for repeater and subform
  @Input() noSubmit: boolean = false;

  @Output() submit = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() action = new EventEmitter();
  @Output() remove = new EventEmitter();
  /* General Form End */

  /* ContactsItem Only */
  @Input() contactsItemOptions: any;
  @Input() contactsItemLoading: boolean = true;
  @Input() contactsItemLoadingError: string = '';
  @Input() contactsItemSaving: boolean = false;
  @Input() contactsItemSavingError: string = '';
  @Input() contactsItemDeleting: boolean = false;
  @Input() contactsItemDeletionError: string = '';
  @Output() contactCreated = new EventEmitter();
  @Output() contactUpdated = new EventEmitter();
  @Output() contactDeleted = new EventEmitter();
  @Output() contactsItemClearErrors = new EventEmitter();
  /* ContactsItem Only End */

  /* ImagesItem Only */
  @Input() imagesItemOptions: any;
  @Input() imagesItemLoadingAll: boolean = true;
  @Input() imagesItemLoadingAllError: string = '';
  @Input() imagesItemLoadingSingleImage: boolean = true;
  @Input() imagesItemLoadingSingleImageError: string = '';
  @Input() imagesItemAdding: boolean = false;
  @Input() imagesItemAdditionError: string = '';
  @Input() imagesItemUpdating: boolean = false;
  @Input() imagesItemUpdatingError: string = '';
  @Input() imagesItemDeleting: boolean = false;
  @Input() imagesItemDeletionError: string = '';
  @Output() fullSizeImageRequested = new EventEmitter();
  @Output() imageAdded = new EventEmitter();
  @Output() imageUpdated = new EventEmitter();
  @Output() imageDeleted = new EventEmitter();
  @Output() imagesItemClearErrors = new EventEmitter();
  /* ImagesItem Only End */

  /* MedicationsItem Only */
  @Input() medicationsItemAllMedications: any;
  @Input() medicationsItemLoadingAll: boolean = true;
  @Input() medicationsItemLoadingAllError: string = '';
  @Input() medicationsItemLoadingDetails: boolean = true;
  @Input() medicationsItemLoadingDetailsError: string = '';
  @Input() medicationsItemAdding: boolean = false;
  @Input() medicationsItemAdditionError: string = '';
  @Input() medicationsItemUpdating: boolean = false;
  @Input() medicationsItemUpdateError: string = '';
  @Input() medicationsItemDeleting: boolean = false;
  @Input() medicationsItemDeletionError: string = '';
  @Output() medicationDetailsRequested = new EventEmitter();
  @Output() medicationAdded = new EventEmitter();
  @Output() medicationUpdated = new EventEmitter();
  @Output() medicationDeleted = new EventEmitter();
  @Output() medicationsItemClearErrors = new EventEmitter();
  /* MedicationsItem Only End */

  form!: FormGroup;
  formSaved: string = '';
  constructor(
    @Optional() @SkipSelf() private parentForm: FormComponent,
    private elementRef: ElementRef,
    private cdRef: ChangeDetectorRef
  ) {}

  get isSubForm(): boolean {
    return !!this.parentForm;
  }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.form = this.toFormGroup(this.formData);

    if (this.noSubmit) {
      this.form.valueChanges.subscribe((data: any) => {
        this.update.emit(data);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['formData'] ||
      changes['formValues'] ||
      this.formValues === undefined // allows for clearing of unsaved form input if values are set to undefined again
    ) {
      this.init();
    }

    if (this.form && this.formValues && !this.loading && !this.error) {
      this.form.patchValue(this.formValues);
      this.updateDropdownPlaceholder();
    }

    if (changes['loading'] || changes['disabledForm']) {
      this.handleInteractionState();
    }
  }

  private handleInteractionState() {
    if (!this.formData || !this.form?.controls) {
      return;
    }

    this.formData.forEach((item) => {
      if (item.disabled || !this.form.controls[item.key]) {
        return;
      }
      if (this.loading === true || this.disabledForm === true) {
        this.form.controls[item.key].disable();
      }
      // only enable if the item is not disabled by default
      else if (this.loading === false && !this.disabledForm) {
        this.form.controls[item.key].enable();
      }
    });
  }

  handleAutofill({ path, value }: { path: string; value: any }) {
    let item = this.formData.find((item) => item.path == path);
    if (item) {
      this.form.controls[item?.key].setValue(value);

      //Trigger validation and force change detection to update UI:
      this.form.controls[item?.key].markAsTouched();
      this.form.controls[item?.key].markAsDirty();
      this.form.controls[item?.key].updateValueAndValidity();
      this.cdRef.detectChanges();
    }
  }

  // Update the dropdown placeholder to an empty string if the value is null (because the placeholder will only show if the value is an empty string)
  private updateDropdownPlaceholder() {
    this.formData
      .filter((item) => item.type == 'dropdown')
      .forEach(({ key }) => {
        var control = this.form.controls[key];

        if (!control.value) {
          control.setValue('');
        }
      });
  }

  private isPassword(item: FormItem<any>): item is FormItemPassword {
    return 'showPassword' in item;
  }
  togglePasswordVisibility(item: FormItemPassword) {
    if (this.isPassword(item)) {
      item.showPassword = !item.showPassword;
    }
  }

  toFormGroup = (formItems: FormItem<any>[]): FormGroup => {
    const group: any = {};

    formItems.forEach((formItem) => {
      // Get the source value via the defined path using _.get
      let sourceValue = _.get(
        this.formValues,
        formItem.path ? formItem.path : formItem.key,
        formItem.value // Fallback to default value
      );

      let formControl: FormControl;

      // Set up the form control
      // If the form item is a dropdown and the source value is null or undefined, set it to an empty string (because the placeholder will only show if the value is an empty string)
      if (
        formItem.type === 'dropdown' &&
        (sourceValue === null || sourceValue === undefined)
      ) {
        sourceValue = '';
      }

      formControl = new FormControl(
        {
          value: sourceValue,
          disabled: formItem.disabled || this.disabledForm || this.loading,
        },
        formItem.validators
      );

      if (formItem.required) {
        formControl.addValidators(Validators.required);
      }
      group[formItem.key] = formControl;
    });

    return new FormGroup(group);
  };

  reset() {
    this.form.reset();
    this.formData
      .filter((item) => item.type == 'dropdown')
      .forEach(({ key }) => {
        this.form.controls[key].setValue('');
      });
  }

  submitForm(e: any = null) {
    let hasUncheckeditem = false;

    this.formData
      .filter(
        (item) =>
          item.type === 'checkbox' &&
          this.questionDisplayConditionsMet(item, this.form)
      )
      .forEach((item) => {
        if (
          item.required &&
          (!this.form.controls[item.key].value ||
            this.form.controls[item.key].value === false)
        ) {
          hasUncheckeditem = true;
        }
      });

    if (!this.validate(this.form, this.formData) || hasUncheckeditem) {
      this.error =
        'Please complete all required fields and resolve any validation issues.';
      setTimeout(() => {
        this.error = '';
      }, 3000);
      return;
    }

    this.formData
      .filter(
        (item) =>
          (item.type === 'dropdown' || item.type === 'tabs') &&
          this.questionDisplayConditionsMet(item, this.form)
      )
      .forEach((item) => {
        this.form.controls[item.key].markAsTouched();
        this.validateDropDown(this.form.controls[item.key], item);
      });

    const invalidDropdowns = this.formData
      .filter(
        (item) =>
          (item.type === 'dropdown' || item.type === 'tabs') &&
          this.questionDisplayConditionsMet(item, this.form)
      )
      .some((item) => this.form.controls[item.key].errors?.['invalidDropDown']);

    const invalidArr =
      this.formData.filter(({ type }) => type == 'array').values.length > 0;

    if (!invalidDropdowns && !invalidArr) {
      this.submit.emit(this.toServerPayload(this.form, this.formData));

      if (this.isSubForm) {
        this.reset();
      }
    }
  }

  validateDropDown(control: AbstractControl, question: FormItem<any>) {
    if (
      control.value === null ||
      control.value === undefined ||
      control.value === ''
    ) {
      control.setErrors(null);
    }
    // simple options
    else if (
      question.options?.find((option) => option.name === control.value)
    ) {
      control.setErrors(null);
    }
    // option groups
    else if (
      question.optionGroups?.some((group) =>
        group.options.some((option) => option.name === control.value)
      )
    ) {
      control.setErrors(null);
    } else {
      control.setErrors({ invalidDropDown: true });
    }
  }

  validate = (formGroup: FormGroup, formItems: FormItem<any>[]) => {
    let valid = true;

    formItems.forEach((formItem) => {
      const control = formGroup.controls[formItem.key];

      if (!control) {
        return; // Skip if control doesn't exist
      }

      // Check for the validity of regular form controls
      if (
        !control.disabled &&
        !control.valid &&
        this.questionDisplayConditionsMet(formItem, formGroup)
      ) {
        valid = false;
        control.markAsTouched();
      }
    });

    return valid;
  };

  toServerPayload = (formGroup: FormGroup, questions: FormItem<any>[]) => {
    const object = {} as Payload;

    questions.forEach((question) => {
      if (
        question.payloadIgnore ||
        !this.questionDisplayConditionsMet(question, this.form)
      ) {
        return;
      }

      let path = question.path ? question.path : question.key;

      if (!path) {
        return;
      }

      let newValue = formGroup?.controls[question.key].value;

      if (implementsSourceValueTransformerInterface(question)) {
        const currentValueAtPath = _.get(this.formValues, path);
        newValue = question.prepareValueForPayload
          ? question.prepareValueForPayload(currentValueAtPath, newValue)
          : newValue;
      }

      set(object, path, newValue);
    });

    return object;
  };

  formError(type: string, key: string): boolean {
    const errors = this.form.controls[key].errors;

    return !!(errors && errors[type]);
  }

  onLabelClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target.tagName === 'A') {
      event.preventDefault();
      event.stopPropagation();

      window.open((target as HTMLAnchorElement).href, '_blank');
    }
  }

  onKeydown(event: any) {
    if (event.key === 'Enter' && !this.noSubmit) {
      event.stopPropagation();
      // Check if the event target is within the current component
      const formElement = this.elementRef.nativeElement;
      if (formElement.contains(event.target)) {
        this.submitForm();
      }
    }
  }

  handleCancel() {
    this.cancel.emit();
  }

  handleRemove() {
    this.remove.emit();
  }

  questionDisplayConditionsMet = (
    formItem: FormItem<any>,
    form?: FormGroup
  ): boolean => {
    let allConditionsMet = true;

    // loop through all display conditions
    formItem.displayConditions.forEach((condition) => {
      const controlValue = form?.controls[condition.key]?.value;

      // Check if any value in the controlValue array is in the condition.values array
      if (
        Array.isArray(controlValue) &&
        controlValue.some((val) => condition.values.includes(val))
      ) {
        return;
      }

      // Handle null values... also matches undefined and empty arrays
      if (
        condition.values.includes(null) &&
        (controlValue === undefined ||
          controlValue === null ||
          (Array.isArray(controlValue) && controlValue.length === 0))
      ) {
        return;
      }

      // Handle 'any' condition for arrays and other types
      if (condition.values.length === 1 && condition.values[0] === 'any') {
        if (Array.isArray(controlValue) && controlValue.length > 0) {
          return;
        } else if (
          controlValue !== undefined &&
          controlValue !== null &&
          controlValue !== ''
        ) {
          return;
        }
      }

      // Direct value match for non-array types
      if (
        !Array.isArray(controlValue) &&
        condition.values.includes(controlValue)
      ) {
        return;
      }

      allConditionsMet = false;
    });

    return allConditionsMet;
  };

  getCustomErrorMessage(
    validatorType: string,
    item: FormItem<any>,
    defaultError: string
  ) {
    const foundValidator = item.validatorsErrorMessages?.find(
      (x) => x.type === validatorType
    );

    if (
      item.validatorsErrorMessages &&
      foundValidator &&
      foundValidator.message.length > 0
    ) {
      return item.validatorsErrorMessages.find((x) => x.type === validatorType)
        ?.message;
    } else {
      return defaultError;
    }
  }

  /*ContactsItem Functions*/
  handleContactCreated(e: any) {
    this.contactCreated.emit(e);
  }

  handleContactUpdated(e: any) {
    this.contactUpdated.emit(e);
  }

  handleContactDeleted(e: any) {
    this.contactDeleted.emit(e);
  }

  handleContactSavingSuccess() {
    this.contactsItems?.forEach((item) => item.handleContactSavingSuccess());
  }

  handleContactDeletionSuccess(contactId: string) {
    this.contactsItems?.forEach((item) =>
      item.handleContactDeletionSuccess(contactId)
    );
  }

  handleClearContactsItemErrors(e: any) {
    this.contactsItemClearErrors.emit(e);
  }

  /*ContactsItem Functions End*/

  /*ImagesItem Functions*/
  handleFullSizeImageRequested(e: any) {
    this.fullSizeImageRequested.emit(e);
  }

  handleImageAdded(e: any) {
    this.imageAdded.emit(e);
  }

  handleImageUpdated(e: any) {
    this.imageUpdated.emit(e);
  }

  handleImageDeleted(e: any) {
    this.imageDeleted.emit(e);
  }

  handleClearImagesItemErrors(e: any) {
    this.imagesItemClearErrors.emit(e);
  }

  handleLoadingImageFullSizeSuccess(fullSizeImage: any) {
    this.imagesItems?.forEach((item) =>
      item.handleLoadingImageFullSizeSuccess(fullSizeImage)
    );
  }

  handleImageUpdatingSuccess() {
    this.imagesItems?.forEach((item) => item.handleImageUpdatingSuccess());
  }

  handleImageDeletionSuccess(imageId: string) {
    this.imagesItems?.forEach((item) =>
      item.handleImageDeletionSuccess(imageId)
    );
  }
  /*ImagesItem Functions End*/

  /*MedicationItem Functions*/
  handleMedicationDetailsRequested(e: any) {
    this.medicationDetailsRequested.emit(e);
  }

  handleMedicationAdded(e: any) {
    this.medicationAdded.emit(e);
  }

  handleMedicationUpdated(e: any) {
    this.medicationUpdated.emit(e);
  }

  handleMedicationDeleted(e: any) {
    this.medicationDeleted.emit(e);
  }

  handleClearMedicationItemErrors(e: any) {
    this.medicationsItemClearErrors.emit(e);
  }

  handleLoadingMedicationDetailsSuccess(medicationDetails: any) {
    this.medicationsItems?.forEach((item) =>
      item.handleLoadingMedicationDetailsSuccess(medicationDetails)
    );
  }

  handleMedicationAdditionSuccess() {
    this.medicationsItems?.forEach((item) =>
      item.handleMedicationAdditionSuccess()
    );
  }

  handleMedicationUpdateSuccess() {
    this.medicationsItems?.forEach((item) =>
      item.handleMedicationUpdateSuccess()
    );
  }

  handleMedicationDeletionSuccess(medicationId: string) {
    this.medicationsItems?.forEach((item) =>
      item.handleMedicationDeletionSuccess(medicationId)
    );
  }
  /*MedicationItem Functions End*/
}

export function getTextInputRegex(): string {
  return '^[a-zA-Z0-9][a-zA-Z0-9. ]*$';
}

export function getCommaSeperatedEmailRegex(): string {
  return '^(([\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}),{0,1})+$';
}

export function getExtendedTextInputRegex(): string {
  return '^[a-zA-Z0-9()/ ][a-zA-Z0-9 ()/]*$';
}
