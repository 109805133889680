import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { RegistrationStateService } from 'src/app/services/registration-state/registration-state.service';
import { environment } from 'src/environments/environment';

export const RegistrationEnabledGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const registrationStateService = inject(RegistrationStateService);

  const registrationEnabled =
    environment.enableRegistration ||
    registrationStateService.getRegistrationEnabled();

  if (registrationEnabled) {
    return true;
  }
  // the registration is disabled, redirect to login page
  return router.createUrlTree(['/login']);
};
