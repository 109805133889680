import {
  FormItemButtonSelect,
  FormItemTextArea,
} from 'src/app/models/form/form';
import { yesNoOptions } from '../item-options';
import { Validators } from '@angular/forms';

// TODO: update/replace once my-support-packages is implemented
export const caringRoleServicesInPlace = new FormItemTextArea({
  key: 'caringRoleServicesInPlace',
  path: 'supportingRole.caringRole.servicesInPlace',
  label: 'Services in place now, for me and/or the person I care for',
  placeholder: '',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const caringRoleInvolvedInServicesDesign = new FormItemButtonSelect({
  key: 'caringRoleInvolvedInServicesDesign',
  label: 'I feel involved in designing the services/support in place',
  path: 'supportingRole.caringRole.involvedInServicesDesign',
  class: 'mb-5',
  options: yesNoOptions,
});

export const caringRoleIsSatisfiedWithServices = new FormItemButtonSelect({
  key: 'caringRoleIsSatisfiedWithServices',
  label: 'I am satisfied with the current services and support I have in place',
  path: 'supportingRole.caringRole.isSatisfiedWithServices',
  class: 'mb-5',
  options: yesNoOptions,
});

export const caringRoleServicesSatisfactionDetails = new FormItemTextArea({
  key: 'caringRoleServicesSatisfactionDetails',
  label: 'Because...',
  path: 'supportingRole.caringRole.satisfactionDetails',
  placeholder: '“I still feel I am spending too much time indoors as a carer”',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
  displayConditions: [
    {
      key: 'caringRoleIsSatisfiedWithServices',
      values: ['Yes', 'No'],
    },
  ],
});

export const caringRoleChallenges = new FormItemTextArea({
  key: 'caringRoleChallenges',
  label:
    'Things I find particularly challenging, or difficult to deal with in my role as a carer',
  path: 'supportingRole.caringRole.challenges',
  placeholder: 'Disturbed night sleep',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
