<div class="container-xl">
  <!--LOADING SPINNER-->
  <app-loading-spinner
    *ngIf="(userService.loading$ | async) || (userService.saving$ | async)"
  ></app-loading-spinner>
  <!--END LOADING SPINNER-->

  <!-- SERVER ERROR: FAILED LOADING USER -->
  <app-no-items-error
    [isDisplayed]="
      !(userService.loading$ | async) && (userService.loadingError$ | async)
    "
    [errorMessage]="userService.loadingError$ | async"
  >
  </app-no-items-error>
  <!-- END SERVER ERROR: FAILED LOADING USER -->

  <!--CONTENT-->
  <ng-container
    *ngIf="
      !(userService.loading$ | async) &&
      !(userService.saving$ | async) &&
      !(userService.loadingError$ | async)
    "
    class="flex flex-col"
  >
    <h1>Ongoing Referrals</h1>
    <p class="w-full max-w-3xl">
      Here, you can see the referrals that grant access to specified areas of
      your Personal Data Store and were started but not completed, yet.
    </p>
    <div class="card bg-white rounded-lg w-full mt-8 box-border px-10 py-8">
      <app-connections
        [connections]="connectionsWithOngoingPdsReferrals"
        [referralDisplayType]="'pds'"
        [errorMessageNoConnections]="'You have no ongoing referrals.'"
      >
      </app-connections>
    </div>
  </ng-container>
  <!--END CONTENT-->
</div>
