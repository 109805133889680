import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { convertIsoStringToUTCIsoString } from 'src/app/helper/dateHelper';
import { FormItemDatePicker } from 'src/app/models/form/form';

@Component({
  selector: 'app-date-picker-control',
  templateUrl: './date-picker-control.component.html',
  styleUrl: './date-picker-control.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerControlComponent),
      multi: true,
    },
  ],
})
export class DatePickerControlComponent implements ControlValueAccessor {
  @Input() item?: FormItemDatePicker;
  @Input() disabled = false;
  @Input() control: any;

  dateStringValue?: string = undefined; // date value as string for the flatpickr input

  onChange = (value: any) => {};
  onTouched = () => {};
  touched = false;

  writeValue(value: Date | undefined): void {
    if (!value) {
      return;
    }

    const date = new Date(value);

    if (isNaN(date.getTime())) {
      return;
    }

    // Set the date value as a string for the flatpickr input
    this.dateStringValue = date.toISOString();
  }

  update(value: any): void {
    this.markAsTouched();

    // Update is called before the bound property (dateStringValue) is updated, hence the value is read from the event object
    if (
      !value ||
      !value?.dateString ||
      isNaN(new Date(value.dateString).getTime())
    ) {
      this.onChange(undefined);
      return;
    }

    // ISO date string is midnight of date selected in picker but would be interpreted as local time if converted to date object directly
    // Hence the ISO string must be converted to an UTC ISO string by appending 'Z' to the end
    const utcIsoString = convertIsoStringToUTCIsoString(value.dateString);

    // Create a new date from the UTC ISO string ensuring the selected date in the picker is interpreted as UTC midnight of that day
    const date = new Date(utcIsoString);

    this.onChange(date);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
