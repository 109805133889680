import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { Connection } from 'src/app/models/connection/connection';
import { MrdConnectionRequest } from 'src/app/models/connection/server/mrdConnectionRequest';
import { getErrorMessage } from 'src/app/helper/getErrorMessage';
import { ConnectionsApiService } from '../connections-api/connections.api.service';

@Injectable({ providedIn: 'root' })
export class ConnectionsStateService implements OnDestroy {
  // PROPERTIES

  private _connectionsSource: Connection[] = [];
  private _connections$: BehaviorSubject<Connection[]> = new BehaviorSubject<
    Connection[]
  >(this._connectionsSource);

  private destroy$: Subject<void> = new Subject<void>();

  private _loading$ = new BehaviorSubject<boolean>(false);
  private _loadingError$ = new BehaviorSubject<string>('');

  private _loadingFromMrd$ = new BehaviorSubject<boolean>(false);
  private _loadingFromMrdError$ = new BehaviorSubject<string>('');

  private _saving$ = new BehaviorSubject<boolean>(false);
  private _savingError$ = new BehaviorSubject<string>('');

  private _deleting$ = new BehaviorSubject<boolean>(false);
  private _deletionError$ = new BehaviorSubject<string>('');

  private readonly _connectionsAdminListUrl = '/admin/connections/';

  // CONSTRUCTOR

  constructor(
    private connectionsApiService: ConnectionsApiService,
    private router: Router
  ) {
    this.fetchConnectionsIfNeeded();
  }

  // PUBLIC GETTERS

  get connections$(): Observable<Connection[]> {
    this.fetchConnectionsIfNeeded();
    return this._connections$.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  get loadingError$(): Observable<string> {
    return this._loadingError$.asObservable();
  }

  get loadingFromMrd$(): Observable<boolean> {
    return this._loadingFromMrd$.asObservable();
  }

  get loadingFromMrdError$(): Observable<string> {
    return this._loadingFromMrdError$.asObservable();
  }

  get saving$(): Observable<boolean> {
    return this._saving$.asObservable();
  }

  get savingError$(): Observable<string> {
    return this._savingError$.asObservable();
  }

  get deleting$(): Observable<boolean> {
    return this._deleting$.asObservable();
  }

  get deletionError$(): Observable<string> {
    return this._deletionError$.asObservable();
  }

  // LIFECYCLE HOOKS

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // PUBLIC API

  getConnectionByName(name: string): Connection | undefined {
    return this._connectionsSource.find(
      (connection) => connection.name === name
    );
  }

  getConnectionsByNames(names: string[]): Connection[] {
    return this._connectionsSource.filter((connection) =>
      names.includes(connection.name)
    );
  }

  getConnectionsForFinderPath(finderPath: string): Connection[] {
    return this._connectionsSource.filter((connection) => {
      // Filter out connections that are not public (TODO: update when display logic becomes more complex)
      return (
        connection.displayInPublicFinder === true &&
        connection.finderPaths.some((path) => path.startsWith(finderPath))
      );
    });
  }

  addConnectionFromMrd(mrdConnectionRequest: MrdConnectionRequest) {
    this._loadingFromMrd$.next(true);
    this._loadingFromMrdError$.next('');

    this.connectionsApiService
      .addConnectionFromMrd(mrdConnectionRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (newConnection) => {
          this._connectionsSource.push(newConnection);
          this.sortConnections();
          this._connections$.next(this._connectionsSource);

          this._loadingFromMrd$.next(false);
          this.router.navigate([
            this._connectionsAdminListUrl + newConnection.name,
          ]);
        },
        error: (error) => {
          this._loadingFromMrdError$.next(
            getErrorMessage(error, 'Unable to add the connection from MRD.')
          );
          this._loadingFromMrd$.next(false);
        },
      });
  }

  saveConnection(connectionName: string, connection: Connection) {
    this._saving$.next(true);
    this._savingError$.next('');

    if (connectionName) {
      this.updateConnection(connectionName, connection);
      return;
    }

    this.createConnection(connection);
  }

  deleteConnection(connectionName: string) {
    this._deleting$.next(true);
    this._deletionError$.next('');

    this.connectionsApiService
      .deleteConnection(connectionName)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this._connectionsSource = this._connectionsSource.filter(
            (connection) => connection.name !== connectionName
          );
          this.sortConnections();
          this._connections$.next(this._connectionsSource);

          this._deleting$.next(false);
          this.router.navigate([this._connectionsAdminListUrl]);
        },
        error: (error) => {
          this._deletionError$.next(
            getErrorMessage(error, 'Unable to delete the connection.')
          );
          this._deleting$.next(false);
        },
      });
  }

  // call in ngOnDestroy of subscribing components to reset the errors (do not reset loading errors)
  resetErrors() {
    this._savingError$.next('');
    this._deletionError$.next('');
  }

  // PRIVATE API

  private fetchConnectionsIfNeeded() {
    // if the connections are already loaded or are being loaded, do nothing
    if (this._connectionsSource.length !== 0 || this._loading$.getValue()) {
      return;
    }

    this._loading$.next(true);
    this._loadingError$.next('');

    this.connectionsApiService
      .getAllConnections()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (connections) => {
          this._connectionsSource = connections;
          this.sortConnections();

          this._connections$.next(this._connectionsSource);
          this._loading$.next(false);
        },
        error: (error) => {
          this._loadingError$.next(
            getErrorMessage(
              error,
              'Unable to fetch connections from the server.'
            )
          );
          this._loading$.next(false);
        },
      });
  }

  private sortConnections() {
    this._connectionsSource = this._connectionsSource.sort((a, b) =>
      a.priority > b.priority
        ? 1
        : a.priority < b.priority
        ? -1
        : a.title > b.title
        ? 1
        : a.title < b.title
        ? -1
        : a.name > b.name // name is unique
        ? 1
        : -1
    );
  }

  private createConnection(connection: Connection) {
    this._saving$.next(true);
    this._savingError$.next('');

    this.connectionsApiService
      .createConnection(connection)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (newConnection) => {
          this._connectionsSource.push(newConnection);
          this.sortConnections();
          this._connections$.next(this._connectionsSource);

          this._saving$.next(false);
          this.router.navigate([this._connectionsAdminListUrl]);
        },
        error: (error) => {
          this._savingError$.next(
            getErrorMessage(error, 'Unable to create the connection.')
          );
          this._saving$.next(false);
        },
      });
  }

  private updateConnection(connectionName: string, connection: Connection) {
    this._saving$.next(true);
    this._savingError$.next('');

    this.connectionsApiService
      .updateConnection(connectionName, connection)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (updatedConnection) => {
          // replace the old connection with the updated one (identified by name)
          this._connectionsSource = this._connectionsSource.map(
            (oldConnection) =>
              oldConnection.name === connectionName
                ? updatedConnection
                : oldConnection
          );
          this.sortConnections();
          this._connections$.next(this._connectionsSource);

          this._saving$.next(false);
          this.router.navigate([this._connectionsAdminListUrl]);
        },
        error: (error) => {
          this._savingError$.next(
            getErrorMessage(error, 'Unable to update the connection.')
          );
          this._saving$.next(false);
        },
      });
  }
}
