import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserStateService } from 'src/app/services/user-state/user.state.service';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { UserEvent } from 'src/app/models/user/userEvent';

@Injectable({
  providedIn: 'root',
})
export class UserEventsService {
  // CONSTRUCTOR

  constructor(
    private userService: UserStateService,
    private sessionStorageService: SessionStorageService
  ) {}

  // PUBLIC API

  // returns the events that match the criteria
  getEventsLikeEvent(event: UserEvent): Observable<UserEvent[] | undefined> {
    return this.userService.user$.pipe(
      take(1),
      map((user) => {
        let resultEvents: UserEvent[] | undefined =
          this.filterEventsForCriteria(event, user?.events) || [];
        return resultEvents;
      })
    );
  }

  // returns the events that match the criteria that are in progress but not completed
  getEventsLikeEventInProgressOnly(
    event: UserEvent
  ): Observable<UserEvent[] | undefined> {
    return this.userService.user$.pipe(
      take(1),
      map((user) => {
        // get all events that match the criteria and are in progress
        let resultEvents: UserEvent[] | undefined =
          this.filterEventsForCriteria(
            {
              ...event,
              status: 'in-progress',
            },
            user?.events
          ) || [];

        // remove events where an event with the same connection, type and context has been completed
        // Start: comment out this method if you want to keep all in-progress events for testing
        resultEvents = resultEvents.filter((event) => {
          const completedEvent = this.filterEventsForCriteria(
            {
              type: event.type,
              context: event.context,
              connection: event.connection,
              status: 'completed',
            },
            user?.events
          );
          return completedEvent?.length === 0; // if there is no completed event, keep the in-progress event
        });
        // End: comment out this method if you want to keep all in-progress events for testing

        return resultEvents;
      })
    );
  }

  // returns the date string of the last event that matches the criteria, if the event has been completed
  checkEventsCompletion(
    eventsToCheck: UserEvent[] | any[]
  ): Observable<string | false> {
    return this.userService.user$.pipe(
      take(1),
      map((user) => {
        // all user events that meet the criteria
        let refEvents: UserEvent[] | undefined = [];

        // all user events
        let userEvents = user?.events;
        if (userEvents === undefined || userEvents.length === 0) {
          userEvents = this.sessionStorageService.getUserData()?.events;
        }

        // check if any of the eventsToCheck have been completed
        for (const eventToCheck of eventsToCheck) {
          refEvents = [
            ...refEvents,
            ...(this.filterEventsForCriteria(eventToCheck, userEvents) || []),
          ];
        }

        // handle case that eventToCheck has been completed and return formatted date string from the last event
        if (refEvents?.length) {
          // get the event with the latest timestamp
          const lastEvent = refEvents.reduce((prev, current) =>
            (prev.timestamp ?? 0) > (current.timestamp ?? 0) ? prev : current
          );

          if (lastEvent?.timestamp) {
            return this.formatDateString(lastEvent.timestamp);
          }
          return 'before';
        }
        return false;
      })
    );
  }

  // PRIVATE API

  private filterEventsForCriteria(
    eventCriteria: UserEvent,
    events?: UserEvent[]
  ): UserEvent[] | undefined {
    return events?.filter(
      (event) =>
        (event.type && eventCriteria.type
          ? event.type === eventCriteria.type
          : true) &&
        (event.timestamp && eventCriteria.timestamp
          ? event.timestamp === eventCriteria.timestamp
          : true) &&
        (event.context && eventCriteria.context
          ? event.context === eventCriteria.context
          : true) &&
        (event.status && eventCriteria.status
          ? event.status === eventCriteria.status
          : true) &&
        (event.connection && eventCriteria.connection
          ? event.connection === eventCriteria.connection
          : true)
    );
  }

  private formatDateString(timestamp: number): string {
    const eventDate = new Date(timestamp);
    const today = new Date();
    if (
      eventDate.getDate() === today.getDate() &&
      eventDate.getMonth() === today.getMonth() &&
      eventDate.getFullYear() === today.getFullYear()
    ) {
      return 'earlier today at ' + eventDate.toLocaleTimeString();
    } else {
      return 'on ' + eventDate.toLocaleDateString();
    }
  }
}
