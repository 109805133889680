export const environment = {
  environment: 'local',
  production: false,
  //apiBaseUrl: 'https://earlyaccess-api-dev.daysix.co',
  apiBaseUrl: 'https://localhost:7173',
  url: 'https://eiken.tail082aa.ts.net',
  gtagId: 'G-BH67J80MQ3',
  functionUrl: 'https://d6med-platform-auth-function.azurewebsites.net',
  oidc: {
    mydex: {
      redirectUrl: 'https://eiken.tail082aa.ts.net/mydex-login',
      postLogoutRedirectUri: 'https://eiken.tail082aa.ts.net',
      clientId: '0d2edbc10a7461d04e6a57ffc306e7551e2ac99530d7874d',
      authority: 'https://sbx-op.mydexid.org',
    },
    scotaccount: {
      redirectUrl: 'https://eiken.tail082aa.ts.net',
      authority: 'https://authz.integration.scotaccount.service.gov.scot',
      postLogoutRedirectUri: 'https://eiken.tail082aa.ts.net',
    },
  },
  enableRegistration: false,
};
