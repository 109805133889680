import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { computeCompletedMultiplePdsSectionsItemsPercentage } from 'src/app/helper/formCompleteness';
import { Connection } from 'src/app/models/connection/connection';
import { OutputPds } from 'src/app/models/connection/connectionOutput';
import { FormItem } from 'src/app/models/form/form';
import { PdsSection } from 'src/app/models/pds/pdsSection';
import { User } from 'src/app/models/user/user';
import { UserEvent } from 'src/app/models/user/userEvent';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConnectionsStateService } from 'src/app/services/connections-state/connections.state.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { PdsReferralApiService } from 'src/app/services/pds-referral-api/pds.referral.api.service';
import { ScrollService } from 'src/app/services/scroll/scroll.service';
import { UserEventsService } from 'src/app/services/user-event/user.event.service';
import { UserStateService } from 'src/app/services/user-state/user.state.service';
import {
  pdsReferralPrimaryInformationSections,
  pdsReferralSecondaryInformationSections,
} from 'src/assets/pds-referrals/pds-referral-sections';
@Component({
  selector: 'app-connection-pds-referral',
  templateUrl: './connection-pds-referral.component.html',
  styleUrl: './connection-pds-referral.component.css',
})
export class ConnectionPdsReferralComponent {
  // PROPERTIES

  protected connection?: Connection;
  protected connectionOutput?: OutputPds;
  protected user: User | undefined;

  protected primaryInformationSections = pdsReferralPrimaryInformationSections;
  protected secondaryInformationSections =
    pdsReferralSecondaryInformationSections;

  protected primaryInformationOpenState: boolean = false;
  protected secondaryInformationOpenState: boolean = false;

  protected primaryInformationPercentageCalculated: number = 0;
  protected secondaryInformationPercentageCalculated: number = 0;

  protected agreementDataProcessing: boolean = false;
  protected requiredFieldsError: string | undefined = '';

  protected currentSelectedSection: PdsSection | undefined;
  protected step: 'overview' | 'form' | 'complete' = 'overview';

  protected form: FormItem<any>[] = [];

  private destroy$: Subject<void> = new Subject<void>();

  // CONSTRUCTOR

  constructor(
    protected userService: UserStateService,
    protected connectionsStateService: ConnectionsStateService,
    protected navigationService: NavigationService,
    protected authService: AuthService,
    protected pdsReferralService: PdsReferralApiService,
    private route: ActivatedRoute,
    private router: Router,
    private eventService: UserEventsService,
    private analyticsService: AnalyticsService,
    private scrollService: ScrollService
  ) {}

  // LIFECYCLE HOOKS

  ngOnInit() {
    this.connectionsStateService.connections$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        let connectionName = this.route.snapshot.paramMap.get('slug') || '';
        this.connection =
          this.connectionsStateService.getConnectionByName(connectionName);
        this.connectionOutput = this.connection?.pdsOutput;

        this.addPdsReferralEventInProgessIfNeeded();
      });

    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        this.user = user;
        this.updateCompletionPercentages();
        this.updateForm();

        this.addPdsReferralEventInProgessIfNeeded();
      },
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.userService.resetErrors();
    this.connectionsStateService.resetErrors();
    this.pdsReferralService.resetError();
  }

  // PUBLIC API

  handleBack() {
    // if we are in the form step, go back to the overview step
    if (this.step === 'form') {
      this.handleFormCancel();
      return;
    }

    // otherwise, go back to the connection page
    const currentUrl = this.router.url;
    const baseRoute = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
    this.router.navigateByUrl(baseRoute);
  }

  handleSectionSelected(section: PdsSection) {
    this.currentSelectedSection = section;
    this.updateForm();
    this.step = 'form';
    this.scrollService.requestScrollToTop();
  }

  handleFormCancel() {
    this.step = 'overview';
    this.scrollService.requestScrollToTop();
    this.currentSelectedSection = undefined;
    this.updateForm();
  }

  handleSubmit() {
    if (!this.connection) {
      return;
    }

    // If the user has not agreed to the data processing, show an error and do not proceed
    if (!this.agreementDataProcessing) {
      this.requiredFieldsError =
        'Please ensure that you consent to the processing of the entered details.';
      return;
    } else {
      this.requiredFieldsError = undefined;
    }

    this.pdsReferralService
      .send(this.connection)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.addPdsReferralEvent('completed');
          this.analyticsService.trackPdsReferralCompleted(
            this.connection?.name ?? 'connection-name-unknown'
          );
          this.step = 'complete';
          this.scrollService.requestScrollToTop();
        },
      });
  }

  // PRIVATE API

  private updateCompletionPercentages() {
    this.primaryInformationPercentageCalculated =
      computeCompletedMultiplePdsSectionsItemsPercentage(
        this.user,
        this.primaryInformationSections
      );

    this.secondaryInformationPercentageCalculated =
      computeCompletedMultiplePdsSectionsItemsPercentage(
        this.user,
        this.secondaryInformationSections
      );
  }

  private addPdsReferralEventInProgessIfNeeded() {
    if (!this.user || !this.connection) {
      return;
    }

    // check if the user has started the referral before without completing it
    this.eventService
      .getEventsLikeEvent({
        type: 'pds-referral',
        connection: this.connection?.name,
        status: 'in-progress',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        // in case there is an in-progress PDS referral event, do nothing, otherwise add a new event
        if (!result || result.length === 0) {
          this.addPdsReferralEvent('in-progress');
        }
      });
  }

  private addPdsReferralEvent(status: 'in-progress' | 'completed') {
    const userEvent = new UserEvent({
      type: 'pds-referral',
      connection: this.connection?.name,
      status: status,
    });

    this.userService.logEvent(userEvent);
  }

  private updateForm() {
    if (!this.user || !this.currentSelectedSection) {
      this.form = [];
      return;
    }

    // Common logic extracted into a helper function
    const processFormItems = (items: FormItem<any>[]) =>
      items.map((item) => {
        item.validators = item.validators.filter(
          ({ name }) => name !== 'required'
        );
        return item;
      });

    this.form = processFormItems(
      this.currentSelectedSection?.getFormItems?.() ?? []
    );
  }
}
