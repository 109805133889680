<div class="navbar z-100">
  <a routerLink="/">
    <img
      alt="Community Connections Moray Logo"
      src="/assets/img/ea-logo.svg"
      class="nav-logo"
  /></a>

  <ul
    class="nav-links hidden lg:flex"
    *ngIf="(authService.isAuthenticated$ | async) === false || layout == 'home'"
  >
    <li><a routerLink="/finder">Find Services</a></li>
    <li><a routerLink="/search">Search Services</a></li>
    <li><a routerLink="/carer">Unpaid Carers</a></li>
    <li>
      <a
        class="btn-theme px-8 py-4 text-base btn"
        [routerLink]="
          (authService.isAuthenticated$ | async)
            ? '/account/about-me'
            : '/login'
        "
      >
        About Me
      </a>
    </li>
  </ul>

  <ul
    class="nav-links hidden lg:flex"
    *ngIf="(authService.isAuthenticated$ | async) && layout != 'home'"
  >
    &nbsp;
    <div class="flex">
      <span class="block my-auto opacity-50 mr-8" *ngIf="layout != 'admin'">
        {{
          user?.me?.personalDetails?.firstName
            ? user.me.personalDetails.firstName + "'s About Me"
            : user?.me?.personalDetails?.email ?? "About Me"
        }}
      </span>
      <span class="block my-auto opacity-50 mr-8" *ngIf="layout == 'admin'">
        Admin Area
      </span>
      <button
        class="btn-theme-outline px-6 py-2 my-auto text-theme flex"
        (click)="logout()"
      >
        <div class="flex" *ngIf="!logoutLoading">
          <span class="block mb-[3px] mr-2 font-medium"
            >Log{{ logoutLoading ? "ging" : "" }} Out</span
          >
          <img class="my-auto" src="/assets/img/icons/signout.svg" alt="" />
        </div>
        <app-loading-spinner
          *ngIf="logoutLoading"
          class="m-auto"
          text=""
        ></app-loading-spinner>
      </button>
    </div>
  </ul>

  <div
    class="fs-nav fixed flex left-0 right-0 top-0 bottom-0 bg-opacity-20 z-10"
    [ngClass]="{ hidden: !navActive }"
  >
    <div class="bg-dark bg-opacity-20 flex-1" (click)="closeNav()"></div>
    <div
      class="h-full bg-white fixed right-0 flex flex-col w-full sm:max-w-md px-8 xs:px-20 box-border overflow-y-auto"
    >
      <ul class="menu-links">
        <!-- could move to display when authed rather then on certain pages: -->
        <div *ngIf="type == 'sidebar'" class="sidebar-links">
          <app-sidebar-links></app-sidebar-links>
          <hr class="mb-6 border-solid border-0 border-b opacity-10" />
        </div>

        <li><a routerLink="/finder">Find Services</a></li>
        <li><a routerLink="/search">Search Services</a></li>
        <li><a routerLink="/carer">Unpaid Carers</a></li>
        <li class="mt-16">
          <a routerLink="/login"
            ><button
              class="btn-theme px-8 py-4 text-lg"
              *ngIf="
                (authService.isAuthenticated$ | async) === false &&
                layout == 'home'
              "
            >
              About Me
            </button></a
          >
          <button
            class="btn-theme-outline px-10 text-lg py-3 my-auto text-theme flex"
            *ngIf="(authService.isAuthenticated$ | async) && layout != 'home'"
            (click)="logout()"
          >
            <div class="flex" *ngIf="!logoutLoading">
              <span class="block mb-[3px] mr-2 font-medium"
                >Log{{ logoutLoading ? "ging" : "" }} Out</span
              >
              <img class="my-auto" src="/assets/img/icons/signout.svg" alt="" />
            </div>
            <app-loading-spinner
              *ngIf="logoutLoading"
              class="m-auto"
              text=""
            ></app-loading-spinner>
          </button>
        </li>
      </ul>
    </div>
  </div>

  <div class="relative right-0 block lg:hidden">
    <button
      class="flex p-0 btn-inline h-10 lg:hidden absolute right-0 z-50 menu-trigger"
      (click)="toggleNav()"
      [ngClass]="{ active: navActive }"
    >
      <div
        class="font-semibold text-base text-theme transition-opacity my-auto mr-3"
      >
        {{ navActive ? "Close" : "Menu" }}
      </div>
      <button class="h-10 w-10 p-[7px] btn-theme-outline flex">
        <div class="hb w-full my-auto">
          <div class="h-[3px] bg-theme w-full mb-[4px] rounded-full line"></div>
          <div class="h-[3px] bg-theme w-full mb-[4px] rounded-full line"></div>
          <div class="h-[3px] bg-theme w-full rounded-full line"></div>
        </div>
      </button>
    </button>
  </div>

  <!-- <div class="spacer lg-hidden "></div> -->
</div>
