<div class="flex-1 max-h-screen overflow-hidden">
  <img
    src="/assets/img/auth-bg.jpg"
    alt=""
    class="w-full h-full object-cover object-center"
  />
  <h2
    class="text-4xl text-white absolute bottom-0 left-0 p-16 m-0 max-w-[200px] hidden lg:block"
  >
    Start your story now
  </h2>
</div>

<div class="h-full ml-auto bg-white lg:max-w-2xl w-full flex flex-col">
  <div class="flex-1 h-full flex w-full px-6 sm:px-12 md:px-24 box-border">
    <div class="my-auto w-full box-border">
      <h1 class="text-4xl font-semibold text-dark mb-2">Sign In</h1>

      <ng-container
        *ngIf="
          (!(authService.isMydexAuthenticated$ | async) || false) &&
          (!(authService.isScotAuthenticated$ | async) || false) &&
          (!(authService.requiresMapping$ | async) || false)
        "
      >
        <p class="mb-4 text-grey">
          Login to Community Connections using Mydex below.
        </p>
        <button
          class="btn-theme font-semibold w-full mb-4"
          (click)="mydexLogin()"
        >
          <div
            *ngIf="loading.mydexLogin"
            class="flex justify-center align-center"
          >
            <app-loading-spinner
              class="font-medium opacity-90"
              text="Redirecting"
            ></app-loading-spinner>
          </div>
          <ng-container *ngIf="!loading.mydexLogin">
            <span class="inline-block align-middle mb-1 mr-2">
              Sign in with
            </span>
            <img
              src="/assets/img/mydex-white.png"
              alt="mydex"
              class="h-5 align-middle"
            />
          </ng-container>
        </button>
      </ng-container>

      <ng-container
        *ngIf="
          enableScotAccountAccess &&
          (!(authService.isMydexAuthenticated$ | async) || false) &&
          (!(authService.isScotAuthenticated$ | async) || false) &&
          (!(authService.requiresMapping$ | async) || false)
        "
      >
        <p class="mb-4 text-grey">
          Login or sign up to Community Connections using ScotAccount below.
        </p>
        <button
          class="btn-theme font-semibold w-full"
          (click)="scotAccountLogin()"
        >
          <div
            *ngIf="loading.scotAccountLogin"
            class="flex justify-center align-center"
          >
            <app-loading-spinner
              class="font-medium opacity-90"
              text="Redirecting"
            ></app-loading-spinner>
          </div>
          <ng-container *ngIf="!loading.scotAccountLogin">
            <span class="inline-block align-middle mb-1 mr-2">
              Sign in with <strong>ScotAccount</strong>
            </span>
          </ng-container>
        </button>
      </ng-container>

      <ng-container *ngIf="(authService.requiresMapping$ | async) || false">
        <p class="mb-10 text-grey">
          You have successfully signed into ScotAccount you now need to link a
          Mydex account to your ScotAccount login. To do this you need to either
          sign in with Mydex or create a Mydex account
        </p>
        <button class="btn-theme font-semibold w-full" (click)="mydexLogin()">
          <div
            *ngIf="loading.mydexLogin"
            class="flex justify-center align-center"
          >
            <app-loading-spinner
              class="font-medium opacity-90"
              text="Redirecting"
            ></app-loading-spinner>
          </div>
          <ng-container *ngIf="!loading.mydexLogin">
            <span class="inline-block align-middle mb-1 mr-2">
              Sign in with
            </span>
            <img
              src="/assets/img/mydex-white.png"
              alt="mydex"
              class="h-5 align-middle"
            />
          </ng-container>
        </button>

        <div class="mb-10"></div>

        <button
          class="btn-theme font-semibold w-full"
          (click)="mydexRegister()"
        >
          <div
            *ngIf="loading.mydexRegister"
            class="flex justify-center align-center"
          >
            <app-loading-spinner
              class="font-medium opacity-90"
              text="Redirecting"
            ></app-loading-spinner>
          </div>
          <ng-container *ngIf="!loading.mydexRegister">
            <span class="inline-block align-middle mb-1 mr-2">
              Register with
            </span>
            <img
              src="/assets/img/mydex-white.png"
              alt="mydex"
              class="h-5 align-middle"
            />
          </ng-container>
        </button>
      </ng-container>

      <p class="max-w-sm text-grey mb-16 mt-8">
        By signing in, you agree that you consent to our
        <a routerLink="/privacy-policy" target="_blank">Privacy Policy</a>.
      </p>
      <a
        class="text-theme text-left mt-[10vh] block"
        routerLink="/register/intro"
        >What are <strong>About Me</strong> & Mydex?</a
      >
    </div>
  </div>

  <div class="auth-footer lg:max-w-2xl ml-auto w-full h-20 flex justify-center">
    <p
      class="text-center"
      *ngIf="!(authService.requiresMapping$ | async) || false"
    >
      Don't have an account? <br />
      <a
        class="text-theme cursor-pointer"
        [routerLink]="enableScotAccountAccess ? '/register' : null"
        (click)="enableScotAccountAccess || modal.open()"
        [queryParams]="{ from: registerFrom }"
        >Register with Mydex</a
      >
    </p>
    <p
      class="text-center ml-4"
      *ngIf="(authService.requiresMapping$ | async) || false"
    >
      Don't want to register? <br />
      <button class="text-theme cursor-pointer p-0" (click)="logout()">
        Logout
      </button>
    </p>
  </div>
</div>

<app-modal>
  <h1 class="text-3xl font-semibold mb-6">
    Registration is currently disabled
  </h1>
  <p>
    We are currently not accepting new registrations but you can still sign in
    if you already have a Mydex account.
  </p>
  <div class="flex mt-24">
    <button class="btn btn-theme px-8 mr-4 outlined" (click)="modal.close()">
      Close
    </button>
  </div>
</app-modal>
