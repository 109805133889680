export interface PdsSection {
  title: string;
  name: string; // used as Id
  description: string;
  longDescription?: string;
  routePath: string;
  getFormItems?: Function;
  getFormItemsNegligibleForCompleteness?: Function; // should include all form items that are not required for completeness (subset of getFormItems)
  userPath?: string;
  serverMamPaths?: string[];
  children?: PdsSection[];
}

export function flattenPdsSections(sections: PdsSection[]): PdsSection[] {
  const flattenedSections: PdsSection[] = [];

  function flatten(section: PdsSection) {
    flattenedSections.push(section);
    if (section.children && section.children.length > 0) {
      section.children.forEach((child) => flatten(child));
    }
  }

  sections.forEach((section) => flatten(section));

  return flattenedSections;
}
