import { PdsSection } from 'src/app/models/pds/pdsSection';
import {
  getFormItemsUserSupportingRoleCaringRole,
  getFormItemsUserSupportingRoleCaringRoleNegligibleForCompleteness,
  getFormItemsUserSupportingRoleEmotionalWellbeing,
  getFormItemsUserSupportingRoleEmotionalWellbeingNegligibleForCompleteness,
  getFormItemsUserSupportingRoleEmploymentImpact,
  getFormItemsUserSupportingRoleEmploymentImpactNegligibleForCompleteness,
  getFormItemsUserSupportingRoleFinancialImpact,
  getFormItemsUserSupportingRoleFuturePlansImpact,
  getFormItemsUserSupportingRoleHealthImpact,
  getFormItemsUserSupportingRoleHealthImpactNegligibleForCompleteness,
  getFormItemsUserSupportingRoleHomeImpact,
  getFormItemsUserSupportingRoleHomeImpactNegligibleForCompleteness,
  getFormItemsUserSupportingRoleLifeBalance,
  getFormItemsUserSupportingRoleOtherLifeAspects,
  getFormItemsUserSupportingRoleOtherLifeAspectsNegligibleForCompleteness,
  getFormItemsUserSupportingRolePersonalDetails,
  getFormItemsUserSupportingRolePersonalDetailsNegligibleForCompleteness,
  getFormItemsUserSupportingRoleSkillsAndExperience,
  getFormItemsUserSupportingRoleSupportedPeople,
  getFormItemsUserSupportingRoleWhatMatters,
} from '../../forms/user/supporting-role-items/form-supporting-role';
import { ServerMamPaths } from '../serverMamPaths';

export const supportingRoleSection: PdsSection = {
  // plural wording for this section is intentional (section name in code being singular is legacy)
  title: 'Supporting Roles',
  name: 'supporting-roles',
  description: 'Your roles as a carer',
  longDescription:
    'In this section, you can tell us about your roles as carer. Click on an area to get started.',
  routePath: 'supporting-role',
  userPath: 'supportingRole',
  children: [
    {
      title: 'Personal Details',
      name: 'supporting-role-personal-details',
      description: 'General information about yourself',
      routePath: 'personal-details',
      userPath: 'personalDetails',
      serverMamPaths: [
        ServerMamPaths.ThisIsMe.PersonalDetails,
        ServerMamPaths.ThisIsMe.ContactDetails,
        ServerMamPaths.SupportingRole.PersonalDetails,
      ],
      getFormItems: getFormItemsUserSupportingRolePersonalDetails,
      getFormItemsNegligibleForCompleteness:
        getFormItemsUserSupportingRolePersonalDetailsNegligibleForCompleteness,
    },
    {
      title: 'The people I support',
      name: 'supporting-role-supported-people',
      description: 'Details about the people you care for',
      routePath: 'supported-people',
      userPath: 'supportedPeople',
      serverMamPaths: [
        ServerMamPaths.ThisIsMe.Current,
        ServerMamPaths.SupportingRole.SupportedPeople,
      ],
      getFormItems: getFormItemsUserSupportingRoleSupportedPeople,
    },
    {
      title: 'My Caring Role',
      name: 'supporting-role-caring-role',
      description: 'Your role as carer',
      routePath: 'caring-role',
      userPath: 'caringRole',
      serverMamPaths: [ServerMamPaths.SupportingRole.CaringRole],
      getFormItems: getFormItemsUserSupportingRoleCaringRole,
      getFormItemsNegligibleForCompleteness:
        getFormItemsUserSupportingRoleCaringRoleNegligibleForCompleteness,
    },
    {
      title: 'Impact on my Health',
      name: 'supporting-role-health-impact',
      description: 'How caring affects your health',
      longDescription:
        'Your health is important, for you and the person you care for. Staying well means having regular checkups, eating well, taking regular exercise, not drinking too much alcohol, avoiding smoking and making sure you get enough sleep.',
      routePath: 'health-impact',
      userPath: 'healthImpact',
      serverMamPaths: [ServerMamPaths.SupportingRole.HealthImpact],
      getFormItems: getFormItemsUserSupportingRoleHealthImpact,
      getFormItemsNegligibleForCompleteness:
        getFormItemsUserSupportingRoleHealthImpactNegligibleForCompleteness,
    },
    {
      title: 'My Emotional Wellbeing',
      name: 'supporting-role-emotional-wellbeing',
      description: 'Tell us about your emotional wellbeing',
      longDescription:
        'Your emotional wellbeing contributes to how you manage sterss or anxiety arising your caring role.',
      routePath: 'emotional-wellbeing',
      userPath: 'emotionalWellbeing',
      serverMamPaths: [ServerMamPaths.SupportingRole.EmotionalWellbeing],
      getFormItems: getFormItemsUserSupportingRoleEmotionalWellbeing,
      getFormItemsNegligibleForCompleteness:
        getFormItemsUserSupportingRoleEmotionalWellbeingNegligibleForCompleteness,
    },
    {
      title: 'Impact on my Finances',
      name: 'supporting-role-financial-impact',
      description: 'How caring affects your financial situation',
      longDescription:
        'Thinking about the financial situation of being a carer, including maximising your household income, managing with the money you have and any financial matters related to caring for someone.',
      routePath: 'financial-impact',
      userPath: 'financialImpact',
      serverMamPaths: [ServerMamPaths.SupportingRole.FinancialImpact],
      getFormItems: getFormItemsUserSupportingRoleFinancialImpact,
    },
    {
      title: 'My Life Balance',
      name: 'supporting-role-life-balance',
      description: 'The impact on your life balance',
      longDescription:
        'Having time for yourself and regular breaks, rather than being completely defined by your caring role, helps you look after yourself. How you do this, and how much time you spend with other people or on other activities, depends on your own needs and wishes.',
      routePath: 'life-balance',
      userPath: 'lifeBalance',
      serverMamPaths: [ServerMamPaths.SupportingRole.LifeBalance],
      getFormItems: getFormItemsUserSupportingRoleLifeBalance,
    },
    {
      title: 'Impact on Future Plans',
      name: 'supporting-role-future-plans-impact',
      description: 'How caring affects the plans you have for the future',
      longDescription:
        'Thinking ahead can help give you confidence in your role. By being prepared for emergencies, and recognising any changes you may face, you can put plans in place to help you and the person you care for highlight any support needed.',
      routePath: 'future-plans-impact',
      userPath: 'futurePlansImpact',
      serverMamPaths: [ServerMamPaths.SupportingRole.FuturePlansImpact],
      getFormItems: getFormItemsUserSupportingRoleFuturePlansImpact,
    },
    {
      title: 'Impact on Employment',
      name: 'supporting-role-employment-impact',
      description: 'The impact of caring on your employment',
      routePath: 'employment-impact',
      userPath: 'employmentImpact',
      serverMamPaths: [ServerMamPaths.SupportingRole.EmploymentImpact],
      getFormItems: getFormItemsUserSupportingRoleEmploymentImpact,
      getFormItemsNegligibleForCompleteness:
        getFormItemsUserSupportingRoleEmploymentImpactNegligibleForCompleteness,
    },
    {
      title: 'Impact on Home Life',
      name: 'supporting-role-home-impact',
      description: 'How caring affects your home life',
      longDescription:
        'The person you care for may need adaptations or aids at home to help them manage, and you need to know how to use them safely. Time spent on caring may impact on time available for daily tasks. You may find your use of space at home changes, to accommodate the needs of the person you care for.',
      routePath: 'home-impact',
      userPath: 'homeImpact',
      serverMamPaths: [ServerMamPaths.SupportingRole.HomeImpact],
      getFormItems: getFormItemsUserSupportingRoleHomeImpact,
      getFormItemsNegligibleForCompleteness:
        getFormItemsUserSupportingRoleHomeImpactNegligibleForCompleteness,
    },
    {
      title: 'Other Aspects of Life',
      name: 'supporting-role-other-life-aspects',
      description: 'The impact of caring on other aspects of your life',
      longDescription:
        'Caring can impact on the relationship you have with those around you: the person you care for, other family members and friends. You may find it harder to find time to spend with friends and family, which can make you feel distanced from them and unable to relate to them in the way you did previously. Maintaining relationships is important, and can help you feel more supported in your role, and to feel recognised as an individual.',
      routePath: 'life-aspects',
      userPath: 'otherLifeAspects',
      serverMamPaths: [ServerMamPaths.SupportingRole.OtherLifeAspects],
      getFormItems: getFormItemsUserSupportingRoleOtherLifeAspects,
      getFormItemsNegligibleForCompleteness:
        getFormItemsUserSupportingRoleOtherLifeAspectsNegligibleForCompleteness,
    },
    {
      title: 'Skills and Experience',
      name: 'supporting-role-skills-and-experience',
      description: 'The skills and experience you have gained as a carer',
      longDescription:
        'As a support giver you will be developing valuable skills and experience, would you like to record what you have learned here. This could just be your own reflections or specific courses you have taken.',
      routePath: 'skills-experience',
      userPath: 'skillsAndExperience',
      serverMamPaths: [ServerMamPaths.SupportingRole.SkillsAndExperience],
      getFormItems: getFormItemsUserSupportingRoleSkillsAndExperience,
    },
    {
      title: 'What Matters',
      name: 'supporting-role-what-matters',
      description: 'Share more about what is important to you as a carer',
      routePath: 'what-matters',
      userPath: 'whatMatters',
      serverMamPaths: [ServerMamPaths.SupportingRole.WhatMatters],
      getFormItems: getFormItemsUserSupportingRoleWhatMatters,
    },
  ],
};
