import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { UserMedicationPdsEntry } from 'src/app/models/medication/userMedicationPdsEntry';
import { UserMedicationsApiService } from '../user-medications-api/user.medications.api.service';
import { getErrorMessage } from 'src/app/helper/getErrorMessage';
import { UserMedication } from 'src/app/models/medication/userMedication';
import { UserMedicationUpdateRequest } from 'src/app/models/medication/server/userMedicationUpdateRequest';

@Injectable({
  providedIn: 'root',
})
export class UserMedicationsStateService implements OnDestroy {
  // PROPERTIES

  private _userMedicationsWithBasics$: BehaviorSubject<
    UserMedicationPdsEntry[]
  > = new BehaviorSubject<UserMedicationPdsEntry[]>([]);

  private _loadingMedicationsWithBasics$ = new BehaviorSubject<boolean>(false);
  private _loadingMedicationsWithBasicsError$ = new BehaviorSubject<string>('');

  private _loadingMedicationDetails$ = new BehaviorSubject<boolean>(false);
  private _loadingMedicationDetailsError$ = new BehaviorSubject<string>('');
  private _loadingMedicationDetailsSuccess$ =
    new Subject<UserMedicationPdsEntry>();

  private _addingMedication$ = new BehaviorSubject<boolean>(false);
  private _addingMedicationError$ = new BehaviorSubject<string>('');
  private _addingMedicationSuccess$ = new Subject<void>();

  private _updatingMedication$ = new BehaviorSubject<boolean>(false);
  private _updatingMedicationError$ = new BehaviorSubject<string>('');
  private _updatingMedicationSuccess$ = new Subject<void>();

  private _deletingMedication$ = new BehaviorSubject<boolean>(false);
  private _deletingMedicationError$ = new BehaviorSubject<string>('');
  private _deletingMedicationSuccess$ = new Subject<string>();

  private _destroy$: Subject<void> = new Subject<void>();

  // CONSTRUCTOR

  constructor(private userMedicationsApiService: UserMedicationsApiService) {
    this.fetchUserMedicationsIfNeeded();
  }

  // PUBLIC GETTERS

  // only includes medication id, name, and url
  get userMedicationsBasics$(): Observable<UserMedicationPdsEntry[]> {
    this.fetchUserMedicationsIfNeeded();
    return this._userMedicationsWithBasics$.asObservable();
  }

  get loadingMedicationsWithBasics$(): Observable<boolean> {
    return this._loadingMedicationsWithBasics$.asObservable();
  }

  get loadingMedicationsWithBasicsError$(): Observable<string> {
    return this._loadingMedicationsWithBasicsError$.asObservable();
  }

  get loadingMedicationDetails$(): Observable<boolean> {
    return this._loadingMedicationDetails$.asObservable();
  }

  get loadingMedicationDetailsError$(): Observable<string> {
    return this._loadingMedicationDetailsError$.asObservable();
  }

  // includes all medication details
  get loadingMedicationDetailsSuccess$(): Observable<UserMedicationPdsEntry> {
    return this._loadingMedicationDetailsSuccess$.asObservable();
  }

  get addingMedication$(): Observable<boolean> {
    return this._addingMedication$.asObservable();
  }

  get addingMedicationError$(): Observable<string> {
    return this._addingMedicationError$.asObservable();
  }

  get addingMedicationSuccess$(): Observable<void> {
    return this._addingMedicationSuccess$.asObservable();
  }

  get updatingMedication$(): Observable<boolean> {
    return this._updatingMedication$.asObservable();
  }

  get updatingMedicationError$(): Observable<string> {
    return this._updatingMedicationError$.asObservable();
  }

  get updatingMedicationSuccess$(): Observable<void> {
    return this._updatingMedicationSuccess$.asObservable();
  }

  get deletingMedication$(): Observable<boolean> {
    return this._deletingMedication$.asObservable();
  }

  get deletingMedicationError$(): Observable<string> {
    return this._deletingMedicationError$.asObservable();
  }

  get deletingMedicationSuccess$(): Observable<string> {
    return this._deletingMedicationSuccess$.asObservable();
  }

  // LIFECYCLE HOOKS

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  // PUBLIC API

  getMedicationDetails(medicationId: string) {
    this._loadingMedicationDetails$.next(true);
    this._loadingMedicationDetailsError$.next('');

    this.userMedicationsApiService
      .getUserMedicationDetailsById(medicationId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (userMedication) => {
          this._loadingMedicationDetailsSuccess$.next(userMedication);
          this._loadingMedicationDetails$.next(false);
        },
        error: (error) => {
          this._loadingMedicationDetailsError$.next(
            getErrorMessage(error, 'Unable to fetch the medication details.')
          );
          this._loadingMedicationDetails$.next(false);
        },
      });
  }

  addUserMedication(userMedication: UserMedication) {
    this._addingMedication$.next(true);
    this._addingMedicationError$.next('');

    this.userMedicationsApiService
      .addUserMedication(userMedication)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (!response.newMedicationId) {
            // server should always return the new medication id in case of success
            this._addingMedicationError$.next(
              'Unexpected response from the server.'
            );
            this._addingMedication$.next(false);
            return;
          }

          // update all medications to include the new one
          let newMedication = new UserMedicationPdsEntry(userMedication);
          newMedication.medicationId = response.newMedicationId;

          var allUserMedications = this._userMedicationsWithBasics$.getValue();
          allUserMedications.push(newMedication);
          allUserMedications =
            this.sortUserMedicationsWithBasics(allUserMedications);

          this._userMedicationsWithBasics$.next(allUserMedications);

          this._addingMedicationSuccess$.next();
          this._addingMedication$.next(false);
        },
        error: (error) => {
          this._addingMedicationError$.next(
            getErrorMessage(error, 'Unable to add the medication.')
          );
          this._addingMedication$.next(false);
        },
      });
  }

  updateUserMedication(updatedUserMedication: UserMedicationPdsEntry) {
    this._updatingMedication$.next(true);
    this._updatingMedicationError$.next('');

    const userMedicationUpdateRequest =
      UserMedicationUpdateRequest.fromUserMedicationPdsEntry(
        updatedUserMedication
      );

    this.userMedicationsApiService
      .updateUserMedication(userMedicationUpdateRequest)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          // Replace the old medication with the updated one (identified by id) in the source
          var allUserMedications = this._userMedicationsWithBasics$.getValue();
          allUserMedications = allUserMedications.map((oldUserMedication) =>
            oldUserMedication.medicationId ===
            updatedUserMedication.medicationId
              ? updatedUserMedication
              : oldUserMedication
          );

          allUserMedications =
            this.sortUserMedicationsWithBasics(allUserMedications);
          this._userMedicationsWithBasics$.next(allUserMedications);

          this._updatingMedicationSuccess$.next();
          this._updatingMedication$.next(false);
        },
        error: (error) => {
          this._updatingMedicationError$.next(
            getErrorMessage(error, 'Unable to update the medication details.')
          );
          this._updatingMedication$.next(false);
        },
      });
  }

  deleteUserMedication(userMedicationToBeDeleted: UserMedicationPdsEntry) {
    this._deletingMedication$.next(true);
    this._deletingMedicationError$.next('');

    if (!userMedicationToBeDeleted.medicationId) {
      this._deletingMedicationError$.next(
        'Invalid medication id. Unable to delete.'
      );
      this._deletingMedication$.next(false);
      return;
    }

    this.userMedicationsApiService
      .deleteUserMedication(userMedicationToBeDeleted.medicationId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          // Remove the medication from the source
          var allUserMedications = this._userMedicationsWithBasics$.getValue();

          allUserMedications = allUserMedications.filter(
            (medication) =>
              medication.medicationId !== userMedicationToBeDeleted.medicationId
          );
          allUserMedications =
            this.sortUserMedicationsWithBasics(allUserMedications);
          this._userMedicationsWithBasics$.next(allUserMedications);

          this._deletingMedicationSuccess$.next(
            userMedicationToBeDeleted.medicationId
          );
          this._deletingMedication$.next(false);
        },
        error: (error) => {
          this._deletingMedicationError$.next(
            getErrorMessage(error, 'Unable to delete the medication.')
          );
          this._deletingMedication$.next(false);
        },
      });
  }

  // call in ngOnDestroy of subscribing components to reset the errors (do not reset loading errors)
  resetErrors() {
    this._addingMedicationError$.next('');
    this._updatingMedicationError$.next('');
    this._deletingMedicationError$.next('');
  }

  // PRIVATE API

  private fetchUserMedicationsIfNeeded() {
    // if the user medications have already been fetched or are being fetched, do nothing
    if (
      this._userMedicationsWithBasics$.getValue().length !== 0 ||
      this._loadingMedicationsWithBasics$.getValue() === true
    ) {
      return;
    }

    this._loadingMedicationsWithBasics$.next(true);
    this._loadingMedicationsWithBasicsError$.next('');

    this.userMedicationsApiService
      .getAllUserMedicationsWithBasics()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (userMedications) => {
          userMedications = this.sortUserMedicationsWithBasics(userMedications);

          this._userMedicationsWithBasics$.next(userMedications);
          this._loadingMedicationsWithBasics$.next(false);
        },
        error: (error) => {
          this._loadingMedicationsWithBasicsError$.next(
            getErrorMessage(
              error,
              'Unable to fetch medications from the server.'
            )
          );
          this._loadingMedicationsWithBasics$.next(false);
        },
      });
  }

  private sortUserMedicationsWithBasics(
    userMedications: UserMedicationPdsEntry[]
  ): UserMedicationPdsEntry[] {
    return userMedications.sort(
      (a, b) =>
        parseInt(a?.medicationId ?? '0', 10) ||
        0 - (parseInt(b?.medicationId ?? '0', 10) || 0)
    );
  }
}
