import {
  alsoKnownAs,
  dateOfBirth,
  ethnicity,
  firstName,
  gender,
  genderAtBirth,
  lastName,
  maidenName,
  maritalStatus,
  middleName,
  preferredName,
  picture,
  pronouns,
} from './personal-details-items';
import {
  email,
  phoneHome,
  phoneMobile,
  propertyType,
  tenancyType,
} from './contact-details-items';
import {
  currentAdditionalInfo,
  currentLifeSituation,
  currentPhotosTellingPersonalStory,
  currentPhotosTellingPersonalStoryForReminiscenceUseage,
  friendsAndFamily,
  interests,
  livingWith,
  supportedBy,
  supporting,
} from './current-items';
import {
  badDayDescription,
  challengingCharacteristics,
  goalsAdditionalInfo,
  goalsAreas,
  goalsFuture,
  goalsRevival,
  goalsTitle,
  goodDayDescription,
  personalQualitiesLikes,
  positiveCharacteristics,
} from './personal-qualities-items';
import { importantRelationships, mainPeopleInLife } from './my-people-items';
import {
  pastimesInterestsNow,
  pastimesInterestsNowAdditionalInfo,
  pastimesInterestsPast,
  pastimesInterestsPastAdditionalInfo,
} from './pastimes-items';
import {
  homeAdditionalInfo,
  homeArea,
  homeSuitability,
  neighbourhood,
} from './home-items';
import {
  breakfastTime,
  regularOutings,
  sleepRoutine,
  startDayTime,
} from './routines-items';
import {
  attendanceAllowance,
  disabilityLivingAllowance,
  financesAdditionalInfo,
  incapacityBenefit,
  moneyMattersSubtitle,
  pensionCredit,
  personalIndependencePayment,
  privatePension,
  statePension,
  universalCredit,
} from './money-matters-items';
import { FormItem, FormItemTitle } from 'src/app/models/form/form';
import {
  address2,
  getFormItemsAddressForUserContactDetails,
  state,
} from '../../shared-items/address';
import { movingAroundInsideLink } from '../support-items/moving-around-inside-items';
import { reminiscenceGallery } from './reminiscence-items';

// All form items for user - this is me
export function getFormItemsUserMe(): FormItem<any>[] {
  return [
    new FormItemTitle({ label: 'Personal Details' }),
    ...getFormItemsUserMePersonalDetails(),
    new FormItemTitle({ label: 'Contact Details' }),
    ...getFormItemsUserMeContactDetails(),
    new FormItemTitle({ label: 'Current' }),
    ...getFormItemsUserMeCurrent(),
    new FormItemTitle({ label: 'Personal Qualities' }),
    ...getFormItemsUserMePersonalQualities(),
    new FormItemTitle({ label: 'My People' }),
    ...getFormItemsUserMeMyPeople(),
    new FormItemTitle({ label: 'pastimes' }),
    ...getFormItemsUserMePastimes(),
    new FormItemTitle({ label: 'Home' }),
    ...getFormItemsUserMeHome(),
    new FormItemTitle({ label: 'Routines' }),
    ...getFormItemsUserMeRoutines(),
    new FormItemTitle({ label: 'Reminiscence' }),
    ...getFormItemsUserMeReminiscence(),
    new FormItemTitle({ label: 'Money Matters' }),
    ...getFormItemsUserMeMoneyMatters(),
  ];
}

// All form items for user - this is me - personal details
export function getFormItemsUserMePersonalDetails(): FormItem<any>[] {
  return [
    firstName,
    middleName,
    lastName,
    picture,
    preferredName,
    alsoKnownAs,
    maidenName,
    maritalStatus,
    gender,
    genderAtBirth,
    pronouns,
    dateOfBirth,
    ethnicity,
  ];
}

export function getFormItemsUserMePersonalDetailsNegligibleForCompleteness(): FormItem<any>[] {
  return [alsoKnownAs];
}

// All form items for user - this is me - contact details
export function getFormItemsUserMeContactDetails(): FormItem<any>[] {
  return [
    phoneHome,
    phoneMobile,
    email,
    ...getFormItemsAddressForUserContactDetails(),
    propertyType,
    tenancyType,
  ];
}

export function getFormItemsUserMeContactDetailsNegligibleForCompleteness(): FormItem<any>[] {
  return [
    address2, // has same path as address2 from getFormItemsAddressForUserContactDetails()
    state, // has same path as state from getFormItemsAddressForUserContactDetails()
  ];
}

// All form items for user - this is me - current
export function getFormItemsUserMeCurrent(): FormItem<any>[] {
  return [
    currentLifeSituation,
    friendsAndFamily,
    livingWith,
    supportedBy,
    supporting,
    interests,
    currentAdditionalInfo,
    currentPhotosTellingPersonalStory,
  ];
}

export function getFormItemsUserMeCurrentNegligibleForCompleteness(): FormItem<any>[] {
  return [livingWith, supportedBy, supporting];
}

// All form items for user - this is me - personal qualities
export function getFormItemsUserMePersonalQualities(): FormItem<any>[] {
  return [
    personalQualitiesLikes,
    positiveCharacteristics,
    challengingCharacteristics,
    goodDayDescription,
    badDayDescription,
    goalsTitle,
    goalsAreas,
    goalsRevival,
    goalsFuture,
    goalsAdditionalInfo,
  ];
}

// All form items for user - this is me - my people
export function getFormItemsUserMeMyPeople(): FormItem<any>[] {
  return [mainPeopleInLife, importantRelationships];
}

// All form items for user - this is me - pastimes
export function getFormItemsUserMePastimes(): FormItem<any>[] {
  return [
    pastimesInterestsNow,
    pastimesInterestsNowAdditionalInfo,
    pastimesInterestsPast,
    pastimesInterestsPastAdditionalInfo,
  ];
}

// All form items for user - this is me - home
export function getFormItemsUserMeHome(): FormItem<any>[] {
  return [
    homeArea,
    livingWith, // from This is Me / Current items
    neighbourhood,
    propertyType, // from This is Me / Contact Details items
    tenancyType, // from This is Me / Contact Details items
    homeSuitability,
    homeAdditionalInfo,
    movingAroundInsideLink,
  ];
}

export function getFormItemsUserMeHomeNegligibleForCompleteness(): FormItem<any>[] {
  return [livingWith];
}

// All form items for user - this is me - routines
export function getFormItemsUserMeRoutines(): FormItem<any>[] {
  return [sleepRoutine, startDayTime, breakfastTime, regularOutings];
}

// All form items for user - this is me - reminiscence
export function getFormItemsUserMeReminiscence(): FormItem<any>[] {
  return [
    reminiscenceGallery,
    currentPhotosTellingPersonalStoryForReminiscenceUseage, // from This is Me / Current items
  ];
}

// All form items for user - this is me - money matters
export function getFormItemsUserMeMoneyMatters(): FormItem<any>[] {
  return [
    moneyMattersSubtitle,
    pensionCredit,
    incapacityBenefit,
    attendanceAllowance,
    disabilityLivingAllowance,
    personalIndependencePayment,
    universalCredit,
    privatePension,
    statePension,
    financesAdditionalInfo,
  ];
}
