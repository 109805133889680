import { OutputSubformDisplayComponent } from 'src/app/components/form/displays/output-subform-display/output-subform-display.component';
import {
  OutputContact,
  OutputEmail,
  OutputPds,
  getOutputContactFromSource,
  getOutputEmailFromSource,
  getOutputPdsFromSource,
  prepareOutputContactForPayload,
  prepareOutputEmailForPayload,
  prepareOutputPdsForPayload,
} from 'src/app/models/connection/connectionOutput';
import {
  FormItem,
  FormItemEmailContent,
  FormItemNumber,
  FormItemRepeater,
  FormItemSubform,
  FormItemTextArea,
  FormItemTextbox,
  FormItemTitle,
} from 'src/app/models/form/form';
import { Validators } from '@angular/forms';
import { getFormItemsAddress } from '../../shared-items/address';

// -------------------------------------------- TITLE --------------------------------------------

export const outputsFormTitle = new FormItemTitle({
  label: 'Outputs (how users can get in touch)',
  help: 'You can configure your connection to help users get in touch with you.',
  class: 'mt-4 mb-6',
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

// -------------------------------------------- ALL OUTPUTS --------------------------------------------

export const introduction = new FormItemTextbox({
  key: 'introduction',
  label: 'Introduction',
  help: 'Explain what the output will do for this connection.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(100)],
  // width: 'half',
});

export const nextSteps = new FormItemTextArea({
  key: 'nextSteps',
  label: 'Next Steps',
  help: 'Explain what will happen next when the output is executed.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(200)],
  // width: 'half',
});

// -------------------------------------------- OUTPUT CONTACT --------------------------------------------

export const phone = new FormItemTextbox({
  key: 'phone',
  label: 'Phone',
  class: 'mb-5',
  // width: 'half',
});

export const emailContact = new FormItemTextbox({
  key: 'email',
  label: 'Email',
  class: 'mb-5',
  // width: 'half',
  validators: [
    Validators.email,
    Validators.minLength(1),
    Validators.maxLength(100),
  ],
});

export const openingHours = new FormItemRepeater({
  label: 'Opening Hours',
  key: 'openingHours',
  formItems: [
    new FormItemTextbox({
      label: '',
      placeholder: 'Opening time',
      validators: [Validators.minLength(1), Validators.maxLength(50)],
    }),
  ],
});

export const website = new FormItemTextbox({
  key: 'website',
  label: 'Website',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(200)],
  // width: 'half',
});

export const facebook = new FormItemTextbox({
  key: 'facebook',
  label: 'Facebook',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(200)],
  // width: 'half',
});

export const outputContactAddress: FormItem<any>[] = [
  ...getFormItemsAddress('address'),
];

export const outputContactSubform = new FormItemSubform({
  key: 'outputContact',
  path: 'outputs',
  label: 'Contact Output',
  class: 'mb-2',
  addTitle: 'Include a contact output',
  help: 'Contact details for this connection.',
  display: 'modal',
  displayComponent: OutputSubformDisplayComponent,
  multiple: false,
  placeholder: '',
  subform: [
    introduction,
    nextSteps,
    phone,
    emailContact,
    openingHours,
    website,
    facebook,
    ...outputContactAddress,
  ],
  getValueFromSource(value) {
    return getOutputContactFromSource(value);
  },
  prepareValueForPayload(currentValueAtPath, newValue) {
    return prepareOutputContactForPayload(currentValueAtPath, newValue);
  },
  createValueWithParams(params) {
    return new OutputContact(params);
  },
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

// -------------------------------------------- OUTPUT EMAIL --------------------------------------------

export const email = new FormItemTextbox({
  key: 'email',
  label: 'Email',
  class: 'mb-5',
  // width: 'half',
  required: true,
  validators: [
    Validators.required,
    Validators.email,
    Validators.minLength(1),
    Validators.maxLength(100),
  ],
});

export const referralEmailText = new FormItemEmailContent({
  key: 'referralEmailText',
  label: 'Referral Email Content (Sent to You)',
  salutation: 'Dear Connection Manager,',
  introduction:
    'This is an email submission for [Organisation / Connection] from https://communityconnect.scot. A user is requesting to connect with the details listed below.',
  placeholder:
    'The following user from has submitted a contact form. Our standard process is for someone on the comms team to phone them within 48 hours and log the contact in the organisation CRM.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const confirmationEmailText = new FormItemEmailContent({
  key: 'confirmationEmailText',
  label: 'Confirmation Email Content (Sent to User)',
  salutation: 'Dear [User],',
  introduction:
    'You requested to connect with [Organisation / Connection] on https://communityconnect.scot. You shared the details listed below.',
  placeholder:
    'Thanks for connecting with OUR CONNECTION. We will be in touch soon.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const outputEmailSubform = new FormItemSubform({
  key: 'outputEmail',
  path: 'outputs',
  label: 'Email Output',
  class: 'mb-2',
  addTitle: 'Include an email output',
  help: "Sends the user's details to the provider after they submitted the output form (see below).",
  display: 'modal',
  displayComponent: OutputSubformDisplayComponent,
  multiple: false,
  placeholder: '',
  subform: [
    introduction,
    nextSteps,
    email,
    referralEmailText,
    confirmationEmailText,
  ],
  getValueFromSource(value) {
    return getOutputEmailFromSource(value);
  },
  prepareValueForPayload(currentValueAtPath, newValue) {
    return prepareOutputEmailForPayload(currentValueAtPath, newValue);
  },
  createValueWithParams(params) {
    return new OutputEmail(params);
  },
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

// -------------------------------------------- OUTPUT PDS --------------------------------------------

export const serviceProviderId = new FormItemNumber({
  key: 'mrdServiceProviderIdV2',
  label: 'MRD Service Provider ID',
  help: 'The MRD identifer of the service provider who will be authorized to access specified user details in the PDS.',
  class: 'mb-5',
  required: true,
  validators: [
    Validators.minLength(1),
    Validators.maxLength(5),
    Validators.required,
  ],
  // width: 'half',
});

export const serviceId = new FormItemNumber({
  key: 'mrdServiceIdV2',
  label: 'MRD Service ID',
  help: 'The MRD service identifier assigned to the service, whose provider will be authorized to access specified user details in the PDS.',
  class: 'mb-5',
  required: true,
  validators: [
    Validators.minLength(1),
    Validators.maxLength(5),
    Validators.required,
  ],
  // width: 'half',
});

export const referralText = new FormItemTextArea({
  key: 'pdsReferralText',
  label: 'Referral Intro Text',
  help: 'This will be displayed on the referral overview page and should help the user understand why the requested data should be provided.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(500)],
  // width: 'half',
});

export const referralCompletedText = new FormItemTextArea({
  key: 'pdsReferralCompletedText',
  label: 'Referral Completed Text',
  help: 'This will be displayed on the referral completion page and should inform the user about the next steps the provider will take.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(1000)],
  // width: 'half',
});

export const outputPdsSubform = new FormItemSubform({
  key: 'outputPds',
  path: 'outputs',
  label: 'PDS Output',
  class: 'mb-2',
  addTitle: 'Include a PDS output',
  help: 'Lets the provider access specified user details in the Personal Data Store after the user completes the referral.',
  display: 'modal',
  displayComponent: OutputSubformDisplayComponent,
  multiple: false,
  placeholder: '',
  subform: [
    introduction,
    nextSteps,
    serviceProviderId,
    serviceId,
    referralText,
    referralCompletedText,
  ],
  getValueFromSource(value) {
    return getOutputPdsFromSource(value);
  },
  prepareValueForPayload(currentValueAtPath, newValue) {
    return prepareOutputPdsForPayload(currentValueAtPath, newValue);
  },
  createValueWithParams(params) {
    return new OutputPds(params);
  },
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});
