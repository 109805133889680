<a
  class="rounded-lg p-8 hover:bg-theme hover:bg-opacity-5 hover:border-theme hover:border-opacity-25 bg-opacity-80 transition-colors bg-gray-50 border border-solid border-gray-200 flex flex-col"
  [routerLink]="cardLink"
>
  <div class="flex justify-start">
    <div class="flex mr-2">
      <app-piechart
        [borderWidth]="6"
        [width]="50"
        [value]="completedPercentageOfSection"
      >
        <div class="m-auto relative">
          <img
            *ngIf="completedPercentageOfSection == 100"
            class="m-auto w-5 transition-opacity"
            src="/assets/img/icons/tick.svg"
          />
          <p
            *ngIf="(completedPercentageOfSection ?? 0) < 100"
            class="m-auto text-sm"
          >
            {{ completedPercentageOfSection }}%
          </p>
        </div>
      </app-piechart>
    </div>
    <div>
      <h2 class="text-theme mt-1 mb-0 font-semibold text-lg">
        {{ pdsSection?.title }}
      </h2>
      <p class="mt-0 text-sm mb-0 text-gray-600">
        {{ pdsSection?.description }}
      </p>
    </div>
  </div>
</a>
