import { Validators } from '@angular/forms';
import {
  FormItem,
  FormItemAddress,
  FormItemDisplayCondition,
  FormItemDropdown,
  FormItemTextbox,
} from 'src/app/models/form/form';

export function getFormItemsAddress(
  basePath: string = '',
  displayConditions: FormItemDisplayCondition[] = []
): FormItem<any>[] {
  const address1Copy = new FormItemAddress(address1);
  address1Copy.path = `${basePath}.${address1.key}`;
  address1Copy.autofill = {
    postal_code: `${basePath}.${postcode.key}`,
    postal_town: `${basePath}.${city.key}`,
    country: `${basePath}.${country.key}`,
  };
  address1Copy.displayConditions = displayConditions;

  const address2Copy = new FormItemTextbox(address2);
  address2Copy.path = `${basePath}.${address2.key}`;
  address2Copy.displayConditions = displayConditions;

  const cityCopy = new FormItemTextbox(city);
  cityCopy.path = `${basePath}.${city.key}`;
  cityCopy.displayConditions = displayConditions;

  const stateCopy = new FormItemTextbox(state);
  stateCopy.path = `${basePath}.${state.key}`;
  stateCopy.displayConditions = displayConditions;

  const postcodeCopy = new FormItemTextbox(postcode);
  postcodeCopy.path = `${basePath}.${postcode.key}`;
  postcodeCopy.displayConditions = displayConditions;

  const countryCopy = new FormItemDropdown(country);
  countryCopy.path = `${basePath}.${country.key}`;
  countryCopy.displayConditions = displayConditions;

  return [
    address1Copy,
    address2Copy,
    cityCopy,
    stateCopy,
    postcodeCopy,
    countryCopy,
  ];
}

export function getFormItemsAddressForUserContactDetails(): FormItem<any>[] {
  return getFormItemsAddress('me.contact.address');
}

export function getFormItemsAddressUserContact(): FormItem<any>[] {
  return getFormItemsAddress('addressData');
}

export function getFormItemsAddressUserContactHidden(): FormItem<any>[] {
  const formItems = getFormItemsAddressUserContact();

  formItems.forEach((formItem) => {
    formItem.hide = true;
  });

  return formItems;
}

export const postcode = new FormItemTextbox({
  key: 'postcode',
  path: 'me.contact.address.postcode',
  label: 'Postcode',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const address1 = new FormItemAddress({
  key: 'address1',
  path: 'me.contact.address.address1',
  label: 'Address Line 1',
  placeholder: 'Search...',
  class: 'mb-5',
  autofill: {
    postal_code: 'me.contact.address.postcode',
    postal_town: 'me.contact.address.city',
    country: 'me.contact.address.country',
  },
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const address2 = new FormItemTextbox({
  key: 'address2',
  path: 'me.contact.address.address2',
  label: 'Address Line 2',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const city = new FormItemTextbox({
  key: 'city',
  path: 'me.contact.address.city',
  label: 'Town/City',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const state = new FormItemTextbox({
  key: 'state',
  path: 'me.contact.address.state',
  label: 'County/Region',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const country = new FormItemDropdown({
  key: 'country',
  label: 'Country',
  path: 'me.contact.address.country',
  placeholder: 'Please select...',
  options: [
    { name: 'Afghanistan', label: 'Afghanistan' },
    { name: 'Åland Islands', label: 'Åland Islands' },
    { name: 'Albania', label: 'Albania' },
    { name: 'Algeria', label: 'Algeria' },
    { name: 'American Samoa', label: 'American Samoa' },
    { name: 'Andorra', label: 'Andorra' },
    { name: 'Angola', label: 'Angola' },
    { name: 'Anguilla', label: 'Anguilla' },
    { name: 'Antarctica', label: 'Antarctica' },
    { name: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
    { name: 'Argentina', label: 'Argentina' },
    { name: 'Armenia', label: 'Armenia' },
    { name: 'Aruba', label: 'Aruba' },
    { name: 'Australia', label: 'Australia' },
    { name: 'Austria', label: 'Austria' },
    { name: 'Azerbaijan', label: 'Azerbaijan' },
    { name: 'Bahamas', label: 'Bahamas' },
    { name: 'Bahrain', label: 'Bahrain' },
    { name: 'Bangladesh', label: 'Bangladesh' },
    { name: 'Barbados', label: 'Barbados' },
    { name: 'Belarus', label: 'Belarus' },
    { name: 'Belgium', label: 'Belgium' },
    { name: 'Belize', label: 'Belize' },
    { name: 'Benin', label: 'Benin' },
    { name: 'Bermuda', label: 'Bermuda' },
    { name: 'Bhutan', label: 'Bhutan' },
    {
      name: 'Bolivia, Plurinational State of',
      label: 'Bolivia, Plurinational State of',
    },
    {
      name: 'Bonaire, Sint Eustatius and Saba',
      label: 'Bonaire, Sint Eustatius and Saba',
    },
    { name: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
    { name: 'Botswana', label: 'Botswana' },
    { name: 'Bouvet Island', label: 'Bouvet Island' },
    { name: 'Brazil', label: 'Brazil' },
    {
      name: 'British Indian Ocean Territory',
      label: 'British Indian Ocean Territory',
    },
    { name: 'Brunei Darussalam', label: 'Brunei Darussalam' },
    { name: 'Bulgaria', label: 'Bulgaria' },
    { name: 'Burkina Faso', label: 'Burkina Faso' },
    { name: 'Burundi', label: 'Burundi' },
    { name: 'Cambodia', label: 'Cambodia' },
    { name: 'Cameroon', label: 'Cameroon' },
    { name: 'Canada', label: 'Canada' },
    { name: 'Cape Verde', label: 'Cape Verde' },
    { name: 'Cayman Islands', label: 'Cayman Islands' },
    { name: 'Central African Republic', label: 'Central African Republic' },
    { name: 'Chad', label: 'Chad' },
    { name: 'Chile', label: 'Chile' },
    { name: 'China', label: 'China' },
    { name: 'Christmas Island', label: 'Christmas Island' },
    { name: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
    { name: 'Colombia', label: 'Colombia' },
    { name: 'Comoros', label: 'Comoros' },
    { name: 'Congo', label: 'Congo' },
    {
      name: 'Congo, the Democratic Republic of the',
      label: 'Congo, the Democratic Republic of the',
    },
    { name: 'Cook Islands', label: 'Cook Islands' },
    { name: 'Costa Rica', label: 'Costa Rica' },
    { name: "Côte d'Ivoire", label: "Côte d'Ivoire" },
    { name: 'Croatia', label: 'Croatia' },
    { name: 'Cuba', label: 'Cuba' },
    { name: 'Curaçao', label: 'Curaçao' },
    { name: 'Cyprus', label: 'Cyprus' },
    { name: 'Czech Republic', label: 'Czech Republic' },
    { name: 'Denmark', label: 'Denmark' },
    { name: 'Djibouti', label: 'Djibouti' },
    { name: 'Dominica', label: 'Dominica' },
    { name: 'Dominican Republic', label: 'Dominican Republic' },
    { name: 'Ecuador', label: 'Ecuador' },
    { name: 'Egypt', label: 'Egypt' },
    { name: 'El Salvador', label: 'El Salvador' },
    { name: 'Equatorial Guinea', label: 'Equatorial Guinea' },
    { name: 'Eritrea', label: 'Eritrea' },
    { name: 'Estonia', label: 'Estonia' },
    { name: 'Ethiopia', label: 'Ethiopia' },
    {
      name: 'Falkland Islands (Malvinas)',
      label: 'Falkland Islands (Malvinas)',
    },
    { name: 'Faroe Islands', label: 'Faroe Islands' },
    { name: 'Fiji', label: 'Fiji' },
    { name: 'Finland', label: 'Finland' },
    { name: 'France', label: 'France' },
    { name: 'French Guiana', label: 'French Guiana' },
    { name: 'French Polynesia', label: 'French Polynesia' },
    {
      name: 'French Southern Territories',
      label: 'French Southern Territories',
    },
    { name: 'Gabon', label: 'Gabon' },
    { name: 'Gambia', label: 'Gambia' },
    { name: 'Georgia', label: 'Georgia' },
    { name: 'Germany', label: 'Germany' },
    { name: 'Ghana', label: 'Ghana' },
    { name: 'Gibraltar', label: 'Gibraltar' },
    { name: 'Greece', label: 'Greece' },
    { name: 'Greenland', label: 'Greenland' },
    { name: 'Grenada', label: 'Grenada' },
    { name: 'Guadeloupe', label: 'Guadeloupe' },
    { name: 'Guam', label: 'Guam' },
    { name: 'Guatemala', label: 'Guatemala' },
    { name: 'Guernsey', label: 'Guernsey' },
    { name: 'Guinea', label: 'Guinea' },
    { name: 'Guinea-Bissau', label: 'Guinea-Bissau' },
    { name: 'Guyana', label: 'Guyana' },
    { name: 'Haiti', label: 'Haiti' },
    {
      name: 'Heard Island and McDonald Islands',
      label: 'Heard Island and McDonald Islands',
    },
    {
      name: 'Holy See (Vatican City State)',
      label: 'Holy See (Vatican City State)',
    },
    { name: 'Honduras', label: 'Honduras' },
    { name: 'Hong Kong', label: 'Hong Kong' },
    { name: 'Hungary', label: 'Hungary' },
    { name: 'Iceland', label: 'Iceland' },
    { name: 'India', label: 'India' },
    { name: 'Indonesia', label: 'Indonesia' },
    { name: 'Iran, Islamic Republic of', label: 'Iran, Islamic Republic of' },
    { name: 'Iraq', label: 'Iraq' },
    { name: 'Ireland', label: 'Ireland' },
    { name: 'Isle of Man', label: 'Isle of Man' },
    { name: 'Israel', label: 'Israel' },
    { name: 'Italy', label: 'Italy' },
    { name: 'Jamaica', label: 'Jamaica' },
    { name: 'Japan', label: 'Japan' },
    { name: 'Jersey', label: 'Jersey' },
    { name: 'Jordan', label: 'Jordan' },
    { name: 'Kazakhstan', label: 'Kazakhstan' },
    { name: 'Kenya', label: 'Kenya' },
    { name: 'Kiribati', label: 'Kiribati' },
    {
      name: "Korea, Democratic People's Republic of",
      label: "Korea, Democratic People's Republic of",
    },
    { name: 'Korea, Republic of', label: 'Korea, Republic of' },
    { name: 'Kuwait', label: 'Kuwait' },
    { name: 'Kyrgyzstan', label: 'Kyrgyzstan' },
    {
      name: "Lao People's Democratic Republic",
      label: "Lao People's Democratic Republic",
    },
    { name: 'Latvia', label: 'Latvia' },
    { name: 'Lebanon', label: 'Lebanon' },
    { name: 'Lesotho', label: 'Lesotho' },
    { name: 'Liberia', label: 'Liberia' },
    { name: 'Libya', label: 'Libya' },
    { name: 'Liechtenstein', label: 'Liechtenstein' },
    { name: 'Lithuania', label: 'Lithuania' },
    { name: 'Luxembourg', label: 'Luxembourg' },
    { name: 'Macao', label: 'Macao' },
    {
      name: 'Macedonia, the former Yugoslav Republic of',
      label: 'Macedonia, the former Yugoslav Republic of',
    },
    { name: 'Madagascar', label: 'Madagascar' },
    { name: 'Malawi', label: 'Malawi' },
    { name: 'Malaysia', label: 'Malaysia' },
    { name: 'Maldives', label: 'Maldives' },
    { name: 'Mali', label: 'Mali' },
    { name: 'Malta', label: 'Malta' },
    { name: 'Marshall Islands', label: 'Marshall Islands' },
    { name: 'Martinique', label: 'Martinique' },
    { name: 'Mauritania', label: 'Mauritania' },
    { name: 'Mauritius', label: 'Mauritius' },
    { name: 'Mayotte', label: 'Mayotte' },
    { name: 'Mexico', label: 'Mexico' },
    {
      name: 'Micronesia, Federated States of',
      label: 'Micronesia, Federated States of',
    },
    { name: 'Moldova, Republic of', label: 'Moldova, Republic of' },
    { name: 'Monaco', label: 'Monaco' },
    { name: 'Mongolia', label: 'Mongolia' },
    { name: 'Montenegro', label: 'Montenegro' },
    { name: 'Montserrat', label: 'Montserrat' },
    { name: 'Morocco', label: 'Morocco' },
    { name: 'Mozambique', label: 'Mozambique' },
    { name: 'Myanmar', label: 'Myanmar' },
    { name: 'Namibia', label: 'Namibia' },
    { name: 'Nauru', label: 'Nauru' },
    { name: 'Nepal', label: 'Nepal' },
    { name: 'Netherlands', label: 'Netherlands' },
    { name: 'New Caledonia', label: 'New Caledonia' },
    { name: 'New Zealand', label: 'New Zealand' },
    { name: 'Nicaragua', label: 'Nicaragua' },
    { name: 'Niger', label: 'Niger' },
    { name: 'Nigeria', label: 'Nigeria' },
    { name: 'Niue', label: 'Niue' },
    { name: 'Norfolk Island', label: 'Norfolk Island' },
    { name: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
    { name: 'Norway', label: 'Norway' },
    { name: 'Oman', label: 'Oman' },
    { name: 'Pakistan', label: 'Pakistan' },
    { name: 'Palau', label: 'Palau' },
    {
      name: 'Palestinian Territory, Occupied',
      label: 'Palestinian Territory, Occupied',
    },
    { name: 'Panama', label: 'Panama' },
    { name: 'Papua New Guinea', label: 'Papua New Guinea' },
    { name: 'Paraguay', label: 'Paraguay' },
    { name: 'Peru', label: 'Peru' },
    { name: 'Philippines', label: 'Philippines' },
    { name: 'Pitcairn', label: 'Pitcairn' },
    { name: 'Poland', label: 'Poland' },
    { name: 'Portugal', label: 'Portugal' },
    { name: 'Puerto Rico', label: 'Puerto Rico' },
    { name: 'Qatar', label: 'Qatar' },
    { name: 'Réunion', label: 'Réunion' },
    { name: 'Romania', label: 'Romania' },
    { name: 'Russian Federation', label: 'Russian Federation' },
    { name: 'Rwanda', label: 'Rwanda' },
    { name: 'Saint Barthélemy', label: 'Saint Barthélemy' },
    {
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      label: 'Saint Helena, Ascension and Tristan da Cunha',
    },
    { name: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
    { name: 'Saint Lucia', label: 'Saint Lucia' },
    { name: 'Saint Martin (French part)', label: 'Saint Martin (French part)' },
    { name: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
    {
      name: 'Saint Vincent and the Grenadines',
      label: 'Saint Vincent and the Grenadines',
    },
    { name: 'Samoa', label: 'Samoa' },
    { name: 'San Marino', label: 'San Marino' },
    { name: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
    { name: 'Saudi Arabia', label: 'Saudi Arabia' },
    { name: 'Senegal', label: 'Senegal' },
    { name: 'Serbia', label: 'Serbia' },
    { name: 'Seychelles', label: 'Seychelles' },
    { name: 'Sierra Leone', label: 'Sierra Leone' },
    { name: 'Singapore', label: 'Singapore' },
    { name: 'Sint Maarten (Dutch part)', label: 'Sint Maarten (Dutch part)' },
    { name: 'Slovakia', label: 'Slovakia' },
    { name: 'Slovenia', label: 'Slovenia' },
    { name: 'Solomon Islands', label: 'Solomon Islands' },
    { name: 'Somalia', label: 'Somalia' },
    { name: 'South Africa', label: 'South Africa' },
    {
      name: 'South Georgia and the South Sandwich Islands',
      label: 'South Georgia and the South Sandwich Islands',
    },
    { name: 'South Sudan', label: 'South Sudan' },
    { name: 'Spain', label: 'Spain' },
    { name: 'Sri Lanka', label: 'Sri Lanka' },
    { name: 'Sudan', label: 'Sudan' },
    { name: 'Suriname', label: 'Suriname' },
    { name: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
    { name: 'Swaziland', label: 'Swaziland' },
    { name: 'Sweden', label: 'Sweden' },
    { name: 'Switzerland', label: 'Switzerland' },
    { name: 'Syrian Arab Republic', label: 'Syrian Arab Republic' },
    { name: 'Taiwan, Province of China', label: 'Taiwan, Province of China' },
    { name: 'Tajikistan', label: 'Tajikistan' },
    {
      name: 'Tanzania, United Republic of',
      label: 'Tanzania, United Republic of',
    },
    { name: 'Thailand', label: 'Thailand' },
    { name: 'Timor-Leste', label: 'Timor-Leste' },
    { name: 'Togo', label: 'Togo' },
    { name: 'Tokelau', label: 'Tokelau' },
    { name: 'Tonga', label: 'Tonga' },
    { name: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
    { name: 'Tunisia', label: 'Tunisia' },
    { name: 'Turkey', label: 'Turkey' },
    { name: 'Turkmenistan', label: 'Turkmenistan' },
    { name: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands' },
    { name: 'Tuvalu', label: 'Tuvalu' },
    { name: 'Uganda', label: 'Uganda' },
    { name: 'Ukraine', label: 'Ukraine' },
    { name: 'United Arab Emirates', label: 'United Arab Emirates' },
    { name: 'United Kingdom', label: 'United Kingdom' },
    { name: 'United States', label: 'United States' },
    {
      name: 'United States Minor Outlying Islands',
      label: 'United States Minor Outlying Islands',
    },
    { name: 'Uruguay', label: 'Uruguay' },
    { name: 'Uzbekistan', label: 'Uzbekistan' },
    { name: 'Vanuatu', label: 'Vanuatu' },
    {
      name: 'Venezuela, Bolivarian Republic of',
      label: 'Venezuela, Bolivarian Republic of',
    },
    { name: 'Viet Nam', label: 'Viet Nam' },
    { name: 'Virgin Islands, British', label: 'Virgin Islands, British' },
    { name: 'Virgin Islands, U.S.', label: 'Virgin Islands, U.S.' },
    { name: 'Wallis and Futuna', label: 'Wallis and Futuna' },
    { name: 'Western Sahara', label: 'Western Sahara' },
    { name: 'Yemen', label: 'Yemen' },
    { name: 'Zambia', label: 'Zambia' },
    { name: 'Zimbabwe', label: 'Zimbabwe' },
  ],
  class: 'mb-5',
});
