import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { RegistrationStateService } from 'src/app/services/registration-state/registration-state.service';

export const RegistrationRedirectGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const registrationStateService = inject(RegistrationStateService);

  // when this guard is executed, the registration is enabled
  registrationStateService.setRegistrationEnabled(true);

  // the registration is disabled, redirect to login page
  return router.createUrlTree(['/register']);
};
