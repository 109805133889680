import {
  FormItemCheckbox,
  FormItemFormSections,
  FormItemTitle,
} from 'src/app/models/form/form';
import {
  getFormItemPathOptions,
  getFormattedItemPath,
} from '../form-sections-items';

// -------------------------------------------- TITLE --------------------------------------------

export const outputFormsTitle = new FormItemTitle({
  label: 'Email Output Form',
  help: 'Please select the data items that your connection will want to capture in an email referral. Add a mandatory email field for the user to receive a confirmation email after a referral is made.',
  class: 'mb-5',
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

// -------------------------------------------- FORM SECTIONS --------------------------------------------

export const outputForm = new FormItemFormSections({
  key: 'outputForm',
  path: 'outputForm',
  label: 'Email Output Form (Standard)',
  class: 'mb-5',
  formItemPathOptions: getFormItemPathOptions(),
  getFormattedItemPath: getFormattedItemPath,
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

export const useSeparateOutputFormThem = new FormItemCheckbox({
  key: 'useSeparateOutputFormThem',
  label: 'Use a different form if the user is referring someone else',
  path: 'useSeparateOutputFormThem',
  class: 'mb-5',
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

export const outputFormThem = new FormItemFormSections({
  key: 'outputFormThem',
  path: 'outputFormThem',
  label: 'Email Output Form (Someone Else)',
  class: 'mb-5',
  formItemPathOptions: getFormItemPathOptions(),
  getFormattedItemPath: getFormattedItemPath,
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
    {
      key: 'useSeparateOutputFormThem',
      values: [true],
    },
  ],
});
