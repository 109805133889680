import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { computeCompletedPdsSectionItemsPercentage } from 'src/app/helper/formCompleteness';
import { UserContactData } from 'src/app/models/contact/userContactData';
import { UserContactPdsEntry } from 'src/app/models/contact/userContactPdsEntry';
import { FormItem } from 'src/app/models/form/form';
import { UserImageData } from 'src/app/models/image/userImageData';
import { UserImagePdsEntry } from 'src/app/models/image/userImagePdsEntry';
import { PdsSection } from 'src/app/models/pds/pdsSection';
import { User } from 'src/app/models/user/user';
import { UserContactsStateService } from 'src/app/services/user-contacts-state/user.contacts.state.service';
import { UserImagesStateService } from 'src/app/services/user-images-state/user.images.state.service';
import { UserStateService } from 'src/app/services/user-state/user.state.service';
import { FormComponent } from '../form/form.component';
import { UserMedicationsStateService } from 'src/app/services/user-medications-state/user.medications.state.service';
import { UserMedicationPdsEntry } from 'src/app/models/medication/userMedicationPdsEntry';
import { UserMedication } from 'src/app/models/medication/userMedication';

@Component({
  selector: 'app-pds-section-form',
  templateUrl: './pds-section-form.component.html',
  styleUrl: './pds-section-form.component.css',
})
export class PdsSectionFormComponent {
  // PROPERTIES
  @ViewChild('formComponent') formComponent?: FormComponent;

  @Input() user: User | undefined;
  @Input() pdsSection?: PdsSection;
  @Input() form: FormItem<any>[] = [];
  @Input() formCancelText: string = 'Cancel';

  @Output() formCancel = new EventEmitter();

  protected contacts: UserContactPdsEntry[] = [];
  protected images: UserImagePdsEntry[] = [];
  protected medications: UserMedicationPdsEntry[] = [];

  protected savingUserSuccess: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  // PUBLIC GETTERS

  // CONSTRUCTOR

  constructor(
    protected userService: UserStateService,
    protected userContactsStateService: UserContactsStateService,
    protected userImagesStateService: UserImagesStateService,
    protected userMedicationsStateService: UserMedicationsStateService
  ) {}

  // LIFECYCLE HOOKS

  ngOnInit() {
    // show success message for 2 seconds when user data is saved successfully
    this.userService.savingSuccess$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.savingUserSuccess = true;
        setTimeout(() => {
          this.savingUserSuccess = false;
        }, 2000);
      },
    });

    this.userContactsStateService.userContacts$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (contacts) => {
          this.contacts = contacts;
        },
      });

    this.userContactsStateService.savingSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.formComponent?.handleContactSavingSuccess();
      });

    this.userContactsStateService.deletionSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((contactId) => {
        this.formComponent?.handleContactDeletionSuccess(contactId);
      });

    this.userImagesStateService.userImageThumbnails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (images) => {
          this.images = images;
        },
      });

    this.userImagesStateService.loadingImageFullSizeSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageFullSize) => {
        this.formComponent?.handleLoadingImageFullSizeSuccess(imageFullSize);
      });

    this.userImagesStateService.updatingImageSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.formComponent?.handleImageUpdatingSuccess();
      });

    this.userImagesStateService.deletingImageSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (imageId) => {
          this.formComponent?.handleImageDeletionSuccess(imageId);
        },
      });

    this.userMedicationsStateService.userMedicationsBasics$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (medications) => {
          this.medications = medications;
        },
      });

    this.userMedicationsStateService.loadingMedicationDetailsSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((medicationDetails) => {
        this.formComponent?.handleLoadingMedicationDetailsSuccess(
          medicationDetails
        );
      });

    this.userMedicationsStateService.addingMedicationSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.formComponent?.handleMedicationAdditionSuccess();
      });

    this.userMedicationsStateService.updatingMedicationSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.formComponent?.handleMedicationUpdateSuccess();
      });

    this.userMedicationsStateService.deletingMedicationSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (medicationId) => {
          this.formComponent?.handleMedicationDeletionSuccess(medicationId);
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.userService.resetErrors();
    this.userContactsStateService.resetErrors();
    this.userImagesStateService.resetErrors();
  }

  get completedPercentageOfSection() {
    return computeCompletedPdsSectionItemsPercentage(
      this.user,
      this.pdsSection
    );
  }

  // PUBLIC API

  handleSave(data: Partial<User>) {
    var serverMamPaths = this.pdsSection?.serverMamPaths ?? [];
    this.userService.updateUser(data, serverMamPaths);
  }

  /* Contacts Item Functions */
  handleContactCreated(userContactData: UserContactData) {
    this.userContactsStateService.createUserContact(userContactData);
  }

  handleContactUpdated(userContact: UserContactPdsEntry) {
    this.userContactsStateService.updateUserContact(userContact);
  }

  handleContactDeleted(userContact: UserContactPdsEntry) {
    this.userContactsStateService.deleteUserContact(userContact);
  }

  handleClearContactsItemErrors() {
    this.userContactsStateService.resetErrors();
  }
  /* End Contacts Item Functions */

  /* Images Item Functions */
  handleFullSizeImageRequested(imageId: string) {
    this.userImagesStateService.getUserImageFullSize(imageId);
  }

  handleImageAdded(userImageData: UserImageData) {
    this.userImagesStateService.addUserImage(userImageData);
  }

  handleImageUpdated(updatedUserImage: UserImagePdsEntry) {
    this.userImagesStateService.updateUserImage(updatedUserImage);
  }

  handleImageDeleted(userImageToBeDeleted: UserImagePdsEntry) {
    this.userImagesStateService.deleteUserImage(userImageToBeDeleted);
  }

  handleClearImagesItemErrors() {
    this.userImagesStateService.resetErrors();
  }
  /* End Images Item Functions */

  /* Medications Item Functions */
  handleMedicationDetailsRequested(medicationId: string) {
    this.userMedicationsStateService.getMedicationDetails(medicationId);
  }

  handleMedicationAdded(userMedication: UserMedication) {
    this.userMedicationsStateService.addUserMedication(userMedication);
  }

  handleMedicationUpdated(updatedUserMedication: UserMedicationPdsEntry) {
    this.userMedicationsStateService.updateUserMedication(
      updatedUserMedication
    );
  }

  handleMedicationDeleted(userMedicationToBeDeleted: UserMedicationPdsEntry) {
    this.userMedicationsStateService.deleteUserMedication(
      userMedicationToBeDeleted
    );
  }

  handleClearMedicationItemErrors() {
    this.userMedicationsStateService.resetErrors();
  }
  /* End Image Item Functions */
}
