<!-- LOADING SPINNER -->
<app-loading-spinner
  *ngIf="!errorMessage"
  [text]="spinnerText"
  [ngClass]="spinnerClass"
></app-loading-spinner>
<!-- END LOADING SPINNER -->

<!-- ERROR -->
<p *ngIf="errorMessage" class="text-error-red">
  {{ errorMessage }}
</p>
<!-- END ERROR -->
