import { UserImageData } from '../userImageData';

export class UserImageCreationRequest {
  file: string;
  fileName?: string;
  description?: string;

  constructor(data?: Partial<UserImageCreationRequest>) {
    this.file = data?.file ?? '';
    this.fileName = data?.fileName;
    this.description = data?.description;
  }

  public static fromUserImageData(
    imageData: UserImageData
  ): UserImageCreationRequest {
    return new UserImageCreationRequest({
      file: imageData.file,
      fileName: imageData.fileName,
      description: imageData.description,
    });
  }
}
