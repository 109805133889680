import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';
import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTextbox,
} from 'src/app/models/form/form';

// THIS IS ME - ROUTINES -------------------------------------------------------

export const sleepRoutine = new FormItemButtonSelect({
  key: 'sleepRoutine',
  label: 'Your sleep routine',
  path: 'me.routines.sleep',
  multiple: true,
  options: [
    { name: 'i_am_an_early_bird', label: 'Early bird' },
    { name: 'i_like_a_long_lie', label: 'Like a long lie' },
    { name: 'i_go_to_bed_early', label: 'Early to bed' },
    { name: 'i_am_a_night_owl', label: 'Night owl' },
    { name: 'sleep_routine_other', label: 'Other' },
  ],
  class: 'mb-5',
});

export const startDayTime = new FormItemTextbox({
  key: 'startDayTime',
  path: 'me.routines.startDayTime',
  label: 'When do you like to get up?',
  placeholder: 'e.g. 8:00am',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const breakfastTime = new FormItemTextbox({
  key: 'breakfastTime',
  path: 'me.routines.breakfastTime',
  label: 'When do you like to have breakfast?',
  placeholder: 'e.g. 9:00am',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const regularOutings = new FormItemTextArea({
  key: 'regularOutings',
  path: 'me.routines.regularOutings',
  label: 'What regular outings do you make (if any)?',
  placeholder:
    'e.g. Reminiscence group at my local library each Sunday afternoon',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});
