export class ConnectionSummary {
  title?: string; // 80 character title
  headline?: string; // 200 characters
  description?: string; // 3000 characters
  highlights?: string[]; // displays as bulleted list of text after the description
  images?: string[]; // additional images to display

  constructor(data?: Partial<ConnectionSummary>) {
    this.title = data && data.title;
    this.headline = data && data.headline;
    this.description =
      data && data.description
        ? this.cleanUpDescriptionFormatting(data.description)
        : undefined;
    this.images = data && data.images ? data.images : [];
    this.highlights = data && data.highlights ? data.highlights : [];
  }

  cleanUpDescriptionFormatting(description: string): string {
    return description
      .replace(/&amp;/g, '&')
      .replace(/&nbsp;/g, ' ')
      .replace(/(\r\n){3,}/g, '\r\n\r\n');
  }
}
