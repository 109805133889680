export const yesNoOptions = [
  { label: 'Yes', name: 'Yes' },
  { label: 'No', name: 'No' },
];

export const yesNoOptionsLegacy = [
  { label: 'Yes', name: true },
  { label: 'No', name: false },
];

export const yesNoSometimesOptions = [
  { name: 'Yes', label: 'Yes' },
  { name: 'No', label: 'No' },
  { name: 'Sometimes', label: 'Sometimes' },
];

export const yesNoMaybeOptions = [
  { name: 'Yes', label: 'Yes' },
  { name: 'No', label: 'No' },
  { name: 'Maybe', label: 'Maybe' },
];

export const yesNoUnknownOptions = [
  { name: 'Yes', label: 'Yes' },
  { name: 'No', label: 'No' },
  { name: 'Do not know', label: 'Do not know' },
];

export const yesNoPreferNotToSayOptions = [
  { name: 'Yes', label: 'Yes' },
  { name: 'No', label: 'No' },
  { name: 'Prefer not to say', label: 'Prefer not to say' },
];

export const yesNoInProgressOptions = [
  { name: 'Yes', label: 'Yes' },
  { name: 'No', label: 'No' },
  { name: 'In Progress', label: 'In Progress' },
];

export const yesNoInProgressLegacyOptions = [
  { name: 'Yes', label: 'Yes' },
  { name: 'No', label: 'No' },
  { name: 'In progress', label: 'In progress' },
];

export const pdsEthnicityOptionGroups = [
  {
    label: 'Asian, Scottish Asian or British Asian',
    options: [
      {
        name: 'Pakistani, Scottish Pakistani or British Pakistani',
        label: 'Pakistani, Scottish Pakistani or British Pakistani',
      },
      {
        name: 'Indian, Scottish Indian or British Indian',
        label: 'Indian, Scottish Indian or British Indian',
      },
      {
        name: 'Bangladeshi, Scottish Bangladeshi or British Bangladeshi',
        label: 'Bangladeshi, Scottish Bangladeshi or British Bangladeshi',
      },
      {
        name: 'Chinese, Scottish Chinese or British Chinese',
        label: 'Chinese, Scottish Chinese or British Chinese',
      },
      {
        name: 'Other Asian',
        label: 'Other Asian',
      },
    ],
  },
  {
    label: 'African',
    options: [
      {
        name: 'Scottish African',
        label: 'Scottish African',
      },
      {
        name: 'British African',
        label: 'British African',
      },
      {
        name: 'Other African',
        label: 'Other African',
      },
    ],
  },
  {
    label: 'Caribbean or Black',
    options: [
      {
        name: 'Black',
        label: 'Black',
      },
      {
        name: 'Black Caribbean',
        label: 'Black Caribbean',
      },
      {
        name: 'Other Caribbean',
        label: 'Other Caribbean',
      },
    ],
  },
  {
    label: 'Mixed or multiple ethnic group',
    options: [
      {
        name: 'White and Asian',
        label: 'White and Asian',
      },
      {
        name: 'White and Black African',
        label: 'White and Black African',
      },
      {
        name: 'White and Black Caribbean',
        label: 'White and Black Caribbean',
      },
      {
        name: 'Any other mixed or multiple ethnic background',
        label: 'Any other mixed or multiple ethnic background',
      },
    ],
  },
  {
    label: 'White',
    options: [
      {
        name: 'Scottish',
        label: 'Scottish',
      },
      {
        name: 'Other British',
        label: 'Other British',
      },
      {
        name: 'Irish',
        label: 'Irish',
      },
      {
        name: 'Polish',
        label: 'Polish',
      },
      {
        name: 'Gypsy or Traveller',
        label: 'Gypsy or Traveller',
      },
      {
        name: 'Roma',
        label: 'Roma',
      },
      {
        name: 'Showman or Showwoman',
        label: 'Showman or Showwoman',
      },
      {
        name: 'Other White',
        label: 'Other White',
      },
    ],
  },
  {
    label: 'Other ethnic group',
    options: [
      {
        name: 'Arab, Scottish Arab or British Arab',
        label: 'Arab, Scottish Arab or British Arab',
      },
      {
        name: 'Other (for example, Sikh, Jewish)',
        label: 'Other (for example, Sikh, Jewish)',
      },
    ],
  },
];
