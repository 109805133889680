<div class="relative">
  <!--LOADING SPINNER-->
  <app-loading-spinner
    *ngIf="
      (userService.loading$ | async) ||
      ((userService.saving$ | async) && !form?.length)
    "
  ></app-loading-spinner>
  <!--END LOADING SPINNER-->

  <!-- SERVER ERROR: FAILED LOADING USER -->
  <app-no-items-error
    [isDisplayed]="
      !(userService.loading$ | async) && (userService.loadingError$ | async)
    "
    [errorMessage]="userService.loadingError$ | async"
  >
  </app-no-items-error>
  <!-- END SERVER ERROR: FAILED LOADING USER -->

  <!--CONTENT-->
  <ng-container
    *ngIf="
      !(userService.loading$ | async) &&
      (!(userService.saving$ | async) || form?.length) &&
      !(userService.loadingError$ | async)
    "
  >
    <!-- BREADCRUMB -->
    <nav
      class="mb-8"
      aria-label="breadcrumb"
      *ngIf="breadcrumbSegments.length > 1"
    >
      <ul style="list-style-type: none; padding: 0">
        <li
          *ngFor="let segment of breadcrumbSegments; index as i"
          style="display: inline"
        >
          <span class="ms-2 me-1" *ngIf="i > 0">/</span>
          <a [routerLink]="segment.path" class="cursor-pointer p-0 border-0">
            {{ segment.label | titlecase }}</a
          >
        </li>
      </ul>
    </nav>
    <!-- END BREADCRUMB -->

    <div class="3xl:flex 3xl:flex-row-reverse 3xl:justify-end">
      <div
        class="card bg-white rounded-lg w-full p-16 pt-6 max-w-5xl box-border"
        [class.w-full]="form?.length"
      >
        <div class="mt-2">
          <!-- BACK BUTTON -->
          <button
            class="btn-inline underline opacity-50 hover:opacity-100 transition-opacity font-semibold p-0 m-0 mb-6"
            (click)="handleBack($event)"
          >
            Back
          </button>
          <!-- TITLE (CASE: NO FORM) -->
          <ng-container *ngIf="!form?.length">
            <h1 class="text-3xl font-semibold m-0">
              {{ currentSection?.title }}
            </h1>
            <p class="m-0 mt-2 text-gray-500">
              {{
                currentSection?.longDescription ?? currentSection?.description
              }}
            </p>
            <!-- END TITLE (CASE: NO FORM) -->
          </ng-container>
        </div>
        <!-- END BACK BUTTON -->

        <!-- CURRENT SECTION FORM (INCLUDES TITLE) -->
        <app-pds-section-form
          *ngIf="currentSection && form?.length"
          [user]="user"
          [pdsSection]="currentSection"
          [form]="form"
          [formCancelText]="
            'Back ' + (parentSection.title ? 'to ' + parentSection.title : '')
          "
          (formCancel)="handleBack($event)"
        >
        </app-pds-section-form>
        <!-- END CURRENT SECTION FORM (INCLUDES TITLE) -->

        <!-- CHILD SECTION CARDS -->
        <div class="mt-8" *ngIf="currentSection?.children?.length">
          <h2 class="mt-8 mb-6" *ngIf="form && form.length">
            Areas of {{ currentSection?.title.toLowerCase() }}
          </h2>
          <div class="grid gap-8 xl:grid-cols-2">
            <app-pds-section-card
              *ngFor="let childSection of currentSection?.children"
              [user]="user"
              [pdsSection]="childSection"
              [cardLink]="
                '/account/about-me/' +
                currentSection.routePath +
                '/' +
                childSection.routePath
              "
            ></app-pds-section-card>
          </div>
        </div>
        <!-- END CHILD SECTION CARDS -->
      </div>
    </div>
  </ng-container>
  <!--END CONTENT-->
</div>
