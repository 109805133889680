import { Component, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { computeCompletedMultiplePdsSectionsItemsPercentage } from 'src/app/helper/formCompleteness';
import { Connection } from 'src/app/models/connection/connection';
import { User } from 'src/app/models/user/user';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { UserStateService } from 'src/app/services/user-state/user.state.service';
import { pdsReferralSections } from 'src/assets/pds-referrals/pds-referral-sections';

@Component({
  selector: 'app-pds-referral-completeness',
  templateUrl: './pds-referral-completeness.component.html',
  styleUrl: './pds-referral-completeness.component.css',
})
export class PdsReferralCompletenessComponent {
  @Input() connection?: Connection;
  @Input() pdsReferralSections = pdsReferralSections;

  protected referralCompletedPercentage: number = 0;

  private user: User | undefined;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    protected userService: UserStateService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        if (user) {
          this.user = user;
        } else {
          this.user = this.sessionStorageService.getUserData();
        }
        this.updatePdsReferralCompletedPercentage();
      },
    });
  }

  ngOnDestory(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updatePdsReferralCompletedPercentage() {
    this.referralCompletedPercentage =
      computeCompletedMultiplePdsSectionsItemsPercentage(
        this.user,
        this.pdsReferralSections
      );
  }
}
