import { Component } from '@angular/core';
import { Connection } from 'src/app/models/connection/connection';
import { ConnectionsStateService } from 'src/app/services/connections-state/connections.state.service';

@Component({
  selector: 'app-admin-connections',
  templateUrl: './admin-connections.component.html',
  styleUrls: ['./admin-connections.component.css'],
})
export class AdminConnectionsComponent {
  // PROPERTIES

  protected connectionsAll: Connection[] = [];
  protected connections: Connection[] = [];
  // TODO: Implement filter
  // protected filter = '';
  protected searchTerm = '';

  private connectionsStateServiceSubscription: any = undefined;

  // CONSTRUCTOR

  constructor(protected connectionsStateService: ConnectionsStateService) {}

  // LIFECYCLE HOOKS

  ngOnInit() {
    this.connectionsStateServiceSubscription =
      this.connectionsStateService.connections$.subscribe((connections) => {
        this.connectionsAll = connections;
        this.connections = connections;
        this.filterList();
      });
  }

  ngOnDestroy() {
    if (this.connectionsStateServiceSubscription) {
      this.connectionsStateServiceSubscription.unsubscribe();
    }

    this.connectionsStateService.resetErrors();
  }

  // COMPONENT METHODS

  protected filterList(searchTerm?: string) {
    // use new search term if triggered by input, otherwise use existing if triggered by connections update
    if (searchTerm !== undefined) {
      this.searchTerm = searchTerm;
    }

    if (this.searchTerm === '') {
      this.connections = this.connectionsAll;
      return;
    }

    this.connections = this.connectionsAll.filter((connection) => {
      return connection.title
        .toLowerCase()
        .includes(this.searchTerm.toLowerCase());
    });
  }

  // TODO: Implement filter
  // setFilter(filter: string) {
  //   this.filter = filter;
  //   switch (filter) {
  //     case 'finderPath':
  //       this.connections =
  //         this.connectionsStateService.getConnectionsForFinderPath(
  //           'me.practical'
  //         );
  //       break;
  //     default:
  //       this.connections = this.connectionsAll;
  //       break;
  //   }
  // }
}
