import {
  FormItemButtonSelect,
  FormItemDropdown,
  FormItemTextArea,
} from 'src/app/models/form/form';
import { yesNoOptions } from '../item-options';
import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';

export const employmentImpactEmploymentStatus = new FormItemButtonSelect({
  key: 'employmentImpactEmploymentStatus',
  label: 'My employment status',
  path: 'supportingRole.employmentImpact.employmentStatus',
  class: 'mb-5',
  options: [
    { name: 'Full-time employed', label: 'Full-time employed' },
    { name: 'Part-time employed', label: 'Part-time employed' },
    { name: 'Self-employed', label: 'Self-employed' },
    { name: 'Unemployed', label: 'Unemployed' },
    { name: 'Choose not to work', label: 'Choose not to work' },
    { name: 'Gave up work to care', label: 'Gave up work to care' },
    { name: 'Retired', label: 'Retired' },
  ],
});

export const employmentImpactHasOrInSearchOfOccupation =
  new FormItemButtonSelect({
    key: 'employmentImpactHasOrInSearchOfOccupation',
    label:
      'Are you in or seeking employment/training/volunteering opportunities?',
    path: 'supportingRole.employmentImpact.hasOrInSearchOfOccupation',
    class: 'mb-5',
    options: yesNoOptions,
  });

export const employmentImpactCaringAffectsOccupation = new FormItemButtonSelect(
  {
    key: 'employmentImpactCaringAffectsOccupation',
    label: 'My caring role impacts on my employment/training/volunteering',
    path: 'supportingRole.employmentImpact.caringAffectsOccupation',
    class: 'mb-5',
    options: yesNoOptions,
  }
);

export const employmentImpactCaringAffectsOccupationDetails =
  new FormItemTextArea({
    key: 'employmentImpactCaringAffectsOccupationDetails',
    path: 'supportingRole.employmentImpact.caringAffectsOccupationDetails',
    label: 'How it impacts',
    class: 'mb-5',
    validators: [Validators.minLength(1), maxByteValidator(2000)],
    displayConditions: [
      {
        key: 'employmentImpactCaringAffectsOccupation',
        values: ['Yes'],
      },
    ],
  });

export const employmentImpactSummary = new FormItemDropdown({
  key: 'employmentImpactSummary',
  label:
    'Thinking about employment/training/volunteering, the selected statement best matches how I currently feel',
  path: 'supportingRole.employmentImpact.summary',
  class: 'mb-5',
  placeholder: 'Please select...',
  options: [
    {
      name: "I'm in work or training and managing well enough/ I'm retired/ I do not want work or training.",
    },
    {
      name: "I'm in work, or training, or volunteering as a way in to work, but there are some problems. I may have to give up in future. OR, I am not in work/training, but would like to be longer term.",
    },
    {
      name: "I'm trying to sort things out with work, otherwise I may have to give up in the next few months. OR I'd like to find work in the medium term, but it's hard.",
    },
    {
      name: "I need to find work soon. OR I'm in work/training but may have to give up soon because of my caring role, even with the help I have to manage work and caring.",
    },
    {
      name: "I need to find work/training but I can't see how due to my caring role. OR, I am about to give up/have just given up work/training because I can't manage it alongside my caring role.",
    },
  ],
});
