enum MamBasePaths {
  THIS_IS_ME = '/this-is-me',
  SUPPORTING_ROLE = '/my-supporting-roles',
}

export const ServerMamPaths = {
  ThisIsMe: {
    PersonalDetails: `${MamBasePaths.THIS_IS_ME}/personal-details`,
    ContactDetails: `${MamBasePaths.THIS_IS_ME}/contact-details`,
    Current: `${MamBasePaths.THIS_IS_ME}/current-support`,
    PersonalQualities: `${MamBasePaths.THIS_IS_ME}/personal-qualities`,
    MyPeople: `${MamBasePaths.THIS_IS_ME}/my-people`,
    Pastimes: `${MamBasePaths.THIS_IS_ME}/my-pastimes`,
    Home: `${MamBasePaths.THIS_IS_ME}/my-home`,
    Routines: `${MamBasePaths.THIS_IS_ME}/my-routines`,
    ReminiscenceGallery: `${MamBasePaths.THIS_IS_ME}/reminiscence-gallery`,
    MoneyMatters: `${MamBasePaths.THIS_IS_ME}/money-matters`,
  },
  SupportingRole: {
    PersonalDetails: `${MamBasePaths.SUPPORTING_ROLE}/personal-details`,
    SupportedPeople: `${MamBasePaths.SUPPORTING_ROLE}/people-i-care-for`,
    CaringRole: `${MamBasePaths.SUPPORTING_ROLE}/my-caring-role`,
    HealthImpact: `${MamBasePaths.SUPPORTING_ROLE}/impact-on-my-health`,
    EmotionalWellbeing: `${MamBasePaths.SUPPORTING_ROLE}/emotional-wellbeing`,
    FinancialImpact: `${MamBasePaths.SUPPORTING_ROLE}/impact-on-my-finances`,
    LifeBalance: `${MamBasePaths.SUPPORTING_ROLE}/my-life-balance`,
    FuturePlansImpact: `${MamBasePaths.SUPPORTING_ROLE}/impact-on-future-plans`,
    EmploymentImpact: `${MamBasePaths.SUPPORTING_ROLE}/impact-on-employment`,
    HomeImpact: `${MamBasePaths.SUPPORTING_ROLE}/impact-on-my-home`,
    OtherLifeAspects: `${MamBasePaths.SUPPORTING_ROLE}/other-aspects-of-life`,
    SkillsAndExperience: `${MamBasePaths.SUPPORTING_ROLE}/skills-experience`,
    WhatMatters: `${MamBasePaths.SUPPORTING_ROLE}/what-matters`,
  },
};

export const getAllServerMamPaths = (): string[] => {
  return Object.entries(ServerMamPaths).flatMap(([_, subPaths]) =>
    Object.values(subPaths)
  );
};
