// const address = new FormItemAddress(){

import {
  FormItem,
  FormItemAddress,
  FormItemSubform,
  FormItemButtonSelect,
  FormItemCheckbox,
  FormItemDatePicker,
  FormItemDropdown,
  FormItemPassword,
  FormItemSelect,
  FormItemTextbox,
} from 'src/app/models/form/form';
import { Validators } from '@angular/forms';
import { city, postcode } from '../shared-items/address';

export const address = new FormItemAddress({
  key: 'address1',
  path: 'me.personalDetails.address.address1',
  label: 'Address',
  placeholder: 'Search...',
  help: 'Search for your address',
  class: 'mb-5',
  type: 'string',
  autofill: {
    postal_code: 'me.contact.address.postcode',
    postal_town: 'me.contact.address.city',
    lat: 'me.personalDetails.address.lat',
    lng: 'me.personalDetails.address.lng',
  },
  validators: [
    Validators.minLength(1),
    Validators.maxLength(50),
    //   Validators.required,
  ],
});

export const lat = new FormItemTextbox({
  key: 'lat',
  path: 'me.personalDetails.address.lat',
  label: 'Latitude',
  placeholder: 'Latitude',
  class: 'mb-5',
  width: 'half',
  type: 'string',
  required: true,
  hide: true,
  validators: [
    Validators.minLength(1),
    Validators.maxLength(50),
    //   Validators.required,
  ],
});

export const lng = new FormItemTextbox({
  key: 'lng',
  path: 'me.personalDetails.address.lng',
  label: 'Longitude',
  placeholder: 'Longitude',
  class: 'mb-5',
  width: 'half',
  type: 'string',
  required: true,
  hide: true,
  validators: [
    Validators.minLength(1),
    Validators.maxLength(50),
    //   Validators.required,
  ],
});

export const check = new FormItemCheckbox({
  key: 'check',
  path: 'me.personalDetails.address.check',
  label: 'Check',
  placeholder: 'Check',
  class: 'mb-5',
  help: 'this is some help text',
  width: 'half',
  type: 'string',
  required: true,
  validators: [
    Validators.minLength(1),
    Validators.maxLength(50),
    //   Validators.required,
  ],
});

export default [
  address,
  // address2,
  city,
  postcode,
  check,
  // country,
  lat,
  lng,
  // state,
  // address,
];
