import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-items-completed-bar',
  templateUrl: './form-items-completed-bar.component.html',
  styleUrl: './form-items-completed-bar.component.css',
})
export class FormItemsCompletedBarComponent {
  @Input() formItemsCompletedPercentage: number = 0;
}
