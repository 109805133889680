import { PdsSection } from 'src/app/models/pds/pdsSection';
import { getFormItemsReferralMe } from '../forms/referral/form-referral-me';
import { ServerMamPaths } from '../about-me/serverMamPaths';
import { getUnpaidCarerForm } from '../forms/carer/form-carer-questions';
import { supportingRoleSection } from '../about-me/sections/supportingRoleSection';

export const basicDetailsSection: PdsSection = {
  title: 'Basic Details',
  name: 'key-details',
  description: 'A few key details about yourself',
  longDescription:
    'In this section, please provide some key details about yourself.',
  routePath: 'basic-details',
  getFormItems: getFormItemsReferralMe,
  serverMamPaths: [
    ServerMamPaths.ThisIsMe.PersonalDetails,
    ServerMamPaths.ThisIsMe.ContactDetails,
  ],
};

export const careQuestionnaireSection: PdsSection = {
  title: 'Care Questionnaire',
  name: 'care-questionnaire',
  description: 'Understanding the support you give',
  longDescription:
    'Below are the questions to understand the support you provide.',
  routePath: 'care-questionnaire',
  getFormItems: getUnpaidCarerForm,
  serverMamPaths: [
    ServerMamPaths.SupportingRole.SupportedPeople,
    ServerMamPaths.SupportingRole.HealthImpact,
    ServerMamPaths.SupportingRole.EmotionalWellbeing,
    ServerMamPaths.SupportingRole.FinancialImpact,
    ServerMamPaths.SupportingRole.PersonalDetails,
  ],
};

// remove code below
export const pdsReferralPrimaryInformationSections = [
  basicDetailsSection,
  careQuestionnaireSection,
];

export const pdsReferralSecondaryInformationSections = [
  ...(supportingRoleSection.children || []),
];

export const pdsReferralSections = [
  ...pdsReferralPrimaryInformationSections,
  ...pdsReferralSecondaryInformationSections,
];
