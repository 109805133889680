import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollRequestSource = new Subject<void>();
  scrollRequest$ = this.scrollRequestSource.asObservable();

  requestScrollToTop() {
    this.scrollRequestSource.next();
  }
}
