import { FormItemDropdown } from 'src/app/models/form/form';

export const supportedPeopleSupportInYears = new FormItemDropdown({
  key: 'supportedPeopleSupportInYears',
  path: 'supportingRole.supportedPeople.supportInYears',
  label: 'I have provided care for',
  class: 'mb-5',
  placeholder: 'Please select...',
  options: [
    { name: 'Under one year', label: 'Under one year' },
    {
      name: 'More than 1 year but less than 5 years',
      label: 'More than 1 year but less than 5 years',
    },
    {
      name: 'More than 5 years but less than 10 years',
      label: 'More than 5 years but less than 10 years',
    },
    {
      name: 'More than 10 years but less than 20 years',
      label: 'More than 10 years but less than 20 years',
    },
    { name: '20 years or more', label: '20 years or more' },
    { name: 'Not known', label: 'Not known' },
  ],
});

export const supportedPeopleWeeklyHours = new FormItemDropdown({
  key: 'supportedPeopleWeeklyHours',
  path: 'supportingRole.supportedPeople.weeklyHours',
  label:
    'In an average week, my caring hours total (include evenings/overnight activity)',
  class: 'mb-5',
  placeholder: 'Please select...',
  options: [
    { name: '4 hours or less', label: '4 hours or less' },
    { name: '5-19 hours', label: '5-19 hours' },
    { name: '20-34 hours', label: '20-34 hours' },
    { name: '35-49 hours', label: '35-49 hours' },
    { name: '50+ hours', label: '50+ hours' },
    { name: 'Not known', label: 'Not known' },
  ],
});
