import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AuthService } from './services/auth/auth.service';
import { NavigationService } from './services/navigation/navigation.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { PdsService } from './services/pds/pds.service';
import { CookieConsentService } from './services/cookie-consent/cookie-consent.service';
import { ScrollService } from './services/scroll/scroll.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'early-access';

  // layout = dashboard, home, admin or auth
  layout: any;
  sidebar: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private navigationService: NavigationService,
    private analyticsService: AnalyticsService, // injection needed to trigger the constructor
    private oidcSecurityService: OidcSecurityService,
    private pdsService: PdsService,
    private cookieConsentService: CookieConsentService, // injection needed to trigger the constructor
    private scrollService: ScrollService
  ) {}
  navActive: boolean = false;

  toggleNav() {
    this.navActive = !this.navActive;
  }
  closeNav() {
    this.navActive = false;
  }

  ngOnInit() {
    this.router.events
      .pipe(
        //method of accessing route data in parent, src: https://stackoverflow.com/questions/43512695/how-to-get-route-data-into-app-component-in-angular-2
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((data) => {
        this.layout = data['layout'];
        this.sidebar = data['sidebar'] == false ? false : true; //default to true

        // Automatic scroll logic for dashboard content (because of the sidebar, regular reset to top of page on route change doesn't work)
        const dashboardContent = document.getElementById('dashboard-content');
        if (dashboardContent) {
          dashboardContent.scrollTo(0, 0);
        }
      });

    // Scroll logic for step navigation within components that can be triggered via the scroll service
    this.scrollService.scrollRequest$.subscribe(() => {
      const dashboardContent = document.getElementById('dashboard-content');
      if (dashboardContent) {
        dashboardContent.scrollTo(0, 0); // Scroll the content container to the top
      }
    });
  }
}
