import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, combineLatest, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth/auth.service';
import { SASessionStoragekeys } from './models/auth/models';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private injector: Injector
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return combineLatest([
      this.oidcSecurityService.getAccessToken('mydex'),
      this.oidcSecurityService.getIdToken('mydex'),
    ]).pipe(
      switchMap(([accessToken, idToken]) => {
        const isMydex = req.url.includes(environment.oidc.mydex.authority);
        const SAAccessToken = sessionStorage.getItem(
          SASessionStoragekeys.AccessToken
        );
        const MDAccessToken = accessToken;
        const MDIdToken = idToken;

        const token = isMydex
          ? MDAccessToken
          : SAAccessToken
          ? SAAccessToken
          : MDIdToken;

        if (!token) return next.handle(req);

        const clonedReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`),
        });

        return next.handle(clonedReq);
      }),
      catchError((error) => {
        const authService = this.injector.get(AuthService);
        if (error.status === 412) {
          // 412 precondition failed  - user needs to authenticate with the mydex to map to scotAccount
          // Redirect to the Mydex login page
          authService.setRequiresMapping(true);
        }
        if (error.status === 401) {
          authService.refresh();
        }
        // Handle any errors in fetching the token or processing the request
        return throwError(() => error);
      })
    );
  }
}
