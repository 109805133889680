import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';
import { UserImageCreationRequest } from 'src/app/models/image/server/userImageCreationRequest';
import { UserImageCreationResponse } from 'src/app/models/image/server/userImageCreationResponse';
import { UserImageListResponse } from 'src/app/models/image/server/userImageListResponse';
import { UserImageUpdateRequest } from 'src/app/models/image/server/userImageUpdateRequest';
import { UserImagePdsEntry } from 'src/app/models/image/userImagePdsEntry';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserImagesApiService {
  private readonly baseUrl: string;
  http: any;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiBaseUrl + '/images';
    this.http = this.httpClient;
  }

  getAllUserImageThumbnails(): Observable<UserImagePdsEntry[]> {
    return this.http.get(this.baseUrl).pipe(
      map((response: UserImageListResponse) => {
        let userImages: UserImagePdsEntry[] = response.images.map(
          (userImage: UserImagePdsEntry) => new UserImagePdsEntry(userImage)
        );
        return userImages;
      })
    );
  }

  getUserImageFullSizeById(imageId: string): Observable<UserImagePdsEntry> {
    return this.http.get(`${this.baseUrl}?id=${imageId}`).pipe(
      map((response: UserImageListResponse) => {
        let userImages: UserImagePdsEntry[] = response.images.map(
          (userImage: UserImagePdsEntry) => new UserImagePdsEntry(userImage)
        );

        // Unexpected response from the server
        if (userImages.length != 1) {
          return throwError(
            () => new Error('Unexpected response from the PDS.')
          );
        }

        // Return the first image in the array (there should only be one)
        return userImages[0];
      })
    );
  }

  addUserImage(
    imageCreationRequest: UserImageCreationRequest
  ): Observable<UserImageCreationResponse> {
    return this.http
      .post(this.baseUrl, imageCreationRequest)
      .pipe(
        map(
          (response: UserImageCreationResponse) =>
            new UserImageCreationResponse(response)
        )
      );
  }

  updateUserImage(
    imageUpdateRequest: UserImageUpdateRequest
  ): Observable<string> {
    return this.http.put(this.baseUrl, imageUpdateRequest, {
      responseType: 'text',
    });
  }

  deleteUserImage(imageId: string): Observable<string> {
    return this.http.delete(`${this.baseUrl}/${imageId}`, {
      responseType: 'text',
    });
  }
}
