import {
  FormItem,
  FormItemTextbox,
  FormItemTitle,
} from 'src/app/models/form/form';
import {
  medicalHistoryDisabilityPerception,
  medicalHistoryDisabilityPerceptionAffect,
  medicalHistoryDisabilityRegistered,
  medicalHistoryDisabilityRegisteredNumber,
  medicalHistoryPastConditions,
  medicalHistoryPresentConditions,
  medicalHistorySurgicalInterventions,
} from './medical-history-items';
import {
  gpEmail,
  gpName,
  gpPhone,
  gpTitle,
  surgeryName,
} from '../key-info-items/medical-details-items';
import {
  address2,
  getFormItemsAddress,
  state,
} from '../../shared-items/address';
import { wellbeingTodo } from './wellbeing-items';
import {
  memoryAdditionalInfo,
  memoryAreasRequiringSupport,
  memoryConcernedSocialCircle,
  memoryConcernedSocialCircleDetails,
  memoryCondition,
  memoryDementiaDiagnosisDate,
  memoryEasyToRemember,
  memoryHardToRemember,
  memoryProblemAwareness,
  memorySupportingAids,
} from './memory-items';
import {
  stayingSafeHomeAdjustments,
  stayingSafeImpactsFeelingSafe,
  stayingSafeImpactsFeelingUnsafe,
} from './staying-safe-items';
import {
  importantAdditionalInfo,
  importantPreferredLifestyle,
  importantSupportNetwork,
  importantThings,
} from './important-to-me-items';
import {
  futurePlansImpactArrangementInPlacePoaContinuingWelfare,
  futurePlansImpactArrangementInPlacePoaFinancial,
} from '../supporting-role-items/future-plans-impact-items';

// All form items for user - health
export function getFormItemsUserHealth(): FormItem<any>[] {
  return [
    new FormItemTitle({ label: 'Medical History' }),
    ...getFormItemsUserHealthMedicalHistory(),
    new FormItemTitle({ label: 'Wellbeing' }),
    ...getFormItemsUserHealthWellbeing(),
    new FormItemTitle({ label: 'Memory' }),
    ...getFormItemsUserHealthMemory(),
    new FormItemTitle({ label: 'Staying Safe' }),
    ...getFormItemsUserHealthStayingSafe(),
    new FormItemTitle({ label: 'Important' }),
    ...getFormItemsUserHealthImportant(),
  ];
}

// All form items for user - health - medical history
export function getFormItemsUserHealthMedicalHistory(): FormItem<any>[] {
  return [
    medicalHistoryPresentConditions,
    medicalHistoryPastConditions,
    medicalHistorySurgicalInterventions,
    medicalHistoryDisabilityPerception,
    medicalHistoryDisabilityPerceptionAffect,
    medicalHistoryDisabilityRegistered,
    medicalHistoryDisabilityRegisteredNumber,
    // Reusing items from Key Information // TODO: figure out storage in pds
    gpTitle,
    surgeryName,
    gpName,
    ...getFormItemsAddress('keyInformation.medicalDetails.gp.address'),
    gpPhone,
    gpEmail,
  ];
}

export function getFormItemsUserHealthMedicalHistoryNegligibleForCompleteness(): FormItem<any>[] {
  // create copies of items to not change the original path (only path needed to determine if item is negligible)
  const address2Copy = new FormItemTextbox(address2);
  address2Copy.path = `keyInformation.medicalDetails.gp.address.address2`;

  const stateCopy = new FormItemTextbox(state);
  stateCopy.path = `keyInformation.medicalDetails.gp.address.state`;

  return [
    address2Copy,
    stateCopy,
    medicalHistorySurgicalInterventions, // possible to have had none
    medicalHistoryDisabilityPerceptionAffect, // optional display
  ];
}

// All form items for user - health - wellbeing
export function getFormItemsUserHealthWellbeing(): FormItem<any>[] {
  return [wellbeingTodo];
}

// All form items for user - health - wellbeing
export function getFormItemsUserHealthMemory(): FormItem<any>[] {
  return [
    memoryProblemAwareness,
    memoryEasyToRemember,
    memoryHardToRemember,
    memoryConcernedSocialCircle,
    memoryConcernedSocialCircleDetails,
    memoryDementiaDiagnosisDate,
    memoryAreasRequiringSupport,
    memorySupportingAids,
    memoryCondition,
    // Reusing items from Supporting Role - Future Plans Impact // TODO: figure out storage in pds
    futurePlansImpactArrangementInPlacePoaFinancial,
    futurePlansImpactArrangementInPlacePoaContinuingWelfare,
    memoryAdditionalInfo,
  ];
}

export function getFormItemsUserHealthMemoryNegligibleForCompleteness(): FormItem<any>[] {
  return [
    memoryConcernedSocialCircleDetails, // optional display
  ];
}

// All form items for user - health - staying safe
export function getFormItemsUserHealthStayingSafe(): FormItem<any>[] {
  return [
    stayingSafeImpactsFeelingSafe,
    stayingSafeImpactsFeelingUnsafe,
    stayingSafeHomeAdjustments,
  ];
}

// All form items for user - health - important
export function getFormItemsUserHealthImportant(): FormItem<any>[] {
  return [
    importantThings,
    importantPreferredLifestyle,
    importantSupportNetwork,
    importantAdditionalInfo,
  ];
}
