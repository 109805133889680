import { environment } from 'src/environments/environment';
import { take } from 'rxjs';
import { Injectable } from '@angular/core';
import { CookieConsentService } from '../cookie-consent/cookie-consent.service';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private trackingEnabled: boolean | undefined = undefined; // undefined = default = not yet enabled/disabled

  constructor(private cookieConsentService: CookieConsentService) {
    this.cookieConsentService.cookieConsentLoading$.subscribe((loading) => {
      if (loading) {
        return;
      }

      // when loading finished, subscribe to cookieConsent
      this.cookieConsentService.cookieConsent$
        .pipe(take(1))
        .subscribe((cookieConsent) => {
          // if cookie consent for GA is given and tracking is not yet enabled, enable tracking
          if (
            cookieConsent?.consentedGoogleAnalytics &&
            !this.trackingEnabled
          ) {
            this.initialize();
            this.enableGoogleAnalytics();
            return;
            // if there is no cookie consent at all or consent for GA specifically is not given, disable tracking
          } else if (
            !cookieConsent ||
            (!cookieConsent?.consentedGoogleAnalytics &&
              this.trackingEnabled !== false)
          ) {
            this.disableGoogleAnalytics();
          }
        });
    });
  }

  // PUBLIC API

  trackSignpostClickedInFinder(
    connectionName: string,
    serviceFinderPath: string
  ) {
    const customParams = {
      connection_name: connectionName,
      service_finder_path: serviceFinderPath,
    };

    if (!this.trackingEnabled) {
      return;
    }

    this.trackEvent(
      'finder_signpost_clicked',
      'finder',
      undefined,
      undefined,
      customParams
    );
  }

  trackConnectionOpenedInFinder(
    connectionName: string,
    serviceFinderPath: string
  ) {
    const customParams = {
      connection_name: connectionName,
      service_finder_path: serviceFinderPath,
    };

    if (!this.trackingEnabled) {
      return;
    }

    this.trackEvent(
      'finder_connection_opened',
      'finder',
      undefined,
      undefined,
      customParams
    );
  }

  trackSignpostClickedInSearch(connectionName: string) {
    const customParams = {
      connection_name: connectionName,
    };

    if (!this.trackingEnabled) {
      return;
    }

    this.trackEvent(
      'search_signpost_clicked',
      'search',
      undefined,
      undefined,
      customParams
    );
  }

  trackConnectionOpenedInSearch(connectionName: string) {
    const customParams = {
      connection_name: connectionName,
    };

    if (!this.trackingEnabled) {
      return;
    }

    this.trackEvent(
      'search_connection_opened',
      'search',
      undefined,
      undefined,
      customParams
    );
  }

  trackConnectionHyperlinkClicked(
    connectionName: string,
    hyperlinkType: 'website' | 'facebook'
  ) {
    const customParams = {
      connection_name: connectionName,
      hyperlink_type: hyperlinkType,
    };

    if (!this.trackingEnabled) {
      return;
    }

    this.trackEvent(
      'connection_hyperlink_clicked',
      'connections',
      undefined,
      undefined,
      customParams
    );
  }

  trackPdsReferralStarted(connectionName: string) {
    const customParams = {
      connection_name: connectionName,
    };

    if (!this.trackingEnabled) {
      return;
    }

    this.trackEvent(
      'pds_referral_started',
      'referrals',
      undefined,
      undefined,
      customParams
    );
  }

  trackPdsReferralCompleted(connectionName: string) {
    const customParams = {
      connection_name: connectionName,
    };

    if (!this.trackingEnabled) {
      return;
    }

    this.trackEvent(
      'pds_referral_completed',
      'referrals',
      undefined,
      undefined,
      customParams
    );
  }

  trackEmailReferralStarted(connectionName: string) {
    const customParams = {
      connection_name: connectionName,
    };

    if (!this.trackingEnabled) {
      return;
    }

    this.trackEvent(
      'email_referral_started',
      'referrals',
      undefined,
      undefined,
      customParams
    );
  }

  trackEmailReferralCompleted(
    connectionName: string,
    referralTarget: 'self' | 'someone else'
  ) {
    const customParams = {
      connection_name: connectionName,
      referral_target: referralTarget,
    };

    if (!this.trackingEnabled) {
      return;
    }

    this.trackEvent(
      'email_referral_completed',
      'referrals',
      undefined,
      undefined,
      customParams
    );
  }

  // PRIVATE API

  private initialize() {
    const gaId = environment?.gtagId;

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
    script.async = true;
    document.head.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', gaId);
    window.gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
    });
  }

  private enableGoogleAnalytics() {
    this.trackingEnabled = true;

    if (typeof window.gtag === 'function') {
      window.gtag('consent', 'update', {
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        ad_storage: 'denied',
        analytics_storage: 'granted',
      });
    }
  }

  private disableGoogleAnalytics() {
    this.trackingEnabled = false;

    if (typeof window.gtag === 'function') {
      window.gtag('consent', 'update', {
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        ad_storage: 'denied',
        analytics_storage: 'denied',
      });
    }

    // get all _ga cookies
    const cookies = document.cookie.split(';');
    const gaCookies = cookies.filter((cookie) =>
      cookie.trim().startsWith('_ga')
    );

    // get all possible cookie domains (https://stackoverflow.com/questions/2959010/how-to-get-the-domain-value-for-a-cookie-in-javascript)
    let domainParts = window.location.host.split('.');
    let possibleCookieDomains = [];
    for (let index = 0; index < domainParts.length; index++) {
      possibleCookieDomains.push(domainParts.slice(index).join('.'));
    }

    // remove all _ga cookies for all possible cookie domains
    gaCookies.forEach((cookie) => {
      const cookieName = cookie.split('=')[0];
      possibleCookieDomains.forEach((domain) => {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
      });
    });
  }

  private trackEvent(
    eventName: string,
    eventCategory?: string,
    eventLabel?: string,
    eventValue?: number,
    customParams?: { [key: string]: any }
  ) {
    // Ensure gtag is defined
    if (typeof window.gtag === 'function') {
      window.gtag('event', eventName, {
        event_category: eventCategory,
        event_label: eventLabel,
        value: eventValue,
        ...customParams,
      });
    }
  }
}
