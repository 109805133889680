import {
  FormItemButtonSelect,
  FormItemContacts,
  FormItemLink,
} from 'src/app/models/form/form';
import {
  getAllContactsForms,
  getContactsFormFullDetails,
} from '../forms-contacts';

// THIS IS ME - MY PEOPLE -------------------------------------------------------

export const mainPeopleInLife = new FormItemButtonSelect({
  key: 'mainPeopleInLife',
  path: 'me.myPeople.mainPeopleInLife',
  label: 'Main people in your life',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'partner_main_person_in_my_life', label: 'Partner' },
    { name: 'daughter_main_person_in_my_life', label: 'Daughter' },
    { name: 'son_main_person_in_my_life', label: 'Son' },
    { name: 'husband_main_person_in_my_life', label: 'Husband' },
    { name: 'wife_main_person_in_my_life', label: 'Wife' },
    { name: 'neighbour_main_person_in_my_life', label: 'Neighbour' },
    { name: 'sister_main_person_in_my_life', label: 'Sister' },
    { name: 'brother_main_person_in_my_life', label: 'Brother' },
    { name: 'mum_main_person_in_my_life', label: 'Mum' },
  ],
});

export const importantRelationships = new FormItemContacts({
  key: 'importantRelationships',
  path: 'me.myPeople.importantRelationships',
  label: 'Important Relationships',
  allContactEditorForms: getAllContactsForms(),
  initialSelectedFormIndex: 1,
  multiple: true,
  class: 'mb-5',
});

export const myPeopleLink = new FormItemLink({
  key: 'myPeopleLink',
  linkLabel: 'This is Me - My People',
  description: 'You can add more people from your life here:',
  linkType: 'routerlink',
  link: '/account/about-me/this-is-me/my-people',
  class: 'mb-5',
});
