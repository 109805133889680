import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemLink,
  FormItemTextbox,
} from 'src/app/models/form/form';
import { yesNoOptionsLegacy } from '../item-options';

export const willDocumentLocation = new FormItemTextbox({
  key: 'willDocumentLocation',
  path: 'planningAhead.will.documentLocation',
  label: 'Where do you keep your will?',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
  displayConditions: [
    {
      key: 'futurePlansImpactArrangementInPlaceWill',
      values: ['Yes', 'In progress'],
    },
  ],
});

export const willMoreInfoLink = new FormItemLink({
  key: 'willMoreInfoLink',
  linkLabel: 'http://dyingmatters.org/page/writing-will',
  description: 'More information about writing a will can be found at:',
  linkType: 'href',
  link: 'https://dyingmatters.org/page/writing-will',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const willHasOrganDonationCard = new FormItemButtonSelect({
  key: 'willHasOrganDonationCard',
  path: 'planningAhead.will.hasOrganDonationCard',
  label: 'Do you have an organ donation card?',
  class: 'mb-5',
  options: yesNoOptionsLegacy,
});
