import { Connection } from '../connection';
import { ConnectionDisplayLogic } from '../connectionDisplayLogic';
import {
  ConnectionOutput,
  OutputContact,
  OutputEmail,
  OutputPds,
  OutputRedirect,
} from '../connectionOutput';
import { ConnectionSummary } from '../connectionSummary';
import { EmailOutputFormSection } from '../outputFormSection';

export class ConnectionServerModel {
  // INFORMATION
  source: 'manual-input' | 'mrd';
  type: 'connection' | 'signpost';
  alissId?: string;
  name: string; // a unique name + used as the slug, regex: ^([a-z0-9]+-?)+$
  title: string; // 80 character title
  excerpt: string; // 200 character intro to the connection
  image: string; // URL to an image for the connection
  icon?: string; // Name of an icon or the SVG. The icon component will determine which it is by the <svg> tag
  priority: number; // used for ordering 1-5 from org perspective (1 is highest, 5 is lowest)
  priorityUser: number; // used for ordering 1-5 from user perspective (1 is highest, 5 is lowest)
  postcode?: string; // used for postcode lookup
  organisation?: string; // used for organisation lookup
  dataUsageStatement?: string; // What the connection intends to do with the data

  connectionData: ConnectionData;

  constructor(connection: Partial<ConnectionServerModel>) {
    this.source = connection.source || 'manual-input';
    this.alissId = connection.alissId;
    this.name = connection.name ?? 'no-connection-name';
    this.type = connection.type === 'signpost' ? 'signpost' : 'connection';
    this.title = connection.title ?? 'Connection Title missing';
    this.excerpt = connection.excerpt || '';
    this.image =
      connection.image ||
      'https://images.pexels.com/photos/5825696/pexels-photo-5825696.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
    this.icon = connection.icon;
    this.priority = connection.priority ?? 3;
    this.priorityUser = connection.priorityUser ?? 5;
    this.postcode = connection.postcode;
    this.organisation = connection.organisation;
    this.dataUsageStatement = connection.dataUsageStatement;
    this.connectionData = new ConnectionData(connection.connectionData);
  }

  public static fromConnection(connection: Connection): ConnectionServerModel {
    let serverModel = new ConnectionServerModel({});

    serverModel.source = connection.source;
    serverModel.alissId = connection.alissId;
    serverModel.name = connection.name;
    serverModel.type = connection.type;
    serverModel.title = connection.title;
    serverModel.excerpt = connection.excerpt;
    serverModel.image = connection.image;
    serverModel.icon = connection.icon;
    serverModel.priority = connection.priority;
    serverModel.priorityUser = connection.priorityUser;
    serverModel.postcode = connection.postcode;
    serverModel.organisation = connection.organisation;
    serverModel.dataUsageStatement = connection.dataUsageStatement;

    serverModel.connectionData.webLink = connection.webLink;
    serverModel.connectionData.appLink = connection.appLink;
    serverModel.connectionData.summary = connection.summary;
    serverModel.connectionData.finderPaths = connection.finderPaths;
    serverModel.connectionData.data = connection.emailOutputForm;
    serverModel.connectionData.outputFormThem = connection.emailOutputFormThem;
    serverModel.connectionData.useSeparateOutputFormThem =
      connection.useSeparateEmailOutputFormThem;
    serverModel.connectionData.displayInPublicFinder =
      connection.displayInPublicFinder;
    serverModel.connectionData.displayLogic = connection.displayLogic;
    serverModel.connectionData.outputs = connection.outputs;

    return serverModel;
  }
}

export class ConnectionData {
  // LINKS
  webLink?: {
    url: string;
    text: string;
  };

  appLink?: {
    url: string;
    text: string;
  };

  // CONTENT
  summary?: ConnectionSummary;

  // OUTPUTS
  outputs: ConnectionOutput<
    OutputPds | OutputEmail | OutputRedirect | OutputContact
  >[];

  // EMAIL OUTPUT FORM
  data: EmailOutputFormSection[]; // legacy naming, should be 'emailOutputForm'
  outputFormThem?: EmailOutputFormSection[]; // legacy naming, should be 'emailOutputFormThem'
  useSeparateOutputFormThem?: boolean; // legacy naming, should be 'useSeparateEmailOutputFormThem'

  // DISPLAY
  finderPaths: string[];
  displayInPublicFinder: boolean;
  displayLogic?: ConnectionDisplayLogic; // not modeled out in backend yet

  constructor(data?: Partial<ConnectionData>) {
    this.webLink = data?.webLink;
    this.appLink = data?.appLink;
    this.summary = new ConnectionSummary(data?.summary);
    this.finderPaths = data?.finderPaths ?? [];
    this.data = data?.data ?? [];
    this.outputFormThem = data?.outputFormThem;
    this.useSeparateOutputFormThem = data?.useSeparateOutputFormThem ?? false;
    this.displayInPublicFinder = data?.displayInPublicFinder ?? true;
    this.displayLogic = data?.displayLogic;
    this.outputs = data?.outputs ?? [];
  }
}
