import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';
import {
  FormItemButtonSelect,
  FormItemSelect,
  FormItemTextArea,
  FormItemTextbox,
} from 'src/app/models/form/form';
import { yesNoOptions } from './item-options';

export const medicationName = new FormItemSelect({
  key: 'name',
  label: 'What medication do you take?',
  class: 'mb-5',
  placeholder: 'Please select...',
  help: "If you cannot find your medication, please select 'Other' and enter the name below",
  multiple: false,
  required: true,
  validators: [Validators.required],
  options: [
    { name: 'Aciclovir' },
    { name: 'Acrivastine' },
    { name: 'Adalimumab' },
    { name: 'Alendronic acid' },
    { name: 'Allopurinol' },
    { name: 'Alogliptin' },
    { name: 'Amitriptyline for depression' },
    { name: 'Amitriptyline for pain' },
    { name: 'Amlodipine' },
    { name: 'Amoxicillin' },
    { name: 'Anastrozole' },
    { name: 'Apixaban' },
    { name: 'Aripiprazole' },
    { name: 'Aspirin for pain relief' },
    { name: 'Atenolol' },
    { name: 'Atorvastatin' },
    { name: 'Azathioprine' },
    { name: 'Azithromycin' },
    { name: 'Baclofen' },
    { name: 'Beclometasone inhalers' },
    { name: 'Beclometasone nasal spray' },
    { name: 'Beclometasone skin creams' },
    { name: 'Beclometasone tablets' },
    { name: 'Bendroflumethiazide' },
    { name: 'Benzoyl peroxide' },
    { name: 'Benzydamine' },
    { name: 'Betahistine' },
    { name: 'Betamethasone eyes ears and nose' },
    { name: 'Betamethasone skin' },
    { name: 'Betamethasone tablets' },
    { name: 'Bimatoprost' },
    { name: 'Bisacodyl' },
    { name: 'Bisoprolol' },
    { name: 'Brinzolamide' },
    { name: 'Budesonide inhalers' },
    { name: 'Budesonide nasal spray' },
    { name: 'Budesonide rectal foam and enemas' },
    { name: 'Budesonide tablets capsules and granules' },
    { name: 'Bumetanide' },
    { name: 'Buprenorphine for pain' },
    { name: 'Buscopan hyoscine butylbromide' },
    { name: 'Calcipotriol' },
    { name: 'Candesartan' },
    { name: 'Carbamazepine' },
    { name: 'Carbimazole' },
    { name: 'Carbocisteine' },
    { name: 'Carmellose sodium' },
    { name: 'Carvedilol' },
    { name: 'Cefalexin' },
    { name: 'Cetirizine' },
    { name: 'Champix varenicline' },
    { name: 'Chloramphenicol' },
    { name: 'Chlorhexidine' },
    { name: 'Chlorphenamine including piriton' },
    { name: 'Cinnarizine' },
    { name: 'Ciprofloxacin' },
    { name: 'Citalopram' },
    { name: 'Clarithromycin' },
    { name: 'Clobetasol' },
    { name: 'Clobetasone' },
    { name: 'Clonazepam' },
    { name: 'Clonidine' },
    { name: 'Clopidogrel' },
    { name: 'Clotrimazole' },
    { name: 'Clotrimazole for thrush' },
    { name: 'Co-amoxiclav' },
    { name: 'Co-beneldopa' },
    { name: 'Co-careldopa' },
    { name: 'Co-codamol for adults' },
    { name: 'Co-codamol for children' },
    { name: 'Co-codaprin' },
    { name: 'Co-dydramol' },
    { name: 'Coal tar' },
    { name: 'Codeine' },
    { name: 'Colchicine' },
    { name: 'Colecalciferol' },
    { name: 'Contraceptive injections medroxyprogesterone' },
    { name: 'Cyanocobalamin' },
    { name: 'Cyclizine' },
    { name: 'Dabigatran' },
    { name: 'Dapagliflozin' },
    { name: 'Dexamethasone eye drops' },
    { name: 'Dexamethasone tablets and liquid' },
    { name: 'Diazepam' },
    { name: 'Diclofenac' },
    { name: 'Digoxin' },
    { name: 'Dihydrocodeine' },
    { name: 'Diltiazem' },
    { name: 'Diphenhydramine' },
    { name: 'Dipyridamole' },
    { name: 'Docusate' },
    { name: 'Domperidone' },
    { name: 'Donepezil' },
    { name: 'Dosulepin' },
    { name: 'Doxazosin' },
    { name: 'Doxycycline' },
    { name: 'Duloxetine' },
    { name: 'Edoxaban' },
    { name: 'Empagliflozin' },
    { name: 'Enalapril' },
    { name: 'Eplerenone' },
    { name: 'Erythromycin' },
    { name: 'Escitalopram' },
    { name: 'Esomeprazole' },
    { name: 'Ezetimibe' },
    { name: 'Felodipine' },
    { name: 'Fentanyl' },
    { name: 'Ferrous fumarate' },
    { name: 'Ferrous sulfate' },
    { name: 'Fexofenadine' },
    { name: 'Finasteride' },
    { name: 'Flucloxacillin' },
    { name: 'Fluconazole' },
    { name: 'Fluoxetine prozac' },
    { name: 'Fluticasone inhalers' },
    { name: 'Fluticasone nasal spray and drops' },
    { name: 'Fluticasone skin creams' },
    { name: 'Folic acid' },
    { name: 'Furosemide' },
    { name: 'Fusidic acid' },
    { name: 'Fybogel ispaghula husk' },
    { name: 'Gabapentin' },
    { name: 'Gaviscon' },
    { name: 'Gliclazide' },
    { name: 'Glimepiride' },
    { name: 'Glyceryl trinitrate gtn' },
    { name: 'Haloperidol' },
    { name: 'Heparinoid' },
    { name: 'Hormone replacement therapy hrt' },
    { name: 'Hydrocortisone' },
    { name: 'Hydrocortisone buccal tablets' },
    { name: 'Hydrocortisone for piles and itchy bottom' },
    { name: 'Hydrocortisone for skin' },
    { name: 'Hydrocortisone injections' },
    { name: 'Hydrocortisone rectal foam' },
    { name: 'Hydrocortisone tablets' },
    { name: 'Hydroxocobalamin' },
    { name: 'Hydroxychloroquine' },
    { name: 'Hyoscine hydrobromide' },
    { name: 'Ibuprofen and codeine nurofen plus' },
    { name: 'Ibuprofen for adults' },
    { name: 'Ibuprofen for children' },
    { name: 'Indapamide' },
    { name: 'Insulin' },
    { name: 'Irbesartan' },
    { name: 'Isosorbide mononitrate dinitrate' },
    { name: 'Isotretinoin capsules' },
    { name: 'Isotretinoin gel' },
    { name: 'Ketoconazole' },
    { name: 'Labetalol' },
    { name: 'Lactulose' },
    { name: 'Lamotrigine' },
    { name: 'Lansoprazole' },
    { name: 'Latanoprost' },
    { name: 'Lercanidipine' },
    { name: 'Letrozole' },
    { name: 'Levetiracetam' },
    { name: 'Levothyroxine' },
    { name: 'Lidocaine for mouth and throat' },
    { name: 'Lidocaine for piles and itchy bottom' },
    { name: 'Lidocaine skin cream' },
    { name: 'Linagliptin' },
    { name: 'Lisinopril' },
    { name: 'Lithium' },
    { name: 'Loperamide' },
    { name: 'Loratadine' },
    { name: 'Lorazepam' },
    { name: 'Losartan' },
    { name: 'Low dose aspirin' },
    { name: 'Lymecycline' },
    { name: 'Macrogol' },
    { name: 'Mebendazole' },
    { name: 'Mebeverine' },
    { name: 'Medroxyprogesterone tablets' },
    { name: 'Melatonin' },
    { name: 'Memantine' },
    { name: 'Mesalazine' },
    { name: 'Metformin' },
    { name: 'Methadone' },
    { name: 'Methotrexate' },
    { name: 'Methylphenidate adults' },
    { name: 'Methylphenidate children' },
    { name: 'Metoclopramide' },
    { name: 'Metoprolol' },
    { name: 'Metronidazole' },
    { name: 'Mirabegron' },
    { name: 'Mirtazapine' },
    { name: 'Molnupiravir' },
    { name: 'Mometasone for skin' },
    { name: 'Mometasone inhaler' },
    { name: 'Mometasone nasal spray' },
    { name: 'Montelukast' },
    { name: 'Morphine' },
    { name: 'Naproxen' },
    { name: 'Nefopam' },
    { name: 'Nicorandil' },
    { name: 'Nifedipine' },
    { name: 'Nitrofurantoin' },
    { name: 'Nortriptyline' },
    { name: 'Nystatin' },
    { name: 'Olanzapine' },
    { name: 'Olmesartan' },
    { name: 'Omeprazole' },
    { name: 'Oxybutynin' },
    { name: 'Oxycodone' },
    { name: 'Pantoprazole' },
    { name: 'Paracetamol for adults' },
    { name: 'Paracetamol for children' },
    { name: 'Paroxetine' },
    { name: 'Paxlovid' },
    { name: 'Peppermint oil' },
    { name: 'Pepto bismol' },
    { name: 'Perindopril' },
    { name: 'Phenoxymethylpenicillin' },
    { name: 'Phenytoin' },
    { name: 'Pioglitazone' },
    { name: 'Pravastatin' },
    { name: 'Pre exposure prophylaxis prep' },
    { name: 'Prednisolone' },
    { name: 'Pregabalin' },
    { name: 'Prochlorperazine' },
    { name: 'Promethazine' },
    { name: 'Propranolol' },
    { name: 'Pseudoephedrine' },
    { name: 'Quetiapine' },
    { name: 'Rabeprazole' },
    { name: 'Ramipril' },
    { name: 'Ranitidine' },
    { name: 'Remdesivir veklury' },
    { name: 'Risedronate' },
    { name: 'Risperidone' },
    { name: 'Rivaroxaban' },
    { name: 'Ropinirole' },
    { name: 'Rosuvastatin' },
    { name: 'Salbutamol inhaler' },
    { name: 'Saxagliptin' },
    { name: 'Senna' },
    { name: 'Sertraline' },
    { name: 'Sildenafil viagra' },
    { name: 'Simeticone' },
    { name: 'Simvastatin' },
    { name: 'Sitagliptin' },
    { name: 'Sodium cromoglicate capsules' },
    { name: 'Sodium cromoglicate eye drops' },
    { name: 'Sodium valproate' },
    { name: 'Solifenacin' },
    { name: 'Sotalol' },
    { name: 'Sotrovimab' },
    { name: 'Spironolactone' },
    { name: 'Sulfasalazine' },
    { name: 'Sumatriptan' },
    { name: 'Tadalafil' },
    { name: 'Tamsulosin' },
    { name: 'Temazepam' },
    { name: 'Terbinafine' },
    { name: 'Thiamine vitamin b1' },
    { name: 'Ticagrelor' },
    { name: 'Timolol eye drops' },
    { name: 'Timolol tablets' },
    { name: 'Tiotropium inhalers' },
    { name: 'Tolterodine' },
    { name: 'Topiramate' },
    { name: 'Tramadol' },
    { name: 'Tranexamic acid' },
    { name: 'Trastuzumab herceptin' },
    { name: 'Trazodone' },
    { name: 'Trimethoprim' },
    { name: 'Valproic acid' },
    { name: 'Valsartan' },
    { name: 'Venlafaxine' },
    { name: 'Verapamil' },
    { name: 'Warfarin' },
    { name: 'Zolpidem' },
    { name: 'Zopiclone' },
    {
      name: 'Other:',
      label: 'Other',
    },
  ],
});

const medicationNameOther = new FormItemTextbox({
  key: 'nameOther',
  label: 'Enter the name:',
  placeholder: 'e.g. homeopathic, alternative medicine',
  class: 'mb-5',
  validators: [
    Validators.required,
    Validators.minLength(1),
    maxByteValidator(2000),
  ],
  required: true,
  displayConditions: [
    {
      key: 'name',
      values: ['Other:'],
    },
  ],
});

const medicationDose = new FormItemTextbox({
  key: 'dose',
  label: 'Dose',
  placeholder: 'e.g. 100mg, 5ml, 1 capsule',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

const medicationFrequency = new FormItemTextbox({
  key: 'frequency',
  label: 'Frequency',
  placeholder: 'e.g. 3 times a day',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

const medicationGuidance = new FormItemTextbox({
  key: 'guidance',
  label: 'Guidance',
  placeholder: 'e.g. take with water',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

const medicationPrescriberName = new FormItemTextbox({
  key: 'prescriberName',
  label: 'Prescribed by',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

// TODO: add prescriptionStartDate once Mydex allows for null

const medicationDispensingPharmacy = new FormItemTextbox({
  key: 'dispensingPharmacy',
  label: 'Dispensing pharmacy',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

const medicationIsRepeatPrescription = new FormItemButtonSelect({
  key: 'isRepeatPrescription',
  label: 'Repeat prescription',
  class: 'mb-5',
  options: yesNoOptions,
});

// TODO: add repeatPrescriptionDays once Mydex allows for null
// TODO: add repeatPrescriptionQuantity once Mydex allows for null
// TODO: add lastIssuedDate once Mydex allows for null

const medicationNotes = new FormItemTextArea({
  key: 'notes',
  label: 'Medication Note',
  placeholder: 'e.g. do not take with dairy products',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const medicationForm = [
  medicationName,
  medicationNameOther,
  medicationDose,
  medicationFrequency,
  medicationGuidance,
  medicationPrescriberName,
  medicationDispensingPharmacy,
  medicationIsRepeatPrescription,
  medicationNotes,
];
