<!--LOADING SPINNER-->
<app-loading-spinner
  *ngIf="
    ((connectionsStateService.loading$ | async) &&
      !(connectionsStateService.loadingError$ | async)) ||
    ((userService.loading$ | async) && !(userService.loadingError$ | async)) ||
    (step === 'form' && (userService.saving$ | async))
  "
></app-loading-spinner>
<!--END LOADING SPINNER-->

<!-- SERVER ERROR: FAILED LOADING CONNECTIONS -->
<app-no-items-error
  [isDisplayed]="
    !(connectionsStateService.loading$ | async) &&
    (connectionsStateService.loadingError$ | async)
  "
  [errorMessage]="connectionsStateService.loadingError$ | async"
  showBackButton="true"
>
</app-no-items-error>
<!-- END SERVER ERROR: FAILED LOADING CONNECTIONS -->

<!-- SERVER ERROR: FAILED LOADING USER -->
<!-- Connections error has higher priority - so only that is shown if both fail -->
<!-- Only show user error if user is authenticated -->
<app-no-items-error
  [isDisplayed]="
    !(userService.loading$ | async) &&
    (userService.loadingError$ | async) &&
    (authService.isAuthenticated$ | async) &&
    !(connectionsStateService.loadingError$ | async)
  "
  [errorMessage]="userService.loadingError$ | async"
  showBackButton="true"
>
</app-no-items-error>
<!-- END SERVER ERROR: FAILED LOADING USER -->

<!-- CONTENT -->
<ng-container
  *ngIf="
    !(connectionsStateService.loading$ | async) &&
    !(connectionsStateService.loadingError$ | async) &&
    !(userService.loading$ | async) &&
    !(userService.loadingError$ | async) &&
    (!(userService.saving$ | async) || step !== 'form')
  "
>
  <!-- ERROR MESSAGE: CONNECTION NOT FOUND -->
  <app-no-items-error
    [isDisplayed]="!connection"
    errorMessage="Unable to find a connection with this name."
    showBackButton="true"
  >
  </app-no-items-error>
  <!--END ERROR MESSAGE: CONNECTION NOT FOUND -->

  <!-- ERROR MESSAGE: CONNECTION HAS NO EMAIL REFERRAL SET UP -->
  <app-no-items-error
    [isDisplayed]="!connectionOutput"
    errorMessage="This connection has no contact form."
    showBackButton="true"
  >
  </app-no-items-error>
  <!--END ERROR MESSAGE: CONNECTION HAS NO EMAIL REFERRAL SET UP -->

  <ng-container *ngIf="connection && connectionOutput">
    <div [ngSwitch]="step" class="mx-auto max-w-5xl box-border">
      <!-- TITLE -->
      <button
        class="btn-inline underline opacity-50 hover:opacity-100 transition-opacity font-semibold p-0 m-0"
        (click)="navigateBackToConnection()"
      >
        Back
      </button>

      <h3 class="text-2xl italic font-thin opacity-80">
        {{ connection?.title }} - Contact Form
      </h3>
      <!-- END TITLE -->

      <!-- CONNECTION FORM -->
      <div *ngSwitchCase="'form'">
        <div
          class="w-full bg-white flex flex-col rounded-lg overflow-hidden p-12 md:p-16 box-border"
        >
          <h2 class="text-theme text-3xl font-semibold mt-0 mb-1">
            The details we need.
          </h2>

          <!-- INTRO: AUTHED USER -->
          <p class="mb-8" *ngIf="authService.isAuthenticated$ | async">
            Below are the details that
            {{ connection?.organisation ?? connection.title }} needs so that
            they can help you best. We have added the details we already have
            but please fill in anything missing. We will also add any new
            details to your <strong>About Me</strong> when you complete the
            referral.
          </p>
          <!-- END INTRO: AUTHED USER -->

          <!-- INTRO: UNAUTHED USER -->
          <p class="mb-8" *ngIf="!(authService.isAuthenticated$ | async)">
            Below are the details that
            {{ connection?.organisation ?? connection.title }} needs so that
            they can help you best. After you complete the referral you can
            create an <strong>About Me</strong> to save time in the future. If
            you do, any details you enter now will be added for the initial set
            up but can always be changed again later.
          </p>
          <!-- END INTRO: UNAUTHED USER -->

          <div class="pb-6 border-0 border-b border-solid border-gray-200">
            <!-- SELECT REFERRAL SUBJECT -->
            <div class="flex justify-start mb-2">
              <h2 class="whitespace-nowrap my-auto mr-1 text-xl">
                I'm referring
              </h2>
              <app-button-select
                class="ml-2 my-auto mr-2 referral-type"
                [(ngModel)]="selectedReferralSubject"
                (ngModelChange)="setFormItemsForSelectedReferralSubject()"
                [options]="referralSubjectOptions"
                [requireOneSelection]="true"
              ></app-button-select>
            </div>
            <!-- END SELECT REFERRAL SUBJECT -->

            <!-- HINT SELECT REFERRAL SUBJECT: AUTHED USER -->
            <div *ngIf="authService.isAuthenticated$ | async">
              <p class="mb-1 mt-4">
                You can add more people from your life here:
              </p>
              <a
                routerLink="/account/about-me/this-is-me/my-people"
                class="mt-0 mb-1 text-theme"
              >
                This is Me - My People
              </a>
            </div>
            <!-- END HINT REFERRAL SUBJECT: AUTHED USER -->
          </div>

          <!-- FORM -->
          <app-form
            [formData]="referralForm"
            [formValues]="formValues"
            (submit)="handleContinue($event)"
            submitText="Continue"
            [cancellable]="true"
            [loading]="userService.loading$ | async"
            cancelText="Back"
            class="connection-form"
            (cancel)="navigateBackToConnection()"
          >
          </app-form>
          <!-- END FORM -->
        </div>
      </div>
      <!-- END CONNECTION FORM -->

      <!-- CONNECTION PERMISSIONS -->
      <div *ngSwitchCase="'permissions'">
        <div
          class="w-full bg-white flex flex-col rounded-lg overflow-hidden p-12 md:p-16 box-border"
        >
          <h2 class="text-theme text-3xl font-semibold mt-0 mb-2">
            Before we share…
          </h2>

          <!-- AUTHED USER: INTRO -->
          <p *ngIf="authService.isAuthenticated$ | async">
            We'll be sharing these details with
            {{ connection?.organisation ?? connection.title }} via email. Please
            read over and click 'Complete Referral' to confirm that you're happy
            for us to do so. We will also update your
            <strong>About Me</strong> with any new information.
          </p>
          <!-- END AUTHED USER: INTRO -->

          <!-- UNAUTHED USER: INTRO -->
          <p *ngIf="!(authService.isAuthenticated$ | async)">
            We'll be sharing these details with
            {{ connection?.organisation ?? connection.title }} via email. Please
            read over and click 'Complete Referral' to confirm that you're happy
            for us to do so.
          </p>
          <!-- END UNAUTHED USER: INTRO -->

          <!-- DETAILS: REFERRING SOMEONE ELSE -->
          <div class="grid gap-4">
            <div *ngIf="dataSharingFields?.them.length">
              <p class="italic">
                {{ themPersonName ? themPersonName + "'s" : "Their" }} details
              </p>
              <div class="bg-light-grey rounded-md bg-opacity-50">
                <table class="whitespace-nowrap py-4 px-8 table-fixed w-full">
                  <tbody>
                    <tr *ngFor="let field of dataSharingFields.them">
                      <td class="opacity-50 text-ellipsis overflow-hidden">
                        {{ field?.name }}:
                      </td>
                      <td class="italic pl-8 text-ellipsis overflow-hidden">
                        {{ field?.value | joinArray }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- END DETAILS: REFERRING SOMEONE ELSE -->

            <!-- DETAILS: SELF-REFERRAL -->
            <div>
              <p class="italic" *ngIf="dataSharingFields?.me.length">
                My details
              </p>
              <div class="bg-light-grey rounded-md bg-opacity-50">
                <table class="whitespace-nowrap py-4 px-8 table-fixed w-full">
                  <tbody>
                    <tr *ngFor="let field of dataSharingFields.me">
                      <td class="opacity-50 text-ellipsis overflow-hidden">
                        {{ field?.name }}:
                      </td>
                      <td class="italic pl-8 text-ellipsis overflow-hidden">
                        {{ field?.value | joinArray }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- END DETAILS: SELF-REFERRAL -->

          <!-- DATA USAGE STATEMENT -->
          <div
            class="bg-light-grey pt-2 pb-4 px-8 bg-opacity-50 rounded-md mt-10"
            *ngIf="connection.dataUsageStatement"
          >
            <h4>How this service uses the provided data</h4>
            <p>{{ connection?.dataUsageStatement }}</p>
          </div>
          <!-- END DATA USAGE STATEMENT -->

          <!-- PERMISSION TO SHARE -->
          <div
            *ngIf="selectedReferralSubject !== 'me'"
            class="form-text flex mt-12 -mb-6"
          >
            <label
              class="custom-checkbox cursor flex cursor-pointer mb-0 mr-auto items-center"
            >
              <input
                type="checkbox"
                class="hidden"
                (change)="permissionToReferSomeoneElse = $event.target.checked"
              />
              <div
                class="checkbox w-4 h-4 rounded border border-solid flex transition-colors my-auto mr-4"
                tabindex="0"
                role="checkbox"
                [attr.aria-checked]="permissionToReferSomeoneElse"
                (keydown.enter)="
                  permissionToReferSomeoneElse = !permissionToReferSomeoneElse
                "
                (keydown.space)="
                  permissionToReferSomeoneElse = !permissionToReferSomeoneElse;
                  $event.preventDefault()
                "
                [ngClass]="
                  permissionToReferSomeoneElse
                    ? 'bg-theme border-theme'
                    : 'bg-white border-gray-500'
                "
              >
                <app-icon name="check" class="text-white"></app-icon>
              </div>

              <span class="mt-auto mb-auto">
                I have permission to share this person's details with Community
                Connections and
                {{ connection?.organisation ?? connection.title }}.
                <span class="text-theme">*</span>
              </span>
            </label>
          </div>
          <!-- END PERMISSION TO SHARE -->

          <!-- AGREEMENT TO PROCESS DATA -->
          <div class="form-text flex mt-12">
            <label
              class="custom-checkbox cursor flex cursor-pointer mb-0 mr-auto items-center"
            >
              <input
                type="checkbox"
                class="hidden"
                (change)="agreementDataProcessing = $event.target.checked"
              />
              <div
                class="checkbox w-4 h-4 rounded border border-solid flex transition-colors my-auto mr-4"
                tabindex="0"
                role="checkbox"
                [attr.aria-checked]="agreementDataProcessing"
                (keydown.enter)="
                  agreementDataProcessing = !agreementDataProcessing
                "
                (keydown.space)="
                  agreementDataProcessing = !agreementDataProcessing;
                  $event.preventDefault()
                "
                [ngClass]="
                  agreementDataProcessing
                    ? 'bg-theme border-theme'
                    : 'bg-white border-gray-500'
                "
              >
                <app-icon name="check" class="text-white"></app-icon>
              </div>

              <span class="mt-auto mb-auto">
                I consent to the processing of this data in accordance with
                Community Connections'
                <a
                  class="text-theme underline"
                  routerLink="/privacy-policy"
                  target="_blank"
                  >Privacy Policy</a
                >. The provided details may be used by Community Connections and
                {{ connection?.organisation ?? connection.title }} for contact
                purposes and as stated above.
                <span class="text-theme">*</span>
              </span>
            </label>
          </div>
          <!-- END AGREEMENT TO PROCESS DATA -->

          <!-- BACK AND SUBMIT BUTTONS -->
          <div class="flex w-full justify-end mt-12 items-end">
            <button
              class="btn-inline underline opacity-50 hover:opacity-100 transition-opacity font-semibold mr-8"
              (click)="toStep('form')"
            >
              Back
            </button>

            <button
              type="button"
              (click)="handleComplete()"
              class="btn-theme px-8 py-4"
            >
              <span *ngIf="!(emailService.sending$ | async)"
                >Complete Referral</span
              >
              <app-loading-spinner
                class="opacity-75"
                *ngIf="emailService.sending$ | async"
              ></app-loading-spinner>
            </button>
          </div>
          <p class="text-error-red text-right -mb-2">
            {{ emailService.sendingError$ | async }}
          </p>
          <p
            *ngIf="requiredFieldsError"
            class="text-error-red text-right -mb-2"
          >
            {{ requiredFieldsError }}
          </p>
          <!-- END BACK AND SUBMIT BUTTONS -->
        </div>
      </div>
      <!-- END CONNECTION PERMISSIONS -->

      <!-- CONNECTION COMPLETE -->
      <div *ngSwitchCase="'complete'">
        <div
          class="w-full bg-white flex flex-col rounded-lg overflow-hidden p-12 md:p-16 box-border gap-8"
        >
          <div>
            <!-- GENERAL COMPLETED MESSAGE -->
            <h2 class="text-theme text-3xl font-semibold mt-0 mb-5">
              All sent.
            </h2>
            <p class="m-0">
              {{
                connection?.referralEmailText ??
                  "Thanks for completing the referral. We've passed your info to " +
                    (connection?.organisation ?? connection?.title) +
                    " and they'll get in touch."
              }}
            </p>
          </div>
          <!-- END GENERAL COMPLETED MESSAGE -->

          <!-- UNVERIFIED USER: NEXT STEPS -->
          <div
            *ngIf="
              (!pdsConnectionStatus ||
                pdsConnectionStatus?.isValid === false) &&
              selectedReferralSubject == 'me'
            "
            class="mb-6"
          >
            <h3 class="mb-2">Next Steps...</h3>
            <p class="m-0 mb-6">
              To save these and more details for future use, you can setup an
              <strong>About Me</strong>
              below. This will save you time in the future and help us to find
              the best support for you.
            </p>
          </div>
          <!-- END UNVERIFIED USER: NEXT STEPS -->

          <!-- VERIFIED USER: ABOUT ME INFO -->
          <!-- VERIFIED USER: LOADING SPINNER -->
          <app-loading-spinner
            *ngIf="
              pdsConnectionStatus?.isValid &&
              !(userService.savingError$ | async) &&
              (userService.saving$ | async) &&
              selectedReferralSubject == 'me'
            "
            text="Updating your About Me"
            class="inline-block mb-6"
          >
          </app-loading-spinner>
          <!-- END VERIFIED USER: LOADING SPINNER -->

          <!-- VERIFIED USER: SUCCESS MESSAGE -->
          <div
            *ngIf="
              pdsConnectionStatus?.isValid &&
              !(userService.savingError$ | async) &&
              !(userService.saving$ | async) &&
              selectedReferralSubject == 'me'
            "
          >
            <p class="mb-6 mt-0">
              We've updated your <strong>About Me</strong> with any new
              information, click below to see.
            </p>
          </div>
          <!-- END VERIFIED USER: SUCCESS MESSAGE -->

          <!-- VERIFIED USER: ERROR MESSAGE -->
          <div
            *ngIf="
              pdsConnectionStatus?.isValid &&
              (userService.savingError$ | async) &&
              !(userService.saving$ | async) &&
              selectedReferralSubject == 'me'
            "
          >
            <p class="mb-6 mt-2 text-error-red">
              Unfortunately, an issue occured and we were unable to update your
              <strong>About Me</strong>.
            </p>
          </div>
          <!-- END VERIFIED USER: ERROR MESSAGE -->
          <!-- END VERIFIED USER: ABOUT ME INFO -->

          <!-- SELF-REFFERAL: BUTTONS -->
          <div class="flex">
            <a
              [routerLink]="
                pdsConnectionStatus?.isValid ? '/account/about-me' : '/register'
              "
              class="mr-6"
              *ngIf="selectedReferralSubject == 'me'"
            >
              <button class="btn-theme outlined px-8">About Me</button>
            </a>
            <a routerLink="/finder">
              <button class="btn-theme-outline px-8">
                Back to Find Services
              </button>
            </a>
          </div>
        </div>
        <!-- END SELF-REFFERAL: BUTTONS -->

        <!-- REFFERED SOMEONE ELSE: MESSAGE AND BUTTON -->
        <div
          *ngIf="selectedReferralSubject !== 'me'"
          class="w-full bg-white flex flex-col rounded-lg overflow-hidden p-12 mt-4 md:p-16 box-border"
        >
          <h2 class="text-theme text-3xl font-semibold mt-0 mb-2">
            Carer support is available
          </h2>
          <p class="mb-12">
            Thank you for making a community connection on behalf of someone
            else. Did you know that there is lots of support available for those
            with caring responsibilities. To find out more and to understand the
            level of support you're giving, please fill out our questionnaire
            for carers.
          </p>
          <a class="btn-theme btn outlined px-8 mr-auto" routerLink="/carer">
            Get started
          </a>
        </div>
        <!-- END REFFERED SOMEONE ELSE: MESSAGE AND BUTTON -->
      </div>
    </div>
  </ng-container>
  <!-- END CONNECTION COMPLETE -->
</ng-container>
<!-- END CONTENT -->
