import { FormItemMedications, FormItemTitle } from 'src/app/models/form/form';
import { medicationForm } from '../form-medication';

export const medications = new FormItemMedications({
  key: 'medications',
  path: 'support.medication.medications',
  label: 'List of Medications',
  help: "Add the medications you are currently taking. You can also save other medications in your Personal Data Store (PDS) for future reference or use. To specify which medications you are actively taking right now, tick the checkbox next to each one. Do not forget to click 'Save' on the bottom of the page if you make changes to your selection.",
  medicationForm: medicationForm,
  class: 'mb-5',
});

export const prescriptionFeatureComingSoonTitle = new FormItemTitle({
  key: 'prescriptionFeatureComingSoonTitle',
  label: 'Prescriptions',
  class: 'mt-2',
  titleType: 'h4',
});

export const prescriptionFeatureComingSoonDescription = new FormItemTitle({
  key: 'prescriptionFeatureComingSoonDescription',
  label: 'Feature to add prescriptions coming soon...',
  class: 'mt-2 mb-5',
  titleType: 'p',
});
