import { Validators } from '@angular/forms';
import {
  FormItemRepeater,
  FormItemTextArea,
  FormItemTextbox,
  FormItemTitle,
} from 'src/app/models/form/form';

// -------------------------------------------- TITLE --------------------------------------------

export const summaryFormTitle = new FormItemTitle({
  label: 'Detailed Information',
  class: 'mb-2',
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

// -------------------------------------------- SUMMARY --------------------------------------------

export const summaryTitle = new FormItemTextbox({
  key: 'summaryTitle',
  path: 'summary.title',
  label: 'Details - Title',
  validators: [Validators.minLength(1), Validators.maxLength(80)],
  class: 'mb-5',
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

export const summaryHeadline = new FormItemTextbox({
  key: 'summaryHeadline',
  path: 'summary.headline',
  label: 'Details - Headline',
  validators: [Validators.minLength(1), Validators.maxLength(200)],
  class: 'mb-5',
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

export const summaryDescription = new FormItemTextArea({
  key: 'summaryDescription',
  path: 'summary.description',
  label: 'Details - Description',
  validators: [Validators.minLength(1), Validators.maxLength(3000)],
  class: 'mb-5',
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

export const summaryHighlights = new FormItemRepeater({
  key: 'summaryHighlights',
  path: 'summary.highlights',
  label: 'Details - Highlights',
  class: 'mb-5',
  formItems: [
    new FormItemTextbox({
      label: '',
      placeholder: "new highlight (e.g. 'Free')",
      validators: [Validators.minLength(1), Validators.maxLength(500)],
    }),
  ],
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

export const summaryImages = new FormItemRepeater({
  key: 'summaryImages',
  path: 'summary.images',
  label: 'Details - Image URLs',
  help: 'Ensure that images at the provided URLs are royalty-free, licensed for reuse, or explicitly permitted for use, and always adhere to the usage terms and guidelines of the respective sources.',
  class: 'mb-5',
  formItems: [
    new FormItemTextbox({
      key: 'summaryImage',
      validators: [Validators.minLength(1), Validators.maxLength(500)],
    }),
  ],
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});
