import { Address } from '../base/address';

export class UserContactData {
  firstName?: string;
  lastName?: string;
  nickname?: string;

  role?: string;
  phoneHome?: string;
  phoneMobile?: string;

  // email?: string; TODO: Re-add once Mydex allows for null
  addressData?: Address;
  additionalInfo?: string;

  isLostAndMissed?: string;
  picture?: string;
  relationshipType?: string;

  ageGroup?: string;
  residence?: string;
  ethnicity?: string;

  chiNumber?: string;
  uniqueRecordNumberInCareSystem?: string;
  nationalInsuranceNumber?: string;

  otherIdentificationNumbers?: string;
  hasCareNeedsAssessment?: string;
  // careNeedsAssessmentDate?: Date; TODO: Re-add once Mydex allows for null

  careNeedsAssessmentDoneBy?: string;
  careNeedsAssessmentNotDoneReason?: string;
  hadOccupationalTherapyAssessment?: string;

  needs?: string[];
  areasOfCareProvided?: string[];
  situationSummary?: string;

  caringForThemIsVoluntary?: string;
  hasCommunityAlarm?: string;
  isSoleCarerForThem?: string;

  otherCarers?: string[];
  otherPossibleCarers?: string[];
  arrangementsForCareInPlace?: string;

  arrangementInPlaceAppointee?: string;
  arrangementInPlaceGuardianFinancial?: string;
  arrangementInPlaceGuardianWelfare?: string;

  arrangementInPlaceNamedPerson?: string;
  arrangementInPlaceWill?: string;
  arrangementInPlacePoaFinancial?: string;

  arrangementInPlacePoaContinuingWelfare?: string;
  isLivingWithThem?: string;
  theirHomeIsSuitableForThem?: string;

  theirHomeIsNotSuitableForThemDetails?: string;

  constructor(userContact?: Partial<UserContactData>) {
    this.firstName = userContact?.firstName;
    this.lastName = userContact?.lastName;
    this.nickname = userContact?.nickname;
    this.role = userContact?.role;
    this.phoneHome = userContact?.phoneHome;
    this.phoneMobile = userContact?.phoneMobile;
    // this.email = userContact?.email; TODO: Re-add once Mydex allows for null
    this.addressData = new Address(userContact?.addressData);
    this.additionalInfo = userContact?.additionalInfo;
    this.isLostAndMissed = userContact?.isLostAndMissed;
    this.picture = userContact?.picture;
    this.relationshipType = userContact?.relationshipType;
    this.ageGroup = userContact?.ageGroup;
    this.residence = userContact?.residence;
    this.ethnicity = userContact?.ethnicity;
    this.chiNumber = userContact?.chiNumber;
    this.uniqueRecordNumberInCareSystem =
      userContact?.uniqueRecordNumberInCareSystem;
    this.nationalInsuranceNumber = userContact?.nationalInsuranceNumber;
    this.otherIdentificationNumbers = userContact?.otherIdentificationNumbers;
    this.hasCareNeedsAssessment = userContact?.hasCareNeedsAssessment;
    // this.careNeedsAssessmentDate = userContact?.careNeedsAssessmentDate; TODO: Re-add once Mydex allows for null
    this.careNeedsAssessmentDoneBy = userContact?.careNeedsAssessmentDoneBy;
    this.careNeedsAssessmentNotDoneReason =
      userContact?.careNeedsAssessmentNotDoneReason;
    this.hadOccupationalTherapyAssessment =
      userContact?.hadOccupationalTherapyAssessment;
    this.needs = userContact?.needs;
    this.areasOfCareProvided = userContact?.areasOfCareProvided;
    this.situationSummary = userContact?.situationSummary;
    this.caringForThemIsVoluntary = userContact?.caringForThemIsVoluntary;
    this.hasCommunityAlarm = userContact?.hasCommunityAlarm;
    this.isSoleCarerForThem = userContact?.isSoleCarerForThem;
    this.otherCarers = userContact?.otherCarers;
    this.otherPossibleCarers = userContact?.otherPossibleCarers;
    this.arrangementsForCareInPlace = userContact?.arrangementsForCareInPlace;
    this.arrangementInPlaceAppointee = userContact?.arrangementInPlaceAppointee;
    this.arrangementInPlaceGuardianFinancial =
      userContact?.arrangementInPlaceGuardianFinancial;
    this.arrangementInPlaceGuardianWelfare =
      userContact?.arrangementInPlaceGuardianWelfare;
    this.arrangementInPlaceNamedPerson =
      userContact?.arrangementInPlaceNamedPerson;
    this.arrangementInPlaceWill = userContact?.arrangementInPlaceWill;
    this.arrangementInPlacePoaFinancial =
      userContact?.arrangementInPlacePoaFinancial;
    this.arrangementInPlacePoaContinuingWelfare =
      userContact?.arrangementInPlacePoaContinuingWelfare;
    this.isLivingWithThem = userContact?.isLivingWithThem;
    this.theirHomeIsSuitableForThem = userContact?.theirHomeIsSuitableForThem;
    this.theirHomeIsNotSuitableForThemDetails =
      userContact?.theirHomeIsNotSuitableForThemDetails;
  }
}
